import React from "react";
import './updateProfile.scss'
import Back from "../../assets/images/backarrow.svg"
import { Link } from "react-router-dom";

const UpdateProfile = () => {
    return (
        <>
            <div className="update-profile">
                <div className="login-content">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-2 text-center">
                            <div className="backpageSection">
                                <Link to="/profile">
                                    <img src={Back} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="boldhHeadingH5 text-center">Profile</h5>
                        </div>
                        <div className="col-2"></div>
                    </div>
                    <br />
                    <div className="profilepic">
                        <img
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDM2ODA0NA&ixlib=rb-1.2.1&q=85"
                            className="avatar"
                        />
                    </div>
                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Name</div>
                        <div className="profile-value">William</div>
                    </div>

                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Lastname</div>
                        <div className="profile-value">Hardy</div>
                    </div>

                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Phone No.</div>
                        <div className="profile-value">08287367346</div>
                    </div>

                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Email</div>
                        <div className="profile-value">username@gmail.com</div>
                    </div>

                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Gender</div>
                        <div className="profile-value">Male</div>
                    </div>

                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Date of Birth</div>
                        <div className="profile-value">05/06/1996</div>
                    </div>

                    <div className="profile-fields  border-bottom">
                        <div className="profile-head">Address</div>
                        <div className="profile-value">123 Hua Lum Phong</div>
                    </div>

                    <Link to="/editprofile">
                        <div className="profile-fields1">

                            <button type="submit">Edit</button>

                        </div>
                    </Link>
                    <div style={{ height: "150px" }}></div>
                </div>
            </div>
        </>
    );
};

export default UpdateProfile;
