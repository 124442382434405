import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en.json';
import thTranslation from './locales/th.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './styles.scss';
import { FormProvider } from './utils/FormContext';
import { CmsProvider } from './utils/cmsContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashRouter  } from "react-router-dom";

const storedLanguage = localStorage.getItem('i18nextLng');

i18next
    .use(Backend)
    .use(LanguageDetector)
    .init({
        lng:storedLanguage || 'th',
        fallbackLng: 'th',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: enTranslation,
            },
            th: {
                translation: thTranslation,
            },
        },
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <CmsProvider>
        <FormProvider>
            <I18nextProvider i18n={i18next}>
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"

                />
                <HashRouter >
                <App lineValidation={true}/>
                </HashRouter>
            </I18nextProvider>
        </FormProvider>
        </CmsProvider>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
