/* eslint-disable no-undef */
import React from "react";
import { useLocation } from "react-router-dom";
import "./lineLanding.scss";
import { useNavigate } from "react-router-dom";
import CustomerApiService from "../../utils/CustomerApiService";
import { toast } from "react-toastify";
import Loading from "../loading/Loading";
import { useEffect } from "react";

const LineLanding = () => {
  const navigate = useNavigate();
  let code = new URLSearchParams(window.location.search).get("code");
  let state = new URLSearchParams(window.location.search).get("state");
  if (code == null) {
    const search = useLocation().search;
    code = new URLSearchParams(search).get("code");
    state = new URLSearchParams(search).get("state");
  }
  console.log(code, state);
  // const lineIdRegex = /^[a-zA-Z0-9_-]{5,20}$/;
  useEffect(() => {
    (async () => {
      
      localStorage.setItem("lineCode", code);

      let lineWrapperData;

      lineWrapperData = await CustomerApiService.linelogin(code);
      if (lineWrapperData?.customerName && lineWrapperData?.customerId) {
        if(lineWrapperData?.mobile){
          if(lineWrapperData?.mobile != localStorage.getItem("mobile")){
             localStorage.clear();
          }
        }else{
          localStorage.clear();
        }

        if (state?.includes("refralcode_"))
        localStorage.setItem("refralCode", state?.split("_")?.[1]);
        localStorage.setItem(
          "i18nextLng",
          process.env.REACT_APP_defaultLanguage
        );
        localStorage.setItem("customerId", lineWrapperData?.customerId);
        localStorage.setItem("lineId", lineWrapperData?.lineId);
        localStorage.setItem("line_mobile", lineWrapperData?.mobile);
        const cleanUrl = window.location.href.split("?")[0]; // Remove query parameters
        window.location.href = cleanUrl;
        navigate("/main-home");
      } else {
        toast.error(lineWrapperData?.error,{toastId:"lineAuthError"});
      }
    })();
  }, [code]);
  // const handleClick = async () => {
  //     localStorage.setItem("lineCode", code)
  //     let lineWrapperData;

  //         lineWrapperData = await CustomerApiService.linelogin(code)
  //         console.log(lineWrapperData)
  //         if (lineWrapperData?.customerName && lineWrapperData?.customerId ) {
  //             localStorage.clear();
  //             localStorage.setItem("i18nextLng", process.env.REACT_APP_defaultLanguage);
  //             localStorage.setItem("customerId",lineWrapperData?.customerId)
  //             localStorage.setItem("lineId",lineWrapperData?.lineId)
  //             const cleanUrl = window.location.href.split('?')[0]; // Remove query parameters
  //             window.location.href = cleanUrl ;
  //             navigate("/main-home")
  //         }
  //         else{
  //             toast.error(lineWrapperData?.error)
  //         }

  // }
  return (
    <>
      {/* <section className="welcomeSection">
                <div className="container">

                    <div className="welcome-text-section">
                        {(code?.length>0) ?
                            <>
                                <h5 className="welcome-text-rd">Autherized Line User </h5>
                                <p>  Line Id : {code}  </p> <button className="comman-button welcomegetstarted" onClick={handleClick}>Click here</button> </> : <h5 className="welcome-text-rd">Unautherized Line User </h5>}

                    </div>
                </div>
            </section> */}
      <Loading></Loading>
    </>
  );
};
export default LineLanding;
