/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import "../edit-you-size/edit.scss"
import Edit from '../../assets/images/edit.svg'
import Reverse from '../../assets/images/reverse.svg'
import Back_arrow from '../../assets/images/backarrow.svg'
import completeImg from '../../assets/images/completed.svg'
import { getCustomer, postCustomer, resetData } from '../../redux/slices/customer/GetCustomer';
import { useNavigate } from 'react-router';
import { useCms } from '../../utils/cmsContext';
import CmsDataService from '../../utils/CmsDataService';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { resetAllData } from "../../utils/resetActions";
const EditYourSize = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { state } = location;
    localStorage.setItem("sizeIndex",state?.selectedTabIndex)
    const [editSizeProductName, setEditSizeProductName] = useState(localStorage.getItem("editSizeProductName"))
    const [editSizeProducDisplaytName, setEditSizeProducDisplaytName] = useState("")
    const { customerDetails, success, loading,postcustomerDetails } = useSelector(state => state.getCustomer);
    const { cmsData, updateCmsData } = useCms()
    const [yourSize, setYourSize] = useState([])
    const [customer, setCustomerinfo] = useState([])
    let [customFields, setCustomFields] = useState([])
    let [updatedCustomeFields, setUpdatedCustomeFields] = useState([]);
    const [availableSizeOptions, setAvailableSizeOptions] = useState([]);
    const [selectedSizeOption, setSelectedSizeOption] = useState({ display: "", value: "" })
    const [defaultSelected, setDefaultSelected] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [submitButtonActive, setSubmitButtonActive] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSizeChange = (e) => {
        if (defaultSelected?.value !== e.target.value) {
            setSubmitButtonActive(true)
        }
        else {
            setSubmitButtonActive(false)
        }
        const value = {
            display: e.target.id,
            value: e.target.value
        }
        setSelectedSizeOption(value)
    }
    const handleSubmit = () => {
        let mutableUpdatedCustomeFields = updatedCustomeFields.map(obj => ({ ...obj }));
        let mutableCustomFields = customFields.map(obj => ({ ...obj }))
        let updatedFields = [...mutableUpdatedCustomeFields]; // Create a copy of the array
        const currentIndex = updatedFields.findIndex(item => item.name === `${editSizeProductName}_current`);
        const oldIndex = updatedFields.findIndex(item => item.name === `${editSizeProductName}_old`);
        const preferIndex = updatedFields.findIndex(item => item.name === `${editSizeProductName}_prefer`);
        if (currentIndex !== -1 && oldIndex !== -1) {
            updatedFields[oldIndex].value = updatedFields.find(item => item.name === `${editSizeProductName}_current`).value;
            updatedFields[currentIndex].value = selectedSizeOption.value
            updatedFields[preferIndex].value = true
            setUpdatedCustomeFields(updatedFields);
            let finalCustomFields = [...mutableCustomFields];
            updatedFields.forEach(item1 => {
                const existingIndex = finalCustomFields.findIndex(item2 => item2.name === item1.name);
                if (existingIndex !== -1) {
                    finalCustomFields[existingIndex].value = item1.value;
                } else {
                    finalCustomFields.push(item1);
                }
            });

            // let postData = {
            //     "root": {
            //         "customer": [{
            //             "mobile": localStorage.getItem("mobile"),
            //             "custom_fields": {
            //                 "field": finalCustomFields
            //             },
            //         }
            //         ]
            //     }
            // }
            let newObj = {};

// Iterate over the array and construct the new object
finalCustomFields.forEach(item => {
    newObj[item.name] = item.value;
});
            let postData = {
                "profiles": [
                    {
                        "fields":newObj,
                        "identifiers": [
                            {
                                "type": "mobile",
                                "value": localStorage.getItem("mobile")
                            }
                        ],
                    }
                ],
                // "customFields": {
                //             "field": finalCustomFields
                // },   
            }

            dispatch(postCustomer(postData));
            console.log(postcustomerDetails)
            // if (postcustomerDetails?.success == true) {
                dispatch(resetData());
                setModalOpen(true);
                setTimeout(function(){
                    navigate("/your-size");
                },[1000])
                
            // }
             if (customerDetails?.status?.code == 401) {
                resetAllData(dispatch);
                let lang=localStorage.getItem("i18nextLng")
                let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
                let lineId = localStorage.getItem('lineId');
                localStorage.clear();
                localStorage.setItem("i18nextLng", lang);
                localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
                localStorage.setItem('lineId', lineId);
                navigate(`/${process.env.REACT_APP_redirectionPage}`);
            }




            

        } else {
            toast.error("something went wrong",{toastId:"yourSizeError"});
            navigate("/your-size")
        }

    }
    const setCustomeFields = () => {
        let updatedCustomeFieldsLocal = []
        if (cmsData?.yourSize.length > 0) {

            for (let i = 0; i < cmsData?.yourSize?.length; i++) {
                for (let j = 0; j < cmsData?.yourSize[i]?.sizechartjson?.customFieldsData.length; j++) {
                    if (editSizeProductName) {
                        if (cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName == editSizeProductName) {
                            setEditSizeProducDisplaytName(cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].displayName)
                            setAvailableSizeOptions(cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].possibleOptions)
                            const customeField_current = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_current`)
                            if (customeField_current) {
                                setSelectedSizeOption(customeField_current)
                                setDefaultSelected(customeField_current)
                            }
                        }
                    }
                    let customeField_old = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_old`)
                    let customeField_current = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_current`)
                    let customeField_prefer = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_prefer`)
                    if (!customeField_old) {
                        updatedCustomeFieldsLocal.push(
                            {
                                name: `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_old`,
                                value: ""
                            }
                        )
                    } else {
                        updatedCustomeFieldsLocal.push(customeField_old)
                    }
                    if (!customeField_current) {
                        updatedCustomeFieldsLocal.push(
                            {
                                name: `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_current`,
                                value: ""
                            }
                        )
                    } else {
                        updatedCustomeFieldsLocal.push(customeField_current)
                    }
                    if (!customeField_prefer) {
                        updatedCustomeFieldsLocal.push(
                            {
                                name: `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_prefer`,
                                value: false
                            }
                        )
                    } else {
                        updatedCustomeFieldsLocal.push(customeField_prefer)
                    }
                }
            }
        }

        setUpdatedCustomeFields(updatedCustomeFieldsLocal)
    }
    useEffect(() => {
        if (success == false) {
            (async () => {
                dispatch(getCustomer(localStorage.getItem("mobile")));
                if (cmsData.language !== localStorage.getItem("i18nextLng") || cmsData.yourSize.length == 0) {
                    const yourSize = await CmsDataService.getCmsData("yoursize");
                    if (yourSize?.status == 200) {
                        updateCmsData((prevValues) => ({
                            ...prevValues,
                            ["yourSize"]: yourSize?.data,
                            ["language"]: localStorage.getItem("i18nextLng")
                        }));
                    }
                }
            })()
        }
        else if (customerDetails.status.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }

    }, [success]);
    useEffect(() => {
        if (customerDetails?.status?.code == 200) {
            setCustomerinfo(customerDetails?.customers?.customer[0]);
            let mutableCustomerDetails = customerDetails?.customers?.customer[0].custom_fields.field
            setCustomFields(mutableCustomerDetails)
            setCustomeFields()
        }
        setYourSize(cmsData?.yourSize)
    }, [customerDetails, cmsData]);
    useEffect(() => {
        return(()=>{
            dispatch(resetData());
        })
    },[])
    const handleOutsideClick = (event) => {
        if (!event.target.closest('.modal-content')) {
            setModalOpen(false); // Close the modal if the click is outside of the modal content
        }
    };

    const handleClick = () => {
        if (modalOpen) {
            setModalOpen(!modalOpen)
            navigate("/your-size", { state: { selectedTabIndex: state.selectedTabIndex } })
        }
    }
    return (
        <>
            {editSizeProductName ?
                <section className="samelayoutpage" onClick={handleClick}>
                    <div className="container" >
                        <div className="row align-items-center border-bottom plr-15">
                            <div className="col-2 text-center">
                                <div className="backpageSection">
                                    <Link to="/your-size">
                                        <img src={Back_arrow} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="nav_heading text-center">
                                    {t('size_chart.edit')}
                                </h5>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                    <div className="your-size edit-your-size">
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-shirt" role="tabpanel" aria-labelledby="nav-shirt-tab">
                                <div className="cat-row" style={{ borderBottom: "0px" }}>
                                    <div className="container" style={{ padding: "0px 0px" }}>
                                        <div className="row align-items-center" style={{ justifyContent: "space-between", margin: "0px 0px 30px", padding: "0px 10px" }}>
                                            <div className="col-4" style={{ width: "auto" }}>
                                                <p className="">{editSizeProducDisplaytName}</p>
                                            </div>
                                            <div className="col-4 select_div" style={{ marginRight: "10px" }}>
                                                {/* <label>{t('size_chart.gq_size')}</label> */}
                                                <select style={{color:"black",padding:"9px !important"}} name="" id={selectedSizeOption?.display} value={selectedSizeOption?.value} onChange={handleSizeChange} required >
                                                    {selectedSizeOption?.value === '' && <option value="" disabled hidden style={{textAlign:"left"}}>{"-"} </option>}

                                                    {availableSizeOptions?.map((sizeOption, sizeOptionIndex) => {
                                                        return (
                                                            <option key={`sizeOption${sizeOptionIndex}`} value={sizeOption.value}>{sizeOption.display}</option>)
                                                    })}

                                                </select>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <button className={submitButtonActive ? "comman-button" : "inactive-button"} disabled={submitButtonActive ? false : true}
                                                    onClick={handleSubmit}>{t('size_chart.save')}</button>

                                                <div className={`modal  ${modalOpen ? 'show' : ''}`} style={{ display: modalOpen ? 'block' : 'none' }} aria-labelledby="exampleModalLabel" id="exampleModalCenter1" aria-hidden="true" >
                                                    <div className="modal-dialog modal-dialog-centered" onClick={handleOutsideClick} style={{ bottom: "20%" }}>
                                                        <div className="modal-content">
                                                            <div className="modal-header"></div>

                                                            <div className="modal-body">
                                                                <img className="pb-2" src={completeImg} alt="" />
                                                                <h5 className='text-black'>{t('size_chart.your_size')}<br />{t('size_chart.completely_updated')}</h5>
                                                            </div>
                                                            <div className="modal-footer"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section> : <div>{t('size_chart.esit_size_error')}</div>}
        </>
    )
}

export default EditYourSize