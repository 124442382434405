import React, { useState, useEffect,useRef } from "react";
import { Helmet } from 'react-helmet-async';
// import { Scanner, useDeviceList } from "@yudiel/react-qr-scanner";
// import Scanner from '@yudiel/react-qr-scanner';
import QrReader from 'react-qr-reader'
import "./scanQrcode.scss";
import CustomerApiService from "../../utils/CustomerApiService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import close from "../../assets/images/Icons/closed.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { resetPointHistory } from '../../redux/slices/customer/GetPointhistory';
import { resetData } from '../../redux/slices/customer/GetCustomer';
import { ErrorBoundary } from 'react-error-boundary';

function ErrorFallback() {
  return (
    <div>
      <h1>Something went wrong:</h1>
      <pre>{"message"}</pre>
      <button 
      // onClick={resetErrorBoundary}
      >Try again</button>
    </div>
  );
}
const ScanQrCode = () => {
  const reader = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [qrOneVisible, setQrOneVisible] = useState(true);
  const [qrTwoVisible, setQrTwoVisible] = useState(false);
  const [openImageInput,setOpenImageInput]=useState(false)
  const[couponRedeemed,setCouponRedeemed]=useState(false)
  const previewStyle = {
    height: 400,
    width: "100%",
  }
  //     const devices = useDeviceList();
  //   const [selectedDeviceId, setSelectedDeviceId] = useState(undefined);

  //   const handleDeviceChange = (deviceId) => {
  //     console.log(deviceId)
  //     setSelectedDeviceId(deviceId);
  //   };

  //   if (!devices || devices.length === 0  ) {
  //     return <div>No devices found</div>;
  //   }
  const openDialog = () => {
    setQrOneVisible(false);
    setQrTwoVisible(true);
    if (reader.current) {
      
      reader.current?.openImageDialog();
    } else {
      
      console.error('Reader ref is not initialized');
    }
  };

  useEffect(() => {
    if (reader.current  ) {
      if(qrTwoVisible){
        if(openImageInput){
          reader.current?.openImageDialog();
          console.log("initialized")
        }
      }
      
    }
    else {
      console.error('Reader ref is not initialized');
    }
  }, [qrTwoVisible]);

  const couponsRedeem = async (text) => {
    
    if(qrTwoVisible && text==null){
      setOpenImageInput(false)
      toast.error(t('scanqr.upload_valid_qrcode'),{toastId:"valiQrEoor"})
    }
   else if(text!== null){
    setOpenImageInput(false)
    let body = {
      root: {
        coupon: [
          {
            code: text,
            customer: {
              mobile: localStorage.getItem("mobile"),
            },
            transaction: [
              {
                amount: "",
              },
            ],
          },
        ],
      },
    };
    if(!couponRedeemed){
        setCouponRedeemed(true)
    let couponRedeemResponse = await CustomerApiService.couponRedeem(body);
    if (couponRedeemResponse?.response?.status?.code == 200) {
        dispatch(resetPointHistory());
        dispatch(resetData());
      let code=couponRedeemResponse?.response?.coupons?.coupon?.code
      navigate(`/scan-success/${code}`)

    } else {
        setModalVisible(true)
    //   toast.error(t("scanqr.can_valid_qrcode"));
    }
  }
 }
};
// useEffect(() => {
//   const getMedia = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
//       const videoElement = document.querySelector('video#localVideo');
//       videoElement.srcObject = stream;
//     } catch (error) {
//       console.error('Error accessing media devices:', error);
//     }
//   };

//   getMedia();
// }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
       <Helmet>
  <meta
    httpEquiv="Feature-Policy"
    content="camera '*';"
  />
</Helmet>
    <div>
      {/* <select id={"selectedDeviceId"} name="device" value={selectedDeviceId} onChange={(e) => handleDeviceChange(e.target.value)}>
        <option>{"select device"}</option>
        {devices?.map((option,id)=>(
        <option key={id} id={option.deviceId} name="device" value={option.deviceId}> {option.label || 'Unknown'}</option>
                                ))}
        </select> */}

      <div
        className="container"
        style={{ padding: "10px 20px 20px 20px", backgroundColor: "#B9B9B9" }}
      >
        <div
          style={{ padding: "10px 0px 20px 0px", backgroundColor: "#B9B9B9" }}
        >
          <Link to="/earn">
            <img
              src={close}
              style={{ width: "20px", cursor: "pointer" }}
              alt=""
            />
          </Link>
        </div>
                  {/* <Scanner
          ref={reader}
          legacyMode={true}
            onResult={(text, result) => couponsRedeem(text)}
            onImageLoad={() => console.log('Image loaded')}
            onError={(error) => console.log(error)}
             deviceId={selectedDeviceId}
        /> */}
        
        { !isModalVisible && qrOneVisible?
      
        <QrReader
        legacyMode={false}
        style={previewStyle}
        // setQrOneVisible(false); setQrTwoVisible(true)}
        onError={(error) => {setQrOneVisible(false); setQrTwoVisible(true)}}
        onScan={(result)=>couponsRedeem(result)}
        /> :  !isModalVisible && qrTwoVisible ?

          <QrReader
          ref={reader}
          legacyMode={true}
          style={previewStyle}
          onError={(error) =>{ setQrOneVisible(true); setQrTwoVisible(false); toast.error(error)}}
          onScan={(result)=>couponsRedeem(result)}
          />

        
           : <div style={{height:"400px",backgroundColor:"#B9B9B9"}}></div>
        } 
      </div>
     {qrTwoVisible && <div style={{textAlign:"center",margin:"20px 20px 0px 20px"}}><button style={{padding:"7px 23px 7px 23px",borderRadius:"5px", border:"0px",backgroundColor:"black",color:"white"}} onClick={()=>{setQrTwoVisible(true);setQrOneVisible(false);openDialog()}}>{t('scanqr.upload_from_device')}</button></div>}
      <div style={{ textAlign: "center", padding: "20px", height: "100%" }}>
        <h5 style={{ fontSize: "22px" }}>{t("scanqr.scantoearn_heading")}</h5>
        <p style={{ paddingTop: "15px", fontSize: "14px" }}>
          {t("scanqr.scantoearn_text")}
        </p>
      </div>


      <div
            className={`modal  ${isModalVisible ? "show" : ""}`}
            style={{ display: isModalVisible ? "block" : "none" }}
            id="exampleModalCenter1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            data-bs-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header" >
                  <img src={close} onClick={() => {setOpenImageInput(false);setOpenImageInput(true);setModalVisible(false); setCouponRedeemed(false)}}/>
                </div>
                <div className="modal-text" style={{fontWeight:"bold"}}>
                {t('scanqr.ops')}
                    </div>
                <div className="modal-text">
                  {t('scanqr.something_wrong')}
                  <br /> {t('scanqr.try_again')}
                </div>
                <div className="modal-button">
                  <div onClick={() => {setOpenImageInput(false);setModalVisible(false); setCouponRedeemed(false)}} >
                    <button
                      type="button"
                      className="btn btn-secondary modal-button"
                      data-dismiss="modal"
                    >
                      {t('scanqr.scan_again')}
                    </button>
                  </div>
                </div>
                <div onClick={() => {setCouponRedeemed(false); navigate("/earn")}}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <div className="modal-foottext">{t('scanqr.try_later')}</div>
                </div>
              </div>
            </div>
          </div>

    </div>
    </ErrorBoundary>
//  <div>
//       <video id="localVideo" autoPlay playsInline controls={false} />
//     </div> 

  );
};

export default ScanQrCode;
