import React from "react";
import logo from "../../assets/images/Logo.png";
import './loading.scss'
import { useCms } from "../../utils/cmsContext";
import CmsDataService from "../../utils/CmsDataService";
import { useEffect } from "react";
import { useState } from "react";
const Loading = () => {
  const { cmsData, updateCmsData } = useCms();
  const [homepageuicontrolData, setHomepageuicontrolData] = useState([]);
  useEffect(() => {
    (async () => {
        if (
            cmsData.language !== localStorage.getItem("i18nextLng") ||
            cmsData.welcomeRewards.length == 0
        ) {
            const welcomeRewardsData = await CmsDataService.getCmsData("welcomeslides");
            if (welcomeRewardsData?.status == 200) {
                updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["welcomeRewards"]: welcomeRewardsData?.data,
                    ["language"]: localStorage.getItem("i18nextLng"),
                }));
            }
        }

        if (
            cmsData.language !== localStorage.getItem("i18nextLng") ||
            cmsData.homepageuicontrolData.length == 0
        ) {
            const homepageuiData = await CmsDataService.getCmsData("homepageuicontrol");
            if (homepageuiData?.status == 200) {
                updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["homepageuicontrolData"]: homepageuiData?.data,
                    ["language"]: localStorage.getItem("i18nextLng"),
                }));
            }
        }

    })();
}, [])
useEffect(() => {
    setHomepageuicontrolData(cmsData?.homepageuicontrolData);
}, [cmsData])
  return (
    <div className="container loading-container">

      <div className="welcome-img-section-loading">
        <img src={homepageuicontrolData?.[0]?.brandlogo} alt="logo" width="150" />
      </div>


      {/* <div className="welcome-img-section">
            <img src={logo} alt=""/>
        </div> */}
      <div className="spinner-body" >
        <div>

          <div className="dot-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>



        </div>
      </div>
      <script>

      </script>
    </div>
  );
};

export default Loading;