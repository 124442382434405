/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getCustomeremail = createAsyncThunk(
    "customer/getbyemail",
    async(email) => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}customer/getbyemail?email=${email}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    customerEmail: {},
    error: "",
    success: false
}
const getCustomeremailSlice = createSlice({
    name: "getCustomeremail",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getCustomeremail.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getCustomeremail.fulfilled, (state,action) => {
            state.loading = false;
            state.customerEmail = action.payload;
            state.success = true;
        });
        builder.addCase(getCustomeremail.rejected,(state)=>{
            state.loading = false;
            state.error = "No data";
        });
    }
});

export {getCustomeremail};
export default getCustomeremailSlice.reducer;