/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Footer from "../../components/footer/Footer";
import Bell from "../../assets/images/bell.svg";
import Congo from "../../assets/images/congo.svg";
import World from "../../assets/images/world2.svg";
// import dailyPointsBg from "../../assets/images/daily-bg.png";
// import GqText from "../../assets/images/gq-text.png";
import { useCms } from "../../utils/cmsContext";
import QRCodeImg from "../../assets/images/qr-code.svg";
import Points3 from "../../assets/images/3-points.png";
import { useSwipeable } from "react-swipeable";
import pointsGif from "../../assets/images/Coin_formotion_1080X1080_3MB.gif";
import close from "../../assets/images/close.svg";
import closed from "../../assets/images/closed.svg";
import gqcoin from "../../assets/images/GQ-Coin.png";
import Congratsicon from "../../assets/images/Icons/Congrats.svg";
import Underwearicon from "../../assets/images/Icons/underwear.svg";
import checkedIn from "../../assets/images/checked_in.svg";
import gift from "../../assets/images/green-gift-icon.png";
// import FriendOne from "../../assets/images/friendNo1.svg"
// import FriendTwo from "../../assets/images/friendNo2.svg"
import { th } from "date-fns/locale";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lock from "../../assets/images/lock.svg";
import Carousel from "react-multi-carousel";
import Exclusive from "../../assets/images/exclusive.jpeg";
import leftArrow from "../../assets/images/Icons/arrow-left.svg";
import "react-multi-carousel/lib/styles.css";
import "./mainHome.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CmsDataService from "../../utils/CmsDataService";
import CustomerApiService from "../../utils/CustomerApiService";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  resetData,
} from "../../redux/slices/customer/GetCustomer";
import { resetPointHistory } from '../../redux/slices/customer/GetPointhistory';
import { toast } from "react-toastify";
import { getPointHistory } from "../../redux/slices/customer/GetPointhistory";
import { getReward } from "../../redux/slices/Rewards/GetReward";
import { CircularProgress } from "@mui/material";
import { resetAllData } from "../../utils/resetActions";
import Loading from "../loading/Loading";
import { DarkModeSharp } from "@mui/icons-material";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    // slidesToSlide:3
  },
};
const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.1,
    // slidesToSlide:1
  },
};
const MainHome = () => {
  const dispatch = useDispatch();
  const { cmsData, updateCmsData } = useCms();
  const { customerDetails, success, loading } = useSelector(
    (state) => state.getCustomer
  );
  const location = useLocation();
  const [homepageuicontrolData, setHomepageuicontrolData] = useState([]);
  const [isProfileVisible, setProfileVisibility] = useState(true);
  const [tierData, setTierData] = useState([]);
  const [marvelrewards, setMarvelrewards] = useState([]);
  const [notificationsData, setNotoficationsData] = useState(false);
  const [ssoLoading, setSsoLoading] = useState(false);
  const [isProfilePopUpVisible, setProfilePopUpVisibility] = useState(false);
  const [sliderIndexnew, setSliderIndexnew] = useState(0);
  const [guest, setGuest] = useState(true);
  const [news, setNews] = useState([]);
  const [customer, setCustomerinfo] = useState([]);
  const [exclusiveForYou, setExclusiveForYou] = useState([]);
  const [pointsExpiry, setpointsExpiry] = useState([]);
  const [pointData, setPointData] = useState();
  const [newArrivals, setNewArrivals] = useState([]);
  const [sampleRewards, setSampleRewards] = useState([]);
  const [seeAllFeatures, setSeeAllFeatures] = useState(
    { 1: false },
    { 2: false },
    { 3: false }
  );
  
  const [apiAssets, setapiAssets] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidthEditProfile, setContainerWidthEditProfile] = useState(0);
  const [containerHeightEditProfile, setContainerHeightEditProfile] =useState(0);
  const [apiCalled,setApiCalled]=useState(false);
  const [pointsModalOpen, setPointsModalOpen] = useState(false);
  const [tierModalOpen, setTierModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [tierBenefitsArr, settierBenefitsArr] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [dailyLoginPoints, setDailyLoginPoints] = useState(false);
  const [dailyppointsButton,setDailyPointsButton]=useState(false)
  const [currentTime, setCurrentTime] = useState(new Date());
  const [cmsLoading, setCmsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng")
  );
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const closeProfile = () => {
    setProfileVisibility(false);
  };
  const modalRef = useRef(null);

  // useEffect(() => {
  //   const modal = modalRef.current;

  //   const handleHiddenModal = (e) => {
  //     document.body.classList.remove('modal-open');
  //     console.log("in modal 3")
  //   };
  //   if (modal) {
  //     modal.addEventListener('hidden.bs.modal', handleHiddenModal);
  //   }

  //   return () => {
  //     if (modal) {
  //       document.body.classList.remove('modal-open');
  //       modal.removeEventListener('hidden.bs.modal', handleHiddenModal);
  //     }
  //     // console.log(tierModalOpen)
  //     // if(tierModalOpen)
  //     // window.location.reload()
  //   };
  // }, []);
  const closeProfilePopUp = () => {
    localStorage.removeItem("newRegistration");
    setProfilePopUpVisibility(false);
    localStorage.setItem("congratsPopup", false);
    document.body.style.overflow = "";
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir == "Right" && sliderIndexnew > 0)
        setSliderIndexnew(sliderIndexnew - 1);
      if (eventData.dir == "Right" && sliderIndexnew == 0)
        setSliderIndexnew(2);
      if (
        eventData.dir == "Left" &&
        sliderIndexnew < 2
      ) {
        console.log(sliderIndexnew + 1);
        setSliderIndexnew(sliderIndexnew + 1);
      }
      
      if (
        eventData.dir == "Left" &&
        sliderIndexnew == 2
      ) {
        setSliderIndexnew(0);
      }
    },
  });
  const handleChange = (e) => {
    setSliderIndexnew(parseInt(e.target.value, 10));
  };
  const openModal = () => {
    setProfilePopUpVisibility(true);
    document.body.style.overflow = "hidden";
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const navigateToEarn = () => {
    navigate("/how-to-earn");
  };
  const returnUnlockedTier = (reward) => {
    if (reward?.tiername?.length > 0 && reward?.tiername) {
      // if (reward?.tier && reward?.tier.length > 0 && customer?.current_slab && customer?.current_slab.length > 0) {
      let arr = reward?.tiername?.toLowerCase().trim();
      if (arr == customer?.current_slab?.toLowerCase().trim()) {
        return false;
      } else if (
        customer?.current_slab?.toLowerCase().trim() ==
        process.env.REACT_APP_tier1.toLowerCase().trim()
      ) {
        return false;
      } else if (
        customer?.current_slab?.toLowerCase().trim() ==
        process.env.REACT_APP_tier3.toLowerCase().trim()
      ) {
        return true;
      } else if (
        customer?.current_slab?.toLowerCase().trim() ==
        process.env.REACT_APP_tier2.toLowerCase().trim()
      ) {
        if (arr == process.env.REACT_APP_tier1.toLowerCase().trim()) {
          return true;
        } else if (arr == process.env.REACT_APP_tier3.toLowerCase().trim()) {
          return false;
        }
      }
      return true;
    }
    //   }else{
    //     return false
    //   }
  };

  const handleLanguageChange = (language) => {
    if (language !== localStorage.getItem("i18nextLng")) {
      updateCmsData((prevValues) => ({
        ...prevValues,
        language: language,
        news: [],
        exclusiveForYou: [],
        newArrivals: [],
        sampleRewards: [],
        tierBenefitsArr: [],
        homepageuicontrolData: [],
        assets: [],
        tierData: [],
        sizeChart: [],
        yourSize: [],
        welcomeRewards: [],
        province: [],
        notifications: [],
        redeemRewardsOnWeb: [],
        earnBenefitsBanners: [],
        itemCodeMapping: [],
        milestoneRewrads:[]
      }));
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
      resetAllData(dispatch);
    }
    setDropdownOpen(false);
  };
  const { t } = useTranslation();
  const { pointDataHis, Pointsuccess } = useSelector(
    (state) => state.getPointHistory
  );
  const { rewardDetails, rewardsuccess } = useSelector(
    (state) => state.getReward
  );
  var today = new Date();
  var curHr = today.getHours();
  var greetMsg;
  if (curHr > 3 && curHr < 12) {
    greetMsg = "global.good_morning";
  } else if (curHr >= 12 && curHr <= 17) {
    greetMsg = "global.good_afternoon";
  } else if (curHr >= 18 && curHr <= 21) {
    greetMsg = "global.good_evening";
  } else if (curHr >= 22 || curHr <= 3) {
    greetMsg = "global.good_night";
  }

  const navigateToRewards = (data) => {
    navigate(`/active-redeem/reward?id=${data.id}`);
  };

  const singleSignOn= async (redirectionUrl,id)=>{
        setSsoLoading(true)
    let arr=redirectionUrl.split("com");

   try{
    // setLoading(true)
    const data = await CustomerApiService.singleSignOn(
        { phone:localStorage.getItem("mobile"), current_link:arr[1]}
      );
      if(data?.code==200 && data?.url){
        setSsoLoading(false)
        // setLoading(false)
        //    setRedirectionUrl(data.url)
        let link = document.getElementById(id);
        link.setAttribute('href', data.url);
        // link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noreferer');
        link.click();
        // window.open(data.url, "_blank")
      }else{
        setSsoLoading(false)
        // setLoading(false)
        toast.error(data.error,{toastId:"ssoerror"})
      }
   }
   catch(e){
    // setLoading(false)
    console.log(e)
    setSsoLoading(false)
   }

}  
const getDailyPoints =async()=>{
  setDailyPointsButton(true)
  let body={
    root:{
       customer:[
          {
             mobile:localStorage.getItem("mobile"),
             firstname:customer?.firstname,
             lastname:customer?.lastname,
             type:"LOYALTY",
             custom_fields:{
                field:[
                   {
                      name:"Daily_Login_Points",
                      value:new Date()
                   }
                ]
             }
          }
       ]
    }
 }
  let getDailyPoints = await CustomerApiService.getDailyPoints(body);
    if (getDailyPoints?.response?.status?.code == 200) {
      setPointsModalOpen(true)
        dispatch(resetPointHistory());
        dispatch(resetData());
    } else {
        // setModalVisible(true)
      toast.error(t("sign_in.toaster_error"),{toastId:"getDailyPointsError"});
    }
}
  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("customerFound")
    ) {
      setGuest(false);
      if (
        localStorage.getItem("newRegistration") == true ||
        localStorage.getItem("newRegistration") == "true"
      ) {
        openModal();
      }
    }
  }, []);
  useEffect(() => {
    (async () => {
      setCmsLoading(true);
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.homepageuicontrolData.length == 0
      ) {
        const homepageuiData = await CmsDataService.getCmsData(
          "homepageuicontrol"
        );
        if (homepageuiData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["homepageuicontrolData"]: homepageuiData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.news.length == 0
      ) {
        const newsData = await CmsDataService.getCmsData("news");
        if (newsData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["news"]: newsData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.exclusiveForYou.length == 0
      ) {
        const exclusiveForYouData = await CmsDataService.getCmsData(
          "exclusiveforyou"
        );
        if (exclusiveForYouData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["exclusiveForYou"]: exclusiveForYouData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.newArrivals.length == 0
      ) {
        const newArrivalsData = await CmsDataService.getCmsData("newarrivals");
        if (newArrivalsData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["newArrivals"]: newArrivalsData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.sampleRewards.length == 0
      ) {
        const sampleRewardsData = await CmsDataService.getCmsData(
          "samplerewards"
        );
        if (sampleRewardsData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["sampleRewards"]: sampleRewardsData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.assets.length == 0
      ) {
        const assetsapiData = await CmsDataService.getCmsData("assets");

        if (assetsapiData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["assets"]: assetsapiData?.data[0],
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.tierData.length == 0
      ) {
        const cmsTierData = await CmsDataService.getCmsData("tierbenefits");
        if (cmsTierData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["tierData"]: cmsTierData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.notifications.length == 0
      ) {
        const notificationsData = await CmsDataService.getCmsData(
          "notification"
        );
        if (notificationsData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["notifications"]: notificationsData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
      setCmsLoading(false);
    })();
  }, [selectedLanguage]);
  useEffect(() => {
    if (
      (success == false || apiCalled!== true)&&
      localStorage.getItem("token") &&
      localStorage.getItem("customerFound")
    ) {
      setApiCalled(true)
      dispatch(getCustomer(localStorage.getItem("mobile")));
    }
    if (
      customerDetails?.status?.code == 401 ||
      customerDetails?.status?.code == 403
    ) {
      resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
        window.location.reload();
    }

    setCustomerinfo(customerDetails?.customers?.customer[0]);
    let userSlab=customerDetails?.customers?.customer[0].points_summaries
    .points_summary[0].currentSlab
    if(userSlab?.toLowerCase() ==
    process.env.REACT_APP_tier3?.toLowerCase()){
      setSliderIndexnew(0)
    }
    else if(userSlab?.toLowerCase() ==
    process.env.REACT_APP_tier2?.toLowerCase()){
      setSliderIndexnew(1)
    }
    else if(userSlab?.toLowerCase() ==
    process.env.REACT_APP_tier1?.toLowerCase()){
      setSliderIndexnew(2)
    }
    const profileCompleteStatus =
      customerDetails?.customers?.customer[0].custom_fields.field.find(
        function (obj) {
          return obj.name === "profile_completed";
        }
      );
    setProfileCompleted(profileCompleteStatus?.value);
    const dailyLoginStatus =
      customerDetails?.customers?.customer[0].custom_fields.field.find(
        function (obj) {
          return obj.name === "Daily_Login_Points";
        }
      );
    setDailyLoginPoints(new Date(dailyLoginStatus?.value).setHours(0,0,0,0) == new Date().setHours(0,0,0,0)?true:false);

    settierBenefitsArr(
      cmsData?.tierData?.filter(
        (item) =>
          customerDetails?.customers?.customer[0]?.current_slab ===
          item.tiername
      )
    );
  }, [customerDetails]);
  useEffect(() => {
    setHomepageuicontrolData(cmsData?.homepageuicontrolData);
    if (cmsData?.news?.length > 0) {
      let temp = cmsData?.news?.filter(
        (e) =>
          new Date() >= new Date(e.startdate) &&
          new Date() <= new Date(e.enddate)
      );
      setNews(temp?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)));
    }
    if (cmsData?.exclusiveForYou?.length > 0)
      setExclusiveForYou(
        cmsData?.exclusiveForYou?.sort((a, b) =>
          a.sequence > b.sequence ? 1 : -1
        )
      );
    if (cmsData?.newArrivals?.length > 0){
      let temp = cmsData?.newArrivals?.filter(
        (e) =>
          new Date() >= new Date(e.startdate) &&
          new Date() <= new Date(e.enddate)
      );
      setNewArrivals(
        temp?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      );
    }
    if (cmsData?.sampleRewards?.length > 0){
      let temp = cmsData?.sampleRewards?.filter(
        (e) =>
          new Date() >= new Date(e.startdate) &&
          new Date() <= new Date(e.enddate)
      );
      setSampleRewards(
        temp?.sort((a, b) =>
          a.sequence > b.sequence ? 1 : -1
        )
      );
    }
    setapiAssets(cmsData?.assets);
    const updateContainerSize = (id, ratio) => {
      const container = document.getElementById(id);
      if (container) {
        const { width } = container.getBoundingClientRect();
        setContainerWidth(width);
        setContainerHeight(width * ratio); // Adjust the aspect ratio here
      }
    };
    updateContainerSize("your-container-id", 0.5);
    window.addEventListener("resize", () =>
      updateContainerSize("your-container-id", 0.5)
    );
    updateContainerSize("check-in", 0.41);
    window.addEventListener("resize", () =>
      updateContainerSize("check-in", 0.41)
    );
    updateContainerSize("tier-background", 107 / 200);
    window.addEventListener("resize", () =>
      updateContainerSize("tier-background", 107 / 200)
    );

    const updateContainerSizeEditProfile = (id, ratio) => {
      const container = document.getElementById(id);
      if (container) {
        const { width } = container.getBoundingClientRect();
        setContainerWidthEditProfile(width);
        setContainerHeightEditProfile(width * ratio); // Adjust the aspect ratio here
      }
    };
    updateContainerSizeEditProfile("closeEditprofile", 59 / 175);
    window.addEventListener("resize", () =>
      updateContainerSizeEditProfile("closeEditprofile", 59 / 175)
    );
    updateContainerSizeEditProfile("refferFriend", 59 / 175);
    window.addEventListener("resize", () =>
      updateContainerSizeEditProfile("refferFriend", 59 / 175)
    );

    if (cmsData?.tierData?.length > 0) {
      setTierData(
        cmsData?.tierData?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      );
      settierBenefitsArr(
        cmsData?.tierData?.filter(
          (item) =>
            customerDetails?.customers?.customer[0]?.current_slab ===
            item.tiername
        )
      );
    }
    if (cmsData?.notifications?.length > 0) {
      const data = cmsData?.notifications?.filter(
        (item) =>
          item.tier
            ?.toLowerCase()
            ?.trim()
            ?.split(",")
            ?.includes(
              customerDetails?.customers?.customer[0]?.current_slab
                ?.toLowerCase()
                ?.trim()
            ) &&
          new Date(item.startdate) <= new Date() &&
          new Date(item.enddate) >= new Date() &&
          new Date(item.startdate).setHours(0, 0, 0, 0) ==
            new Date().setHours(0, 0, 0, 0)
      );
      if (data.length > 0) setNotoficationsData(true);
    }
    return () => {
      window.removeEventListener("resize", () =>
        updateContainerSize("your-container-id", 0.5)
      );
      window.removeEventListener("resize", () =>
        updateContainerSize("check-in", 0.41)
      );
      window.removeEventListener("resize", () =>
        updateContainerSize("tier-background", 107 / 200)
      );
      window.removeEventListener("resize", () =>
        updateContainerSizeEditProfile("closeEditprofile", 59 / 175)
      );
      window.removeEventListener("resize", () =>
        updateContainerSizeEditProfile("refferFriend", 59 / 175)
      );
    };
  }, [cmsData, customerDetails,guest,profileCompleted]);
  useEffect(() => {
    if (
      rewardsuccess == false &&
      localStorage.getItem("token") &&
      localStorage.getItem("customerFound")
    ) {
      dispatch(getReward());
    }
    if (rewardDetails?.length > 0) {
      let rewardDetailsDatesFilter = rewardDetails?.filter(
        (eachreward) =>
          eachreward.started == true && eachreward.expired == false
      );
      setMarvelrewards(
        rewardDetailsDatesFilter
          ?.slice()
          .sort((a, b) => (a.priority > b.priority ? 1 : -1))
          .slice(0, 2)
      );
    } else {
      setMarvelrewards([]);
    }
  }, [rewardsuccess]);
  useEffect(() => {
    if (
      Pointsuccess == false &&
      localStorage.getItem("token") &&
      localStorage.getItem("customerFound")
    ) {
      dispatch(getPointHistory());
    } else if (
      pointDataHis?.status?.code == 401 ||
      pointDataHis?.status?.code == 403
    ) {
      resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
      window.location.reload();
    } else if (pointDataHis?.expiry_schedule?.[0]?.schedule?.length > 0) {
      setpointsExpiry(pointDataHis?.expiry_schedule?.[0]?.schedule[0]);
      setPointData(pointDataHis?.status?.code);
    }
  }, [Pointsuccess]);
  useEffect(() => {
    const updateTime = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(updateTime);
  }, []);
  // const history = useHistory();
const handleHideModal=()=>{
  setQrModalOpen(false);
  setTierModalOpen(false);
}
  useEffect(() => {
    if(tierModalOpen||qrModalOpen){
      window.history.pushState(null, "", window.location.href);
    }
    window.onpopstate = function () {
      if(tierModalOpen||qrModalOpen){
      const closeQrModalButton = document.getElementById('closeqr');
      const  closeModalButton= document.getElementById('closetier');
        if (closeModalButton && tierModalOpen) {
            closeModalButton.click();
            setTierModalOpen(false)
        }
        else if (closeQrModalButton && qrModalOpen) {
          closeQrModalButton.click();
          setQrModalOpen(false)
      }
    }
    
  }
  }, [tierModalOpen,qrModalOpen]);
  return (
    <>
      {loading || cmsLoading ? (
        <div className="loader">
          <CircularProgress />
          <div> Loading....</div>
        </div>
      ) : (
        <>
          <section className="login position-relative">
            <div className="text-white position-top ">
              <div
                style={{
                  backgroundColor: `${homepageuicontrolData[0]?.headerbgcolor}`,
                }}
                className="hello d-flex justify-content-between logo-sec bg-custom"
              >
                <div className="logo">
                  <img
                    src={homepageuicontrolData[0]?.brandlogo}
                    alt="logo"
                    width="150"
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    className="langCol text-end "
                    onClick={toggleDropdown}
                    ref={dropdownRef}
                  >
                    <img src={World} alt="" />
                    <span className="">
                      {" "}
                      {selectedLanguage == "en" ? "EN" : "ไทย"} &#160;{" "}
                    </span>{" "}
                    <Link to="/notification-list">
                      {" "}
                      {notificationsData && !guest ? (
                        <div className="red-dot-notification" ></div>
                      ) : (
                        ""
                      )}
                      <img src={Bell} alt="" />
                    </Link>
                  </div>
                </div>
                <>
                  {isDropdownOpen && (
                    <div className="dropdown-options">
                      <ul style={{ paddingLeft: "0px" }}>
                        <li
                          onClick={() => handleLanguageChange("en")}
                          className={
                            selectedLanguage == "en" ? "selectedLang" : ""
                          }
                        >
                          EN
                        </li>
                        <li
                          onClick={() => handleLanguageChange("th")}
                          className={
                            selectedLanguage == "th" ? "selectedLang" : ""
                          }
                        >
                          ไทย
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url('${homepageuicontrolData[0]?.topbackgroundimage}')`,
              }}
              className="customBgimage bg-custom bg-size-adjust"
            >
              <span style={{ display: "flex" }}>
                <div
                  style={{ paddingBottom: "0px" }}
                  className="d-flex justify-content-between align-items-center container-fluid p-3"
                >
                  <div className="morning-text" style={{ overflow: "hidden" }}>
                    {!guest ? <p className="">{t(greetMsg)}!</p> : ""}
                    <p>
                      {guest ? (
                        <>
                          {" "}
                          {t("mainhome.guest")}
                          <br /> {t("mainhome.guest2")}
                        </>
                      ) : (
                        customer?.firstname
                      )}
                    </p>
                  </div>
                  {guest && (
                    <div
                      className="text-end"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{ width: "95px" }}
                        className="btn text-white my-2 sign-in_btn"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        {t("global.sign_in")}
                      </button>
                    </div>
                  )}
                </div>
              </span>
              <div className="onpage-load">
                <div
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  className={`modal fade ${
                    isProfilePopUpVisible ? "show" : ""
                  }`}
                  style={{ display: isProfilePopUpVisible ? "block" : "none" }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0 justify-content-end">
                        <img
                          src={closed}
                          alt="Close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={closeProfilePopUp}
                        />
                      </div>
                      <div className="modal-body">
                        <img className="pb-2" src={Underwearicon} alt="icon" />
                        <h1>{t("mainhome.congrats")}</h1>
                        <h1>
                          {t("mainhome.get_free")}{" "}
                          <img
                            className="congrat-img"
                            src={Congratsicon}
                            alt=""
                          />
                        </h1>
                        <h1 className="mt-1 stoke-text">
                          {t("mainhome.cool_tech")}
                        </h1>
                      </div>
                      <div className="modal-footer border-0"></div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="container-fluid dashboard mt--1">
                {guest && (
                  <div
                    id="your-container-id"
                    style={{
                      backgroundImage: `url('${apiAssets?.guestgetitnow}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: containerHeight,
                      padding:"7px"
                    }}
                    className="bg-pink cool-section  d-flex justify-content-space"
                  >
                    <div className="img-text-area get_it_now">
                      {/* <h3 className="fw-bold">
                            {t('mainhome.free_cool_tech')}<sup className="fs-6 text">{t('mainhome.tm')}</sup>
                        </h3> */}
                      <div >
                        {/* <p className="fw-medium white-font">
                                                    {t("mainhome.just_easily")}
                                                </p> */}
                        <Link to="/signup">
                          <button className="btn bg-white-btn my-2" style={{ minWidth:"100px"}}>
                            {t("mainhome.get_it_now")}
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* <img className='hidden' src={Complete} alt="" width="50%" /> */}
                  </div>
                )}
                {!guest && !profileCompleted && (
                  <>
                    <div
                      className={`close-sec ${
                        isProfileVisible ? "" : "d-none"
                      }`}
                    >
                      <img onClick={closeProfile} src={close} alt="" />
                    </div>
                    <div
                      id="closeEditprofile"
                      style={{
                        backgroundImage: `url('${apiAssets?.completeprofileimage}')`,
                        height:
                          containerHeightEditProfile &&
                          containerHeightEditProfile != 0
                            ? containerHeightEditProfile
                            : "150px",
                      }}
                      className={`bg-pink cool-section p-3 d-flex justify-content-between ${
                        isProfileVisible ? "" : "d-none"
                      }`}
                    >
                      <div className="img-text-area complete-profile-mainhome">
                        <h3 className="fw-bold">
                          {t("mainhome.lets_complete_your_profile")}
                        </h3>
                        <p className="fw-medium">
                          {t("mainhome.dont_missout_toearn_free_points")}
                        </p>
                        <NavLink to="/editprofile">
                          <button className="btn bg-dark text-white my-2">
                            {t("mainhome.get_ten_points")}
                          </button>
                        </NavLink>
                      </div>
                      {/* <img className='hidden' src={Complete} alt="" width="27%" /> */}
                    </div>
                  </>
                )}
                {!guest && (
                  <>
                    <div
                      id="tier-background"
                      style={{
                        backgroundImage: `url('${tierBenefitsArr[0]?.tierbackgroungimage}')`,
                        height: containerHeight,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="bg-gratGradient cool-section mt-3"
                    >
                      <div className="text-right">
                        <div>
                          {customer?.mobile
                            ?.toString()
                            .replace(/\B(?=(\d{4})+(?!\d))/g, " ")}
                        </div>
                      </div>
                      <div className="tier-text-area">
                        <h3 className="memberShip_TierName py-0">
                          {customer?.current_slab}
                        </h3>
                        <p className="">
                          {t("mainhome.current_point")}{" "}
                          <span className="fw-bold text-black">
                            {customer?.loyalty_points?.toLocaleString()}
                          </span>{" "}
                          {t("mainhome.points")}
                        </p>
                        {customer?.points_summaries?.points_summary[0]
                          ?.gap_to_upgrade?.upgrade_strategy[0]
                          ?.customer_upgrade_entity_values?.gap_to_upgrade ? (
                          <p className="lastpara">
                            {t("mainhome.point_to_next_tier")}{" "}
                            {
                              customer?.points_summaries?.points_summary[0]
                                ?.gap_to_upgrade?.upgrade_strategy[0]
                                ?.customer_upgrade_entity_values?.gap_to_upgrade
                            }{" "}
                            {t("mainhome.points")}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="tier-text-area">
                        <div
                          className={` d-flex justify-content-between align-items-end ${
                            pointsExpiry.expiry_date || customer?.external_id
                              ? ""
                              : "justifyEnd"
                          }`}
                        >
                          <div>
                            {pointsExpiry.expiry_date && (
                              <p className="lastpara">
                                {pointsExpiry.points}{" "}
                                {t("mainhome.points_will_expired_by")}{" "}
                                {localStorage.getItem("i18nextLng") == "th"
                                  ? format(
                                      new Date(pointsExpiry.expiry_date),
                                      "dd/MM/yy",
                                      { locale: th }
                                    )
                                  : format(
                                      new Date(pointsExpiry.expiry_date),
                                      "dd/MM/yy"
                                    )}
                              </p>
                            )}
                            {customer?.external_id && (
                              <p className="lastpara">
                                {t("mainhome.card_id")}
                                {": "}
                                {customer.external_id}
                              </p>
                            )}
                          </div>
                          <div
                            className="qr-sec shadow"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal_qr"
                            // data-bs-backdrop="false"
                            onClick={()=>{setQrModalOpen(true)}}
                          >
                            <img src={QRCodeImg} alt="" width="22px" />
                            <div
                              className="modal "
                              id="exampleModal_qr"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                              data-bs-backdrop="false"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    {localStorage.getItem("mobile") && (
                                      <QRCode
                                        value={localStorage.getItem("mobile")}
                                        size={128}
                                      ></QRCode>
                                    )}
                                    <p className="qr-code-digit">
                                      {customer?.mobile
                                        ?.toString()
                                        .replace(/\B(?=(\d{4})+(?!\d))/g, " ")}
                                    </p>
                                  </div>
                                  <div className="modal-footer">
                                    <h6>{t("mainhome.scan_to_get_points")}</h6>
                                    <div onClick={()=>{setQrModalOpen(false)}}>
                                    <img
                                      className="pb-2"
                                      src={close}
                                      alt=""
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      id="closeqr" 
                                    />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="seeMoreTierseeMoreTier" >
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal_currentMember"
                        onClick={()=>{setTierModalOpen(true)}}
                      >
                        {t("mainhome.see_tier_benefit")}
                      </span>
                    </p>
                  </>
                )}
                <div
                // ref={modalRef}
                  className="modal"
                  id="exampleModal_currentMember"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  data-bs-backdrop="false"
                >
                  <div
                    className="modal-dialog modal-dialog-centered custom-modal-dialog modal-dialog-scrollable"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="border-0 text-end modal-header">
                        <button
                        onClick={()=>{handleHideModal()}}
                          type="button"
                          className="btn-close custom-close-btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          id="closetier"
                        ></button>
                      </div>
                      <section className="sections earn bg-white">
                        <div className="friend-box-outer_mainHome">
                          <div className="container">
                            <div className="friend-box">
                              <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                              >
                                {/* <div className="carousel-inner tierLi">
                                  <div
                                    className={
                                      customer?.current_slab?.toLocaleLowerCase() ==
                                      process.env.REACT_APP_tier3.toLocaleLowerCase()
                                        ? "carousel-item active"
                                        : "carousel-item"
                                    }
                                  >
                                    <img src={tierData[0]?.tierlogo} alt="" />
                                    <h5 className="memberShip_TierName py-0">
                                      {tierData[0]?.tiername}
                                    </h5>
                                    <div className="friend-text-first">
                                      <h6>
                                        <strong>
                                          {tierData[0]?.benefitsarray?.heading}
                                        </strong>
                                      </h6>
                                      <ul>
                                        {tierData[0]?.benefitsarray?.data?.map(
                                          (element, index) => {
                                            if (
                                              (!seeAllFeatures["1"] &&
                                                index < 4) ||
                                              seeAllFeatures["1"]
                                            )
                                              return (
                                                <li key={`2${index}`}>
                                                  {element.data}
                                                </li>
                                              );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                    {tierData[0]?.benefitsarray?.data?.length >
                                      4 && !seeAllFeatures["1"] ? (
                                      <p
                                        style={{
                                          padding: "5px 5px 20px 5px",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          setSeeAllFeatures(
                                            (seeAllFeature) => ({
                                              ...seeAllFeature,
                                              ["1"]: true,
                                            })
                                          );
                                        }}
                                      >
                                        {" "}
                                        {t("profile.see_all_featues")}
                                      </p>
                                    ) : tierData[0]?.benefitsarray?.data
                                        ?.length > 4 && seeAllFeatures["1"] ? (
                                      <p
                                        style={{
                                          padding: "5px 5px 20px 5px",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          setSeeAllFeatures(
                                            (seeAllFeature) => ({
                                              ...seeAllFeature,
                                              ["1"]: false,
                                            })
                                          );
                                        }}
                                      >
                                        {" "}
                                        {t("profile.see_few_features")}
                                      </p>
                                    ) : (
                                      <p
                                        style={{ padding: "5px 5px 20px 5px" }}
                                      ></p>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      customer?.current_slab?.toLocaleLowerCase() ==
                                      process.env.REACT_APP_tier2.toLocaleLowerCase()
                                        ? "carousel-item active"
                                        : "carousel-item"
                                    }
                                  >
                                    <img src={tierData[1]?.tierlogo} alt="" />
                                    <h5 className="memberShip_TierName py-0">
                                      {tierData[1]?.tiername}
                                    </h5>
                                    {returnUnlockedTier(tierData?.[1]) && (
                                      <p className="unlock_tier d-flex align-items-center">
                                        {" "}
                                        <img
                                          src={Lock}
                                          alt=""
                                          style={{
                                            paddingRight: "5px",
                                            paddingBottom: "2px",
                                          }}
                                        />{" "}
                                        {t("mainhome.unlock_tier")}
                                        {"  "}
                                        <span
                                          onClick={navigateToEarn}
                                          data-bs-dismiss="modal"
                                          style={{
                                            textDecoration: "underline",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {" "}
                                          {t("mainhome.lets_see_how_to_earn")}
                                        </span>
                                      </p>
                                    )}
                                  
                                    <div className="friend-text-first">
                                      <h6>
                                        <strong>
                                          {tierData[1]?.benefitsarray?.heading}
                                        </strong>
                                      </h6>
                                      <ul>
                                        {tierData[1]?.benefitsarray?.data?.map(
                                          (element, index) => {
                                            if (
                                              (!seeAllFeatures["2"] &&
                                                index < 4) ||
                                              seeAllFeatures["2"]
                                            )
                                              return (
                                                <li key={`2${index}`}>
                                                  {element.data}
                                                </li>
                                              );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                    {tierData[1]?.benefitsarray?.data?.length >
                                      4 && !seeAllFeatures["2"] ? (
                                      <p
                                        style={{
                                          padding: "5px 5px 20px 5px",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          setSeeAllFeatures(
                                            (seeAllFeature) => ({
                                              ...seeAllFeature,
                                              ["2"]: true,
                                            })
                                          );
                                        }}
                                      >
                                        {" "}
                                        {t("profile.see_all_featues")}
                                      </p>
                                    ) : tierData[1]?.benefitsarray?.data
                                        ?.length > 4 && seeAllFeatures["2"] ? (
                                      <p
                                        style={{
                                          padding: "5px 5px 20px 5px",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          setSeeAllFeatures(
                                            (seeAllFeature) => ({
                                              ...seeAllFeature,
                                              ["2"]: false,
                                            })
                                          );
                                        }}
                                      >
                                        {" "}
                                        {t("profile.see_few_features")}
                                      </p>
                                    ) : (
                                      <p
                                        style={{ padding: "5px 5px 20px 5px" }}
                                      ></p>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      customer?.current_slab?.toLocaleLowerCase() ==
                                      process.env.REACT_APP_tier1.toLocaleLowerCase()
                                        ? "carousel-item active"
                                        : "carousel-item"
                                    }
                                  >
                                    <img src={tierData[2]?.tierlogo} alt="" />
                                    <h5 className="memberShip_TierName py-0">
                                      {tierData[2]?.tiername}
                                    </h5>
                                    {returnUnlockedTier(tierData?.[2]) && (
                                      <p className="unlock_tier d-flex align-items-center">
                                        {" "}
                                        <img
                                          src={Lock}
                                          alt=""
                                          style={{
                                            paddingRight: "5px",
                                            paddingBottom: "2px",
                                          }}
                                        />{" "}
                                        {t("mainhome.unlock_tier")}
                                        {"  "}
                                        <span
                                          onClick={navigateToEarn}
                                          data-bs-dismiss="modal"
                                          style={{
                                            textDecoration: "underline",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {" "}
                                          {t("mainhome.lets_see_how_to_earn")}
                                        </span>
                                      </p>
                                    )}
                                   
                                    <div className="friend-text-first">
                                      <h6>
                                        <strong>
                                          {tierData[1]?.benefitsarray?.heading}
                                        </strong>
                                      </h6>
                                      <ul>
                                        {tierData[2]?.benefitsarray?.data?.map(
                                          (element, index) => {
                                            if (
                                              (!seeAllFeatures["3"] &&
                                                index < 4) ||
                                              seeAllFeatures["3"]
                                            )
                                              return (
                                                <li key={`2${index}`}>
                                                  {element.data}
                                                </li>
                                              );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                    {tierData[2]?.benefitsarray?.data?.length >
                                      4 && !seeAllFeatures["3"] ? (
                                      <p
                                        style={{
                                          padding: "5px 5px 20px 5px",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          setSeeAllFeatures(
                                            (seeAllFeature) => ({
                                              ...seeAllFeature,
                                              ["3"]: true,
                                            })
                                          );
                                        }}
                                      >
                                        {" "}
                                        {t("profile.see_all_featues")}
                                      </p>
                                    ) : tierData[2]?.benefitsarray?.data
                                        ?.length > 4 && seeAllFeatures["3"] ? (
                                      <p
                                        style={{
                                          padding: "5px 5px 20px 5px",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          setSeeAllFeatures(
                                            (seeAllFeature) => ({
                                              ...seeAllFeature,
                                              ["3"]: false,
                                            })
                                          );
                                        }}
                                      >
                                        {" "}
                                        {t("profile.see_few_features")}
                                      </p>
                                    ) : (
                                      <p
                                        style={{ padding: "5px 5px 20px 5px" }}
                                      ></p>
                                    )}
                                  </div>
                                  <div className="carousel-indicators">
                                    <button
                                      type="button"
                                      data-bs-target="#carouselExampleIndicators"
                                      data-bs-slide-to="0"
                                      className={
                                        customer?.current_slab?.toLocaleLowerCase() ==
                                        process.env.REACT_APP_tier3.toLocaleLowerCase()
                                          ? "active"
                                          : ""
                                      }
                                     
                                      aria-current="true"
                                      aria-label="Slide 1"
                                    ></button>
                                    <button
                                      type="button"
                                      data-bs-target="#carouselExampleIndicators"
                                      data-bs-slide-to="1"
                                      className={
                                        customer?.current_slab?.toLocaleLowerCase() ==
                                        process.env.REACT_APP_tier2.toLocaleLowerCase()
                                          ? "active"
                                          : ""
                                      }
                                    
                                      aria-label="Slide 2"
                                    ></button>
                                    <button
                                      type="button"
                                      data-bs-target="#carouselExampleIndicators"
                                      data-bs-slide-to="2"
                                      className={
                                        customer?.current_slab?.toLocaleLowerCase() ==
                                        process.env.REACT_APP_tier1.toLocaleLowerCase()
                                          ? "active"
                                          : ""
                                      }
                                      
                                      aria-label="Slide 3"
                                    ></button>
                                  </div>
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselExampleIndicators"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselExampleIndicators"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button> */}
                                <div {...handlers} id="carouselExampleIndicators" className=" earn-carousals carousel slide">
              <div className="carousel">
                <div className="carousel-inner tierLi">
                {sliderIndexnew == 0 && <div
                      className="carousel-item active">
                      <img style={{width:"150px"}} src={tierData[0]?.tierlogo} alt="" />
                      {/* <h5 className="memberShip_TierName py-0">
                        {tierData[0]?.tiername}
                      </h5> */}

                                                          <div className="friend-text-first">
                                      <h6><strong>{tierData[0]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[0]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["1"] && index <4)||(seeAllFeatures["1"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[0]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["1"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["1"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[0]?.benefitsarray?.data?.length > 4 && seeAllFeatures["1"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["1"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>}
                    {sliderIndexnew == 1 && <div
                      className="carousel-item active"
                    >
                      <img style={{width:"150px"}} src={tierData[1]?.tierlogo} alt="" />
                      {/* <h5 className="memberShip_TierName py-0">
                        {tierData[1]?.tiername}
                      </h5> */}
                      {returnUnlockedTier(tierData?.[1]) && (
                        <p className="unlock_tier d-flex align-items-center">
                          {" "}
                          <img
                            src={Lock}
                            alt=""
                            style={{
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          />{" "}
                          {t("mainhome.unlock_tier")}
                          {"  "}
                          <span
                            onClick={navigateToEarn}
                            data-bs-dismiss="modal"
                            style={{
                              textDecoration: "underline",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            {t("mainhome.lets_see_how_to_earn")}
                          </span>
                        </p>
                      )}


                      <div className="friend-text-first">
                                      <h6><strong>{tierData[1]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[1]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["2"] && index <4)||(seeAllFeatures["2"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[1]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["2"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["2"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[1]?.benefitsarray?.data?.length > 4 && seeAllFeatures["2"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["2"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>}
                    {sliderIndexnew == 2 &&<div
                      className="carousel-item active"
                    >
                      <img style={{width:"150px"}} src={tierData[2]?.tierlogo} alt="" />
                      {/* <h5 className="memberShip_TierName py-0">
                        {tierData[2]?.tiername}
                      </h5> */}
                      {returnUnlockedTier(tierData?.[2]) && (
                        <p className="unlock_tier d-flex align-items-center">
                          {" "}
                          <img
                            src={Lock}
                            alt=""
                            style={{
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          />{" "}
                          {t("mainhome.unlock_tier")}
                          {"  "}
                          <span
                            onClick={navigateToEarn}
                            data-bs-dismiss="modal"
                            style={{
                              textDecoration: "underline",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            {t("mainhome.lets_see_how_to_earn")}
                          </span>
                        </p>
                      )}
                       <div className="friend-text-first">
                                      <h6><strong>{tierData[1]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[2]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["3"] && index <4)||(seeAllFeatures["3"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[2]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["3"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["3"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[2]?.benefitsarray?.data?.length > 4 && seeAllFeatures["3"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["3"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>}
                                    </div>
                </div></div>
                <div style={{ position: "static",textAlign:"center" }} >
              {tierData?.map((buttonData, buttonIndex) => {
                return (
                  <button
                    onClick={handleChange}
                    value={buttonIndex}
                    key={`button${buttonIndex}`}
                    type="button"
                    className={
                      sliderIndexnew == buttonIndex ? "earnbanner-active earn-dots" : "earn-dots"
                    }
                  ></button>
                );
              })}
            </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                </div>
              </div>
            </div>
                <section className="sections">
                  <div className="bg-light-gray">
                    <h5 className="heading_main-home text-white">
                      {t("mainhome.gqreward_foryou")}
                    </h5>
                    <div className="row">
                      <div className="col">
                        <div className="rewards" style={{ width: "100%" }}>
                          <img
                            src={apiAssets.beneficialreward}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="rewards" style={{ width: "100%" }}>
                          <img
                            src={apiAssets.beneficialreward}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="rewards" style={{ width: "100%" }}>
                          <img
                            src={apiAssets.beneficialreward}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="rewards" style={{ width: "100%" }}>
                          <img
                            src={apiAssets.beneficialreward}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
            {!guest && (
              <div
                className=" referral-program container-fluid"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <div
                  id="check-in"
                  style={{
                    backgroundImage: `url('${homepageuicontrolData[0]?.pointsbgbefore}')`,
                    borderRadius:"0px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    paddingTop:"25px"
                  }}
                  className="check_in  cool-section  text-white"
                >
                                <div className="referral-text" style={{width:"100%"}}>
                <h3 className="fw-bold" style={{color:"black"}}>{t("mainhome.daily_points_text1")}</h3>
                <p className="" style={{color:"black"}}>{!dailyLoginPoints ? t("mainhome.daily_points_text2") : t("mainhome.daily_points_text3")}</p>
                
              
                 {!dailyLoginPoints ?  <div
                    className="img-text-area referral-text-points"
                    style={{ width: "100%" }}
                  >
                    <button
                      className="btn my-3 text-white"
                      style={{ alignItems: "end !important",backgroundColor:"black" }}
                      // onClick={() => setPointsModalOpen(true)}
                      onClick={getDailyPoints}
                      disabled={dailyppointsButton}
                    >
                      {t("mainhome.check_in")}
                    </button>
                  </div> :
                  
                  <div
                    className="img-text-area referral-text-points"
                    style={{ width: "100%" }}
                  >                    <button
                  className="btn my-3 text-white"
                  style={{ alignItems: "end !important",backgroundColor:"transparent",border:"none" }}
                  
                  disabled={true}
                >
                </button></div>}
                  </div>
                  <div className="referal-program-img">
                    <div style={{display:"flex",justifyContent:"end"}}>
                  {!dailyLoginPoints ? <img src={pointsGif} style={{width:"70px",height:"70px"}}></img>:<img src={checkedIn} style={{width:"50px",height:"50px"}}></img>}
                   <img src={Points3} style={{width:"40px",height:"20px",marginTop:!dailyLoginPoints ? "10px" :"0px"}}></img>
                   </div>
                  </div>
                </div>
              </div>
            )}

            <div className="main-home-popup">
              <div
                className={`modal col-12 text-center fade ${
                  pointsModalOpen ? "show" : ""
                }`}
                style={{ display: pointsModalOpen ? "flex" : "none" ,flexDirection: "column",justifyContent: "center"}}
                aria-labelledby="exampleModalLabel"
                id="exampleModalCenter1"
                aria-hidden="true"
              >
                <div className=" ">
                  <div className="modal-content" style={{ width: "auto" }}>
                    <div className="modal-body">
                      <h5><img src={Congo}></img> </h5>
                      <h5 style={{paddingBottom:"3px"}}>{t("mainhome.congo")}</h5>
                      <p style={{fontSize:"22px"}}> {t("mainhome.got_x_points")}{" "}{process.env.REACT_APP_dailyLoginPoints}{" "}{t("mainhome.points")} </p>
                      <p style={{fontSize:"22px"}}>{t("mainhome.check_in_tmr")} </p>
                      <div className="referral-text">
                        <button
                          className="btn my-4 text-white"
                          style={{
                            alignItems: "end !important",
                            padding: "10px 20px",
                            fontSize: "18px",
                          }}
                          onClick={() => setPointsModalOpen(false)}
                        >
                          {t("mainhome.check_in_now")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!guest && (
              <div className=" referral-program-size container-fluid" style={{
                backgroundImage: `url('${homepageuicontrolData[0]?.gqsizehomepagebg}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100%",
                backgroundColor: "white",
                paddingBottom:"25px"
              }}>
                <h5 style={{color:"white"}}className="boldhHeadingH5">
                  {t("mainhome.gq_size_fit_all_of_you")}
                </h5>
                <div
                  style={{
                    backgroundImage: `url('${homepageuicontrolData[0]?.sizechangebgimage}')`,
                  }}
                  className="editsize mesurement-img cool-section d-flex justify-content-space align-items-center text-white"
                >
                  <div className="img-text-area referral-text">
                    <h3 className="fw-bold">
                      {t("mainhome.your_sizes_matter")}
                    </h3>
                    <p className="">{t("mainhome.sizes_that_perfectly_fit")}</p>
                    <Link to="/your-size">
                      <button className="btn my-2 text-white">
                        {t("mainhome.complete_your_size")}
                      </button>
                    </Link>
                  </div>
                  <div className="referal-program-img"></div>
                </div>
              </div>
            )}
            <section className="sections paddingGap-left">
              {news?.length > 0 && (
                <div className="newsPromotion">
                  <h5 className="boldhHeadingH5">
                    {t("global.news_promotion")}
                  </h5>
                  <div className="react-silder Reward-section news_silder">
                    <Carousel
                      responsive={responsive2}
                      className="reward"
                      showDots={true}
                      draggable={false}
                      swipeable={true}
                      // infinite={true}
                      removeArrowOnDeviceType={["mobile"]}
                      // slidesToScroll={3}
                      minimumTouchDrag={1}
                      transitionDuration={null}
                      // customTransition="transform 500ms ease-in-out"
                      // customTransition="transform 0ms cubic-bezier(0.25, 0.46, 0.45, 0.94)"
                    >
                      {news.map((data, index) => {
                        if (
                          new Date() >= new Date(data.startdate) &&
                          new Date() <= new Date(data.enddate)
                        ) {
                          return (
                            <div
                              key={`news${index}`}
                              className=""
                              onClick={() => {
                                data?.redirecturl
                                  ? singleSignOn(data?.redirecturl,"newsId")
                                  : "";
                              }}
                            >
                              <div className="image-box ">
                                <div className="img-tag">
                                  <img
                                  style={{borderRadius:"4px"}}
                                    src={data?.thumbnailimage}
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </Carousel>
                  </div>
                </div>
              )}
              <a id="newsId" style={{display:"none"}} ></a>
            </section>
            <section
              style={{
                backgroundImage: `url('${homepageuicontrolData[0]?.exclusiveforyoubg}')`,
              }}
              className="dif-bg"
            >
              <div className="container-fluid">
                <h5 className="boldhHeadingH5" style={{ color: "#fff" }}>
                  {t("global.exclusive_for_you")}
                </h5>
                <p>{t("mainhome.dontmiss_specialoffers")}</p>
                <div className="d-flex justify-content-space">
                  <div className="col-6">
                    <div
                      className="image-box  pr-2"
                      onClick={() => {
                        exclusiveForYou?.[0]?.redirecturl
                          ? singleSignOn(exclusiveForYou?.[0]?.redirecturl,"exclusiveId")
                          : "";
                      }}
                    >
                      <img
                        className=""
                        src={
                          exclusiveForYou?.length > 0 &&
                          new Date() >
                            new Date(exclusiveForYou?.[0]?.startdate) &&
                          new Date() < new Date(exclusiveForYou?.[0]?.enddate)
                            ? exclusiveForYou?.[0]?.thumbnailimage
                            : Exclusive
                        }
                        alt=""
                        width="100%"
                      />
                    </div>
                    <a id="exclusiveId" style={{display:"none"}} ></a>
                  </div>
                  <div className="col-6">
                    <div
                      className="image-box image-box-2"
                      onClick={() => {
                        exclusiveForYou?.[1]?.redirecturl
                          ? singleSignOn(exclusiveForYou?.[1]?.redirecturl,"exclusiveId")
                          : "";
                      }}
                    >
                      <img
                        src={
                          exclusiveForYou?.length > 0 &&
                          new Date() >
                            new Date(exclusiveForYou?.[1]?.startdate) &&
                          new Date() < new Date(exclusiveForYou?.[1]?.enddate)
                            ? exclusiveForYou?.[1]?.thumbnailimage
                            : Exclusive
                        }
                        alt=""
                        width="100%"
                      />
                    </div>
                    <a id="exclusiveId" style={{display:"none"}} ></a>
                  </div>
                </div>
              </div>
            </section>
            {newArrivals?.length >0 ?
            <section className="dif-bf new-arrival">
              <div className="container-fluid">
                <h5 className="boldhHeadingH5">{t("global.new_arrival")}</h5>
                <div className="arrival-img-silder">
                   <div className="react-silder Reward-section">
                    
                    <Carousel
                      responsive={responsive}
                      className="reward"
                      showDots={true}
                      draggable={true}
                      swipeable={true}
                      removeArrowOnDeviceType={["mobile"]}
                      // slidesToScroll={3}
                      transitionDuration={null}
                      minimumTouchDrag={1}
                      // customTransition="transform 500ms ease-in-out"
                    >
                      {newArrivals?.map((data, index) => {
                        if (
                          new Date() > new Date(data.startdate) &&
                          new Date() < new Date(data.enddate)
                        ) {
                          return (
                            <div
                              key={`newArrvals${index}`}
                              className="pe-100"
                              id="new-arrivals2"
                              onClick={() => {
                                data?.redirecturl
                                  ? singleSignOn(data?.redirecturl,"new-arrivals")
                                  : "";
                              }}
                            >
                             
                              <div className="image-box">
                                <div className="img-tag">
                                  <img
                                    src={data.thumbnailimage}
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                                <div className="img-box-text">
                                  <div className="reward-text">
                                    {data.title}
                                  </div>
                                </div>
                              </div>

                            </div>
                          );
                        }
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </section>:""}
            <a id="new-arrivals" style={{display:"none"}} >
                              </a>
            <section className="sections Reward-section">
              <div className="exclusive-box" style={{ paddingTop: "15px" }}>
                <div className="item-navigation">
                  <div>
                    <h1
                      className="boldhHeadingH5 title"
                      style={{ padding: "15px 0px", marginBottom: "0px" }}
                    >
                      {t("global.rewards")}
                    </h1>
                  </div>
                  <NavLink to="/reward">
                    <div className="fs-14">
                      {t("mainhome.all_rewards")}
                      <span>
                        <img src={leftArrow} alt="" className="rotate-image" />
                      </span>
                    </div>
                  </NavLink>
                </div>
                <div className="d-flex flex-wrap reward">
                  {guest
                    ? sampleRewards.map((data, index) => {
                        if (
                          index < 2 &&
                          new Date() > new Date(data.startdate) &&
                          new Date() < new Date(data.enddate)
                        ) {
                          return (
                            <div
                              key={`rewards${index}`}
                              className="reward-sec-col"
                              onClick={() => navigateToRewards(data)}
                            >
                              <div className="image-box">
                                <div className="RewardImgwrap">
                                  <div className="thumbnailImgwrap">
                                    <img
                                      src={data.thumbnailimage}
                                      alt=""
                                      width="100%"
                                    />
                                  </div>
                                  <div className="status-div d-flex flex-nowrap reward_button_store">
                                    <button
                                      type="button"
                                      className="btn btn-outline rewards-label-btn"
                                      style={{ marginLeft: 0 }}
                                    >
                                      {t("mainhome.special")}
                                    </button>
                                  </div>
                                </div>
                                <div className="img-box-text">
                                  <div className="reward-text">
                                    {data.title}
                                  </div>
                                  <div className="points store_channel">
                                    {data.points == "free" ||
                                    DarkModeSharp.points == 0 ? (
                                      <div className="status-div d-flex flex-nowrap reward_button_store highest">
                                        <span className="freebox">
                                          {/* <FontAwesomeIcon
                                            icon={faGift}
                                            style={{ color: "#0CAA37" }}
                                          />{" "} */}
                                          <img src={gift} style={{width:"12px",height:"12px",margin:"0px 3px 3px 0px"}}></img>
                                          <span className="greenfree">
                                            {t("rewards.free")}
                                          </span>
                                        </span>
                                        {/* <img src={gqcoin} className="GQ-Coin" /> */}
                                      </div>
                                    ) : (
                                      <>
                                        <img
                                          src={gqcoin}
                                          alt=""
                                          width="15px"
                                          style={{ paddingBottom: "5px" }}
                                        />{" "}
                                        <span className="pointBold">
                                          {" "}
                                          {data.points}
                                        </span>{" "}
                                        {t("mainhome.points")}
                                      </>
                                    )}
                                   {data.tiertag &&  <div className="status-div d-flex flex-nowrap reward_button_store highest tiertag">
                                      
                                        <button
                                          type="button"
                                          className="btn btn-outline tag"
                                          style={{ marginLeft: 0 }}
                                        >
                                          {data.tiertag}
                                        </button>
                                      
                                    </div>}
                                    <div className="status-div d-flex flex-wrap reward_button_store">
                                      {data.shopingmodes.map(
                                        (shopingmodes, id) => (
                                          <div
                                            className="categoryName"
                                            key={`shopingmodes${id}`}
                                          >
                                            {shopingmodes}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="date">
                                      <p>
                                        {t("global.expire_in")}:{" "}
                                        <span>
                                          {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(data.enddate),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(data.enddate),
                                                "dd MMM yyyy"
                                              )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    : marvelrewards?.map((item) => {
                        if (item.started == true && item.expired == false) {
                          return (
                            <div
                              className="reward-sec-col"
                              key={item.id}
                              onClick={() => navigateToRewards(item)}
                            >
                              <div className="image-box">
                                <div className="RewardImgwrap">
                                  <div className="thumbnailImgwrap">
                                    <img
                                      src={item.thumbnailUrl}
                                      alt={item.name}
                                      title={item.name}
                                      width="100%"
                                    />
                                  </div>
                                 {item?.label?.length > 0 && <div className="status-div d-flex flex-nowrap reward_button_store">
                                    <button
                                      type="button"
                                      className="btn btn-outline"
                                      style={{ marginLeft: 0 }}
                                    >
                                      {t("mainhome.special")}
                                    </button>
                                  </div>}
                                </div>
                                <div className="img-box-text">
                                  <div className="reward-text">{item.name}</div>
                                  <div className="points store_channel">

                                  {item.intouchPoints == 0 ? (
                                      <div className="status-div d-flex flex-nowrap reward_button_store highest">
                                        <span className="freebox">
                                          {/* <FontAwesomeIcon
                                            icon={faGift}
                                            style={{ color: "#0CAA37" }}
                                          />{" "} */}
                                          <img src={gift} style={{width:"12px",height:"12px",margin:"0px 3px 3px 0px"}}></img>
                                          <span className="greenfree" style={{fontSize:"10px"}}>
                                            {t("rewards.free")}
                                          </span>
                                        </span>
                                        {/* <img src={gqcoin} className="GQ-Coin" /> */}
                                      </div>
                                    ) : (
                                      <>
                                    <img
                                      src={gqcoin}
                                      alt=""
                                      width="15px"
                                      style={{ paddingBottom: "5px" }}
                                    />{" "}
                                    <span className="pointBold">
                                      {" "}
                                      {item.intouchPoints}
                                    </span>{" "}
                                    {t("mainhome.points")}
                                    </>
                                    )}

                    {/* <div className="status-div d-flex flex-nowrap reward_button_store highest">
                                      {
                                        item.tier?.length > 0
                                          ? item?.tier
                                              .split(",")
                                              .map((tierName, id) => {
                                                return (
                                                  <button
                                                    key={`tierName${id}`}
                                                    type="button"
                                                    className="btn btn-outline"
                                                    style={{ marginLeft: 0 }}
                                                  >
                                                    {tierName}
                                                  </button>
                                                );
                                              })
                                          : ""
                                        // <button type="button"
                                        //   style={{ marginLeft: 0, height: "17px", background: "transparent", border: "none" }}></button>
                                      }
                                    </div> */}


                                    <div className="status-div d-flex flex-wrap reward_button_store">
                                      {item?.categoryList?.map(
                                        (categoryList) => (
                                          <div
                                            className="categoryName"
                                            key={categoryList.id}
                                          >
                                            {categoryList.name}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="date">
                                      <p>
                                        {t("mainhome.expire_in")}{" "}
                                        <span>
                                          {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(item.endTime),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(item.endTime),
                                                "dd MMM yyyy"
                                              )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                </div>
              </div>
            </section>
          </section>
          <div className=" referral-program container-fluid">
            <h5 className="boldhHeadingH5">
              {t("mainhome.letus_tell_yourfriend")}
            </h5>
            <div
            id="refferFriend"
              style={{ backgroundImage: `url('${apiAssets.referalimage}')`, height: containerHeightEditProfile &&
              containerHeightEditProfile != 0
                ? containerHeightEditProfile
                : "150px", }}
              className="bg-pink cool-section d-flex justify-content-space align-items-center linerGradientRedBlack"
            >
              <div className="img-text-area referral-text" style={{display:"flex",flexDirection:"column",height:"100%",justifyContent:"end"}}>
                {/* <h3 className="fw-bold">{t("mainhome.referal_program")}</h3> */}
                {/* <p className="">{t("mainhome.referal_sub_text")}</p> */}
                <Link to="/tell-friend">
                  <button className="btn bg-white-btn">
                    {t("mainhome.how_it_works")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {ssoLoading?
        <div className="loading-overlay" style={{backgroundColor:"transparent"}}>
          <div className="blurred-background" style={{backgroundColor:"transparent"}}></div>
          <CircularProgress />
        </div>
        :""}
          <footer
            style={{
              // backgroundColor: `${homepageuicontrolData[0]?.footerbgcolor}`,
              backgroundImage: `url('${homepageuicontrolData[0]?.footerbgimage}')`,
            }}
            className="footerEndSp"
          >
            <div className="container">
              {/* <img src={Logo} alt="" width="60" /> */}
              <img
                src={homepageuicontrolData[0]?.brandlogo}
                alt="logo"
                width="150"
              />
              <div className="footer-text">
                <h5 className="pb-2">{t("global.address")}</h5>
                <p>
                  {t("mainhome.address_1")} <br />
                  {t("mainhome.address_2")}
                </p>
              </div>
              <div className="footer-text">
                <h5 className="pb-2">{t("mainhome.helps")}</h5>
                <p className="white-font text-decoration-none">
                  <NavLink to="/faq">{t("global.faqs")}</NavLink>
                </p>
                <p className="white-font text-decoration-none">
                  <NavLink to="/term-of-use">
                    {t("global.term_of_service")}
                  </NavLink>
                </p>
                <p className="white-font text-decoration-none">
                  <NavLink to="/privacy-policy">
                    {t("global.provacy_policy")}
                  </NavLink>
                </p>
                <p className="pt-3">{t("mainhome.gqsize_com")}</p>
              </div>
            </div>
          </footer>
          <Footer />
        </>
      )}
    </>
  );
};

export default MainHome;
