// features/index.js
import { combineReducers } from 'redux';
import GetCustomer, { postCustomer } from '../slices/customer/GetCustomer';
import getPointHistoryReducer from '../slices/customer/GetPointhistory'
import GetCustomerEmail from '../slices/customer/GetCustomerEmail';
import getOrder  from '../slices/customer/GetOrder';
import getCustomerReferral from '../slices/customer/GetReferralcode';
import nearbystore from '../slices/customer/GetStore'
import getReward  from '../slices/Rewards/GetReward';
import getRewardVoucher from '../slices/Rewards/GetRewardVoucher';
import issueReward from '../slices/Rewards/IssueReward';
import GetProfilePicture from '../slices/customer/GetProfilePicture';
import getMilestoneReward from '../slices/Rewards/GetMilestoneRewards';
const rootReducer = combineReducers({
//counter: counterReducer,
// Add more reducers as needed
    getCustomer: GetCustomer,
    getPointHistory: getPointHistoryReducer,
    getemail: GetCustomerEmail,
    getOrder: getOrder,
    getCustomerReferral: getCustomerReferral,
    nearbystore: nearbystore,
    getReward: getReward,
    getRewardVoucher: getRewardVoucher,
    issueReward: issueReward,
    getProfilePicture:GetProfilePicture,
    getMilestoneReward:getMilestoneReward
});

export default rootReducer;
