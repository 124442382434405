/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './profile.scss'
import { Link, useNavigate } from 'react-router-dom';
import coin from "../../assets/images/GQ-Coin.png";
import leftArrow from "../../assets/images/Icons/arrow-left.svg";
import closeIcon from "../../assets/images/closed.svg";
// import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import Footer from '../../components/footer/Footer';
import AuthService from '../../utils/AuthService.js';
// import GetCustomerApi from "../../utils/CustomerApiService"
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../../redux/slices/customer/GetCustomer';
import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { resetAllData } from "../../utils/resetActions";
import CustomerApiService from '../../utils/CustomerApiService';
import { useCms } from "../../utils/cmsContext";
import { getProfilePicture } from '../../redux/slices/customer/GetProfilePicture';
import profile from "../../assets/images/Default-Avatar.jpg"

const Profile = () => {
    const navigate = useNavigate();
    const { cmsData, updateCmsData } = useCms();
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [profilePictureFileName, setProfilePictureFileName] = useState("")
    const [apiCalled,setApiCalled]=useState(false);
    const dispatch = useDispatch();
    const { customerDetails, success, loading } = useSelector(state => state.getCustomer);
    const { profileImage, imageSuccess } = useSelector(state => state.getProfilePicture);
    const logoutbtn = async () => {
        const token = localStorage.getItem("token");
        const logoutApi = await AuthService.tokenExpire(token);
        if (logoutApi.status.code == 200) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            updateCmsData((prevValues) => ({
                ...prevValues,
                news: [],
                exclusiveForYou: [],
                newArrivals: [],
                sampleRewards: [],
                tierBenefitsArr: [],
                homepageuicontrolData: [],
                assets: [],
                tierData: [],
                sizeChart: [],
                yourSize: [],
                province: [],
                welcomeRewards: [],
                redeemRewardsOnWeb: [],
                earnBenefitsBanners: [],
                itemCodeMapping: [],
                milestoneRewrads:[]
            }));
            navigate("/main-home");
            
        }
        else if (logoutApi?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            updateCmsData((prevValues) => ({
                ...prevValues,
                news: [],
                exclusiveForYou: [],
                newArrivals: [],
                sampleRewards: [],
                tierBenefitsArr: [],
                homepageuicontrolData: [],
                assets: [],
                tierData: [],
                sizeChart: [],
                yourSize: [],
                province: [],
                welcomeRewards: [],
                redeemRewardsOnWeb: [],
                earnBenefitsBanners: [],
                itemCodeMapping: [],
                milestoneRewrads:[]
            }));
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
    }
    const [cusInfo, setCusInfo] = useState({
        firstname: "", tier: "", avPoint: ""
    });
    const getApi = async () => {
        if (success) {
            setCusInfo({
                firstname: customerDetails?.customers?.customer[0].firstname,
                tier: customerDetails?.customers?.customer[0].current_slab,
                avPoint: customerDetails?.customers?.customer[0].loyalty_points,
            });
            let profile_pic = customerDetails?.customers?.customer[0]?.custom_fields?.field.find((data) => data.name == "profile_pic")
            setProfilePictureFileName(profile_pic?.value)
        }
    }
    const handleClick = (key) => {
        const data = {
            customerData: customerDetails
        }
        navigate(`/${key}`, { state: data })
    }
    const { t } = useTranslation();

    // image get
    const [ppimage, setppImage] = useState({});
    useEffect(() => {
        if (imageSuccess== false && profilePictureFileName?.length > 0) {
            dispatch(getProfilePicture(profilePictureFileName))
        }
        if (profileImage.status == "true") {
            setppImage(profileImage)
        } 
        else if (profileImage?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        else {
            console.log("network error")
        }

    }, [profilePictureFileName,profileImage]);

    useEffect(() => {
        if (success == false || apiCalled!== true) {
            setApiCalled(true)
            dispatch(getCustomer(localStorage.getItem("mobile")));
        }
        else if (customerDetails.status.code == "401" || customerDetails.status.code == "403") {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        getApi();
    }, [customerDetails])
    const { firstname, tier, avPoint } = cusInfo;
    const handleSignUp = () => {
        navigate("/signup")
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        // return () => {
        //   dispatch(resetData());
        //   dispatch(resetOrderData());
        //   dispatch(resetPointHistory());
        //   dispatch(resetRewardData());
        //   dispatch(resetRewardsVouchersData());
        //   dispatch(resetReferralCode())
        // };
    }, [])

    // profilefilepick(event) {
    //     this.ppselectedFile = <File>event.target.files[0]
    //     this.profilefile_name = event.target.files[0].name;
    //     // if (event.target.files.length > 0) {
    //     //     this.fileiconshow = true;
    //     // }
    // }
    var today = new Date();
    var curHr = today.getHours();
    var greetMsg;
    if (curHr > 3 && curHr < 12) {
        greetMsg = "global.good_morning";
    } else if (curHr >= 12 && curHr <= 17) {
        greetMsg = "global.good_afternoon";
    } else if (curHr >= 18 && curHr <= 21) {
        greetMsg = "global.good_evening";
    } else if (curHr >= 22 || curHr <= 3) {
        greetMsg = "global.good_night";
    }

    const handleHideModal=()=>{
        setQrModalOpen(false);
      }
        useEffect(() => {
          if(qrModalOpen){
            window.history.pushState(null, "", window.location.href);
          }
          window.onpopstate = function () {
            if(qrModalOpen){
            const closeQrModalButton = document.getElementById('closeqr');
            if (closeQrModalButton && qrModalOpen) {
                closeQrModalButton.click();
                setQrModalOpen(false)
            }
          }
          
        }
        }, [qrModalOpen]);
    return (
        <>
            {loading ? <div className="loader">
                <CircularProgress />
                <div> Loading....</div>
            </div> :
                <>
                    <div className="profile_login-container">
                        <div className="profile_login-content">

                            {(!localStorage.getItem("token") || !localStorage.getItem("customerFound")) && <div className="profile_top" style={{ justifyContent: "space-between" }}>
                                <span style={{ display: "flex" }}>
                                    <div className="profile_top-background" ><img
                                        src={profile}
                                        className="profile_avatar" /></div>
                                    <div className="profile_profile_text">{t('mainhome.guest')}<br/>
                                        {t('mainhome.guest2')}</div>
                                </span>
                                <div className="text-end" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
                                    <button style={{ width:"95px" }} className="btn text-white my-2 sign-in_btn" onClick={handleSignUp}>{t('global.sign_in')}</button>
                                </div>
                            </div>
                            }
                            {localStorage.getItem("token") && localStorage.getItem("customerFound") && <div className="profile_top">
                                <div className="profile_top-background"><img
                                    src={ppimage.file_path ? ppimage.file_path : profile}
                                    className="profile_avatar" /></div>
                                <div className="profile_profile_text">{t(greetMsg)}!  {firstname}<br />
                                    {tier} <span className="profile_top-background" style={{ color: "#C9C9C9", fontWeight: "normal" }}>| </span>
                                    <img src={coin} style={{ width: "16px", height: "16px",marginBottom:"3px" }} className="profile_top-background" ></img> {avPoint?.toLocaleString()}</div>
                            </div>
                            }

                            <div className="profile_profile_header">{t('profile.my_account')}</div>
                            <Link to="/membership-tier">
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.membership_tier')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/viewprofile" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.profile')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/your-size" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.your_sizes')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <div onClick={() => handleClick("order-history")}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.order_history')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </div>
                            <Link to="/point-history">
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.point_history')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/reward-history">
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.reward_history')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/how-to-earn" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.how_to_earn')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/how-to-redeem" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.how_to_redeem')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/order-tracking" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.order_tracking')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <div className="profile_profile_header">{t('profile.setting')}</div>
                            {localStorage.getItem("token") && localStorage.getItem("customerFound") && <Link to="/notification" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.notification')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>}
                            <Link to="/language" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content1">
                                    <div className="profile_subheader">{t('profile.language')}</div>
                                    <div className="profile_lang_arrow">
                                        <div className="profile_lang">{localStorage.getItem("i18nextLng") == 'en' ? "English" : "ไทย" }</div>
                                        <img src={leftArrow} alt="" className="profile_arrow_image" />
                                    </div>
                                </div>
                            </Link>

                            <div className="profile_profile_header">{t('profile.about')}</div>
                            <Link to="/faq" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.faqs')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/term-of-use" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.terms_of_use')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/privacy-policy" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.privacy_policy')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/contact-us" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.contact_us')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            <Link to="/tell-friend" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.tell_a_friend')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>
                            {localStorage.getItem("token") && localStorage.getItem("customerFound") && <Link to="/delete-account" style={{ textDecoration: "none", color: "#000" }}>
                                <div className="profile_header_content">
                                    <div className="profile_subheader">{t('profile.delete_account')}</div>
                                    <img src={leftArrow} alt="" className="profile_arrow_image" />
                                </div>
                            </Link>}
                            {localStorage.getItem("token") && localStorage.getItem("customerFound") && <div className="profile_logout">
                                <div className='Logout_sec' data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={()=>{setQrModalOpen(true)}} >
                                    <span aria-label="logout" >
                                        <LogoutIcon style={{width:"21px",height:"21px"}} />
                                    </span>
                                    <span className="profile_title">{t('profile.logout')}</span>
                                </div>
                                <div className="logout_popupModal">
                                    <div id="exampleModal1" aria-labelledby="exampleModalLabel" aria-hidden="false" data-bs-backdrop="false"
                                        className="modal">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <img src={closeIcon} data-bs-dismiss="modal" id="closeqr" onClick={()=>{handleHideModal()}}/>
                                                </div>
                                                <div className="modal-text">{t('profile.logout_account')}?</div>
                                                <div className="popup-button">
                                                    <div className="modal-button1"><button type="button" className="btn btn-secondary modal-button"
                                                        data-bs-dismiss="modal">{t('profile.cancel')}</button></div>
                                                    <div className="modal-button2">
                                                        <button type="button" className="btn btn-secondary modal-button" data-bs-dismiss="modal" onClick={logoutbtn}>{t('profile.logout')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div style={{ height: "75px" }}></div>
                        </div>
                    </div>
                    <Footer></Footer>
                </>}
        </>
    )
};

export default Profile;