/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BackArrow from "../../assets/images/backarrow.svg"
import './viewProfile.scss'
// import GetCustomerApi from "../../utils/CustomerApiService";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCustomer } from '../../redux/slices/customer/GetCustomer';
import { CircularProgress } from "@mui/material";
import { resetAllData } from "../../utils/resetActions";
import CustomerApiService from '../../utils/CustomerApiService';
import { getProfilePicture } from '../../redux/slices/customer/GetProfilePicture';
import profile from "../../assets/images/Default-Avatar.jpg"
import CmsDataService from '../../utils/CmsDataService';
import { useCms } from "../../utils/cmsContext";
const ViewProfile = () => {
    // const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { cmsData, updateCmsData } = useCms();
    const { profileImage,imageSuccess} = useSelector(state => state.getProfilePicture);
    const [profilePic,setProfilePic]=useState("")
    const [provience,setProvinces]=useState([])
    const [cusInfo, setCusInfo] = useState({
        firstname: "", lastname: "", mobile: "", email: "", gender: "", dob: "", address: ""
    });
    const { customerDetails, success, loading } = useSelector(state => state.getCustomer);
    const navigate = useNavigate();
    const formatDate = (v) => {
        // let date = new Date(v);
        let date = v?.split(" ")[0];
        let finaldate = date.split("-").reverse().join("-");
        return finaldate;
    }
    const getApi = async () => {
        if (success) {
            let extFieldarr = customerDetails?.customers?.customer[0].extended_fields.field;
            let cusFieldarr = customerDetails?.customers?.customer[0].custom_fields.field;
            let GenVal = extFieldarr?.find(f => f.name == "gender");
            GenVal = (GenVal == undefined ? "" : GenVal.value);
            let dobVal = extFieldarr?.find(f => f.name == "dob");
            dobVal = (dobVal == undefined ? "" : dobVal.value);
            let addressVal = cusFieldarr?.find(f => f.name == "cus_address");
            addressVal = (addressVal == undefined ? "" : addressVal.value);
            let provienceCode = cusFieldarr?.find(f => f.name == "province");
            provienceCode = (provienceCode == undefined ? "" : provienceCode.value);
            let district = cusFieldarr?.find(f => f.name == "district");
            district = (district == undefined ? "" : district.value);
            let subdistrict = cusFieldarr?.find(f => f.name == "subdistrict");
            subdistrict = (subdistrict == undefined ? "" : subdistrict.value);
            let postcode = cusFieldarr?.find(f => f.name == "postcode");
            postcode = (postcode == undefined ? "" : postcode.value);
            let ppimageVal = cusFieldarr?.find(f => f.name == "profile_pic");
            ppimageVal = (ppimageVal == undefined ? "" : ppimageVal.value);
if(provience?.length>0){
    let provienceVlaue=provience?.find(f => f.provincecode == provienceCode);
    let districtVlaue=provience?.find(f => f.districtcode == district);
    let subDistrictVlaue=provience?.find(f => f.subdistrictcode == subdistrict);
     addressVal= addressVal + (provienceVlaue?.province ? ", "+provienceVlaue?.province:"") + (districtVlaue?.district ? ", "+districtVlaue?.district:"") +(subDistrictVlaue?.subdistrict ? ", "+subDistrictVlaue?.subdistrict:"") +" "+(postcode ? postcode:"");

}
            setCusInfo({
                firstname: customerDetails?.customers?.customer[0].firstname,
                lastname: customerDetails?.customers?.customer[0].lastname,
                mobile: customerDetails?.customers?.customer[0].mobile,
                // email: customerDetails?.customers?.customer[0].email,
                email: customerDetails?.customers?.customer[0]?.email!==null ?  customerDetails?.customers?.customer[0]?.email?.includes('testgq.com') ? '':customerDetails?.customers?.customer[0].email : "",
                gender: GenVal,
                dob: dobVal,
                address: addressVal,
            });
            setProfilePic(ppimageVal)
            // setLoading(false);
        }
    }
    const { t } = useTranslation();
    useEffect(() => {
        if (success == false) {
            dispatch(getCustomer(localStorage.getItem("mobile")));
        }
        else if (customerDetails?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        
    }, [success]);

    useEffect(()=>{
        getApi();
    }, [customerDetails,provience]);
    const { firstname, lastname, mobile, email, gender, dob, address } = cusInfo;
    
    // image get
    const [ppimage, setppImage] = useState({});
    useEffect(() => {
        if(imageSuccess== false &&profilePic?.length>0 ){
        dispatch(getProfilePicture(profilePic))
        }
        if (profileImage.status == "true") {
            setppImage(profileImage)
        } 
        else if (profileImage?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }

}, [profileImage,profilePic]);

useEffect(()=>{
    (async()=>{
        if (
            cmsData.language !== localStorage.getItem("i18nextLng") ||
            cmsData?.province?.length == 0
        ) {
        const provinceApi = await CmsDataService.getCmsData("provinces");
        if (provinceApi?.status == 200) {
            updateCmsData((prevValues) => ({
                ...prevValues,
                ["province"]: provinceApi?.data,
                ["language"]: localStorage.getItem("i18nextLng"),
            }));
        }
    }
    })()
},[])
// const filteredProvince = (arr) =>{
//     const provSet = new Set();
//     return arr.filter(obj=>{
//         if(!provSet.has(obj.province)){
//             provSet.add(obj.province)
//             return true
//         }
//         return false
//     })
// }
useEffect(()=>{
    const GetProvince = cmsData?.province;
    // const filterProvinceResp = filteredProvince(GetProvince);
    setProvinces(GetProvince);
},[cmsData]);

    return (
        <>
            {loading ? <div className="loader">
                <CircularProgress />
                <div> Loading....</div>
            </div> :
                <div className="view-profile">
                    <div className="login-content">
                        <div className="row align-items-center border-bottom plr-15">
                            <div className="col-2 text-center">
                                <div className="backpageSection">
                                    <Link to="/profile">
                                        <img src={BackArrow} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="boldhHeadingH5 text-center">
                                    {t('viewprofile.profile')}
                                </h5>
                            </div>
                            <div className="col-2"></div>
                        </div><br />
                        
                        <div className="profilepic">
                            <img
                            src={ ppimage?.file_path ?  ppimage?.file_path : profile }
                            className="avatar1" /></div>
                        <div className="profile-fields border-bottom">
                            <div className="profile-head">{t('viewprofile.name')}</div>
                            <div className="profile-value" style={{width: "auto",maxWidth: "200px",textAlign: "end",overflow:"hidden"}}>{firstname}</div>
                        </div>
                        <div className="profile-fields  border-bottom">
                            <div className="profile-head">{t('viewprofile.lastname')}</div>
                            <div className="profile-value" style={{width: "auto",maxWidth: "200px",textAlign: "end",overflow:"hidden"}}>{lastname}</div>
                        </div>
                        <div className="profile-fields  border-bottom">
                            <div className="profile-head">{t('viewprofile.phoneno')}</div>
                            <div className="profile-value" style={{width: "auto",maxWidth: "200px",textAlign: "end"}}>{mobile}</div>
                        </div>
                        <div className="profile-fields  border-bottom">
                            <div className="profile-head">{t('viewprofile.email')}</div>
                            <div className="profile-value" style={{width: "auto",maxWidth: "200px",textAlign: "end",wordWrap:"break-word"}}><span className={(email == "" || email == null) ? "novalue" : ""}>{(email == "" || email == null) ? t('profile.no_info') : email}</span></div>
                        </div>
                        <div className="profile-fields  border-bottom">
                            <div className="profile-head">{t('viewprofile.gender')}</div>
                            <div className="profile-value"><span className={(gender == "" || gender == null) ? "novalue" : ""}>{(gender == "" || gender == null) ? t('profile.no_info') : gender?.toLowerCase()== 'male' ?  t('editprofile.male') : gender?.toLowerCase()=='female' ? t('editprofile.female') : gender?.toLowerCase()?.includes('transgender') ? t('editprofile.LGBTQIA+') : gender?.toLowerCase()?.includes('other') ? t('editprofile.rather_not_say'): gender }</span></div>
                        </div>
                        <div className="profile-fields  border-bottom">
                            <div className="profile-head">{t('viewprofile.dateofbirth')}</div>
                            <div className="profile-value"><span className={(dob == "" || dob == null) ? "novalue" : ""}>{(dob == "" || dob == null) ? t('profile.no_info') : formatDate(dob)}</span></div>
                        </div>
                        <div className="profile-fields  border-bottom">
                            <div className="profile-head">{t('viewprofile.address')}</div>
                            <div className="profile-value" style={{width: "auto",maxWidth: "200px",textAlign: "end"}}><span className={(address == "" || address == null || address == " ") ? "novalue" : ""}>{(address == "" || address == null || address==" ") ? t('profile.no_info') : address}</span></div>
                        </div>
                        <Link to="/editprofile">
                            <div className="profile-fields1">
                                <button type="submit">{t('viewprofile.edit')}</button>
                            </div>
                        </Link>
                        <div style={{ height: "150px" }}></div>
                    </div>
                </div>}
        </>
    );
}

export default ViewProfile;
