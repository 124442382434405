import React, { useState } from 'react'
import Back from "../../assets/images/backarrow.svg";
import './language.scss'
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { resetAllData } from '../../utils/resetActions';
import { useDispatch } from 'react-redux';
import { useCms } from "../../utils/cmsContext";
const Language = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { i18n } = useTranslation();
    const { cmsData, updateCmsData } = useCms();
    const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"))
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1);
    };
    const changeLanguage = (e) => {
        updateCmsData((prevValues) => ({
            ...prevValues,
            ["language"]: language,
            news: [],
            exclusiveForYou: [],
            newArrivals: [],
            sampleRewards: [],
            tierBenefitsArr: [],
            homepageuicontrolData: [],
            assets: [],
            tierData: [],
            sizeChart: [],
            yourSize: [],
            welcomeRewards: [],
            province: [],
            redeemRewardsOnWeb: [],
            earnBenefitsBanners: [],
            notifications:[],
            itemCodeMapping: [],
            milestoneRewrads:[]
        }));
        setLanguage(e.target.id)
        i18n.changeLanguage(e.target.id);
        resetAllData(dispatch)
    };

    return (
        <>
            <section className="samelayoutpage">
                <div className="container">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-1">
                            <div className="backpageSection" onClick={goBack}>

                                <img src={Back} alt="" />

                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">
                                {t('global.languages')}
                            </h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <section className="language">
                        <div className="form-check">
                            {language == "th" ? <input className="form-check-input" type="radio" checked name="exampleRadios" id="th" value="option1" onChange={changeLanguage} /> :
                                <input className="form-check-input" type="radio" name="exampleRadios" id="th" value="option1" onChange={changeLanguage} />}
                            <label className="form-check-label" htmlFor="th">
                                ไทย
                            </label>
                        </div>
                        <div className="form-check">
                            {language == "en" ? <input className="form-check-input" checked type="radio" name="exampleRadios" id="en" value="option2"
                                onChange={changeLanguage} /> :
                                <input className="form-check-input" type="radio" name="exampleRadios" id="en" value="option2"
                                    onChange={changeLanguage} />}
                            <label className="form-check-label" htmlFor="en">
                                English
                            </label>
                        </div>
                    </section>


                </div>
            </section>
        </>
    )
}

export default Language