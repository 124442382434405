import React, { useState } from "react";
import Back from "../../assets/images/backarrow.svg";
import Delete from "../../assets/images/delete-svgrepo-com (4).svg";
import bluedelete from "../../assets/images/delete-svgrepo-com (4) (1).svg";
import Closed from "../../assets/images/Icons/closed.svg";
import "./notificationList.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCms } from "../../utils/cmsContext";
import CmsDataService from "../../utils/CmsDataService";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import {
    getCustomer
} from "../../redux/slices/customer/GetCustomer";
import { useDispatch, useSelector } from "react-redux";
import { resetAllData } from "../../utils/resetActions";
const NotificationList = () => {
    const dispatch = useDispatch();
  const [notificationsData, setNotoficationsData] = useState([]);
  const { cmsData, updateCmsData } = useCms();
  const { customerDetails, success, loading } = useSelector(
    (state) => state.getCustomer
);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNotificationClick = (e) => {
    navigate(`/notification-details/${e?._id}`);
  };

  useEffect(() => {
    (async () => {
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData.notifications.length == 0
      ) {
        const notificationsData = await CmsDataService.getCmsData(
          "notification"
        );
        if (notificationsData?.status == 200) {
          updateCmsData((prevValues) => ({
            ...prevValues,
            ["notifications"]: notificationsData?.data,
            ["language"]: localStorage.getItem("i18nextLng"),
          }));
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (success == false ) {
        dispatch(getCustomer(localStorage.getItem("mobile")));
    }
    if (
        customerDetails?.status?.code == 401 ||
        customerDetails?.status?.code == 403
    ) {
        resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
        navigate(`/${process.env.REACT_APP_redirectionPage}`);

    }
    if (cmsData?.notifications?.length > 0) {
        
      const data = cmsData?.notifications
      ?.filter((item) =>
        item.tier.toLowerCase().trim().split(",").includes(customerDetails?.customers?.customer[0]?.current_slab.toLowerCase().trim()) &&  new Date(item.startdate) <= new Date() && new Date(item.enddate) >= new Date()
      );
      data?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
      setNotoficationsData(data);
    }
  }, [customerDetails,cmsData]);
  return (
    <>
      <section className="notification-list">
        <div className="container">
          <div className="row align-items-center border-bottom plr-15" style={{paddingRight:"0px"}}>
            <div className="col-2 backarrow " >
              <div className="backpageSection">
                <Link to="/">
                  <img src={Back} alt="" />
                </Link>
              </div>
            </div>
            <div className="col text-center" >
              <h5 className="boldhHeadingH5_topHeading text-center">
                {t("notifiction.notifiction")}
              </h5>
            </div>
            <div className="col-2 deleteicon">
              <div
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      {" "}
                      <img src={Closed} />
                    </div>
                    <div className="modal-text">Do you want to delete it?</div>
                    <div className="popup-button">
                      <div className="modal-button1">
                        <Link to="">
                          <button
                            type="button"
                            className="btn btn-secondary modal-button"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                      <div className="modal-button2">
                        <Link to="">
                          <button
                            type="button"
                            className="btn btn-secondary modal-button"
                            data-dismiss="modal"
                          >
                            Delete
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {notificationsData?.length > 0 ? (
          notificationsData?.map((notificationData, index) => {
            return (
              <div
                className="notification_challengeHistory_row"
                key={`notificationData${index}`}
                onClick={() => handleNotificationClick(notificationData)}
              >
                <div className="container">
                  <div className="d-flex justify-content-between">
                    <div style={{width:"100%"}}> 
                      <div className="noti-head">
                        <div>
                          <ul>
                            <li>
                              <span className="noti-text">
                                {notificationData?.title}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="expDate">
                        {notificationData?.startdate ? localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(notificationData?.startdate),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(notificationData?.startdate),
                                                "dd MMM yyyy"
                                              ) :""}
                          {" " }
                          <img src={Back} alt="" className="rotateimg" />
                        </div>
                      </div>
                      
                      <p dangerouslySetInnerHTML={{ __html: `${notificationData?.description?.split(/\s+/).slice(0, 20).join(" ")}` }} className="friend-text-first" style={{maxHeight:"50px",overflow:"hidden"}}></p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no_data">{t("point_history.no_data")}</div>
        )}
      </section>
    </>
  );
};

export default NotificationList;
