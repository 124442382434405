/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect,useRef } from "react";
import eyeslash from "../../assets/images/eye-slash.svg";
import Back from "../../assets/images/backarrow.svg";
import './login.scss';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { countries } from 'countries-list';
import AuthService from "../../utils/AuthService";
import eye from "../../assets/images/Icons/eye.svg";
import CustomerApiService from "../../utils/CustomerApiService";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import {resetData} from '../../redux/slices/customer/GetCustomer';
import { resetOrderData } from '../../redux/slices/customer/GetOrder';
import { resetPointHistory } from '../../redux/slices/customer/GetPointhistory';
import { resetRewardData } from '../../redux/slices/Rewards/GetReward';
import { resetRewardsVouchersData } from '../../redux/slices/Rewards/GetRewardVoucher';
import { resetReferralCode } from '../../redux/slices/customer/GetReferralcode';
import { useDispatch } from "react-redux";
import { resetstoreData } from "../../redux/slices/customer/GetStore";
import {phone} from 'phone';
const Login = () => {
    const dispatch=useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [countryCodes, setCountryCodes] = useState({})
    const [selectedCountry, setSelectedCountry] = useState({});
    const [lineNumberavailable,setLineNumberAvailable]=useState(false)
    const [type, setType] = useState('password');
    const [loginForm, setLoginForm] = useState({
        countryCode: process.env.REACT_APP_countryCode,
        phoneNo: "",
        password: "",
    });
    const [loginFormErrors, setLoginFormErrors] = useState({
        phoneNo: "",
        password: "",
    });
    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }
    const handleSelect = (countryCode) => {
        setSelectedCountry(countryCode);
        setLoginForm((prevValues) => ({
            ...prevValues,
            ["countryCode"]: countryCodes[countryCode].primary,
        }));
        if (!phone(`${countryCodes[countryCode].primary}${loginForm.phoneNo.trim().replace(/^0+/, '')}`,{strictDetection: true}).isValid ) {
            setLoginFormErrors((prevErrors) => ({
                ...prevErrors,
                ["phoneNo"]: `${t("sign_up.phone_error")}`,
            }));
        }
        else {
            setLoginFormErrors((prevErrors) => ({
                ...prevErrors,
                ["phoneNo"]: "",
            }));
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target
        let newValue = value;
        if (name === 'phoneNo') {
            newValue = value.replace(/[^0-9]/g, '');
            if (!phone(`${loginForm.countryCode}${newValue.trim().replace(/^0+/, '')}`,{strictDetection: true}).isValid ) {
                setLoginFormErrors((prevErrors) => ({
                    ...prevErrors,
                    ["phoneNo"]: `${t("sign_up.phone_error")}`,
                }));
            }
            else {
                setLoginFormErrors((prevErrors) => ({
                    ...prevErrors,
                    ["phoneNo"]: "",
                }));
            }
        }
        setLoginForm((prevValues) => ({
            ...prevValues,
            [name]: newValue.trim(),
        }));

    };
    const validateForm = () => {
        const errors = {
            phoneNo: "",
            password: "",
        };

        const regex = /^[1-9][0-9]*$/;
        if (!loginForm.phoneNo.trim()) {
            errors.phoneNo = t('sign_up.phone_required_error');
        } else if (!phone(`${loginForm.countryCode}${loginForm.phoneNo.trim().replace(/^0+/, '')}`,{strictDetection: true}).isValid) {
            errors.phoneNo = t('sign_up.phone_error');
        }
        // if (!loginForm.password.trim()) {
        //     errors.password =t('sign_up.password_required_error');
        // }
        setLoginFormErrors(errors);
        return Object.values(errors).every(error => error === "");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validation = validateForm();
        if (validation) {
            try {
                const mobile = `${loginForm.countryCode}${loginForm.phoneNo?.replace(/^0+/, '')}`.replace(/^\+/, '')
                let lang=localStorage.getItem("i18nextLng")
                let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
                let lineId = localStorage.getItem('lineId');
                localStorage.clear();
                localStorage.setItem("i18nextLng", lang);
                localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
                localStorage.setItem('lineId', lineId);
                localStorage.setItem("mobile", mobile)
                localStorage.setItem("login",true)
                const tokenGenerateResponse = await AuthService.tokenGenerate();
                if (tokenGenerateResponse?.status?.code == 200) {
                    // const passwordValidateResponse = await AuthService.passwordValidate(loginForm.password)

                    // if (!passwordValidateResponse.status.success && passwordValidateResponse.status.code == 1528) {
                    //     setLoginFormErrors((prevValues) => ({
                    //         ...prevValues,
                    //         ["password"]: t('sign_in.password_validate_error'),
                    //     }));
                    // }
                    // else if (passwordValidateResponse.status.success && passwordValidateResponse.status.code == 200) {
                    //     localStorage.setItem("token", passwordValidateResponse.auth.token)
                    //     const getCustomerResponse = await CustomerApiService.getCustomerByMobile(localStorage.getItem("mobile"))
                    //     if (getCustomerResponse.status.success === "true") {
                    //         localStorage.setItem("customerFound", true)
                    //         navigate("/main-home")
                    //     }
                    //     else if (getCustomerResponse.status.success === "false" && getCustomerResponse.status.code == 500) {
                    //         toast.error(t('sign_in.toaster_user_not_found'))
                    //     }
                    // } else if (!passwordValidateResponse.status.success) {
                    //     toast.error(passwordValidateResponse.status.message)

                    // }
                    // navigate("/otp");
                    const otpGenerateResponse = await AuthService.generateOtp()
                    navigate("/otp");
                }
                else {
                    toast.error(tokenGenerateResponse?.status?.message,{toastId:"loginError"})
                }
            } catch (e) {
                toast.error(t('sign_in.toaster_error'),{toastId:"loginError"})
                console.log(e)
            }
        }
    }
    useEffect(() => {
        const countryCodes = {};
        Object.keys(countries).forEach((countryCode) => {
            if (countryCode !== 'TH' || countryCode !== 'SG' || countryCode !== 'IN' || countryCode !== 'CN') {
                const countryData = countries[countryCode];
                const dialCode = `+${countryData.phone}`;
                countryCodes[countryCode] = { primary: dialCode, secondary: countryData.name };
            }
        });
        const newCountry = {
            "TH": {
                "primary": "+66",
                "secondary": "Thailand"
            },
            "IN": {
                "primary": "+91",
                "secondary": "India"
            },
            "SG": {
                "primary": "+65",
                "secondary": "Singapore"
            },
            "CN": {
                "primary": "+86",
                "secondary": "China"
            }
        };
        const updatedCountryCodes = { ...newCountry, ...countryCodes }
        setCountryCodes(updatedCountryCodes);
        setSelectedCountry(Object.keys(updatedCountryCodes).find(key => updatedCountryCodes[key].primary === process.env.REACT_APP_countryCode))
    }, []);

    useEffect(() => {
        let line_mobile=localStorage.getItem("line_mobile")
        if(line_mobile ){
            const { phoneNumber, countryCode } = phone(`+${line_mobile}`);
            if(phoneNumber && countryCode){
            const formattedPhoneNumber = phoneNumber.replace(countryCode, '');
            let arrayCountryCodes=Object.entries(countryCodes)
            let selectedCountryCode=arrayCountryCodes?.filter(e=>e[1].primary == countryCode)
            if(selectedCountryCode?.length>0)
            setSelectedCountry(selectedCountryCode[0][0])
            setLineNumberAvailable(true)
            setLoginForm((prevValues) => ({
            ...prevValues,
            ["countryCode"]: countryCode,
             }));
            setLoginForm((prevValues) => ({
            ...prevValues,
             ["phoneNo"]: formattedPhoneNumber,
          }));
        }
}
            return () => {
              dispatch(resetData());
              dispatch(resetOrderData());
              dispatch(resetPointHistory());
              dispatch(resetRewardData());
              dispatch(resetRewardsVouchersData());
              dispatch(resetReferralCode());
              dispatch(resetstoreData())
            };
    }, [countryCodes])
    const phoneNoRef = useRef(null);
    const changeColourSize=()=>{
        if (phoneNoRef.current && lineNumberavailable) {
            phoneNoRef.current.style.backgroundColor = "rgb(242, 242, 242)";
            phoneNoRef.current.style.color = "rgba(0, 0, 0, 0.38)";
          }
    }
    useEffect(()=>{
        changeColourSize()
    },[lineNumberavailable])
    return (
        <>
        <div style={{overflowX:"hidden"}}>
            <div className="row align-items-center border-bottom plr-15" >
                <div className="col-2 text-center">
                    <div className="backpageSection">
                        <Link to="/main-home" style={{ textDecoration: "none", color: "#000" }}>
                            <img src={Back} alt="" />
                        </Link>
                    </div>
                </div>
                <div className="col">
                    <h5 className="boldhHeadingH5_topHeading text-center">{t('sign_in.sign_in')}</h5>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="log-container">
                <div className="login-content">

                    <form>
                        <div>
                            <div className="moblabel" style={{ marginTop: "24px" }}>
                                <label htmlFor="lastname">{t('sign_in.phone_no')}</label>
                            </div>
                            <div className="mobile flag-btn">
                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    onSelect={handleSelect}
                                    countries={Object.keys(countryCodes)}
                                    customLabels={countryCodes}
                                    showSelectedLabel={true}
                                    showSecondarySelectedLabel={false}
                                    fullWidth={false}
                                    disabled={lineNumberavailable}
                                    searchable
                                />
                                <input 
                                ref={phoneNoRef}
                                readOnly={lineNumberavailable} 
                                type="text" id="phoneNo" inputMode="numeric" pattern="[0-9]*" placeholder="XXXXXXXX" name="phoneNo" style={{ height: "50px" }} value={loginForm.phoneNo} onChange={handleInputChange} />
                            </div>
                            <span className="error-message">{loginFormErrors.phoneNo}</span>
                        </div>

                        {/* <div>
                            <label htmlFor="password">{t('sign_in.password')} </label>
                            <br />
                            <div className="position-relative">
                                <input
                                    type={type}
                                    name="password"
                                    placeholder={t('sign_in.password')}
                                    value={loginForm.password}
                                    onChange={handleInputChange}
                                // autoComplete="current-password"
                                />
                                <span className="eyeicon1" onClick={handleToggle}>
                                <img src={type == "text" ?eye:eyeslash} /> 
                                </span>
                            </div>
                            <span className="error-message">{loginFormErrors.password}</span>
                            <p>
                                <NavLink to="/forgot-password" className="forgot-label" > {t('sign_in.forgot_password')}</NavLink></p>
                        </div> */}
                        <div className="savebutton">
                            <button type="submit" onClick={handleSubmit}>{t('sign_in.sign_in')}</button>
                            <p className="alternative">
                                {" "}
                                {t('sign_in.do_not_have_account')}{" "}
                                <Link to="/signup" style={{ textDecoration: "none", color: "#000" }}>
                                    <span
                                        style={{ fontWeight: "600", textDecoration: "underline" }}

                                    >
                                        {t('sign_in.sign_up')}
                                    </span>
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </>
    );
};

export default Login;
