/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getCustomerReferral = createAsyncThunk(
    "customer/getreferral",
    async() => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}customer/refereesdetails`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_mobile: localStorage.getItem("mobile"),
                }
            });
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    custRef: {},
    error: "",
    refSuccess: false
}
const getCustomerReferralSlice = createSlice({
    name: "getreferralcode",
    initialState,
    reducers:{
        resetReferralCode : (state) => {
            state.custRef = {};
            state.refSuccess=false
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerReferral.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getCustomerReferral.fulfilled, (state,action) => {
            state.loading = false;
            state.custRef = action.payload;
            state.refSuccess = true;
            
        });
        builder.addCase(getCustomerReferral.rejected,(state)=>{
            state.loading = false;
            state.error = "No data";
        });
    }
});

export {getCustomerReferral};
export const { resetReferralCode } = getCustomerReferralSlice.actions;
export default getCustomerReferralSlice.reducer;