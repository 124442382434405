import React from "react";
import back from "../../assets/images/backarrow.svg";
import close from "../../assets/images/Icons/closed.svg";
import banner from "../../assets/images/Artworks for mockup/Notification Banner.png"
import { Link, useParams } from 'react-router-dom';
import './notificationDetails.scss'
import { useCms } from "../../utils/cmsContext";
import { useEffect } from "react";
import CmsDataService from "../../utils/CmsDataService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const Contact = () => {
    const { id } = useParams();
    console.log(id)
    const [notificationsData, setNotoficationsData] = useState()
    const { cmsData, updateCmsData } = useCms();
    const { t } = useTranslation();
    useEffect(() => {
        (async () => {
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData.notifications.length == 0
            ) {
                const notificationsData = await CmsDataService.getCmsData("notification");
                if (notificationsData?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["notifications"]: notificationsData?.data,
                        ["language"]: localStorage.getItem("i18nextLng"),
                    }));
                }
            }
        })();
    }, [])
    useEffect(() => {
        console.log("useeffect",cmsData?.notifications?.length)
        if (cmsData?.notifications?.length > 0 && id)
        {
            const data=cmsData?.notifications?.filter((item) =>item._id == id)
            setNotoficationsData(data[0])
}
    }, [cmsData])
    return (
        <>
            <section className="notification-details">
                <div className="container">
                    <div className="row align-items-center border-bottom">
                        <div className="col-2">
                            <div className="backpageSection">
                                <Link to="/notification-list">
                                    <img src={back} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="boldhHeadingH5_topHeading text-center">
                            {t("notifiction.notifiction")}
                            </h5>
                        </div>
                        <div className="col-2 cross">
                            <Link to="/notification-list"> <img src={close} alt="" /></Link>
                        </div>
                    </div>
                </div>
                <div><img src={notificationsData?.banner ? notificationsData.banner : banner} alt="" width="100%" height="155px" /></div>
                <div className="container">
                    <div className="terms-text" style={{paddingTop:"0px"}}>

                        <h3 className="heading mb-0 note-head">{notificationsData?.title}</h3>
                        <div className="text-details" dangerouslySetInnerHTML= {{ __html: notificationsData?.description}}>
                        </div>
                    </div>
                </div>
                {notificationsData?.gotoevent?.length > 0 ? 
                <a href={notificationsData?.gotoevent} className="profile-fields1" style={{textDecoration:"none"}}
                //  target="_blank" 
                 rel="noreferrer">
                    <button type="submit" className="eventbutton">{t("notifiction.go_to_event")}</button></a> 
                    :""}
                <div className="bottomheight"></div>
            </section>
        </>
    );
};

export default Contact;