/* eslint-disable no-undef */
// FormContext.js
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
// import axios from "./AxiosService"
const CmsContext = createContext();

const CmsProvider = ({ children }) => {
  const [cmsData, setCmsData] = useState({
    news: [],
    exclusiveForYou: [],
    newArrivals: [],
    sampleRewards: [],
    tierBenefitsArr: [],
    homepageuicontrolData: [],
    assets: [],
    tierData: [],
    sizeChart: [],
    yourSize: [],
    welcomeRewards: [],
    province: [],
    redeemRewardsOnWeb: [],
    earnBenefitsBanners: [],
    notifications:[],
    itemCodeMapping: [],
    milestoneRewrads:[],
    language: process.env.REACT_APP_defaultLanguage
  });


  const updateCmsData = (newCmsData) => {
    setCmsData(newCmsData);
  };

  return (
    <CmsContext.Provider value={{ cmsData, updateCmsData }}>
      {children}
    </CmsContext.Provider>
  );
};
CmsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
const useCms = () => useContext(CmsContext);

export { CmsProvider, useCms };
