import React, { useEffect, useState } from "react";
import Back from "../../assets/images/backarrow_white.png";
// import FriendOne from "../../assets/images/friendNo1.svg"
// import FriendTwo from "../../assets/images/friendNo2.svg"
import Lock from "../../assets/images/lock.svg";
import "./membershipTier.scss";
import gqcoin from "../../assets/images/GQ-Coin.png";
import { Link, useNavigate } from "react-router-dom";
import CmsDataService from "../../utils/CmsDataService";
import { useSwipeable } from "react-swipeable";
// import CustomerApi from "../../utils/CustomerApiService"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../redux/slices/customer/GetCustomer";
import { CircularProgress } from "@mui/material";
import { resetAllData } from "../../utils/resetActions";
const MembershipTier = () => {
  const [tierData, setTierData] = useState([]);
  const dispatch = useDispatch();
  const [sliderIndexnew, setSliderIndexnew] = useState(0);
  const [apiCalled,setApiCalled]=useState(false);
  const navigate = useNavigate();
  const { customerDetails, success, loading } = useSelector(
    (state) => state.getCustomer
  );
  const [tierinfo, setTierinfo] = useState({
    cSlab: "",
    nSlab: "",
    avPoint: "",
    gapUpgrade: "",
  });
  const[seeAllFeatures,setSeeAllFeatures]=useState({ "1" : false },{"2":false},{ "3":false})
  const getApi = async () => {
    // const customerResp = await CustomerApi.customerGet();
    setTierinfo({
      cSlab:
        customerDetails?.customers?.customer[0].points_summaries
          .points_summary[0].currentSlab,
      nSlab:
        customerDetails?.customers?.customer[0].points_summaries
          .points_summary[0].nextSlab,
      avPoint:
        customerDetails?.customers?.customer[0].points_summaries
          .points_summary[0].loyaltyPoints,
      gapUpgrade:
        customerDetails?.customers?.customer[0].points_summaries
          .points_summary[0].gap_to_upgrade.upgrade_strategy[0]
          ?.customer_upgrade_entity_values.gap_to_upgrade,
    });
    let userSlab=customerDetails?.customers?.customer[0].points_summaries
    .points_summary[0].currentSlab
    if(userSlab?.toLowerCase() ==
    process.env.REACT_APP_tier3?.toLowerCase()){
      setSliderIndexnew(0)
    }
    else if(userSlab?.toLowerCase() ==
    process.env.REACT_APP_tier2?.toLowerCase()){
      setSliderIndexnew(1)
    }
    else if(userSlab?.toLowerCase() ==
    process.env.REACT_APP_tier1?.toLowerCase()){
      setSliderIndexnew(2)
    }
  };
  const returnUnlockedTier = (reward) => {
    if (reward?.tiername?.length > 0 && reward?.tiername) {
      // if (reward?.tier && reward?.tier.length > 0 && customer?.current_slab && customer?.current_slab.length > 0) {
      let customer = customerDetails?.customers?.customer?.[0];
      let arr = reward?.tiername?.toLowerCase().trim();
      if (arr == customer?.current_slab?.toLowerCase().trim()) {
        return false;
      } else if (
        customer?.current_slab?.toLowerCase().trim() ==
        process.env.REACT_APP_tier1.toLowerCase().trim()
      ) {
        return false;
      } else if (
        customer?.current_slab?.toLowerCase().trim() ==
        process.env.REACT_APP_tier3.toLowerCase().trim()
      ) {
        return true;
      } else if (
        customer?.current_slab?.toLowerCase().trim() ==
        process.env.REACT_APP_tier2.toLowerCase().trim()
      ) {
        if (arr == process.env.REACT_APP_tier1.toLowerCase().trim()) {
          console.log(arr, process.env.REACT_APP_tier1.toLowerCase().trim());
          return true;
        } else if (arr == process.env.REACT_APP_tier3.toLowerCase().trim()) {
          console.log(arr);
          return false;
        }
      }
      return true;
    }
    //   }else{
    //     return false
    //   }
  };
  const handleChange = (e) => {
    setSliderIndexnew(parseInt(e.target.value, 10));
  };
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir == "Right" && sliderIndexnew > 0)
        setSliderIndexnew(sliderIndexnew - 1);
      if (eventData.dir == "Right" && sliderIndexnew == 0)
        setSliderIndexnew(2);
      if (
        eventData.dir == "Left" &&
        sliderIndexnew < 2
      ) {
        console.log(sliderIndexnew + 1);
        setSliderIndexnew(sliderIndexnew + 1);
      }
      
      if (
        eventData.dir == "Left" &&
        sliderIndexnew == 2
      ) {
        setSliderIndexnew(0);
      }
    },
    // Other configuration options
  });
  const navigateToEarn = () => {
    navigate("/how-to-earn");
  };
  const { cSlab, nSlab, avPoint, gapUpgrade } = tierinfo;
  const { t } = useTranslation();
  useEffect(() => {
    if (success == false || apiCalled!== true) {
      setApiCalled(true)
      dispatch(getCustomer(localStorage.getItem("mobile")));
    } else if (customerDetails?.status?.code == 401) {
      resetAllData(dispatch);
      let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
      navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    getApi();
  }, [customerDetails]);
  useEffect(() => {
    (async () => {
      const cmsTierData = await CmsDataService.getCmsData("tierbenefits");
      if (cmsTierData?.status == 200) {
        setTierData(
          cmsTierData?.data?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
        );
      } 
    })();
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
          <div> Loading....</div>
        </div>
      ) : (

        <section className="sections earn membership">
          <div className="earn-pointRange linerGradientRedBlack">
            <div className="bg-light-gray ">
              <div className="container">
                <div className="row align-items-center plr-15">
                  <div className="col-2">
                    <div className="backpageSection">
                      <Link to="/profile">
                        <img src={Back} alt="" className="w-auto" />
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <h5 className="boldhHeadingH5 text-center">
                      {t("profile.membership_tier")}
                    </h5>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
              <div className="paddingGap-right paddingGap-left">
                <div className="points d-flex align-items-center">
                  {" "}
                  {t("membershiptier.your_curr_point")} &nbsp;{" "}
                  <img src={gqcoin} alt="" width="15px" />{" "}
                  <span className="pointBold"> &nbsp;{avPoint ? parseInt(avPoint)?.toLocaleString(): avPoint}&nbsp;</span>
                  {t("membershiptier.points")}{" "}
                </div>
                <div className="tier">
                  <h5 className="memberShip_tier_heading title"> {cSlab} </h5>
                  <h5 className="memberShip_tier_heading nextTier title">
                    {" "}
                    {nSlab}{" "}
                  </h5>
                </div>
                <div
                  role="progressbar"
                  aria-label="Success example"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  className="progress"
                >
                  <div
                    className="pointProgressBar"
                    style={{
                      width: `${(
                        (avPoint /
                          customerDetails?.customers?.customer?.[0]
                            ?.points_summaries?.points_summary?.[0]
                            ?.gap_to_upgrade?.upgrade_strategy?.[0]
                            ?.upgrade_threshold) *
                        100
                      ).toFixed(2)}%`,
                    }}
                  ></div>
                </div>
              </div>
              {gapUpgrade ? (
                <p className="nextTierText" style={{ paddingLeft: "10px" }}>
                  {t("membershiptier.earn")} {gapUpgrade}{" "}
                  {t("membershiptier.point_to_next_tier")}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="friend-box-outer">
            <div className="container">
              <div className="outer-text">
                <p>{t("membershiptier.earn_point_next_tier")}</p>
                <p >{t("membershiptier.let_see")} <span onClick={()=>{navigate("/how-to-earn")}}  style={{textDecoration:"underline"}}>{t("membershiptier.how_to_earn")}</span></p>
              </div>

              <div className="friend-box">
                <div id="carouselExampleIndicators" className="carousel slide">
                  {/* <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="0"
                      aria-current="true"
                      aria-label="Slide 1"
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier3?.toLowerCase()
                          ? "active"
                          : ""
                      }
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier2?.toLowerCase()
                          ? "active"
                          : ""
                      }
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier1?.toLowerCase()
                          ? "active"
                          : ""
                      }
                    ></button>
                  </div>
                  <div className="carousel-inner">
                    <div
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier3?.toLowerCase()
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img src={tierData[0]?.tierlogo} alt="" />
                      <h5 className="memberShip_TierName py-0">
                        {tierData[0]?.tiername}
                      </h5>

                                                          <div className="friend-text-first">
                                      <h6><strong>{tierData[0]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[0]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["1"] && index <4)||(seeAllFeatures["1"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[0]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["1"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["1"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[0]?.benefitsarray?.data?.length > 4 && seeAllFeatures["1"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["1"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>
                    <div
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier2?.toLowerCase()
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img src={tierData[1]?.tierlogo} alt="" />
                      <h5 className="memberShip_TierName py-0">
                        {tierData[1]?.tiername}
                      </h5>
                      {returnUnlockedTier(tierData?.[1]) && (
                        <p className="unlock_tier d-flex align-items-center">
                          {" "}
                          <img
                            src={Lock}
                            alt=""
                            style={{
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          />{" "}
                          {t("mainhome.unlock_tier")}
                          {"  "}
                          <span
                            onClick={navigateToEarn}
                            data-bs-dismiss="modal"
                            style={{
                              textDecoration: "underline",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            {t("mainhome.lets_see_how_to_earn")}
                          </span>
                        </p>
                      )}


                      <div className="friend-text-first">
                                      <h6><strong>{tierData[1]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[1]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["2"] && index <4)||(seeAllFeatures["2"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[1]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["2"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["2"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[1]?.benefitsarray?.data?.length > 4 && seeAllFeatures["2"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["2"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>
                    <div
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier1?.toLowerCase()
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img src={tierData[2]?.tierlogo} alt="" />
                      <h5 className="memberShip_TierName py-0">
                        {tierData[2]?.tiername}
                      </h5>
                      {returnUnlockedTier(tierData?.[2]) && (
                        <p className="unlock_tier d-flex align-items-center">
                          {" "}
                          <img
                            src={Lock}
                            alt=""
                            style={{
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          />{" "}
                          {t("mainhome.unlock_tier")}
                          {"  "}
                          <span
                            onClick={navigateToEarn}
                            data-bs-dismiss="modal"
                            style={{
                              textDecoration: "underline",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            {t("mainhome.lets_see_how_to_earn")}
                          </span>
                        </p>
                      )}
                       <div className="friend-text-first">
                                      <h6><strong>{tierData[1]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[2]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["3"] && index <4)||(seeAllFeatures["3"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[2]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["3"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["3"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[2]?.benefitsarray?.data?.length > 4 && seeAllFeatures["3"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["3"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>
                  </div> */}
                  {/* <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button> */}

<div {...handlers} id="carouselExampleIndicators" className=" earn-carousals carousel slide">
              <div className="carousel">
                <div className="carousel-inner">
                {sliderIndexnew == 0 && <div
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier3?.toLowerCase()
                          ? "carousel-item active"
                          : "carousel-item active"
                      }
                    >
                      <img style={{width:"150px"}} src={tierData[0]?.tierlogo} alt="" />
                      {/* <h5 className="memberShip_TierName py-0">
                        {tierData[0]?.tiername}
                      </h5> */}

                                                          <div className="friend-text-first">
                                      <h6><strong>{tierData[0]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[0]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["1"] && index <4)||(seeAllFeatures["1"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[0]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["1"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["1"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[0]?.benefitsarray?.data?.length > 4 && seeAllFeatures["1"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["1"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>}
                    {sliderIndexnew == 1 && <div
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier2?.toLowerCase()
                          ? "carousel-item active"
                          : "carousel-item active"
                      }
                    >
                      <img style={{width:"150px"}} src={tierData[1]?.tierlogo} alt="" />
                      {/* <h5 className="memberShip_TierName py-0">
                        {tierData[1]?.tiername}
                      </h5> */}
                      {returnUnlockedTier(tierData?.[1]) && (
                        <p className="unlock_tier d-flex align-items-center">
                          {" "}
                          <img
                            src={Lock}
                            alt=""
                            style={{
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          />{" "}
                          {t("mainhome.unlock_tier")}
                          {"  "}
                          <span
                            onClick={navigateToEarn}
                            // data-bs-dismiss="modal"
                            style={{
                              textDecoration: "underline",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            {t("mainhome.lets_see_how_to_earn")}
                          </span>
                        </p>
                      )}


                      <div className="friend-text-first">
                                      <h6><strong>{tierData[1]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[1]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["2"] && index <4)||(seeAllFeatures["2"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[1]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["2"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["2"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[1]?.benefitsarray?.data?.length > 4 && seeAllFeatures["2"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["2"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>}
                    {sliderIndexnew == 2 &&<div
                      className={
                        cSlab?.toLowerCase() ==
                        process.env.REACT_APP_tier1?.toLowerCase()
                          ? "carousel-item active"
                          : "carousel-item active"
                      }
                    >
                      <img style={{width:"150px"}} src={tierData[2]?.tierlogo} alt="" />
                      {/* <h5 className="memberShip_TierName py-0">
                        {tierData[2]?.tiername}
                      </h5> */}
                      {returnUnlockedTier(tierData?.[2]) && (
                        <p className="unlock_tier d-flex align-items-center">
                          {" "}
                          <img
                            src={Lock}
                            alt=""
                            style={{
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          />{" "}
                          {t("mainhome.unlock_tier")}
                          {"  "}
                          <span
                            onClick={navigateToEarn}
                            // data-bs-dismiss="modal"
                            style={{
                              textDecoration: "underline",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            {t("mainhome.lets_see_how_to_earn")}
                          </span>
                        </p>
                      )}
                       <div className="friend-text-first">
                                      <h6><strong>{tierData[1]?.benefitsarray?.heading}</strong></h6>
                                      <ul>
                                      {tierData[2]?.benefitsarray?.data?.map((element,index)=>{
                                        if((!seeAllFeatures["3"] && index <4)||(seeAllFeatures["3"]))
                                        return(
                                             <li key={`2${index}`}>{element.data}</li>
                                        )
                                      })}
                                      </ul>
                                    </div>
                                    {tierData[2]?.benefitsarray?.data?.length > 4 && !seeAllFeatures["3"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["3"]:true }) )}}> {t("profile.see_all_featues")}</p>: tierData[2]?.benefitsarray?.data?.length > 4 && seeAllFeatures["3"] ? <p style={{padding:"5px 5px 20px 5px",textAlign:"center"}} onClick={()=>{setSeeAllFeatures(seeAllFeature=>({ ...seeAllFeature, ["3"]:false }) )}}> {t("profile.see_few_features")}</p>:<p style={{padding:"5px 5px 20px 5px"}}></p>}
                    </div>}
                                    </div>
                </div></div>
                <div style={{ position: "static",textAlign:"center" }} >
              {tierData?.map((buttonData, buttonIndex) => {
                return (
                  <button
                    onClick={handleChange}
                    value={buttonIndex}
                    key={`button${buttonIndex}`}
                    type="button"
                    className={
                      sliderIndexnew == buttonIndex ? "earnbanner-active earn-dots" : "earn-dots"
                    }
                  ></button>
                );
              })}
            </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MembershipTier;
