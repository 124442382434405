/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getProfilePicture = createAsyncThunk(
    "image/getImage",
    async(filename) => {
        try {
            const headers = {
                cap_authorization: localStorage.getItem("token"),
                cap_identifier_value: localStorage.getItem("mobile"),
            };

            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}image?filename=` + filename, {
                headers:headers
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    profileImage: {},
    error: "",
    imageSuccess: false
}
const getProfilePictureSlice = createSlice({
    name: "getProfilePicture",
    initialState,
    reducers:{
        ProfileresetData: (state) => {
            console.log("inside resest")
            state.profileImage = {};
            state.imageSuccess=false
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getProfilePicture.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getProfilePicture.fulfilled, (state,action) => {
            state.loading = false;
            state.profileImage = action.payload;
            state.imageSuccess = true;
        });
        builder.addCase(getProfilePicture.rejected,(state)=>{
            state.loading = false;
            state.error = "No data";
            state.imageSuccess = false;
        });
    }
});

export {getProfilePicture};
export const { ProfileresetData } = getProfilePictureSlice.actions;
export default getProfilePictureSlice.reducer;