import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Back from "../../assets/images/backarrow.svg";
import './orderDetail.scss'
import invoiceImg from "../../assets/images/invoice.svg"
import { format } from 'date-fns';
import { th } from "date-fns/locale";
import { useTranslation } from 'react-i18next';
import { useCms } from '../../utils/cmsContext';
import CmsDataService from '../../utils/CmsDataService';
const OrderDetail = () => {
    // const [orderDetails,setOrderDeatils]=useState(null)
    const { cmsData, updateCmsData } = useCms()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const [itemCodeMapping, setItemCodeMapping] = useState([])
    const { state } = location;
    // setOrderDeatils(state.orderDetails)
    const profileCompleteStatus = state?.customerData?.customers?.customer[0].custom_fields.field.find(function (obj) {
        return obj.name === "cus_address";
    })
    const handleClick = () => {
        navigate('/order-history', { state: { month: state?.month, year: state?.year, customerData: state?.customerData } })
    }
    const capitalizeFirstLetter = (str) => {
        return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
      };
    useEffect(()=>{

        (async () => {
            if (cmsData.language !== localStorage.getItem("i18nextLng") || cmsData.itemCodeMapping.length == 0) {
                const itemCodeMappingData = await CmsDataService.getCmsData("itemcodemapping");
                if (itemCodeMappingData?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["itemCodeMapping"]: itemCodeMappingData?.data?.[0]?.itemdata,
                        ["language"]: localStorage.getItem("i18nextLng")
                    }));
                   
                }
            }
        })()
            setItemCodeMapping(cmsData?.itemCodeMapping)
    },[cmsData])
    return (
        <>
            <section className="orderdetils">
                <div className="container">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-1">
                            <div className="backpageSection" onClick={handleClick}>
                                {/* <Link to="/order-history"> */}
                                <img src={Back} alt="" />
                                {/* </Link> */}
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">
                            {t('order_history.order_details')}
                            </h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="order-section">
                        <div>
                            <span className="heading-gray">{t('order_history.order')}</span> <span className="order-id bold-dark"> {state?.orderDetails.number?.includes("#") ? "":"#"}{state?.orderDetails.number}</span>
                        </div>
                        <div>
                            <span className="heading-gray">{t('order_history.date')}{" "}</span>
                            {state?.orderDetails?.billing_time ?
                                (<span key="billing_time" >
                                    {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(state?.orderDetails?.billing_time),
                                                "dd/MMM/yyyy HH:mm a",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(state?.orderDetails?.billing_time),
                                                "dd/MMM/yyyy HH:mm a"
                                              )}</span>) : ""}
                        </div>
                        <div>
                            <span className="heading-gray">{t('order_history.channel')}</span> <span >{state?.orderDetails.number?.includes("#") ? t('rewards.online') : t('rewards.offline') }</span>
                        </div>
                        <div className="product-list">
                            <div className="d-flex justify-content-between bold-dark">
                                <span className="">{t('order_history.product_list')}</span>
                                <span>{t('order_history.price')}</span>
                            </div>
                            {state?.orderDetails?.line_items?.line_item.map((data, index) => {
                                return (
                                    <div key={`orderDetails${index}`} style={{paddingBottom:"20px"}}>
                                    <span >
                                        <span className="d-flex justify-content-between heading-gray">
                                            <div className="heading-gray">
                                            {/* <span>{ ! itemCodeMapping?.filter(e=>e.barcode == data?.item_code)?.length >0 ?  data?.description : ( capitalizeFirstLetter(itemCodeMapping?.filter(e=>e.barcode == data?.item_code)?.[0]?.meta_info)  +" "+ data?.attributes?.attribute?.filter(e=>e.name.toLowerCase() == "color")?.[0]?.value+" "+ itemCodeMapping?.filter(e=>e.barcode == data?.item_code)?.[0]?.SizeDesc ) }</span> */}
                                                <span>{data?.description}</span>
                                                <p> {t('order_history.qyt')} {data?.qty}</p>
                                            </div>
                                            <span>
                                                {data?.rate}
                                            </span>
                                        </span>
                                        <p className="d-flex justify-content-between heading-gray">
                                            <span>{t('order_history.discount')}</span>
                                            <span>-{data.discount}</span>
                                        </p>
                                        {/* <br/> */}
                                    </span>
                                    </div>
                                )

                            })}
                            {/* <div className="heading-gray">
                                <p> GQWhite™ Shirt Classic</p>
                                <p> Variant: White</p>
                                <p> QTY: 1</p>
                            </div> */}
                        </div>
                        {/* <div className="d-flex justify-content-between heading-gray">
                            <div className="">
                                <p>
                                    Discount
                                </p>
                                <p>
                                    GQ Promotion Code
                                </p>
                            </div>
                            <div>
                                <p>-{state?.orderDetails?.discount}</p>
                            </div>
                        </div> */}
                        {/* <p className="d-flex justify-content-between heading-gray">
                            <span>VAT 7%</span>
                            <span>XXX</span>
                        </p> */}
                        <p className="d-flex justify-content-between heading-gray">
                            <span>
                            {t('order_history.shipping')}
                            </span>
                            <span>
                            {t('order_history.free')}
                            </span>
                        </p>
                        <p className="d-flex justify-content-between bold-dark">
                            <span>
                            {t('order_history.total')}
                            </span>
                            <span>{state?.orderDetails?.amount}</span>

                        </p>
                    </div>
                </div>
                <div className="border-bottom"></div>
                <div className="personal-detail">
                    <div className="container">
                        <div className="">
                            <span className="bold-dark">{t('order_history.customer')}: </span>
                            {state && <span className="heading-gray">
                                {state?.customerData?.customers?.customer[0].firstname + " " + state?.customerData?.customers?.customer[0].lastname}
                            </span>}
                        </div>
                        <div className="">
                            <span className="bold-dark">{t('order_history.address')}: </span>
                            <span className="heading-gray">{profileCompleteStatus?.value}</span>
                        </div>
                        <div className="d-grid gap-2 mt-5">
                            <button className="btn btn-outline-secondary mt-5" type="button" data-bs-toggle="modal"
                                data-bs-target="#exampleModal_TaxInvoice">{t('order_history.tax_invoice')}</button>
                            <div id="exampleModal_TaxInvoice" aria-labelledby="exampleModalLabel" aria-hidden="true"
                                className="modal fade">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0"></div>
                                        <div className="modal-body">
                                            <img className="pb-2" src={invoiceImg} alt="" />

                                            <h5 className="firsth5">{t('order_history.for_invoice')}</h5>
                                            <h5 className="secondh5">  {t('order_history.contact_us')}</h5>
                                            <h5>
                                                <a href={`mailto:${process.env.REACT_APP_taxinvoice_email}`} >{process.env.REACT_APP_taxinvoice_email}</a>
                                            </h5>
                                        </div>
                                        <div className="modal-footer border-0"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderDetail