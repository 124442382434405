/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow_white.png"
import Refer from "../../assets/images/referearn.jpg"
import Line from "../../assets/images/line.svg"
import Link from "../../assets/images/link.svg"
import Facebook from "../../assets/images/facebook.svg"
import Twitter from "../../assets/images/twitter.svg"
import Messenger from "../../assets/images/messenger.svg"
import './tellFriend.scss'
import { useNavigate } from 'react-router'
// import CustomerApiService from '../../utils/CustomerApiService.js'
import CmsDataService from '../../utils/CmsDataService.js'
import { useTranslation } from 'react-i18next'
import { Collapse } from "@mui/material";
import { toast } from 'react-toastify';
import { CircularProgress } from "@mui/material";
import { FacebookShareButton, LineShareButton, FacebookMessengerShareButton, TwitterShareButton, XIcon } from "react-share";
import { getCustomerReferral } from '../../redux/slices/customer/GetReferralcode.js'
import { useDispatch, useSelector } from 'react-redux'
import { resetAllData } from "../../utils/resetActions";
import { useCms } from "../../utils/cmsContext";
import copy from 'copy-to-clipboard';
const TellFriend = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { cmsData, updateCmsData } = useCms();
    const goBack = () => {
        navigate(-1);
    };
    const [refcode, setRefcode] = useState(null);
    const [apiAssets, setapiAssets] = useState([]);
    const [refCount, setRefCount] = useState(0);
    const [fbshareUrl,setFbshareUrl]=useState(null)
    const title = `Supara refferal code ${refcode}`;
    const shareUrl = `${refcode}`;
    
  
    const dispatch = useDispatch();
    const { custRef, loading, refSuccess } = useSelector(state => state.getCustomerReferral);
    const copyText = async () => {
        // if ("clipboard" in navigator) {
        //     await navigator.clipboard.writeText(refcode);
            copy(refcode)
            toast.success(t('refer.text_copied'),{toastId:"copySussess"});
        // }
    }
    const copyLink = async () => {
        // if ("clipboard" in navigator) {
            // await navigator.clipboard.writeText(fbshareUrl);
            copy(fbshareUrl)
            toast.success(t('refer.text_copied'),{toastId:"copySussess"});
        // }
    }
    // function isMessengerAppInstalled() {
    //     const userAgent = window.navigator.userAgent.toLowerCase();
    //     console.log(userAgent)
    //     const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    //     const isAndroid = /android/.test(userAgent);
        
    //     if (isIOS) {
    //         return userAgent.includes('FBAN') || userAgent.includes('FBAV');
    //     } else if (isAndroid) {
    //         return userAgent.includes('fbav');
    //     } else {
    //         return false; // Defaultget to false for unsupported platforms
    //     }
    // }
    
    const handleMessangerShare =()=>{
        // if(isMessengerAppInstalled()){
        window.open(
            "fb-messenger://share?link=" +
              encodeURIComponent(`${fbshareUrl}`) +
              "&app_id=" +
              encodeURIComponent(`${process.env.REACT_APP_fbId}`)
          );
      
    }
    useEffect(() => {
        if (refSuccess == false) {
            dispatch(getCustomerReferral());
        }
        else if (custRef?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        setRefcode(custRef?.customers?.referral_code);
        setRefCount(custRef?.customers?.referees?.referee?.length);
       const encodedStateValue = encodeURIComponent(`refralcode_${custRef?.customers?.referral_code}`);
       let url=process.env.REACT_APP_referral;
       let updatedUrl = url.replace(/state=\d+&/, `state=${encodedStateValue}&`);
    setFbshareUrl(updatedUrl)
    }, [custRef]);
    const [isCollapsed, setCollapsed] = useState(false);
    const [apifaqs, setApifaqs] = useState([]);
    const handleClick = (itemId) => {
        setCollapsed((prevId) => (prevId === itemId ? null : itemId));
      };
    // CMS Faqs
    useEffect(() => {
        (async () => {
            const faqAPIdata = await CmsDataService.getCmsData('referfriend_faqs')
            setApifaqs(faqAPIdata.data.sort((a, b) => a.sequence > b.sequence ? 1 : -1));
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData.assets.length == 0
              ) {
                const assetsapiData = await CmsDataService.getCmsData("assets");
        
                if (assetsapiData?.status == 200) {
                  updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["assets"]: assetsapiData?.data[0],
                    ["language"]: localStorage.getItem("i18nextLng"),
                  }));
                }
              }
        })()
    }, []);
    useEffect(()=>{
        setapiAssets(cmsData?.assets);
    },[cmsData])
    return (
        <>
            {loading ? <div className="loader">
                <CircularProgress />
                <div> Loading....</div>
            </div> :
                <section className="tellsfriend">
                    <div className="earn-pointRange bg-black">
                        <div className="bg-light-gray">
                            <div className="container">
                                <div className="row align-items-center plr-15">
                                    <div className="col-1">
                                        <div className="backpageSection" onClick={goBack}>

                                            <img className="backarrow_white" src={Back} alt="" />

                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5 className="nav_heading text-center text-white">
                                            {t('refer.tell_friend')}
                                        </h5>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>
                        </div>
                        <img src={apiAssets?.tellfriend ? apiAssets?.tellfriend: Refer} alt="" width="100%" />

                    </div>
                    <div className="container tellsfriend-text">
                        <div className="box text-center">
                            <h5 className='pb-4'>{t('refer.share_friend')}</h5>
                            <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{display:"flex",width:"max-content",fontSize: "12px"}}>
                            <div><input type="text" placeholder="CodeToTellFriend" readOnly value={refcode == undefined ? "" : refcode} /></div>
                            <div><button onClick={copyText} className='copyButton'> {t('refer.copy')}</button></div></div></div>
                            {/* <input type="text" placeholder="CodeToTellFriend" readOnly value={refcode == undefined ? "" : refcode} /> */}
                            {/* <button onClick={copyText} className='copyButton'> {t('refer.copy')}</button> */}
                            <div className="social-box">
                                <p>{t('refer.share_code')}</p>
                                <div className="social-box-row d-flex">
                                    <LineShareButton url={fbshareUrl} title={title}>
                                        <div className="social-box-item">
                                            <img src={Line} alt="" />{t('refer.line')}
                                        </div>
                                    </LineShareButton>
                                    <FacebookShareButton url={fbshareUrl} title={title} hashtag={`#${refcode}`}>
                                        <div className="social-box-item">
                                            <img src={Facebook} alt="" />{t('refer.facebook')}
                                        </div>
                                    </FacebookShareButton>
                                    <div  onClick={handleMessangerShare}
                                    // url={fbshareUrl} appId="708265771475311" title={title}
                                    > 
                                        <div className="social-box-item">
                                            <img src={Messenger} alt="" />{t('refer.messenger')}
                                        </div>
                                    </div>
                                    {/* <FacebookMessengerShareButton url={fbshareUrl} appId={process.env.REACT_APP_fbId} title={title}>
                                        <div className="social-box-item">
                                            <img src={Messenger} alt="" />{t('refer.messenger')}
                                        </div>
                                    </FacebookMessengerShareButton> */}
                                    <TwitterShareButton url={fbshareUrl} title={title}>
                                        <div className="social-box-item">
                                            <img src={Twitter} alt="" />{t('refer.twitter')}
                                        </div>
                                    </TwitterShareButton>
                                    <div className="social-box-item pr-5 pl-5" onClick={copyLink}>
                                        <img src={Link} alt="" />{t('refer.link')}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className="">
                            {t('refer.your_currently')} {refCount == undefined ? 0 : refCount} {t('refer.friend')}
                            {/* {t('refer.user')} */}
                        </p>
                        <div className="faq-row">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {apifaqs?.map((item, index) => {
                                    return (
                                        <div className="accordion-item" key={`${item._id}${index}`}>
                                            <h2 className="accordion-header pt-2 pb-2 background-none" id="flush-headingOne">
                                                <button className={`accordion-button ${isCollapsed === item._id ? '' : 'collapsed'}`} onClick={() => handleClick(item._id)}
                                                    type="button">{item.question}</button>
                                            </h2>
                                            <Collapse
                                                id="flush-collapseOne" in={isCollapsed === item._id}
                                                className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <div dangerouslySetInnerHTML={
                                                        { __html: item.answer }
                                                    } />
                                                </div>
                                            </Collapse>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>}
        </>
    )
}

export default TellFriend