/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './yourSize.scss'
import { Link } from 'react-router-dom';
import Edit from "../../assets/images/edit.svg"
import Reverse from "../../assets/images/reverse.svg"
import BackArrow from "../../assets/images/backarrow.svg"
import Shirt from "../../assets/images/shirt.svg"
import Underwear from "../../assets/images/underwear.svg"
import Pants from "../../assets/images/Pants.svg"
import socks from "../../assets/images/socks.svg"
import longShirt from "../../assets/images/long-shirt.svg"
import Measuring from "../../assets/images/measuring.svg"
import { useLocation, useNavigate } from 'react-router';
import { useCms } from '../../utils/cmsContext';
import CmsDataService from '../../utils/CmsDataService';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, postCustomer, resetData } from '../../redux/slices/customer/GetCustomer';
import { toast } from 'react-toastify';
import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { resetAllData } from "../../utils/resetActions";
import { getOrder, resetOrderData } from '../../redux/slices/customer/GetOrder'
function YourSizes() {
    const location = useLocation();
    const { state } = location;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showSizeChart, setShowSizeChart] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const { customerOrder,orderLoading ,orderSuccess} = useSelector(state => state.getOrder);
    const { customerDetails, success, loading,postcustomerDetails } = useSelector(state => state.getCustomer);
    const { cmsData, updateCmsData } = useCms()
    const [transactionData, setTransactionData] = useState(null)
    const [yourSize, setYourSize] = useState([])
    const [itemCodeMapping, setItemCodeMapping] = useState([])
    const [customer, setCustomerinfo] = useState([])
    const [customFields, setCustomFields] = useState([])
    const [updatedCustomeFields, setUpdatedCustomeFields] = useState([])
    const [orignalCustomFields, setOrignalCustomFields] = useState([])
    const [activeCategoryIndex, setActiveCategoryIndex] = useState(localStorage.getItem("sizeIndex") ? localStorage.getItem("sizeIndex") : 0)
    const handleClick = () => {
        if (showSizeChart) {
            setShowSizeChart(false)
        }
        else {
            navigate("/profile")
        }
    }
    const setCustomeFields = () => {
        let updatedCustomeFieldsLocal = []
        if (cmsData?.yourSize.length > 0) {
            for (let i = 0; i < cmsData?.yourSize?.length; i++) {
                for (let j = 0; j < cmsData?.yourSize[i]?.sizechartjson?.customFieldsData.length; j++) {
                    const customeField_old = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_old`)
                    const customeField_current = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_current`)
                    const customeField_prefer = customerDetails?.customers?.customer[0].custom_fields.field.find(item => item.name === `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_prefer`)
                    if (!customeField_old) {
                        updatedCustomeFieldsLocal.push(
                            {
                                name: `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_old`,
                                value: ""
                            }
                        )
                    } else {
                        updatedCustomeFieldsLocal.push(customeField_old)
                    }
                    if (!customeField_current) {
                        updatedCustomeFieldsLocal.push(
                            {
                                name: `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_current`,
                                value: ""
                            }
                        )
                    } else {
                        updatedCustomeFieldsLocal.push(customeField_current)
                    }
                    if (!customeField_prefer) {
                        updatedCustomeFieldsLocal.push(
                            {
                                name: `${cmsData?.yourSize[i]?.sizechartjson?.customFieldsData[j].capillaryCustomFiledName}_prefer`,
                                value: false
                            }
                        )
                    } else {
                        updatedCustomeFieldsLocal.push(customeField_prefer)
                    }
                }
            }
        }
        setUpdatedCustomeFields(updatedCustomeFieldsLocal)
        setOrignalCustomFields(updatedCustomeFieldsLocal)
        let mutableUpdatedCustomeFields = updatedCustomeFieldsLocal.map(obj => ({ ...obj }));
        transactionData?.line_items?.line_item?.map((lineItemData,lineIteIndex)=>{
            let item_code= itemCodeMapping?.find(item=>item.barcode == lineItemData?.item_code)   
            if(item_code){
            let transactionCustomField=item_code?.meta_info
            let transactionSize=item_code?.SizeDesc
            console.log(transactionSize,transactionCustomField)
            if(transactionCustomField && transactionSize){
            let cmsCustomField_old=updatedCustomeFieldsLocal?.find(item=>item.name == `${transactionCustomField}_old`)
            let cmsCustomField_current=updatedCustomeFieldsLocal?.find(item=>item.name == `${transactionCustomField}_current`)
            let cmsCustomField_prefer=updatedCustomeFieldsLocal?.find(item=>item.name ==  `${transactionCustomField}_prefer`)
           if(cmsCustomField_old?.value=="" && cmsCustomField_current.value==""  && (cmsCustomField_prefer.value=="false"|| cmsCustomField_prefer.value== false) ){
           const currentIndex = updatedCustomeFieldsLocal.findIndex(item => item.name === `${transactionCustomField}_current`);
           const oldIndex = updatedCustomeFieldsLocal.findIndex(item => item.name === `${transactionCustomField}_old`);
           const preferIndex = updatedCustomeFieldsLocal.findIndex(item => item.name === `${transactionCustomField}_prefer`);
           if (currentIndex !== -1 && oldIndex !== -1 && preferIndex!== -1) {
            // updatedCustomeFieldsLocal[oldIndex].value = updatedFields.find(item => item.name === `${editSizeProductName}_current`).value;
            mutableUpdatedCustomeFields[currentIndex].value = transactionSize
            // updatedCustomeFieldsLocal[preferIndex].value = updatedFields[oldIndex].value?.length > 0 ? true: false
            setUpdatedCustomeFields(mutableUpdatedCustomeFields)
            // let postData = {
            //     "root": {
            //         "customer": [{
            //             "mobile": localStorage.getItem("mobile"),
            //             "custom_fields": {
            //                 "field": updatedCustomeFieldsLocal
            //             },
            //         }
            //         ]
            //     }
            // }

            // dispatch(postCustomer(postData));
            // const apiMsg = customerDetails?.customers?.customer[0].item_status.message;
            // if (customerDetails?.status?.code == 200) {
            //     dispatch(resetData());
            // }
            // else {
            //     toast.error(apiMsg);
            // }
           }
        }
    }
            }
            
        })

    }


    // const [activeTab, setActiveTab] = useState(0);
    // const temp = ["Shirt", "Underwear", "Pants", "Socks", "Essential", "Next"];
    const handlePreferChange = (editSizeProductName) => {
        let mutableUpdatedCustomeFields = orignalCustomFields.map(obj => ({ ...obj }));
        let mutableCustomFields = customFields.map(obj => ({ ...obj }))
        let updatedFields = [...mutableUpdatedCustomeFields]; // Create a copy of the array
        const currentIndex = updatedFields.findIndex(item => item.name === `${editSizeProductName}_current`);
        const oldIndex = updatedFields.findIndex(item => item.name === `${editSizeProductName}_old`);
        const preferIndex = updatedFields.findIndex(item => item.name === `${editSizeProductName}_prefer`);
        if (currentIndex !== -1 && oldIndex !== -1 && preferIndex !== -1) {
            updatedFields[currentIndex].value = updatedFields.find(item => item.name === `${editSizeProductName}_old`).value;
            updatedFields[oldIndex].value = ""
            updatedFields[preferIndex].value = false
            setUpdatedCustomeFields(updatedFields);
            let finalCustomFields = [...mutableCustomFields];
            updatedFields.forEach(item1 => {
                const existingIndex = finalCustomFields.findIndex(item2 => item2.name === item1.name);
                if (existingIndex !== -1) {
                    finalCustomFields[existingIndex].value = item1.value;
                } else {
                    finalCustomFields.push(item1);
                }
            });

            let newObj = {};

            // Iterate over the array and construct the new object
            finalCustomFields.forEach(item => {
                newObj[item.name] = item.value;
            });

            let postData = {
                "profiles": [
                    {
                        "fields":newObj,
                        "identifiers": [
                            {
                                "type": "mobile",
                                "value": localStorage.getItem("mobile")
                            }
                        ],
                    }
                ],
                // "customFields": {
                //             "field": finalCustomFields
                // },   
            }
            dispatch(postCustomer(postData));
            // const apiMsg = postcustomerDetails?.success
            // if (loading==false && postcustomerDetails?.success == true) {
                console.log("doing reset")
                dispatch(resetData());
                // toast.success("size reverted successfully");
                // setLoading2(true)
                window.location.reload()
            // setLoading2(false)
            // }
             if (customerDetails?.status?.code == 401) {
                resetAllData(dispatch);
                let lang=localStorage.getItem("i18nextLng")
                let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
                let lineId = localStorage.getItem('lineId');
                localStorage.clear();
                localStorage.setItem("i18nextLng", lang);
                localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
                localStorage.setItem('lineId', lineId);
                navigate(`/${process.env.REACT_APP_redirectionPage}`);
            }
            // else {
            //     toast.error(apiMsg);
            // }

        } else {
            toast.error("something went wrong, please try again",{toastId:"yourSizeError"});

        }
    }
    const handleTabClick = (index) => {
        localStorage.setItem("sizeIndex",index)
        if(showSizeChart){
            setShowSizeChart(false)
        }
        setActiveCategoryIndex(index);
    };

    const handleNavigate = (data) => {
        localStorage.setItem("editSizeProductName", data)
        navigate("/edit", { state: { selectedTabIndex: activeCategoryIndex } })
    }
    useEffect(() => {
        if (success == false) {
            // setLoading2(false)
            dispatch(getCustomer(localStorage.getItem("mobile")));
        }
        else if (customerDetails?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        
        
        if (customerDetails?.status?.code == 200 && customerOrder.status?.code==200) {
            setCustomerinfo(customerDetails?.customers?.customer[0]);
            setCustomFields(customerDetails?.customers?.customer[0].custom_fields.field)
            if(transactionData!==null)
            setCustomeFields()
        }
        
    }, [customerDetails,transactionData,postcustomerDetails]);
    
useEffect(()=>{

    if (orderSuccess == false) {
        dispatch(getOrder());
    }
    else if (customerOrder.status.code == 401) {
        resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
        navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    if (customerOrder.status?.code==200) {
        setTransactionData(customerOrder?.customer?.transactions?.transaction[0])
    }
},[customerOrder])

useEffect(()=>{

    (async () => {
        if (cmsData.language !== localStorage.getItem("i18nextLng") || cmsData.yourSize.length == 0) {
            setLoading2(true)
            const yourSize = await CmsDataService.getCmsData("yoursize");
            if (yourSize?.status == 200) {
                updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["yourSize"]: yourSize?.data,
                    ["language"]: localStorage.getItem("i18nextLng")
                }));
                setLoading2(false)
            }
        }
        if (cmsData.language !== localStorage.getItem("i18nextLng") || cmsData.itemCodeMapping.length == 0) {
            setLoading2(true)
            const itemCodeMappingData = await CmsDataService.getCmsData("itemcodemapping");
            if (itemCodeMappingData?.status == 200) {
                updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["itemCodeMapping"]: itemCodeMappingData?.data?.[0]?.itemdata,
                    ["language"]: localStorage.getItem("i18nextLng")
                }));
                setLoading2(false)
            }
        }
    })()
    setYourSize(cmsData?.yourSize?.sort((a, b) => a.sequence > b.sequence ? 1 : -1))
        setItemCodeMapping(cmsData?.itemCodeMapping)
},[cmsData])

    useEffect(() => {
        window.scrollTo(0, 0);
            return () => {
                dispatch(resetOrderData());
                localStorage.removeItem("sizeIndex")
            };
    }, []);
    const onClickSizeChart = () => {
        setShowSizeChart(!showSizeChart);
    }
    return (
        <section className="samelayoutpage">
            {loading || loading2 || orderLoading ? <div className="loader">
                <CircularProgress />
                <div> Loading....</div>
            </div> :
                <>
                    <div className="container yourSizeSticky" style={{top:"0"}}>
                        <div className="row align-items-center border-bottom plr-15">
                            <div className="col-1">
                                <div className="backpageSection">
                                    <img src={BackArrow} alt="" onClick={handleClick} />
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="nav_heading text-center">
                                    {!showSizeChart ? t('size_chart.your_size_heading') : t('size_chart.size_chart_heading')}
                                </h5>
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>

                    <div className="your-size">
                        <div className="container yourSizeSticky" style={{top:"7%",paddingTop:"20px"}}>

                            <nav className="pt-2">
                                <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{ justifyContent: "center" }}>
                                    {yourSize.length > 0 ? yourSize?.map((categoryNameData, categoryNameIndex) => {
                                        return (
                                            <button key={`categoryName${categoryNameIndex}`} className={`nav-link ${activeCategoryIndex == categoryNameIndex ? "active" : ""}`} style={{ borderRight: yourSize?.length == (categoryNameIndex + 1) ? '1px solid' : "none" }} id={`nav-${categoryNameData.sizechartjson.categoryName}-tab`} data-bs-toggle="tab" data-bs-target={`#nav-${categoryNameData.sizechartjson.categoryName}`}
                                                type="button" role="tab" aria-controls={`nav-${categoryNameData.sizechartjson.categoryName}`} onClick={() => handleTabClick(categoryNameIndex)} aria-selected="true">
                                                <div>
                                                    <img src={categoryNameData.categoryicon} alt="" />
                                                </div>
                                                {categoryNameData.sizechartjson.categoryName}
                                            </button>
                                        )
                                    }) : ""}

                                </div>
                            </nav>

                        </div>

                        {!showSizeChart ?
                            <div className="tab-content" id="nav-tabContent" style={{paddingTop:"0px"}}>
                                {yourSize.length > 0 && updatedCustomeFields.length > 0 ? yourSize?.map((categoryNameNavigateData, categoryNameNavigateIndex) => {
                                    return (
                                        <div key={`categoryNameNavigateData${categoryNameNavigateIndex}`} className={`tab-pane fade show ${activeCategoryIndex == categoryNameNavigateIndex ? "active" : ""}`} id={`nav-${categoryNameNavigateData?.sizechartjson?.categoryName}`} role="tabpanel" aria-labelledby={`nav-${categoryNameNavigateData?.sizechartjson.categoryName}-tab`}>
                                            <div className="container" style={{paddingTop:"25px"}}>
                                                <div className="Chart">

                                                    <div to="/size-chart" style={{ color: "#2172DA", textDecoration: "underline" }} onClick={onClickSizeChart}>
                                                        <img src={Measuring} alt="" />
                                                        {t('size_chart.size_chart')}
                                                    </div>

                                                </div>
                                            </div>
                                            {/* {categoryNameNavigateData.categoryName} */}
                                            {categoryNameNavigateData?.sizechartjson?.customFieldsData.map((subCategoryData, subCategoryDataIndex) => {
                                                const currentValue = updatedCustomeFields.find(item => item.name === `${subCategoryData.capillaryCustomFiledName}_current`)
                                                const displayVlaue = subCategoryData?.possibleOptions.find(item => item.value === currentValue.value)
                                                console.log(currentValue,currentValue?.value,displayVlaue,subCategoryData.capillaryCustomFiledName)
                                                const preferVlaue = updatedCustomeFields.find(item => item.name === `${subCategoryData.capillaryCustomFiledName}_prefer`)
                                                return (
                                                    <div key={`subCategoryData${subCategoryDataIndex}`} className="cat-row">
                                                        <div className="container">
                                                            <div className="row align-items-center" >
                                                                <div className="col-5">
                                                                    <p className="">{subCategoryData.displayName}</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <input type="text" readOnly placeholder="-" value={displayVlaue?.display.length > 0 ? `${displayVlaue?.display}` : currentValue?.value?.length > 0 ? `${currentValue?.value}` : ""} />
                                                                </div>
                                                                <div className="col-1 edit-sec">
                                                                    <div to="/edit" onClick={() => handleNavigate(subCategoryData.capillaryCustomFiledName)}> <img src={Edit} alt="" /></div>
                                                                </div>
                                                                {preferVlaue.value == "true" && <div className="col-2 reverse-sec" onClick={() => handlePreferChange(subCategoryData.capillaryCustomFiledName)}>
                                                                    <img src={Reverse} alt="" />
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {/* <div className="cat-row">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <p className="">GQWhite Collection</p>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" placeholder="GQSize: 40 - S" />
                                    </div>
                                    <div className="col-2 edit-sec">
                                        <Link to="/edit"> <img src={Edit} alt="" /></Link>
                                    </div>
                                    <div className="col-2 reverse-sec">
                                        <img src={Reverse} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cat-row">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <p className="">Perfect Polo</p>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" placeholder="GQSize: 40 - S" />
                                    </div>
                                    <div className="col-2 edit-sec">
                                        <img src={Edit} alt="" />
                                    </div>
                                    <div className="col-2 reverse-sec"></div>
                                </div>
                            </div>
                        </div>
                        <div className="cat-row">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <p className="">Minimal Polo</p>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" placeholder="GQSize: 40 - S" />
                                    </div>
                                    <div className="col-2 edit-sec">
                                        <img src={Edit} alt="" />
                                    </div>
                                    <div className="col-2 reverse-sec"></div>
                                </div>
                            </div>
                        </div>
                        <div className="cat-row">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <p className="">Smart T-Shirt</p>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" placeholder="GQSize: 40 - S" />
                                    </div>
                                    <div className="col-2 edit-sec">
                                        <img src={Edit} alt="" />
                                    </div>
                                    <div className="col-2 reverse-sec"></div>
                                </div>
                            </div>
                        </div> */}
                                        </div>
                                    )
                                }) : ""}


                            </div> : <div>
                                <div className="tab-content yourSizeTabContent" id="nav-tabContent" style={{paddingTop:"0px",maxWidth:"540px",margin:"auto"}}>
                                    {yourSize.length > 0 && updatedCustomeFields.length > 0 ? yourSize?.map((categoryNameNavigateData, categoryNameNavigateIndex) => {
                                        return (
                                            <div  key={`categoryNameNavigateData${categoryNameNavigateIndex}`} className={`tab-pane fade show sizeChanrtCms ${activeCategoryIndex == categoryNameNavigateIndex ? "active" : ""}`} id={`nav-${categoryNameNavigateData?.sizechartjson?.categoryName}`} role="tabpanel" aria-labelledby={`nav-${categoryNameNavigateData?.sizechartjson.categoryName}-tab`}>
                                                <div >
                                                    <div dangerouslySetInnerHTML={
                                                        { __html: categoryNameNavigateData?.sizechartcontent }
                                                    } />
                                                </div>

                                            </div>
                                        )
                                    }) : ""}


                                </div>
                            </div>}

                        {!showSizeChart ?
                            <div className="container pt-3">
                                <h3 className="heading mb-0">{t('size_chart.please_noted')}</h3>
                                <ul>
                                    <li>
                                        {t('size_chart.note_1')}
                                    </li>
                                    <li>{t('size_chart.note_2')}
                                    </li>
                                    <li>
                                        {t('size_chart.notw_3')}
                                    </li>
                                    <li>
                                        {t('size_chart.note_4')}
                                    </li>
                                </ul>
                            </div> : ""}
                    </div>
                </>}

        </section>
    );
}

export default YourSizes;
