import React from 'react';
import './Welcome.scss'
import '../../styles.scss';
import logoImage from '../../assets/images/Logo3.png';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const WelcomeComponent = () => {
    const navigate= useNavigate()
    const {t}=useTranslation()
    const loaded = () => {
        navigate("/welcome-rewards")
    }
    return (
        <>
            <section className="welcomeSection" style={{alignItems:"flex-start"}}>
                <div className="container">
                    <div className="welcome-img-section" style={{paddingTop:"150px",textAlign:"center",paddingLeft:"0px"}} >
                        <img src={logoImage} alt="" />
                    </div>

                    <div className="welcome-text-section">
                        <h5 className="welcome-text-rd">{t('welcome_component.welcome')}</h5>
                        <p>
                            {t('welcome_component.welcome_text')}
                        </p>
                        <button className="comman-button welcomegetstarted" onClick={loaded}>{t('welcome_component.start_button')}</button>
                  
                    </div>
                </div>
            </section>
        </>
    );
};

export default WelcomeComponent;
