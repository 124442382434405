/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import "./activeRedeem.scss";
import back from "../../assets/images/backarrow.svg";
// import Reward from "../../assets/images/Artworks for mockup/Re-1.jpg"
import gqcoin from "../../assets/images/GQ-Coin.png";
import closed from "../../assets/images/Icons/closed.svg";
import giftbox from "../../assets/images/gift-box-with-lace-svgrepo-com.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CustomerApiService from "../../utils/CustomerApiService";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer, resetData } from "../../redux/slices/customer/GetCustomer";
import { getReward, resetRewardData } from "../../redux/slices/Rewards/GetReward";
import { issueReward } from '../../redux/slices/Rewards/IssueReward';
import { useTranslation } from "react-i18next";
import { resetRewardsVouchersData } from "../../redux/slices/Rewards/GetRewardVoucher";
import { resetAllData } from "../../utils/resetActions";
import { resetPointHistory } from '../../redux/slices/customer/GetPointhistory';
const activeRedeem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [marvelrewards, setMarvelrewards] = useState([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [customer, setCustomer] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [tAndC, setTAndC] = useState("");
  const [updateLoading,setUpdateLoading]=useState(false)
  const search = useLocation().search;
  const rewardid = new URLSearchParams(search).get("id");
  // const [loading, setLoading] = useState(false)
  // const location = useLocation();
  // const { state } = location;
  // console.log(state)
  const { customerDetails, success, loading } = useSelector(
    (state) => state.getCustomer
  );
  const { rewardDetails, rewardsuccess } = useSelector(
    (state) => state.getReward
  );
  const { issueDetail } = useSelector(state => state.issueReward)
  const viewQrCode = (data) => {
    setModalVisible(false)
    if (data == "reward") {
      localStorage.removeItem("redeemedReward")
    }
    navigate(`/${data}`)
  }

  const returnRedeemReward = (reward) => {
    if(reward?.tier?.length > 0 && reward?.tier){
    if (reward?.tier && reward?.tier.length > 0 && customer?.current_slab && customer?.current_slab.length > 0) {
      let arr = reward?.tier?.toLowerCase().trim().split(",")
      if (arr.includes(customer?.current_slab?.toLowerCase().trim())) {
        return false
      }
      else if (customer?.current_slab?.toLowerCase().trim() == process.env.REACT_APP_tier1.toLowerCase().trim()) {
        return false
      } else if (customer?.current_slab?.toLowerCase().trim() == process.env.REACT_APP_tier2.toLowerCase().trim()) {
        if (arr.includes(process.env.REACT_APP_tier3.toLowerCase().trim())) {
          return false
        }
      }
      return true
    }
  }else{
    return false
  }
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateLoading(true)
    // dispatch(issueReward(marvelrewards.id, marvelrewards.name));
    try {
      const data = await CustomerApiService.issueReward(
        marvelrewards.id,
        marvelrewards.name
      );

      if (data.status.success == true) {
        setUpdateLoading(false)
        setModalVisible(true);
        dispatch(resetData());
        localStorage.setItem("redeemedReward", "true")
        localStorage.setItem("couponCode", data.intouch.couponCode);
        localStorage.setItem("rewardId", rewardid);
      }
      else if (data?.status?.code == 401) {
        setUpdateLoading(false)
        resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
        navigate(`/${process.env.REACT_APP_redirectionPage}`);
      }
      else {
        setUpdateLoading(false)
        toast.error(data?.status?.message.toLowerCase().replace(/_/g, ' ').replace(/:/g, ' '),{toastId:"activeCouponError"});
      }
    } catch (e) {
      setUpdateLoading(false)
      console.log(e);
    }
  };
  const closeModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    if (success == false) {
      dispatch(getCustomer(localStorage.getItem("mobile")));
    } else if (customerDetails?.status?.code == 401) {
      resetAllData(dispatch);
      let lang=localStorage.getItem("i18nextLng")
      let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
      let lineId = localStorage.getItem('lineId');
      localStorage.clear();
      localStorage.setItem("i18nextLng", lang);
      localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
      localStorage.setItem('lineId', lineId);
      navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    setCustomer(customerDetails?.customers?.customer[0]);
  }, [customerDetails]);
  useEffect(() => {
    (async () => {
      if (rewardsuccess == false) {
        dispatch(getReward());
      }
      if(rewardDetails?.length>0){
      setMarvelrewards(
        rewardDetails?.find((eachreward) => eachreward.id == rewardid)
      );
      const reward = rewardDetails?.find(
        (eachreward) => eachreward.id == rewardid
      );
      if(reward?.termAndConditionsUrl && reward?.termAndConditionsUrl?.length >0 ){
      const tandc = await fetch(reward?.termAndConditionsUrl);
      if (!tandc.ok) {
        console.log("Failed to fetch data");
      }else{
      const text = await tandc.text();
      setTAndC(text);
      }
    }
    }
    })();
  }, [rewardDetails]);
  useEffect(() => {
    setModalVisible(false);

  }, [issueDetail])

  useEffect(() => {
    // window.location.scroll(0,0)
    return () => {
      dispatch(resetRewardsVouchersData());
      dispatch(resetRewardData());
      dispatch(resetPointHistory());
    };
  }, []);
  const handleHideModal=()=>{
    setQrModalOpen(false);
  }
    useEffect(() => {
      if(qrModalOpen){
        window.history.pushState(null, "", window.location.href);
      }
      window.onpopstate = function () {
        if(qrModalOpen){
        const closeQrModalButton = document.getElementById('closeqr');
        if (closeQrModalButton && qrModalOpen) {
            closeQrModalButton.click();
            setQrModalOpen(false)
        }
      }
      
    }
    }, [qrModalOpen]);
  // useEffect(() => {
  //     // if (state) {
  //     //     (async () => {
  //     //         setMarvelrewards(state?.marvelrewards)
  //     //         setCustomer(state?.customerData)
  //     //         const tandc = await fetch(state?.marvelrewards?.termAndConditionsUrl)
  //     //         if (!tandc.ok) {
  //     //             console.log('Failed to fetch data');
  //     //         }
  //     //         const text = await tandc.text()
  //     //         setTAndC(text)

  //     //     })()
  //     // } else {

  //         // (async () => {
  //         //     let marvelRewardsapi;
  //         //     let getCustomer;
  //         //     try {
  //         //         setLoading(true)
  //         //         marvelRewardsapi = await CustomerApiService.getMarvelrewards();
  //         //         getCustomer = await CustomerApiService.getCustomerByMobile(localStorage.getItem("mobile"));
  //         //     } catch (e) {
  //         //         console.log(e)
  //         //     } finally {
  //         //         setLoading(false)
  //         //         if (marvelRewardsapi.status.success == true && getCustomer.status.success == 'true') {
  //         //             setMarvelrewards(marvelRewardsapi.rewardList.find(eachreward => eachreward.id == rewardid))
  //         //             const reward = marvelRewardsapi.rewardList.find(eachreward => eachreward.id == rewardid)
  //         //             setCustomer(getCustomer.customers.customer[0])
  //         //             const tandc = await fetch(reward.termAndConditionsUrl)
  //         //             if (!tandc.ok) {
  //         //                 console.log('Failed to fetch data');
  //         //             }
  //         //             const text = await tandc.text()
  //         //             setTAndC(text)
  //         //             console.log(marvelRewardsapi.rewardList.find(eachreward => eachreward.id == rewardid), tAndC, reward.termAndConditionsUrl)
  //         //         } else {
  //         //             console.log("network error")
  //         //         }
  //         //     }
  //         // })()
  //     // }
  // }, []);
  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
          <div> Loading....</div>
        </div>
      ) : (
        <section className="earnprogress">
          <div className="opacity-popup">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-1">
                  <div className="backpageSection">
                    <Link to="/reward">
                      <img src={back} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col">
                  <h5 className="boldhHeadingH5_topHeading text-center">{t('rewards.redeem')}</h5>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
            {updateLoading && 
              <div className="loading-overlay" style={{backgroundColor:"transparent"}}>
              <div className="blurred-background" style={{backgroundColor:"transparent"}}></div>
              <CircularProgress />
            </div>
              }
              <>
                <div className="earnprogressSec">
                  <div className="img-section">
                    <img src={marvelrewards.thumbnailUrl} alt="" width="100%" style={{border:"0px"}} />
                    {marvelrewards?.label?.length > 0 && (
                      <div className="status-div d-flex flex-nowrap reward_button_store special" style={{padding:"0px",justifyContent:"center"}}>
                        <button
                          type="button"
                          className="btn btn-outline"
                          style={{ marginLeft: 0,padding:"0px",color:"white",fontSize:"14px" }}
                        >
                          {marvelrewards?.label}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="container-fluid footerEndSp">
                  <div className="challenger-box">
                    <div className="challenger-details">
                      <div className="heading">
                        <h5 className="boldhHeadingH5">{marvelrewards.name}</h5>
                        
                        <div className="left-item">
                          {marvelrewards?.restrictions?.isValid == true && (marvelrewards?.restrictions?.rewardLevel?.[0]?.limit - marvelrewards?.restrictions?.rewardLevel?.[0]?.consumed) <= 20 ? `${marvelrewards?.restrictions?.rewardLevel?.[0]?.limit - marvelrewards?.restrictions?.rewardLevel?.[0]?.consumed} ${t('rewards.items_left')}`:"" }
                          
                          <br />
                          &nbsp;
                        </div>
                      </div>
                      <div className="points store_channel">
                        <img src={gqcoin} className="GQ-Coin" />
                        <span className="pointBold">
                          {marvelrewards.intouchPoints?.toLocaleString()}
                        </span>{" "}
                        {t('rewards.points')}
                      </div>
                      {marvelrewards.endTime ? (
                        <div className="date">
                          <div>
                            {t('mainhome.expire_in')}{": "}
                            <span>
                              {" "}
                              {console.log(new Date(new Date(customer?.registered_on)).getDate()+7)}
                              {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? (new Date().setHours(0,0,0,0)-new Date(customer?.registered_on).setHours(0,0,0,0))/(24 * 60 * 60 * 1000) <= 7 &&  marvelrewards.label =="Sign up reward" ? 
                                            format(
                                              new Date(new Date(customer?.registered_on).getFullYear(), new Date(customer?.registered_on).getMonth(), new Date(customer?.registered_on).getDate() + 7),
                                              "dd MMM yyyy",
                                              { locale: th }
                                            )
                                            :
                                             format(
                                                new Date(marvelrewards.endTime),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : 
                                            (new Date().setHours(0,0,0,0)-new Date(customer?.registered_on).setHours(0,0,0,0))/(24 * 60 * 60 * 1000) <= 7 && marvelrewards.label =="Sign up reward" ? 

                                            format(
                                              new Date(new Date(customer?.registered_on).getFullYear(), new Date(customer?.registered_on).getMonth(), new Date(customer?.registered_on).getDate() + 7),
                                              "dd MMM yyyy",
                                            )
                                            :
                                            format(
                                                new Date(marvelrewards.endTime),
                                                "dd MMM yyyy"
                                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="store-button">
                        <div className="store-text">{t('rewards.Store')}</div>

                        {Array.isArray(marvelrewards["categoryList"]) &&
                          marvelrewards["categoryList"]?.map((data, index) => {
                            return (
                              <div
                                key={`categoryList${index}`}
                                className={`store-but1`}
                              >
                                {data.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="faq-row">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingOne"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                              style={{paddingLeft:"0px"}}
                            >
                              {t('rewards.description')}
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body" style={{paddingBottom:"10px",paddingLeft:"0px"}}>
                              {marvelrewards.description}
                            </div>
                          </div>
                        </div>
                        <div className="faq-row">
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTwo"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwo"
                                  style={{paddingLeft:"0px"}}
                                >
                                  {t('rewards.tandc')}
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body" style={{paddingBottom:"10px",paddingLeft:"0px"}}>
                                  {tAndC.length > 0 ? tAndC : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="savebutton-rewards">
                  {customer?.loyalty_points >= marvelrewards?.intouchPoints ? (
                    <div className="above-button">
                      {t('rewards.points_left')}{" "}
                      {customer?.loyalty_points - marvelrewards?.intouchPoints}{" "}
                      {t('rewards.after_redumption')}
                    </div>
                  ) : (
                    <div className="above-button">
                      {t('rewards.you_need')}{" "}
                      {marvelrewards?.intouchPoints - customer?.loyalty_points}{" "}
                      {t('rewards.more_points')}
                    </div>
                  )}

                  <button
                    type="submit"
                    className={
                      (customer?.loyalty_points >= marvelrewards?.intouchPoints && !returnRedeemReward(marvelrewards) )
                        ? ""
                        : "inactive_button"
                    }
                    data-bs-toggle={
                      (customer?.loyalty_points >= marvelrewards?.intouchPoints && !returnRedeemReward(marvelrewards))
                        ? "modal"
                        : ""
                    }
                    data-bs-target={
                      (customer?.loyalty_points >= marvelrewards?.intouchPoints && !returnRedeemReward(marvelrewards))
                        ? "#exampleModalCenter"
                        : ""
                    }
                    style={{
                      display:
                        (customer?.loyalty_points >= marvelrewards?.intouchPoints && !returnRedeemReward(marvelrewards))
                          ? "block"
                          : "",
                    }}
                    onClick={()=>{setQrModalOpen(true)}}
                  >
                    <img src={giftbox} className="redeem-img" />{" "}
                    {t('rewards.redeem')}
                  </button>
                </div>
                <div
                  className="modal"
                  id="exampleModalCenter"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                  data-bs-backdrop="false"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content" style={{borderRadius:"0px"}}>
                      <div className="modal-text">{t('rewards.confirm')}</div>
                      <div className="modal-subtext">
                        {t('rewards.do_you_want_to_use')} {marvelrewards.intouchPoints} {t('rewards.points_to_redeem')}
                      </div>
                      <div className="popup-button">
                        <div className="modal-button1" style={{margin:"0px 5px 0px 5px"}}>
                          <button
                            type="button"
                            className="btn btn-secondary modal-button"
                            data-bs-dismiss="modal"
                            id="closeqr"
                            onClick={()=>{handleHideModal()}}
                          >
                            {t('rewards.cancel')}
                          </button>
                        </div>
                        <div className="modal-button2" style={{margin:"0px 5px 0px 5px"}}>
                          <button
                            type="button"
                            className="btn btn-secondary modal-button"
                            data-bs-dismiss="modal"
                            onClick={handleSubmit}
                          >
                            {t('rewards.final_confirm')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            
          </div>
          <div
            className={`modal  ${isModalVisible ? "show" : ""}`}
            style={{ display: isModalVisible ? "block" : "none" }}
            id="exampleModalCenter1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            data-bs-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header" onClick={closeModal}>
                  <img src={closed} />
                </div>

                <div className="modal-text">
                  {t('rewards.used_points')}
                  <br /> {t('rewards.to_redeem')}
                </div>
                <div className="modal-button">
                  <div onClick={() => viewQrCode("qr-code")} >
                    <button
                      type="button"
                      className="btn btn-secondary modal-button"
                      data-dismiss="modal"
                    >
                      {t('rewards.use_now')}
                    </button>
                  </div>
                </div>
                <div onClick={() => viewQrCode("my-coupon")}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <div className="modal-foottext">{t('rewards.later')}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default activeRedeem;
