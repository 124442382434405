/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import eyeslash from "../../assets/images/eye-slash.svg";
import eye from "../../assets/images/Icons/eye.svg";
import Back from "../../assets/images/backarrow.svg";
import './newPassword.scss';
import { useNavigate } from 'react-router-dom';
import AuthService from "../../utils/AuthService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const NewPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [type, setType] = useState('password');
    const [type1, setType1] = useState('password');
    const [passwordError, setPasswordError] = useState({
        password: "",
        confirmPassword: "",
        match: ""
    })
    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }
    const handleToggle2 = () => {
        if (type1 === 'password') {
            setType1('text')
        } else {
            setType1('password')
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'password') {
            setPassword(value.trim())
        }
        if (name == 'confirmPassword') {
            setPassword2(value.trim())
        }

        if (name == 'password') {
            if (value.trim().length > 0) {
                if (value.trim().length >= 8) {
                    setPasswordError((prevValues) => ({
                        ...prevValues,
                        ["password"]: "",
                    }));
                }
            }
        }
        if (name == 'confirmPassword') {
            if (value.trim().length > 0) {
                setPasswordError((prevValues) => ({
                    ...prevValues,
                    ["confirmPassword"]: "",
                }));
            }
        }

        if (name == 'confirmPassword') {
            if (value.trim() == password) {
                setPasswordError((prevValues) => ({
                    ...prevValues,
                    ["match"]: "",
                }));
            }
        }
        if (name == 'password') {
            if (value.trim() == password2) {
                setPasswordError((prevValues) => ({
                    ...prevValues,
                    ["match"]: "",
                }));
            }
        }

    }
    const Validate = () => {

        const errors = {
            password: "",
            confirmPassword: "",
            match: ""
        }
        if (password.trim().length == 0) {
            errors.password = t('sign_up.password_required_error')
            setPasswordError(errors);
            return false;
        }


        if (password.trim().length > 7) {

            if (password2.trim().length !== 0) {

                if (password.trim() == password2.trim()) {
                    setPasswordError({
                        password: "",
                        confirmPassword: "",
                        match: ""
                    })
                    return true;
                }
                else {
                    errors.match = t('sign_in.password_does_not_match')
                    setPasswordError(errors);
                    return false;
                }


            } else {
                errors.confirmPassword = t('sign_in.confirm_password_required')
                setPasswordError(errors);
                return false;
            }


        } else {
            errors.password = t('sign_up.password_error')
            setPasswordError(errors);
            return false;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validate = Validate()
        if (validate) {
            if (localStorage.getItem("mobile")) {
                try {
                    const tokenGenerateResponse = await AuthService.tokenGenerate(password);
                    if (tokenGenerateResponse?.status?.code == 200) {
                        // if (tokenGenerateResponse.user.userRegisteredForPassword == true) {
                            const otpGenerateResponse = await AuthService.generateOtp()
                            console.log("otpGenerateResponse", otpGenerateResponse)
                            if (otpGenerateResponse.data.status.code == 200) {
                                console.log("done")
                                localStorage.setItem("updatePassword", true)
                                navigate("/otp")
                            } else {
                                toast.error(otpGenerateResponse.data.status.message);
                                let lang=localStorage.getItem("i18nextLng")
                                let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
                                let lineId = localStorage.getItem('lineId');
                                localStorage.clear();
                                localStorage.setItem("i18nextLng", lang);
                                localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
                                localStorage.setItem('lineId', lineId);
                                navigate(-1)
                            }
                            // navigate("/otp");
                        // }
                        // else {
                        //     toast.error(t('sign_in_new.not_registered'))
                        //     localStorage.clear();
                        //     localStorage.setItem("i18nextLng", process.env.REACT_APP_defaultLanguage);
                        //     navigate(-1)
                        // }
                    }
                    else {
                        toast.error(tokenGenerateResponse.message)
                    }
                }
                catch (e) {
                    console.log(e)
                }
            } else {
                toast.error(t('sign_in.invalid_session'))
                let lang=localStorage.getItem("i18nextLng")
                let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
                let lineId = localStorage.getItem('lineId');
                localStorage.clear();
                localStorage.setItem("i18nextLng", lang);
                localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
                localStorage.setItem('lineId', lineId);
                navigate(-1)
                window.location.reload()
            }

        } else {
            console.log("not validated")
        }

    }
    return (
        <>

            <div className="new-password">
                <div className="login-content">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-2 text-center">
                            <div className="backpageSection">
                                {/* <Link to="/verify-password" style={{ textDecoration: "none", color: "#000" }}> */}
                                <img src={Back} alt="" onClick={() => { navigate(-1) }} />
                                {/* </Link> */}
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="boldhHeadingH5 text-center">{t('sign_in.new_password')}</h5>
                        </div>
                        <div className="col-2"></div>
                    </div>
                    <form>


                        <div>
                            <label htmlFor="password">{t('sign_in.new_password')} </label>
                            <br />
                            <input
                                type={type}
                                name="password"
                                placeholder="**********"
                                value={password}
                                required
                                onChange={handleInputChange}
                            // autoComplete="current-password"
                            />
                            <span className="eyeicon2" onClick={handleToggle}>
                            <img src={type == "text" ?eye:eyeslash} />
                            </span>
                            <br></br>
                            <span className="error-message">{passwordError.password}</span>
                        </div>

                        <div>
                            <label htmlFor="password">{t('sign_in.confirm_password')} </label>
                            <br />
                            <input
                                type={type1}
                                name="confirmPassword"
                                placeholder="**********"
                                value={password2}
                                onChange={handleInputChange}
                            // autoComplete="current-password"
                            />
                            <span className="eyeicon1" onClick={handleToggle2}>
                            <img src={type1 == "text" ?eye:eyeslash} />
                            </span>
                            <br></br>
                            <span className="error-message">{passwordError.confirmPassword}</span>
                        </div>
                        <br></br>
                        <span className="error-message">{passwordError.match}</span>
                        {/* <Link to="/otp"> */}
                        <div className="savebutton">
                            <button type="submit" onClick={handleSubmit}>{t('sign_in.save')}</button>

                        </div>
                        {/* </Link> */}
                    </form>
                </div>
            </div>
        </>
    );
};

export default NewPassword;
