/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import Back from "../../assets/images/backarrow.svg";
import './forgetPassword.scss';
import {  useNavigate } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { countries } from 'countries-list';
import { useTranslation } from "react-i18next";
import {phone} from 'phone';
const ForgetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [countryCodes, setCountryCodes] = useState({})
    const [selectedCountry, setSelectedCountry] = useState({});
    const [loginForm, setLoginForm] = useState({
        countryCode: process.env.REACT_APP_countryCode,
        phoneNo: ""
    });
    const [loginFormErrors, setLoginFormErrors] = useState({
        phoneNo: ""
    });

    const handleSelect = (countryCode) => {
        setSelectedCountry(countryCode);
        setLoginForm((prevValues) => ({
            ...prevValues,
            ["countryCode"]: countryCodes[countryCode].primary,
        }));
        if (!phone(`${countryCodes[countryCode].primary}${loginForm.phoneNo.trim()}`,{strictDetection: true}).isValid ) {
            setLoginFormErrors((prevErrors) => ({
                ...prevErrors,
                ["phoneNo"]: `${t("sign_up.phone_error")}`,
            }));
        }
        else {
            setLoginFormErrors((prevErrors) => ({
                ...prevErrors,
                ["phoneNo"]: "",
            }));
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target
        let newValue = value;
        if (name === 'phoneNo') {
            newValue = value.replace(/[^0-9]/g, '');
            if (!phone(`${loginForm.countryCode}${newValue.trim()}`,{strictDetection: true}).isValid ) {
                setLoginFormErrors((prevErrors) => ({
                    ...prevErrors,
                    ["phoneNo"]: `${t("sign_up.phone_error")}`,
                }));
            }
            else {
                setLoginFormErrors((prevErrors) => ({
                    ...prevErrors,
                    ["phoneNo"]: "",
                }));
            }
        }
        setLoginForm((prevValues) => ({
            ...prevValues,
            [name]: newValue.trim(),
        }));

    };
    const validateForm = () => {
        const errors = {
            phoneNo: "",
        };

        const regex = /^[1-9][0-9]*$/;
        if (!loginForm.phoneNo.trim()) {
            errors.phoneNo =t('sign_up.password_required_error');
        } else if (!phone(`${loginForm.countryCode}${loginForm.phoneNo.trim()}`,{strictDetection: true}).isValid) {
            errors.phoneNo =t('sign_up.phone_error');
        }
        setLoginFormErrors(errors);
        return Object.values(errors).every(error => error === "");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validation = validateForm();
        if (validation) {

            const mobile = `${loginForm.countryCode}${loginForm.phoneNo}`.replace(/^\+/, '')
            localStorage.setItem("mobile", mobile)
            navigate("/new-password");

        }
    }
    useEffect(() => {
        const countryCodes = {};
        Object.keys(countries).forEach((countryCode) => {
            if (countryCode !== 'TH' || countryCode !== 'SG' || countryCode !== 'IN'  || countryCode !== 'CN') {
                const countryData = countries[countryCode];
                const dialCode = `+${countryData.phone}`;
                countryCodes[countryCode] = { primary: dialCode, secondary: countryData.name };
            }
        });
        const newCountry = {
            "TH": {
                "primary": "+66",
                "secondary": "Thailand"
            },
            "IN": {
                "primary": "+91",
                "secondary": "India"
            },
            "SG": {
                "primary": "+65",
                "secondary": "Singapore"
            },
            "CN": {
                "primary": "+86",
                "secondary": "China"
            }
        };
        const updatedCountryCodes = { ...newCountry, ...countryCodes }
        setCountryCodes(updatedCountryCodes);
        setSelectedCountry(Object.keys(updatedCountryCodes).find(key => updatedCountryCodes[key].primary === process.env.REACT_APP_countryCode))
    }, []);

    return (
        <>
            <div className="row align-items-center border-bottom plr-15">
                <div className="col-2 text-center">
                    <div className="backpageSection">
                        {/* <Link to="/signup" style={{ textDecoration: "none", color: "#000" }}> */}
                            <img src={Back} alt="" onClick={()=>{navigate(-1)}} />
                        {/* </Link> */}
                    </div>
                </div>
                <div className="col">
                    <h5 className="boldhHeadingH5 text-center">{t('sign_in.forgot_password')}</h5>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="log-container">
                <div className="login-content">

                    <form>
                        <div>
                            <div className="moblabel" style={{ marginTop: "24px" }}>
                                <label htmlFor="lastname">{t('sign_in.phone_no')}</label>
                            </div>
                            <div className="mobile flag-btn">
                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    onSelect={handleSelect}
                                    countries={Object.keys(countryCodes)}
                                    customLabels={countryCodes}
                                    showSelectedLabel={true}
                                    showSecondarySelectedLabel={false}
                                    fullWidth={false}
                                    searchable
                                />
                                <input type="text" id="phoneNo" placeholder="XXXXXXXX" name="phoneNo" style={{ height: "50px" }} value={loginForm.phoneNo} onChange={handleInputChange} />
                            </div>
                            <span className="error-message">{loginFormErrors.phoneNo}</span>
                        </div>

                        {/* <div>
                            <label htmlFor="password">Password </label>
                            <br />
                            <div className="position-relative">
                                <input
                                    type={type}
                                    name="password"
                                    placeholder="Password"
                                    value={loginForm.password}
                                    onChange={handleInputChange}
                                // autoComplete="current-password"
                                />
                                <span className="eyeicon1" onClick={handleToggle}>
                                    <img src={eyeslash} />
                                </span>
                            </div>
                            <span className="error-message">{loginFormErrors.password}</span>
                            <p>
                            <NavLink to="/forgot-password" className="forgot-label" > Forgot Password?</NavLink></p>
                        </div> */}
                        <div className="savebutton">
                            <button type="submit" onClick={handleSubmit}>{t('sign_in.send')}</button>
                            {/* <p className="alternative">
                                {" "}
                                Don't have an account?{" "}
                                <Link to="/signup" style={{ textDecoration: "none", color: "#000" }}>
                                    <span
                                        style={{ fontWeight: "bold", textDecoration: "underline" }}
                                        
                                    >
                                        Sign up
                                    </span>
                                </Link>
                            </p> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgetPassword;
