/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import search from "../../assets/images/Icons/search.svg";
import Info from "../../assets/images/information-svgrepo-com.svg";
// import Dropdown from "../../assets/images/Icons/dropdown.svg";
import iconMarker from "../../assets/images/Icons/default-mark-icon.png";
import RightArrow from "../../assets/images/Icons/blue-arrow-right.svg";
import pinLocation from "../../assets/images/pin-location.svg";
import gqLogo from "../../assets/images/GQ-Logo-Official.svg";
import GoSize from "../../assets/images/Artworks for mockup/GQSIZE-Store.png";
// import storeimg from "../../assets/images/store.png"
import { resetAllData } from "../../utils/resetActions";
import { useForm } from '../../utils/FormContext';
import "./storeLocator.scss";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { useDispatch, useSelector } from "react-redux";
import { nearbystore } from "../../redux/slices/customer/GetStore";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import CustomerApiService from "../../utils/CustomerApiService";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";
import { toast } from "react-toastify";
import { resetstoreData } from "../../redux/slices/customer/GetStore";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "320px",
};
const defaultcenter = {
  lat: 12.9139446, // default latitude
  lng: 77.5397546, // default longitude
};
const StoreLocator = () => {
  const { currentLocation, updateCurrentLocation } = useForm();
  const { cmsData, updateCmsData } = useCms();
  const [center, setCenter] = useState();
  const [currentCenter, setCurrentCenter] = useState();
  const [zoom, setZoom] = useState(11);
  const [loading2, setLoading2] = useState(true);
  const { t } = useTranslation();
  const [storeList, setstoreList] = useState([]);
  const [searchedStoreList, setSearchedstoreList] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [homepageuicontrolData, setHomepageuicontrolData] = useState([]);
  const [allStoresList, setAllStoresList] = useState([]);
  const [allStoresList2, setAllStoresList2] = useState([]);
  const [distance, setDistance] = useState("10");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchedStoreValue, setSearchedStoreValue] = useState("");
  const dispatch = useDispatch();
  const { storeDetails, Storesuccess, loading } = useSelector(
    (state) => state.nearbystore
  );
  const navigate = useNavigate();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 13.7250481, lng: () => 100.3034528 },
      radius: 3 * 1000,
    },
  });

  // const filterSearchedStores = () => {
  //   setShowDropdown(false);
  //   try {
  //     // console.log(allStoresList)
  //     let temp = allStoresList.filter((e) =>
  //       e?.storeDescription
  //         ?.trim()
  //         ?.toLowerCase()
  //         ?.includes(searchedStoreValue?.trim()?.toLowerCase())
  //     );
  //     console.log(temp);
  //     setSearchedstoreList(temp);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const storeApi = async() => {
    try {
      setLoading2(true);
      // window.location.reload();
      console.log(center);
      // navigator.geolocation.getCurrentPosition(
      //   (position) => {
        let latitude = [
          currentCenter?.lat,
          currentCenter?.lng,
          "10",
        ];
        setCenter({
          lat: Number(currentCenter?.lat),
          lng: Number(currentCenter?.lng),
        });
        // setDistance("10");
        setZoom(11);
        setSearchedStoreValue("");
        // setSearchedstoreList(storeList);
        dispatch(nearbystore(latitude));
        if (
          storeDetails?.status?.code == "403" ||
          storeDetails?.status?.code == "401"
        ) {
          resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
          navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        setLoading2(false);
      // },
      // (error) => {
      //   toast.error(error.message)
      //   console.error('Error getting location:', error.message);
      // },
      // );
    } catch (e) {
      console.log(e);
      setLoading2(false);
    }

  };
  
  useEffect(() => {
    
    
    navigator.geolocation.getCurrentPosition(
      (position) => {
      let latitude = [
        `${position.coords.latitude}`,
        `${position.coords.longitude}`,
        distance,
      ];
      // setCenter({
      //   lat:  position.coords.latitude,
      //   lng: position.coords.longitude,
      // });

      if (Storesuccess == false) {
        dispatch(nearbystore(latitude));
      } else if (
        storeDetails?.status?.code == "403" ||
        storeDetails?.status?.code == "401"
      ) {
        resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
        localStorage.setItem('lineId', lineId);
        navigate(`/${process.env.REACT_APP_redirectionPage}`);
      }
    })
      // setLoading2(false);
      setstoreList(
        storeDetails?.storesWithDistance?.filter(
          (item) => item?.customFields?.Active == 1
        )
      );
      let temp;
      storeNameValue !== null && storeNameValue !== ""
        ? (temp = storeDetails?.storesWithDistance?.filter(
            (item) =>
              item?.customFields?.Active == 1 &&
              item?.customFields?.group?.trim()?.toLowerCase() ==
                storeNameValue?.toLocaleLowerCase()
          ))
        : (temp = storeDetails?.storesWithDistance?.filter(
            (item) => item?.customFields?.Active == 1
          ));

          if(toggle){
            temp=temp?.filter(
                (element) =>element?.customFields?.group?.trim()?.toLowerCase() =="flagship"
              )
          }
      let storeTemp;
      center?.lat && center?.lng
        ? (storeTemp = temp?.filter(
            (item) =>
              item?.latitude == center.lat && item?.longitude == center.lng
          ))
        : "";

      center?.lat && center?.lng
        ? temp?.sort((a, b) => {
            const distanceA = calculateDistance(
              center?.lat,
              center?.lng,
              a.latitude,
              a.longitude
            );
            const distanceB = calculateDistance(
              center?.lat,
              center?.lng,
              b.latitude,
              b.longitude
            );
            return distanceA - distanceB;
          })
        : "";

      storeTemp?.length > 0
        ? setSearchedstoreList(storeTemp)
        : setSearchedstoreList(temp);
    // },
    // )
    
  }, [storeDetails]);

  function calculateDistance(lat1, long1, lat2, long2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLong = ((long2 - long1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyBAA4Y_cdAwc8RO3MbmOKAMqO33dKsokD8",
  //   libraries,
  // });

  // if (loadError) {
  //   console.log("error")
  //   return <div>Error loading maps</div>;
  // }

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const handleStoreSearch = (e) => {
    setValue(e?.target?.value);
    setSearchedStoreValue(e?.target?.value);
    if (e?.target?.value.length >= 1) {
      setShowDropdown(true);
      // setSearchedstoreList(allStoresList);
    } else {
      // setZoom(11)
      setShowDropdown(false);
      // setSearchedstoreList(allStoresList);
    }
    // setSearchedstoreList(
    //   allStoresList?.filter(
    //     (item1) =>
    //       item1?.storeName
    //         ?.toLowerCase()
    //         .includes(e?.target?.value?.toLowerCase()) ||
    //       item1?.storeDescription
    //         ?.toLowerCase()
    //         .includes(e?.target?.value?.toLowerCase())
    //   )
    // );
  };
  const [storeNameValue, setStoreNameValue] = useState("Flagship");
  // const handleChange = (event) => {
  //   console.log(
  //     event.target[event.target.selectedIndex].getAttribute("data-optionlat"),
  //     event.target[event.target.selectedIndex].getAttribute("data-storeid")
  //   );
  //   setStoreNameValue(event.target.value);
  //   if (event.target.value == "All") {
  //     setZoom(11);
  //     // navigator.geolocation.getCurrentPosition((position) => {
  //     //   let latitude = [
  //     //     position.coords.latitude,
  //     //     position.coords.longitude,
  //     //     "5000",
  //     //   ];
  //     //   setCenter({
  //     //     lat: position.coords.latitude,
  //     //     lng: position.coords.longitude,
  //     //   });
  //     //   setDistance("5000");
  //     //   setZoom(15);
  //     //   setSearchedStoreValue("");
  //     //   // setSearchedstoreList(storeList);
  //     //   dispatch(nearbystore(latitude));
  //     // });
  //   } else {
  //     // setSearchedStoreValue("");
  //     setCenter({
  //       lat: Number(
  //         event.target[event.target.selectedIndex].getAttribute(
  //           "data-optionlat"
  //         )
  //       ),
  //       lng: Number(
  //         event.target[event.target.selectedIndex].getAttribute(
  //           "data-optionlong"
  //         )
  //       ),
  //     });
  //     event.target.value == "All" ? setZoom(11) : setZoom(14);
  //     handleActiveMarker(
  //       event.target[event.target.selectedIndex].getAttribute("data-storeid")
  //     );
  //     const selectedOptionElement = document.getElementById(event.target.value);
  //     if (selectedOptionElement) {
  //       selectedOptionElement.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     }
  //   }
  // };
  const handleChange = (event) => {
    console.log(event.target.value, event.target.id);
    setStoreNameValue(event.target.value);
    if (event.target.value?.length > 0) {
      if (event.target.value?.toLowerCase() == "flagship") {
        if (event.target.id == "toggle") {
          if (toggle) {
            setStoreNameValue("");
            setSearchedstoreList(storeList);
          } else {
            setSearchedstoreList(
              storeList?.filter(
                (element) =>
                  element?.customFields?.group?.trim()?.toLowerCase() ==
                  event.target.value?.toLowerCase()
              )
            );
          }
          setToggle(!toggle);
        } else {
          setToggle(true);
          setSearchedstoreList(
            storeList?.filter(
              (element) =>
                element?.customFields?.group?.trim()?.toLowerCase() ==
                event.target.value?.toLowerCase()
            )
          );
        }
      } else {
        setToggle(false);
        setSearchedstoreList(
          storeList?.filter(
            (element) =>
              element?.customFields?.group?.trim()?.toLowerCase() ==
              event.target.value?.toLowerCase()
          )
        );
      }
    } else {
      setToggle(false);
      setSearchedstoreList(storeList);
    }
  };
  // const handleSearchChange = (event) => {
  //   console.log(event);
  //   setShowDropdown(false);
  //   setStoreNameValue(event?.storeName);
  //   setSearchedStoreValue(event?.storeName);
  //   let arr = [];
  //   arr.push(event);
  //   setSearchedstoreList(arr);
  //   setCenter({
  //     lat: Number(event?.latitude),
  //     lng: Number(event?.longitude),
  //   });
  //   setZoom(14);
  //   // event.target.value == "All" ? setZoom(11) : setZoom(14);
  //   handleActiveMarker(event?.storeId);
  //   const selectedOptionElement = document.getElementById(event?.storeName);
  //   if (selectedOptionElement) {
  //     selectedOptionElement.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // };
  async function callCmsData(){
    
      try {
        // let marvelRewardsapi = await CustomerApiService.getNearbyStores(
        //   latitude
        // );
        // console.log(marvelRewardsapi);
        // if (marvelRewardsapi?.status?.code) {
        //   setAllStoresList(
        //     marvelRewardsapi?.storesWithDistance?.filter(
        //       (item) => item?.customFields?.Active == 1
        //     )
        //   );
        //   setAllStoresList2(
        //     marvelRewardsapi?.storesWithDistance?.filter(
        //       (item) => item?.customFields?.Active == 1
        //     )
        //   );
          
        // }

        if (
          cmsData.language !== localStorage.getItem("i18nextLng") ||
          cmsData.homepageuicontrolData.length == 0
        ) {
          const homepageuiData = await CmsDataService.getCmsData(
            "homepageuicontrol"
          );
          if (homepageuiData?.status == 200) {
            updateCmsData((prevValues) => ({
              ...prevValues,
              ["homepageuicontrolData"]: homepageuiData?.data,
              ["language"]: localStorage.getItem("i18nextLng"),
            }));
          }
        }
      } catch (e) {
        console.log(e);
      }
    
  }

  useEffect(() => {
    if(currentLocation?.length==0){
    navigator.geolocation.getCurrentPosition((position) => {
      let latitude = [
        `${position.coords.latitude}`,
        `${position.coords.longitude}`,
        "50000",
      ];
      updateCurrentLocation(latitude)
      console.log("current lat long",position.coords.latitude,position.coords.longitude)
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setCurrentCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setLoading2(false);
      callCmsData()
    },
    (error) => {
      setLoading2(false)
      toast.error(t('storelocator.location_error'),{toastId:"storeLocatorError"})
      console.error('Error getting location:', error.message);
    },
    {
      // Options
      enableHighAccuracy: false,
      timeout: 7000
    }
    )
  }else{
    setCenter({
      lat: Number(currentLocation?.[0]),
      lng: Number(currentLocation?.[1]),
    });
    setCurrentCenter({
      lat: Number(currentLocation?.[0]),
      lng: Number(currentLocation?.[1]),
    });
    setLoading2(false);
    callCmsData()
  }
  return()=>{
    dispatch(resetstoreData())
  }
  }, []);

  useEffect(() => {
    setHomepageuicontrolData(cmsData?.homepageuicontrolData);
  }, [cmsData]);

  const handleSelect =
    ({ description }) =>
    () => {
      setLoading2(true);
      setSearchedStoreValue(description);
      setValue(description, false);
      clearSuggestions();
      setZoom(10);
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        console.log(lat, lng)
        setCenter({
          lat: Number(lat),
          lng: Number(lng),
        });
        let latitude = [Number(lat), Number(lng), distance];
        dispatch(nearbystore(latitude));
        if (
          storeDetails?.status?.code == "403" ||
          storeDetails?.status?.code == "401"
        ) {
          setLoading2(false);
          resetAllData(dispatch);
          let lang=localStorage.getItem("i18nextLng")
          let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
          let lineId = localStorage.getItem('lineId');
          localStorage.clear();
          localStorage.setItem("i18nextLng", lang);
          localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
          localStorage.setItem('lineId', lineId);
          navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        setLoading2(false);
      });
      // filterSearchedStores()

      setShowDropdown(false);
      // try{
      //   // console.log(allStoresList)
      //   let search=description?.split(" ");
      //   let temp=allStoresList.filter((e) => ( e?.customFields?.['Store addres']?.trim()?.toLowerCase().includes(search[0]?.toLowerCase().replace(/[^\w\s]/gi, '')) || e?.customFields?.['Store addres']?.trim()?.toLowerCase().includes(search[1]?.toLowerCase().replace(/[^\w\s]/gi, '')) ) )
      //   console.log(temp)
      //   setSearchedstoreList(temp)
      // }catch(e){}
    };
  const capitalizeFirstLetter = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)} value={main_text}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  // if (!isLoaded) {
  //   return (
  //     <div className="loader">
  //       <CircularProgress />
  //       <div> Loading....</div>
  //     </div>
  //   );
  // }
  return (
    <>
      {center ? (
        <div style={{ backgroundColor: "white" }}>
          <section
            className="storelocator"
            style={{ backgroundColor: "white" }}
          >
            <div className="container1">
              <div className="position">
                <div className="row align-items-center border-bottom">
                  <div className="col-2"></div>
                  <div className="col">
                    <h5 className="boldhHeadingH5 text-center">
                      {t("storelocator.store_locator")}
                    </h5>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
              <div className="map-height position-relative">
                <GoogleMap
                  options={{ disableDefaultUI: true }}
                  mapContainerStyle={mapContainerStyle}
                  zoom={zoom}
                  center={center}
                >
                  {searchedStoreList?.map((item) => (
                    <Marker
                      animation={2}
                      averageCenter
                      enableRetinaIcons
                      gridSize={100}
                      key={item.storeId}
                      icon={{
                        url: iconMarker,
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                      position={{ lat: item.latitude, lng: item.longitude }}
                      onClick={() => handleActiveMarker(item.storeId)}
                    >
                      {activeMarker === item.storeId && (
                        <InfoWindow
                          position={{ lat: item.latitude, lng: item.longitude }}
                        >
                          <div className="store-name">
                            {item?.storeName}
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </GoogleMap>

                <div className="searchOptions position-absolute top-0 d-flex justify-content-start" style={{width:"100%"}}>
                  <div
                    className="left col-6"
                    style={{ width: "60%", fontSize: "14px" }}
                  >
                    <div className="p-2 ">
                      <div className="serachIconWrap">
                        <img
                          src={search}
                          className="searchIcon"
                          // onClick={() => filterSearchedStores()}
                        />
                        {/* onClick={()=>filterSearchedStores(searchedStoreValue)} */}
                        <input
                          type="text"
                          id="searchStore"
                          placeholder={t("storelocator.search_tore")}
                          className="w-100 p-2"
                          name="searchStore"
                          value={searchedStoreValue}
                          onChange={handleStoreSearch}
                          style={{
                            height: "50px"
                          }}
                        ></input>
                      </div>

                      {showDropdown && status === "OK" && (
                        <div>
                          <ul
                            className="dropdown"
                            style={{
                              paddingLeft: "0px",
                              fontSize: "inherit",
                              marginBottom: "5px",
                            }}
                          >
                            {/* {searchedStoreList?.map((option, id) => {
                              return (
                                <li
                                  key={`searchedStoreList${id}`}
                                  onClick={() => handleSearchChange(option)}
                                  value={option.storeName}
                                  className="liHover"
                                >
                                  {option?.storeName}
                                </li>
                              );
                            })} */}
                            {renderSuggestions()}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="right col-6"
                    style={{ width: "36%", fontSize: "14px" }}
                  >
                    <div className=" storesNearmeWrap" onClick={storeApi}>
                      <div className="pinlocWrap">
                        <img src={pinLocation} className="pinLocator" />
                      </div>
                      <input
                        type="button"
                        id="stores"
                        value={t("storelocator.store_near_me")}
                        className=" storesNearme"
                        name="stores"
                        style={{ border: "0px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="store-sec">
                <div className="store-sec1">{t("storelocator.store")}</div>
                <div className="store-sec1-1">
                  <img
                    src={gqLogo}
                    style={{
                      height: "inherit",
                      width: "30px",
                      margin: "0px 5px 0px 10px",
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <div className="store-sec2"> */}
                    {/* <img src={GQLogo} /> */}
                    <div className="store-sec2">
                      <span className="store-sec2-text">
                        {t("storelocator.own_shop")}
                      </span>
                    </div>
                    <div style={{ fontSize: "10px", width: "max-content" }}>
                      <span className="belowtext">
                        {t("storelocator.get_more_privileges")}
                      </span>
                    </div>
                    {/* </div> */}

                    {/* <div className="belowcontent">
                      <img src={Info} />
                      <span className="belowtext">
                        {t("storelocator.get_more_privileges")}
                      </span>
                    </div> */}
                    {/* <div className="toggler-section"> */}

                    {/* </div> */}
                  </div>
                  <div
                    className="form-check form-switch "
                    style={{
                      paddingLeft: "45px",
                      width: "max-content",
                      borderRight: "solid #EBEBEB 1px",
                      marginRight: " 10px",
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="toggle"
                      value="Flagship"
                      checked={toggle}
                      onChange={handleChange}
                    />
                  </div>

                  {/* <div className="store-sec3">
                    <select value={storeNameValue} onChange={handleChange}>
                      <option
                        key="All"
                        data-optionlat={defaultcenter.lat}
                        data-optionlong={defaultcenter.lng}
                        value="All"
                      >
                        {t("storelocator.all_stores")}
                      </option>
                      {searchedStoreList?.map((option) => (
                        <option
                          key={option.storeId}
                          data-optionlat={option.latitude}
                          data-optionlong={option.longitude}
                          value={option.storeName}
                          data-storeid={option.storeId}
                        >
                          {option.storeName}
                        </option>
                      ))}
                    </select>
                     <span className="store-sec3-text">{t('storelocator.filter_by_store')}</span>
                                <img src={Dropdown} />
                  </div> */}
                  <div className="store-sec3">
                    <select value={storeNameValue} onChange={handleChange}>
                      <option key="All" value={""} style={{ fontSize: "10px" }}>
                        {t("storelocator.filter_by_store")}
                      </option>
                      {/* {searchedStoreList?.map((option) => ( */}
                      <option key={1} value={"The Mall"}>
                        {" "}
                        The Mall
                      </option>
                      <option key={2} value={"Robinson"}>
                        {" "}
                        Robinson
                      </option>
                      <option key={3} value={"Central"}>
                        {" "}
                        Central
                      </option>
                      <option key={4} value={"Flagship"}>
                        {" "}
                        Flagship
                      </option>
                      <option key={5} value={"Others"}>
                        {" "}
                        Others
                      </option>
                      {/* ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="scrollable pt-4 mt-1 pb-2">
                {searchedStoreList?.map((item) => (
                  <div
                    className="eachStore"
                    key={item.storeId}
                    id={item.storeName}
                  >
                    <div>
                      <div className="flagship">
                        <div className="flagship-left">{item.storeName}</div>
                        <div className="flagship-right">
                          <a
                            href={`https://www.google.com/maps/dir//${item.latitude},${item.longitude}`}
                            //   href={`https://www.google.com/maps/dir/${currLatLong[0]},${currLatLong[1]}/${item.latitude},${item.longitude}`}
                            // target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <div className="right-content">
                              <span className="right-text weight-500">
                                {t("storelocator.goto_shop")}
                              </span>
                              <img src={RightArrow} />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="address">
                      {item?.customFields?.["Floor"]?.length > 0
                        ? `Floor: ${item?.customFields?.["Floor"]}`
                        : ""}{" "}
                      {item?.customFields?.["store_addres"]}
                      <br />
                      {item?.customFields?.["Timings"]?.length > 0
                        ? `Open ${item?.customFields?.["Timings"]}`
                        : ""}
                      <br />
                      {item?.customFields?.["Land Mark"]?.length > 0
                        ? `Remark: ${item?.customFields?.["Land Mark"]}`
                        : ""}
                      {item?.customFields?.["group"]?.length > 0 ? (
                        <div
                          className="status-div d-flex flex-wrap reward_button_store"
                          data-set="set2"
                        >
                          <button
                            type="button"
                            className="btn  btn-sm rounded-pill"
                            id="popular"
                            value={3}
                            style={{
                              marginLeft: 0,
                              fontSize: "10px",
                              padding: "0px 10px ",
                              borderColor: "#C1C1C1",
                              color: "#0D0D0D",
                            }}
                            // onClick={handleSecondFilter}
                          >
                            {capitalizeFirstLetter(
                              item?.customFields?.["group"]
                            )}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Remark: The shop is opposite to Starbucks */}
                    </div>
                    {item?.customFields?.["Shop Tag"]?.length > 0
                      ? item?.customFields?.["Shop Tag"]
                          ?.split(",")
                          .map((data) => {
                            return (
                              <div className="shop-but" key={data}>
                                {data}
                              </div>
                            );
                          })
                      : ""}
                  </div>
                ))}
                <div className="GQSIZE-Store">
                  <img src={GoSize} />
                  <div className="text-overlay">
                    <span className="text1">{t("storelocator.too_far")}</span>
                    <br />
                    <span className="text2">
                      {t("storelocator.no_worries_shop_online")}
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ height: "70px" }}> </div>
            </div>
          </section>
          <Footer></Footer>
        </div>
      ) : (
        <>
<div style={{ backgroundColor: "white" }}>
          <section
            className="storelocator"
            style={{ backgroundColor: "white" }}
          >
            <div className="container1">
              <div className="position">
                <div className="row align-items-center border-bottom">
                  <div className="col-2"></div>
                  <div className="col">
                    <h5 className="boldhHeadingH5 text-center">
                      {t("storelocator.store_locator")}
                    </h5>
                  </div>
                  <div className="col-2"></div>
                </div>
              </div></div></section></div>
        <Footer />
        </>
      )}
      {loading2|| loading ? (
        <div
          className="loading-overlay"
          style={{ backgroundColor: "transparent" }}
        >
          <div
            className="blurred-background"
            style={{ backgroundColor: "transparent" }}
          ></div>
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default StoreLocator;
