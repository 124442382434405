import React from 'react';
import { Link } from 'react-router-dom';
import BackArrow from "../../assets/images/backarrow.svg"
import Bus from "../../assets/images/cart-bus.svg"
import Bag from "../../assets/images/shopping-bag.svg"
import './orderTracking.scss'
import { useTranslation } from 'react-i18next';
const OrderTracking = () => {
    const { t } = useTranslation();
    return (
        <section className="orderdetils">
            <div className="container">
                <div className="row align-items-center border-bottom plr-15">
                    <div className="col-1">
                        <div className="backpageSection">
                            <Link to="/profile">
                                <img src={BackArrow} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="nav_heading text-center">
                        {t('order_tracking.order_tracking')}
                        </h5>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="container">
                <div className="track-order-section max-width-box">
                    <div className="track-order-box">
                        <img src={Bag} alt="" />
                        <p style={{padding:"20px 43px 15px"}}>
                        {t('order_tracking.track_order_status')}
                        </p>
                        <button className="comman-button">
                            <a href="https://track.gqsize.com" 
                            // target="_blank"
                            rel="noreferrer">{t('order_tracking.track_order')}</a>
                        </button>
                    </div>
                    <div className="shipping-box">
                        <h5>{t('order_tracking.for_shipping_tracking')}</h5>
                        <div className="d-flex">
                            <p className="col-10">{t('order_tracking.note')}</p>
                            <img className="col-2" src={Bus} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OrderTracking;
