import React, { useEffect, useState, useTransition } from "react";
import Logo from "../../assets/images/Logo.png";
import Mobile from "../../assets/images/mobileholding.svg";
import "./welcomeReward.scss";
import { useSwipeable } from "react-swipeable";
import { Link, useNavigate } from "react-router-dom";
import { useCms } from "../../utils/cmsContext";
import CmsDataService from "../../utils/CmsDataService";
import { useTranslation } from "react-i18next";
import Loading from "../loading/Loading";
import Carousel from "react-multi-carousel";
const WelcomeRewards = () => {
  const { cmsData, updateCmsData } = useCms();
  const [welcomeRewardsData, setWelcomeRewardsData] = useState([]);
  const [homepageuicontrolData, setHomepageuicontrolData] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderIndexnew, setSliderIndexnew] = useState(0);
  const [loader, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setSliderIndex(parseInt(e.target.value, 10));
    setSliderIndexnew(parseInt(e.target.value, 10));
  };
  const selectNext = () => {
    setSliderIndex(parseInt(sliderIndex, 10) + 1);
    setSliderIndexnew(parseInt(sliderIndexnew, 10) + 1);
  };
  const navigateHomePage = () => {
    localStorage.setItem("newRegistration", true);
    navigate("/main-home");
  };
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (
          cmsData.language !== localStorage.getItem("i18nextLng") ||
          cmsData.welcomeRewards.length == 0
        ) {
          const welcomeRewardsData = await CmsDataService.getCmsData(
            "welcomeslides"
          );
          if (welcomeRewardsData?.status == 200) {
            updateCmsData((prevValues) => ({
              ...prevValues,
              ["welcomeRewards"]: welcomeRewardsData?.data,
              ["language"]: localStorage.getItem("i18nextLng"),
            }));
          }
        }

        if (
          cmsData.language !== localStorage.getItem("i18nextLng") ||
          cmsData.homepageuicontrolData.length == 0
        ) {
          const homepageuiData = await CmsDataService.getCmsData(
            "homepageuicontrol"
          );
          if (homepageuiData?.status == 200) {
            updateCmsData((prevValues) => ({
              ...prevValues,
              ["homepageuicontrolData"]: homepageuiData?.data,
              ["language"]: localStorage.getItem("i18nextLng"),
            }));
          }
        }
      } catch {
        setLoading(false);
      }
    })();
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    setHomepageuicontrolData(cmsData?.homepageuicontrolData);
    setWelcomeRewardsData(
      cmsData?.welcomeRewards?.length > 0 ? cmsData.welcomeRewards : []
    );
    setLoading(false);
  }, [cmsData]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir == "Right" && sliderIndexnew >= 0)
        setSliderIndexnew(sliderIndexnew - 1);
      if (eventData.dir == "Right" && sliderIndexnew == 0)
        setSliderIndexnew(welcomeRewardsData?.length - 1);
      if (
        eventData.dir == "Left" &&
        sliderIndexnew < welcomeRewardsData?.length - 1
      ) {
        console.log(sliderIndexnew + 1);
        setSliderIndexnew(sliderIndexnew + 1);
      }
      
      if (
        eventData.dir == "Left" &&
        sliderIndexnew == welcomeRewardsData?.length - 1
      ) {
        setSliderIndexnew(0);
      }
    },
    // Other configuration options
  });

  return (
    <>
      {loader ? (
        <Loading></Loading>
      ) : (
        // <section className="welcomeRewardSection ">
        //     <div className="container">
        //         <div className="welcome-img-section">
        //             <img src={homepageuicontrolData[0]?.brandlogo} alt="logo" width="150" />
        //         </div>

        //         <div id="carouselExampleIndicators" className="carousel slide" data-bs-touch="false"  >
        //             <div className="carousel-inner" >
        //                 {welcomeRewardsData.map((data, index) => {
        //                     return (
        //                         <div  className={sliderIndex == index ? "carousel-item active" : " carousel-item"} key={`welcomeRewardsData${index}`}>
        //                             <div className="welcome-text-section">
        //                                 <h6 className="">0{index + 1}.</h6>
        //                                 <h5 className="welcome-text_welcomeRewards">{data?.title}</h5>
        //                                 <p>
        //                                     {data?.subtitle}
        //                                 </p>
        //                                 <div className="pb-4">
        //                                     <img
        //                                         src={data?.image}
        //                                         alt=""
        //                                         className=""
        //                                     />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )
        //                 })}

        //             </div>
        //             <div className="carousel-indicators" style={{ position: "static" }}>
        //                                 {welcomeRewardsData.map((buttonData, buttonIndex) => {
        //                                     return (
        //                                         <button
        //                                             onClick={handleChange}
        //                                             value={buttonIndex}
        //                                             key={`button${buttonIndex}`}
        //                                             type="button"
        //                                             data-bs-target="#carouselExampleIndicators"
        //                                             data-bs-slide-to={buttonIndex}
        //                                             className={sliderIndex == buttonIndex ? "active" : ""}
        //                                         ></button>)
        //                                 })}

        //                             </div>
        //                             {console.log(sliderIndex)}
        //             {sliderIndex != welcomeRewardsData.length - 1 ? <div className="fixedheigth">
        //                                 <button className="comman-button welcomegetstarted " style={{ padding: "10px 27px",fontSize:"18px" }} onClick={selectNext}>
        //                                     {t("welcome_rewards.continue")}
        //                                 </button>
        //                                 <p className="skip-text" style={{ paddingTop: "15px", color: "white" ,fontSize:"18px" }} onClick={navigateHomePage}>{t("welcome_rewards.skip")}</p>
        //                             </div> :
        //                                 <div className="fixedheigth">
        //                                     <button className="comman-button welcomegetstarted " style={{ padding: "10px 27px",fontSize:"18px" }} onClick={navigateHomePage}>
        //                                         {t("welcome_rewards.get_started")}
        //                                     </button>

        //                                 </div>}
        //         </div>
        //     </div>
        //     <div style={{height:"100px"}}></div>
        // </section>

        <section className="welcomeRewardSection ">
          <div className="container">
            <div className="welcome-img-section">
              <img
                src={homepageuicontrolData[0]?.brandlogo}
                alt="logo"
                width="150"
              />
            </div>
            <div {...handlers}>
              <div className="carousel">
                {welcomeRewardsData.map((data, index) => {
                  if (sliderIndexnew == index) {
                    return (
                      <div key={`welcomeRewardsData${index}`}>
                        <div className="welcome-text-section">
                          <h6 className="">0{index + 1}.</h6>
                          <h5 className="welcome-text_welcomeRewards">
                            {data?.title}
                          </h5>
                          <p>{data?.subtitle}</p>
                          <div className="pb-4">
                            <img src={data?.image} alt="" className="" />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div style={{ position: "static" }}>
              {welcomeRewardsData.map((buttonData, buttonIndex) => {
                return (
                  <button
                    onClick={handleChange}
                    value={buttonIndex}
                    key={`button${buttonIndex}`}
                    type="button"
                    className={
                      sliderIndexnew == buttonIndex ? "welcomerewards-active dots" : "dots"
                    }
                  ></button>
                );
              })}
            </div>
            <div style={{ height: "20px" }}></div>
            {sliderIndexnew != welcomeRewardsData.length - 1 ? (
              <div className="fixedheigth">
                <button
                  className=" welcomegetstarted "
                  style={{ padding: "10px 27px", fontSize: "18px" }}
                  onClick={selectNext}
                >
                  {t("welcome_rewards.continue")}
                </button>
                <p
                  className="skip-text"
                  style={{
                    paddingTop: "15px",
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={navigateHomePage}
                >
                  {t("welcome_rewards.skip")}
                </p>
              </div>
            ) : (
              <div className="fixedheigth">
                <button
                  className=" welcomegetstarted "
                  style={{ padding: "10px 27px", fontSize: "18px" }}
                  onClick={navigateHomePage}
                >
                  {t("welcome_rewards.get_started")}
                </button>
              </div>
            )}
          </div>
          <div style={{height:"100px"}}></div>
        </section>
      )}
    </>
  );
};

export default WelcomeRewards;
