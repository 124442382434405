import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow.svg";
import Shirt from "../../assets/images/shirt.svg"
import UnderWear from "../../assets/images/underwear.svg"
import Pants from "../../assets/images/Pants.svg"
import Socks from "../../assets/images/socks.svg"
import LongShirt from "../../assets/images/long-shirt.svg"
import Edit from "../../assets/images/edit.svg"
import Reverse from "../../assets/images/reverse.svg"
import ClothesMeasure from "../../assets/images/clothes-mesure.jpeg"
import Polo from "../../assets/images/Polo-Size-chart.jpeg"
import BodyMeasure from "../../assets/images/body-measure.png"
import { useNavigate } from 'react-router';
import './sizeChart.scss'
import { useCms } from '../../utils/cmsContext';
import CmsDataService from '../../utils/CmsDataService';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../../redux/slices/customer/GetCustomer';
const SizeChart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { customerDetails, success } = useSelector(state => state.getCustomer);
    const { cmsData, updateCmsData } = useCms()
    const [sizeChart, setSizeChart] = useState([])
    const [customer, setCustomerinfo] = useState([])
    const handleClick = () => {
        navigate(-1)
    }
    useEffect(() => {

        (async () => {
            console.log("in useeffect")
            dispatch(getCustomer(localStorage.getItem("mobile")));
            if (cmsData.language !== localStorage.getItem("i18nextLng") || cmsData.sizeChart.length == 0) {
                const sizeChart = await CmsDataService.getCmsData("sizechart");
                if (sizeChart?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["sizeChart"]: sizeChart?.data,
                        ["language"]: localStorage.getItem("i18nextLng")
                    }));
                }
            }
        })()

    }, []);
    useEffect(() => {

        if (customerDetails?.status?.code == 200) {
            setCustomerinfo(customerDetails?.customers?.customer[0]);
            
        }

        setSizeChart(cmsData?.sizeChart)
    }, [customerDetails, cmsData]);
    return (
        <>
            <section className="samelayoutpage">
                <div className="container">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-2 text-center">
                            <div className="backpageSection" >
                                <img src={Back} alt="" onClick={handleClick} />
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="boldhHeadingH5 text-center">
                                Your Size
                            </h5>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>
                <div className="your-size">
                    <div className="container">
                        <nav className="pt-2">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link" id="nav-shirt-tab" data-bs-toggle="tab" data-bs-target="#nav-shirt"
                                    type="button" role="tab" aria-controls="nav-shirt" aria-selected="true">
                                    <div>
                                        <img src={Shirt} alt="" />
                                    </div>
                                    Shirt
                                </button>
                                <button className="nav-link" id="nav-underwear-tab" data-bs-toggle="tab" data-bs-target="#nav-underwear"
                                    type="button" role="tab" aria-controls="nav-underwear" aria-selected="false">
                                    <div>
                                        <img src={UnderWear} alt="" />
                                    </div>
                                    Underwear
                                </button>
                                <button className="nav-link" id="nav-pants-tab" data-bs-toggle="tab" data-bs-target="#nav-pants"
                                    type="button" role="tab" aria-controls="nav-pants" aria-selected="false">
                                    <div>
                                        <img src={Pants} alt="" />
                                    </div>
                                    Pants
                                </button>
                                <button className="nav-link" id="nav-socks-tab" data-bs-toggle="tab" data-bs-target="#nav-socks"
                                    type="button" role="tab" aria-controls="nav-socks" aria-selected="false">
                                    <div>
                                        <img src={Socks} alt="" />
                                    </div>
                                    Socks
                                </button>
                                <button className="nav-link" id="nav-essential-tab" data-bs-toggle="tab" data-bs-target="#nav-essential"
                                    type="button" role="tab" aria-controls="nav-essential" aria-selected="false">
                                    <div>
                                        <img src={LongShirt} alt="" />
                                    </div>
                                    Essential
                                </button>
                                <button className="nav-link border-left-last" id="nav-next-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-next" type="button" role="tab" aria-controls="nav-next"
                                    aria-selected="false">
                                    <div>
                                        <img src={Shirt} alt="" />
                                    </div>Next
                                </button>

                            </div>
                        </nav>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show" id="nav-shirt" role="tabpanel" aria-labelledby="nav-shirt-tab">
                            <div className="cat-row">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <p className="">GQWhite Collection</p>
                                        </div>
                                        <div className="col-4">
                                            <input type="text" placeholder="GQSize: 40 - S" />
                                        </div>
                                        <div className="col-2">
                                            <a
                                            // routerLink="edit"
                                            > <img src={Edit} alt="" /></a>
                                        </div>
                                        <div className="col-2">
                                            <img src={Reverse} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cat-row">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <p className="">Perfect Polo</p>
                                        </div>
                                        <div className="col-4">
                                            <input type="text" placeholder="GQSize: 40 - S" />
                                        </div>
                                        <div className="col-2">
                                            <img src={Edit} alt="" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="cat-row">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <p className="">Minimal Polo</p>
                                        </div>
                                        <div className="col-4">
                                            <input type="text" placeholder="GQSize: 40 - S" />
                                        </div>
                                        <div className="col-2">
                                            <img src={Edit} alt="" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="cat-row">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <p className="">Smart T-Shirt</p>
                                        </div>
                                        <div className="col-4">
                                            <input type="text" placeholder="GQSize: 40 - S" />
                                        </div>
                                        <div className="col-2">
                                            <img src={Edit} alt="" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-underwear" role="tabpanel" aria-labelledby="nav-underwear-tab">
                            underwear
                        </div>
                        <div className="tab-pane fade" id="nav-pants" role="tabpanel" aria-labelledby="nav-pants-tab">
                            Pants
                        </div>
                        <div className="tab-pane fade" id="nav-socks" role="tabpanel" aria-labelledby="nav-socks-tab">
                            Socks
                        </div>
                        <div className="tab-pane fade" id="nav-essential" role="tabpanel" aria-labelledby="nav-essential-tab">
                            Essential
                        </div>
                        <div className="tab-pane fade" id="nav-next" role="tabpanel" aria-labelledby="nav-next-tab">
                            Next
                        </div>
                    </div>

                    <div className="container pt-3">
                        <div className="measure-sec">
                            <h3 className="heading mb-0">GQWhite Shirt Size Chart</h3>
                            <img src={ClothesMeasure} alt="" width="100%" />
                        </div>
                        <div className="measure-sec">
                            <h3 className="heading mb-0">Minimal Polo Size Chart</h3>
                            <img src={Polo} alt="" width="100%" />
                        </div>
                        <div className="measure-sec body-sec">
                            <h3 className="heading mb-0">How to measure</h3>
                            <img className="body-img" src={BodyMeasure} alt="" width="100%" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SizeChart