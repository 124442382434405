import React, { useState, useEffect, useRef } from "react";
import "./earnHistory.scss";
import Back from "../../assets/images/backarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import CustomerApiService from "../../utils/CustomerApiService";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPointHistory } from "../../redux/slices/customer/GetPointhistory";
import { resetAllData } from "../../utils/resetActions";
import { getMilestoneReward } from "../../redux/slices/Rewards/GetMilestoneRewards";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";
// const EarnHistory = () => {
//     const { cmsData, updateCmsData } = useCms();
//     const { t } = useTranslation();
//     const navigate = useNavigate()
//     const [history, setHistory] = useState(false)
//     const [selectedMonth, setSelectedMonth] = useState(new Date().toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }));
//     const [selectedYear, setSelectedYear] = useState(new Date().toLocaleString('en-US', { year: 'numeric' }));
//     const [pointHistory, setPointHistory] = useState({

//         promotionPoints: []
//     });
//     const [mileStoneRewards,setMileStoneReward]=useState([])
//     const [milestoneRewards,setMilestoneRewards]=useState([])
//     const dispatch = useDispatch();
//     const { milestoneRewardDetails, milestoneRewardsuccess , mileStoneLoading} = useSelector(
//         (state) => state.getMilestoneReward
//     );
//     const { pointDataHis, loading, Pointsuccess } = useSelector(
//         (state) => state.getPointHistory
//     );
//     const monthOptions = [
//         { value: '01', label: t('global.january') },
//         { value: '02', label: t('global.february') },
//         { value: '03', label: t('global.march') },
//         { value: '04', label: t('global.april') },
//         { value: '05', label: t('global.may') },
//         { value: '06', label: t('global.june') },
//         { value: '07', label: t('global.july') },
//         { value: '08', label: t('global.august') },
//         { value: '09', label: t('global.september') },
//         { value: '10', label: t('global.october') },
//         { value: '11', label: t('global.november') },
//         { value: '12', label: t('global.december') },
//     ];
//     const yearOptions = [
//         { value: new Date().toLocaleString('en-US', { year: 'numeric' }), label: new Date().toLocaleString('en-US', { year: 'numeric' }) },
//         { value: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1, label: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1 },
//     ];
//     const handleSelectChange = (event) => {
//         setHistory(false)
//         setSelectedMonth(event.target.value);
//     };
//     const handleSelectYearChange = (e) => {
//         setHistory(false)
//         setSelectedYear(e.target.value)
//     }

//     useEffect(() => {
//         if (Pointsuccess == false) {
//             dispatch(getPointHistory());
//         }
//         setPointHistory({
//             promotionPoints: pointDataHis?.promotion_points?.[0]?.customer?.item
//         })
//         if (pointDataHis?.status?.code == 401 || pointDataHis?.status?.code == 403) {
//             resetAllData(dispatch);
//         let lang=localStorage.getItem("i18nextLng")
//         let line_mobile=localStorage.getItem("line_mobile")
//           let customerId=localStorage.getItem("customerId")
//         let lineId = localStorage.getItem('lineId');
//         localStorage.clear();
//         localStorage.setItem("i18nextLng", lang);
//         localStorage.setItem("line_mobile", line_mobile);
//           localStorage.setItem("customerId",customerId);
//         localStorage.setItem('lineId', lineId);
//             navigate(`/${process.env.REACT_APP_redirectionPage}`);
//         }

//     }, [pointDataHis]);
//     useEffect(()=>{
//         if (milestoneRewardsuccess == false) {
//             dispatch(getMilestoneReward());
//         }

//         if(milestoneRewardDetails?.data){
//             if (milestoneRewardDetails?.data?.targetGroups?.length > 0) {
//                 setMileStoneReward(milestoneRewardDetails?.data?.targetGroups?.filter(e=>new Date(e?.targets[0]?.periodEndDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0) || e?.targets[0]?.targetAchievedValue >= e?.targets[0]?.targetValue))
//                 }
//                 let targets=[];
//                 if (milestoneRewardDetails?.data?.targetGroups?.length > 0) {
//                     milestoneRewardDetails?.data?.targetGroups.map((targetGroup, targetGroupIndex) => {
//                         targetGroup?.targets?.map((target,targetIndex)=>{
//                             if(new Date(target?.periodEndDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0) || target?.targetAchievedValue >= target?.targetValue){
//                             let temp={...target};
//                             temp['id']=targetGroup.id;
//                             targets.push(temp)
//                             }
//                         })
//                     })

//                     let updatedFiltered=targets;

//                     targets?.map((data,index)=>{
//                        let tempMileStoneReward= milestoneRewards?.filter((e)=> {return e.targetid == data.id})

//                        if(tempMileStoneReward?.length>0){
//                        updatedFiltered[index].points=tempMileStoneReward?.[0]?.points,
//                        updatedFiltered[index].description=tempMileStoneReward?.[0]?.description
//                     }
//                     })
//                     setMileStoneReward(updatedFiltered)
//                     }
//         }
//     },[milestoneRewardDetails,milestoneRewards])

//     useEffect(() => {
//         (async () => {
//             if (
//                 cmsData.language !== localStorage.getItem("i18nextLng") ||
//                 cmsData?.milestoneRewrads?.length == 0
//             ) {
//                 const milestoneRewradsData = await CmsDataService.getCmsData("milestonerewards");
//                 if (milestoneRewradsData?.status == 200) {
//                     updateCmsData((prevValues) => ({
//                         ...prevValues,
//                         ["milestoneRewrads"]: milestoneRewradsData?.data,
//                         ["language"]: localStorage.getItem("i18nextLng"),
//                     }));
//                 }
//             }

//         })();
//     }, [])
//     useEffect(() => {

//         if (cmsData?.milestoneRewrads?.length !==0){
//             setMilestoneRewards(cmsData?.milestoneRewrads)
//         }
//     }, [cmsData])

//     const { promotionPoints } = pointHistory;
//     return (
//         <>
//             {loading ? <div className="loader">
//                 <CircularProgress />
//                 <div> Loading....</div>
//             </div> :
//                 <section className="earnhistory">
//                     <div className="container">
//                         <div className="row align-items-center border-bottom plr-15">
//                             <div className="col-1">
//                                 <div className="backpageSection">
//                                     <Link
//                                         to="/earn"
//                                     >
//                                         <img src={Back} alt="" />
//                                     </Link>
//                                 </div>
//                             </div>
//                             <div className="col">
//                                 <h5 className="nav_heading text-center">{t('earn.earn_history')}</h5>
//                             </div>
//                             <div className="col-1"></div>
//                         </div>
//                         <div className="year-dropdown">
//                             <div className="custom-select" >
//                                 <select name="" id="Jan" value={selectedMonth} onChange={handleSelectChange}>
//                                     {monthOptions.map((option) => (
//                                         <option key={option.value} value={option.value}>
//                                             {option.label}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                             <div className="custom-select" >
//                                 <select name="" id="2024" value={selectedYear} onChange={handleSelectYearChange}>
//                                     {yearOptions.map((option) => (
//                                         <option key={option.value} value={option.value}>
//                                             {option.label}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="year-dropdown">

//                         </div>

//                         {mileStoneRewards?.map((data, index) => {
//                             //  let data=target?.targets[0]

//                             if (data.targetAchievedValue >= data.targetValue && new Date(data.enrolledOn).toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }) == selectedMonth && new Date(data.enrolledOn).toLocaleString('en-US', { year: 'numeric' }) == selectedYear ) {
//                                 if (!history) setHistory(true)
//                                 return (
//                                     <div className="challengeHistory_row" key={index}>
//                                         <div className="container" style={{paddingLeft:"0px"}}>
//                                             <div className="d-flex justify-content-between">
//                                                 <div style={{width:"70%"}}>
//                                                     <p>{data.targetName}</p>
//                                                     <p className="pointGreen">+ {data.points ? data.points : 0} {t('point_history.point')}</p>
//                                                 </div>
//                                                 <div style={{width:"25%"}} className="expDate">
//                                                 {localStorage.getItem("i18nextLng") ==
//                                           "th"
//                                             ? format(
//                                                 new Date(data.enrolledOn),
//                                                 "dd MMM yyyy",
//                                                 { locale: th }
//                                               )
//                                             : format(
//                                                 new Date(data.enrolledOn),
//                                                 "dd MMM yyyy"
//                                               )}  </div>
//                                             </div>
//                                         </div>
//                                     </div>)
//                             }
//                         })
//                         }

//                         {!history ? <p className="nodatapara">{t('point_history.no_data')}</p> : ""}

//                     </div>

//                 </section>}
//         </>
//     );
// };

const EarnHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pointDataHis, loading, Pointsuccess } = useSelector(
    (state) => state.getPointHistory
  );
  const [pointData, setPointData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("en-US", {
      month: "2-digit",
      minimumIntegerDigits: 2,
    })
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date().toLocaleString("en-US", { year: "numeric" })
  );
  const [filterData, setFilterData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [goodWillPoints, setGoodWillPoints] = useState([]);
  const [earn, setEarn] = useState(true);
  const options = [
    { value: "01", label: t("global.january") },
    { value: "02", label: t("global.february") },
    { value: "03", label: t("global.march") },
    { value: "04", label: t("global.april") },
    { value: "05", label: t("global.may") },
    { value: "06", label: t("global.june") },
    { value: "07", label: t("global.july") },
    { value: "08", label: t("global.august") },
    { value: "09", label: t("global.september") },
    { value: "10", label: t("global.october") },
    { value: "11", label: t("global.november") },
    { value: "12", label: t("global.december") },
  ];
  const yearOptions = [
    {
      value: new Date().toLocaleString("en-US", { year: "numeric" }),
      label: new Date().toLocaleString("en-US", { year: "numeric" }),
    },
    {
      value: new Date().toLocaleString("en-US", { year: "numeric" }) - 1,
      label: new Date().toLocaleString("en-US", { year: "numeric" }) - 1,
    },
  ];
  const handleEarn = (data) => {
    setEarn(data);
  };
  const combineData = (data, goodwillPointsData) => {
    const output = [];
    for (let i = 0; i < goodwillPointsData?.length; i++) {
      if (
        goodwillPointsData?.[i]?.goodwillType == "POINTS" &&
        goodwillPointsData?.[i]?.goodwillStatus == "APPROVED"
      ) {
        let temp = { id: "", billing_time: "", issued: "" };
        // temp.id=data.customer.transactions.transaction[i].id;
        temp.billing_time = goodwillPointsData?.[i]?.addedOn;
        temp.issued = goodwillPointsData?.[i]?.approvedValue;
        temp.notes = goodwillPointsData?.[i]?.program?.programDescription;
        output.push({
          ...temp,
          sortFlag: goodwillPointsData?.[i]?.addedOn,
        });
      }
    }
    for (
      let i = 0;
      i < data?.customerburn?.redemptions?.points?.point?.length;
      i++
    ) {
      output.push({
        ...data?.customerburn?.redemptions?.points?.point[i],
        name: "customerBurn",
        flag: "-",
        sortFlag:
          data?.customerburn?.redemptions?.points?.point[i].redeemed_time,
      });
    }
    for (
      let i = 0;
      i < data?.expired_points?.[0]?.item?.[0]?.expired_points_summary?.length;
      i++
    ) {
      output.push({
        ...data?.expired_points?.[0]?.item?.[0]?.expired_points_summary[i],
        name: "customerBurn",
        flag: "-",
        sortFlag:
          data?.expired_points?.[0]?.item?.[0]?.expired_points_summary[i]
            .event_date,
      });
    }
    // for (let i = 0; i < data?.expiry_schedule?.[0]?.schedule?.length; i++) {
    //   output.push({
    //     ...data?.expiry_schedule?.[0]?.schedule[i],
    //     sortFlag: data?.expiry_schedule?.[0]?.schedule[i].expiry_date,
    //   });
    // }
    for (
      let i = 0;
      i < data?.promotion_points?.[0]?.customer?.item?.length;
      i++
    ) {
      output.push({
        ...data?.promotion_points?.[0]?.customer?.item[i],
        sortFlag: data?.promotion_points?.[0]?.customer?.item[i].issued_on,
      });
    }
    for (let i = 0; i < data?.customer?.transactions?.transaction.length; i++) {
      if (data?.customer?.transactions?.transaction?.[i]?.points?.issued > 0) {
        let temp = { id: "", billing_time: "", issued: "" };
        temp.id = data.customer.transactions.transaction[i].id;
        temp.billing_time =
          data.customer.transactions.transaction[i].billing_time;
        temp.issued = data.customer.transactions.transaction[i].points.issued;
        output.push({
          ...temp,
          sortFlag:
            data?.customer?.transactions?.transaction?.[i]?.billing_time,
        });
      }
    }
    setFilterData(output);
  };
  const monthFilter = () => {
    const data = [];
    let copyfilter = [...filterData];
    if (selectedMonth && copyfilter) {
      for (let i = 0; i < copyfilter?.length; i++) {
        if (
          new Date(
            copyfilter[i].redeemed_time ||
              copyfilter[i].event_date ||
              copyfilter[i].issued_on ||
              copyfilter[i].billing_time
          ).toLocaleString("en-US", {
            month: "2-digit",
            minimumIntegerDigits: 2,
          }) == selectedMonth &&
          new Date(
            copyfilter[i].redeemed_time ||
              copyfilter[i].event_date ||
              copyfilter[i].issued_on ||
              copyfilter[i].billing_time
          ).toLocaleString("en-US", {
            year: "numeric",
          }) == selectedYear
        ) {
          data.push(copyfilter[i]);
        }
      }
      const sortData = [...data];
      sortData.sort(
        (a, b) =>
          new Date(b.sortFlag).getTime() - new Date(a.sortFlag).getTime()
      );
      setMonthData(sortData);
    }
  };
  const handleSelectChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleSelectYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const goofWillApi = async () => {
    if (pointDataHis?.customer?.user_id) {
      const data = await CustomerApiService.getGoodWillPoints(
        pointDataHis?.customer?.user_id
      );
      // pointDataHis?.customer?.user_id,
      if (data?.data) {
        setGoodWillPoints(data?.data);
      }
    }
  };
  useEffect(() => {
    combineData(pointDataHis, goodWillPoints);
  }, [pointDataHis, goodWillPoints]);
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      monthFilter();
    }
  }, [selectedMonth, filterData, selectedYear]);
  useEffect(() => {
    if (Pointsuccess == false) {
      dispatch(getPointHistory());
      setPointData(pointDataHis?.status?.success);
    } else if (pointDataHis.status.code == 401) {
      resetAllData(dispatch);
      let lang = localStorage.getItem("i18nextLng");
      let line_mobile = localStorage.getItem("line_mobile");
      let customerId = localStorage.getItem("customerId");
      let lineId = localStorage.getItem("lineId");
      localStorage.clear();
      localStorage.setItem("i18nextLng", lang);
      localStorage.setItem("line_mobile", line_mobile);
      localStorage.setItem("customerId", customerId);
      localStorage.setItem("lineId", lineId);
      navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    // goofWillApi();
  }, [pointDataHis]);
  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
          <div> Loading....</div>
        </div>
      ) : (
        <section className="earnhistory">
          <div>
            <div className="container">
              <div className="row border-bottom align-items-center plr-15">
                <div className="col-1">
                  <div className="  ">
                    <Link to="/earn">
                      <img src={Back} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col">
                  <h5 className="nav_heading text-center">
                    {t("earn.earn_history")}
                  </h5>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
            {/* <hr style={{margin:"5px"}}></hr> */}
            <div className="year-dropdown container">
              <div className="custom-select">
                <select
                  name=""
                  id="Jan"
                  value={selectedMonth}
                  onChange={handleSelectChange}
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="custom-select">
                <select
                  name=""
                  id="2024"
                  value={selectedYear}
                  onChange={handleSelectYearChange}
                >
                  {yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="year-dropdown"></div>
            {/* <div style={{display:"flex",flexDirection:"row"}} className="container">
    <div style={{padding:"30px 35px 3px 35px", borderBottom:earn? "1px solid black": "",cursor:"pointer",fontSize:"14px"}} onClick={()=>handleEarn(true)}>{t("point_history.earn")}</div>
    <div style={{padding:"30px 35px 3px 35px",borderBottom:!earn? "1px solid black": "",cursor:"pointer",fontSize:"14px"}} onClick={()=>handleEarn(false)}>{t("point_history.usage")}</div>
  </div> */}

            {monthData?.length > 0 ? (
              monthData?.map((data, index) => {
                if (
                  new Date(
                    data.redeemed_time ||
                      data.event_date ||
                      data.issued_on ||
                      data.billing_time
                  ).toLocaleString("en-US", {
                    month: "2-digit",
                    minimumIntegerDigits: 2,
                  }) == selectedMonth
                ) {
                  if (
                    (data?.name == "customerBurn" && !earn) ||
                    (data?.name !== "customerBurn" && earn)
                  ) {
                    return (
                      <div className="challengeHistory_row" key={index}>
                        <div className="container">
                          <div className="d-flex justify-content-between">
                            <div style={{ width: "70%" }}>
                            { data.promotion_name == "Goodwill Promotion" ?  
                              <p>
                              Referral Points
                            </p>:
                              <p>
                                {data.notes ||
                                  data.promotion_name ||
                                  data.expiry_strategy_name ||
                                  "Txn Id: " + data.id ||
                                  "Points going to be expire"}
                              </p>}
                              <p
                                className={
                                  data?.name == "customerBurn"
                                    ? "pointRed"
                                    : "pointGreen"
                                }
                              >
                                {data?.flag ? "-" : "+"}{" "}
                                {data.points_redeemed ||
                                  data.points ||
                                  data.issued ||
                                  "--"}{" "}
                                {t("point_history.point")}
                              </p>
                            </div>
                            <div className="expDate" style={{ width: "25%" }}>
                              {localStorage.getItem("i18nextLng") == "th"
                                ? format(
                                    new Date(
                                      data.redeemed_time ||
                                        data.event_date ||
                                        data.issued_on ||
                                        data.billing_time ||
                                        null
                                    ),
                                    "dd MMM yyyy",
                                    { locale: th }
                                  )
                                : format(
                                    new Date(
                                      data.redeemed_time ||
                                        data.event_date ||
                                        data.issued_on ||
                                        data.billing_time ||
                                        null
                                    ),
                                    "dd MMM yyyy"
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              })
            ) : (
              <p className="nodatapara">{t("point_history.no_data")}</p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default EarnHistory;
