/* eslint-disable no-undef */
import React, { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../assets/images/backarrow.svg"
import Camera from "../../assets/images/camera-profile.png"
import "./editProfile.scss";
import { Link } from "react-router-dom";
import ReactFlagsSelect from 'react-flags-select';
import Dropdown from "../../assets/images/Icons/dropdown.svg";
import { countries } from 'countries-list';
import CustomerApi from "../../utils/CustomerApiService";
import axios from "../../utils/AxiosService";
import { format } from "date-fns";
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { getCustomer, postCustomer,resetData } from '../../redux/slices/customer/GetCustomer';
// // import {getCustomeremail} from '../../redux/slices/customer/GetCustomerEmail';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import { TextField } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { resetAllData } from "../../utils/resetActions";
import { CircularProgress } from "@mui/material";
import CustomerApiService from "../../utils/CustomerApiService";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";
import { getProfilePicture, ProfileresetData } from '../../redux/slices/customer/GetProfilePicture';
import profile from "../../assets/images/Default-Avatar.jpg"
const EditProfile = () => {
    const dispatch = useDispatch();
    const [profilePic,setProfilePic]=useState("");
    const { profileImage,imageSuccess} = useSelector(state => state.getProfilePicture);
    const { t } = useTranslation();
    const { cmsData, updateCmsData } = useCms();
    const [cusInfo, setCusInfo] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        dob: "",
        address: "",
        gender: "",
        postcode: "",
        province: "",
        profilepic: "",
        district:"",
        subdistrict: ""
    });
    const [provinces, setProvinces] = useState([]);
    const [searchProvincesList, setSearchProvincesList] = useState([]);
    const [provincesDropDown, setProvincesDropDown] = useState(false);
    const [districts, setDistricts] = useState([]);
    const [buttonDisabled,setButtondisbaled]=useState(false)
    const [searchDistrictsList, setSearchDistrictsList] = useState([]);
    const [districtsDropDown, setDistrictsDropDown] = useState(false);
    const [subdistricts, setSubdistricts] = useState([]);
    const [searchSubdistrictsList, setSearchSubdistrictsList] = useState([]);
    const [subdistrictsDropDown, setSubdistrictsDropDown] = useState(false);
    const [postcodes, setPostcodes] = useState([]);
    const [searchPostcodesList, setSearchPostcodesList] = useState([]);
    const [postcodesDropDown, setPostcodesDropDown] = useState(false);
    const [updateLoading,setUpdateLoading]=useState(false)
    const [disabled, setDisabled] = useState(true);
    const [emailerr, setEmailerr] = useState("");
    const [dobError, setDobError] = useState("");
    const [countryCodes, setCountryCodes] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({});
    const [searchProvience,setSearchProvience]=useState("")
    const [searchDistrict,setSearchDistrict]=useState("")
    const [useDobValue,setUserDobValue]=useState(undefined)
    const [searchSubdistrict,setSearchSubdistrict]=useState("")
    const [searchPostcode,setSearchPostcode]=useState("")
    const [ppimage, setppImage] = useState({});
    const { firstname, lastname, mobile, email, dob, address, gender, postcode, province, profilepic,district,subdistrict } = cusInfo;
    const { customerDetails, success, loading,postcustomerDetails,postCustomerLoading,postCustomerSuccess } = useSelector(state => state.getCustomer);
    const phoneNoRef = useRef(null);
  const dateRef = useRef(null);
    const getApi = async () => {
        const mobile = localStorage.getItem("mobile");
        if (success) {
            let extFieldarr = customerDetails?.customers?.customer[0].extended_fields.field;
            let cusFieldarr = customerDetails?.customers?.customer[0].custom_fields.field;
            let GenVal = extFieldarr?.find(f => f.name == "gender");
            GenVal = (GenVal == undefined ? "" : GenVal.value);
            let dobVal = extFieldarr?.find(f => f.name == "dob");
            dobVal = (dobVal == undefined ? "" : dobVal.value?.split(" ")[0]);
            let addressVal = cusFieldarr?.find(f => f.name == "cus_address");
            addressVal = (addressVal == undefined ? "" : addressVal.value);
            let postcodeVal = cusFieldarr?.find(f => f.name == "postcode");
            postcodeVal = (postcodeVal == undefined ? "" : postcodeVal.value);
            let districtVal = cusFieldarr?.find(f => f.name == "district");
            districtVal = (districtVal == undefined ? "" : districtVal.value);
            let subdistrictVal = cusFieldarr?.find(f => f.name == "subdistrict");
            subdistrictVal = (subdistrictVal == undefined ? "" : subdistrictVal.value);
            let provinceVal = cusFieldarr?.find(f => f.name == "province");
            provinceVal = (provinceVal == undefined ? "" : provinceVal.value);
            let ppimageVal = cusFieldarr?.find(f => f.name == "profile_pic");
            ppimageVal = (ppimageVal == undefined ? "" : ppimageVal.value);
            setCusInfo({
                firstname: customerDetails?.customers?.customer[0].firstname,
                lastname: customerDetails?.customers?.customer[0].lastname,
                mobile: customerDetails?.customers?.customer[0].mobile,
                email: customerDetails?.customers?.customer[0]?.email!==null ?  customerDetails?.customers?.customer[0]?.email?.includes('testgq.com') ? '':customerDetails?.customers?.customer[0].email : "",
                gender: GenVal,
                dob: dobVal,
                address: addressVal,
                postcode: postcodeVal,
                province: provinceVal,
                profilepic: ppimageVal,
                district: districtVal,
                subdistrict: subdistrictVal
            });
            let temp=cmsData?.province?.filter(e=>e.provincecode == provinceVal)
            // console.log(temp)
            setSearchProvience(temp?.length >0  ? temp[0]?.province:"")
            let tempDistrict=cmsData?.province?.filter(e=>e.districtcode == districtVal)
            // console.log(tempDistrict)
            setSearchDistrict(tempDistrict?.length >0 ? tempDistrict[0]?.district:"")
            let tempSubDistrict=cmsData?.province?.filter(e=> e.subdistrictcode == subdistrictVal)
            
            setSearchSubdistrict(tempSubDistrict?.length >0 ? tempSubDistrict[0]?.subdistrict:"")
            setSearchPostcode(postcodeVal)
            dobVal?.length >0 ? setUserDobValue(dobVal):""
            
            setProfilePic(ppimageVal)
            const countryCodes = {};
            Object.keys(countries).forEach((countryCode) => {
                if (countryCode !== 'TH' || countryCode !== 'SG' || countryCode !== 'IN' || countryCode !== 'CN') {
                    const countryData = countries[countryCode];
                    const dialCode = `+${countryData.phone}`;
                    countryCodes[countryCode] = { primary: dialCode, secondary: countryData.name };
                }
            });
            const newCountry = {
                "TH": {
                    "primary": "+66",
                    "secondary": "Thailand"
                },
                "IN": {
                    "primary": "+91",
                    "secondary": "India"
                },
                "SG": {
                    "primary": "+65",
                    "secondary": "Singapore"
                },
                "CN": {
                    "primary": "+86",
                    "secondary": "China"
                }
            };
            const updatedCountryCodes = { ...newCountry, ...countryCodes }
            setCountryCodes(updatedCountryCodes);
            setSelectedCountry(Object.keys(updatedCountryCodes).find(key => updatedCountryCodes[key].primary === `+${mobile?.slice(0, 2)}`));
            handleBlur();
        }
    }
    const handleChange = (e,data) => {
        console.log(e,data)
        let name;
        let value ;
        let newValue
        if(e!==""){
        name= e.target.name;
        value=e.target.value
        newValue= value;
        }
        else{
            name=e,
            value=data
            newValue=new Date(value)
        }
        console.log(name,value)
         
        if ((name === 'firstname') || (name === 'lastname')) {
            newValue = value.replace(/[^a-zA-Zก-๙เ-ไๅๆ\s]/gi, '');
            newValue = newValue.replace(/\s{2,}/g, ' ');
        }
        if ((name === 'phoneNo') || (name === 'postcode')) {
            newValue = value.replace(/[^0-9]/g, '');
        }
        if (name === 'address') {
            // newValue = value.replace(/[^a-zA-Z0-9 ]/g, '');
            newValue = value.replace(/^(\d{1,}) [a-zA-Z0-9\s]+(,)? [a-zA-Z]+(,)? [A-Z]{2} [0-9]{5,6}$/,"");
            // newValue = value.replace(/^(?![ -.&,_'":?!/])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!/]{2})[a-zA-Z0-9- .#@&,_'":.?!/]+$/, "")
        }
        if (name === 'email') {
            let emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!emailregex.test(newValue)) {
                setEmailerr(t('viewprofile.valid_email_error'));
            }
            else {
                setEmailerr("");
            }
        }
    
        if (name == 'dob') {
            
            const minAgeDate = new Date();
            minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);
            const dobDate = new Date(value);
            console.log(new Date(value).setHours(0,0,0,0) > new Date().setHours(0,0,0,0))
            if (new Date(value).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)) {
                console.log("error")
                setDobError(t('viewprofile.valid_dob_error'));
            }
            else if (dobDate > minAgeDate) {
                setDobError(t('viewprofile.valid_dob_error_18'));
                // console.log("You must be at least 18 years old.");
            } 
            else {
                setDobError("");
            }
        }
        setCusInfo((prevValues) => ({
            ...prevValues,
            [name]: newValue
        }));
    }
    const handleCodeChange = (e) => {
        const { name, value ,data} = e;
        // console.log(name,value)
        
        let newValue = value;
        if(name == "province"){
            setProvincesDropDown(false)
            setSearchProvience(data?.province)
        }
        if(name == "district"){
            setDistrictsDropDown(false)
            setSearchDistrict(data?.district)
        }
        if(name == "subdistrict"){
            setSubdistrictsDropDown(false)
            setSearchSubdistrict(data?.subdistrict)
        }
        if(name == "postcode"){
            setPostcodesDropDown(false)
            setSearchPostcode(data?.postalcode)
        }
        setCusInfo((prevValues) => ({
            ...prevValues,
            [name]: newValue
        }));
    }
    const navigate = useNavigate();
    const handleSubmit = async () => {
        if (firstname != "" && lastname != "" && mobile != "" && gender != "" && dob != "" && address != "" && postcode != "" && searchProvience != "" && searchDistrict != "" &&email!="" && searchSubdistrict != "" && emailerr== "" && dobError == "") {
            setUpdateLoading(true)
            setButtondisbaled(true)
            if(customerDetails?.customers?.customer[0]?.email?.trim() !== email.trim()){
          try {
        let postData ={
            "add": [
                {
                    "type": "email",
                    "value": email
                }
            ],
            "remove": [
                {
                    "type": "email",
                    "value": customerDetails?.customers?.customer[0]?.email
                }
            ]
        }
        const data = await CustomerApiService.updateIdentifier(
            customerDetails?.customers?.customer[0]?.user_id,postData
        );
         if (data?.status?.code == 401) {
            setButtondisbaled(false)
            setUpdateLoading(false)
          resetAllData(dispatch);
          let lang=localStorage.getItem("i18nextLng")
          let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
          let lineId = localStorage.getItem('lineId');
          localStorage.clear();
          localStorage.setItem("i18nextLng", lang);
          localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
          localStorage.setItem('lineId', lineId);
          navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        else if(data?.errors){
            setUpdateLoading(false)
            setButtondisbaled(false)
            toast.error(t('editprofile.email_already_exists',{toastId:"emailError"}));
            return;
        }
        // else if(data.status.success == false) {
        //   toast.error(data?.status?.message);
        // }
    
      } catch (e) {
        setUpdateLoading(false)
        setButtondisbaled(false)
        console.log(e);
      }
}
        let postData = {
            "profiles": [
                {
                    "firstName": firstname,
                    "lastName": lastname,
                    "fields":{
                     "cus_address": address ,
                         "postcode": postcode ,
                        "province": province ,
                        "profile_completed": true  ,
                        "district": district,
                        "subdistrict": subdistrict
                    },
                    "identifiers": [
                        {
                            "type": "mobile",
                            "value": mobile
                        }
                    ],
                }
            ],
            "extendedFields": {    
                     "dob": dob ,
                     "gender": gender 
                
            }  
        }
    let shopifyPostData={
        "phone": mobile,
        "first_name": firstname,
        "last_name": lastname,
        "email": email,
        "gender": gender,
        "dob": format(dob, 'yyyy-MM-dd'),
        "address": {
            "address1": address,
            "province_code": province,
            "district_code": district,
            "subdistrict_code": subdistrict,
             "zip":postcode
         }
     }
        // dispatch(getCustomeremail(email));
       
            // const updateResp = await CustomerApi.updateCustomer(postData);
            localStorage.setItem('email',email)
            try {
                const data = await axios.post(`${process.env.REACT_APP_gatewayURL}customerupdatecap`, postData, {
                    headers: {
                        cap_authorization: localStorage.getItem("token"),
                        cap_identifier_value: localStorage.getItem("mobile"),
                    }
                })
               if ( data.data?.createdId) {
                const shopifyUpdate = await axios.post(`${process.env.REACT_APP_gatewayURL}customerupdateshopify`, shopifyPostData, {
                    headers: {
                        cap_authorization: localStorage.getItem("token"),
                        cap_identifier_value: localStorage.getItem("mobile"),
                    }
                })
                if(shopifyUpdate.data?.success == true){
                    
                    dispatch(resetData());
                    setUpdateLoading(false)
                    setButtondisbaled(false)
                    toast.success(t('editprofile.customer_updated'),{toastId:"shopifyUpdateSuccess"});
                    navigate("/viewprofile");
                }else{
                    dispatch(resetData())
                    setUpdateLoading(false)
                    setButtondisbaled(false)
                    const apiMsg = shopifyUpdate.data?.error;
                    toast.error(apiMsg,{toastId:"shopifyError"});
                }
            }
            else {
                dispatch(resetData())
                setButtondisbaled(false)
                setUpdateLoading(false)
             const apiMsg = data.data.status.message;
                console.log(apiMsg)
                toast.error(apiMsg,{toastId:"capcustUpdate"});
            }
            } catch (e) {
                dispatch(resetData())
                setButtondisbaled(false)
                setUpdateLoading(false)
                console.log(e)
                toast.error(e,{toastId:"shopifyError"});
            }
        }else{
            setButtondisbaled(false)
            setUpdateLoading(false)
            toast.error(t('editprofile.add_all_info'),{toastId:"editProfileError"});
        }
    }
    const handleBlur = () => {
        if (email?.length > 0 && emailerr.length === 0) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }
    // image upload
    async function setSelectedImage(event) {
        setUpdateLoading(true)
        let nameSplit = event.name.split(".");
        nameSplit[0]=nameSplit[0].replaceAll(/[^\w\s]/g, "_");
        nameSplit[0]=nameSplit[0].replaceAll(" ", "_");
        const s3Url = await CustomerApiService.imageURLget(nameSplit[0]);
        const uploadImagetodb = await CustomerApiService.imageUpload(s3Url.file_path, event)
        const ppimageApi = await CustomerApiService.imageGet(nameSplit[0])
            if (ppimageApi?.status == "true") {
                setppImage(ppimageApi);
            } else {
                setUpdateLoading(false)
                console.log("network error");
            }
        setProfilePic(nameSplit[0])
        let cusFieldarr = customerDetails?.customers?.customer[0].custom_fields.field;
        let customFields=cusFieldarr ? JSON.parse(JSON.stringify(cusFieldarr)) : [];
        let index=customFields?.findIndex(f => f.name == "profile_pic");
        if(index !== -1){
            customFields[index].value=nameSplit[0]
        }else{
            customFields.push({ "name": "profile_pic", "value": nameSplit[0] })
        }
        // let postData = {
        //     "root": {
        //         "customer": [{
        //             "mobile": localStorage.getItem("mobile"),
        //             "customFields": {
        //                 "field":customFields
        //             },
        //         }
        //         ]
        //     }
        // }
        let newObj = {};

        // Iterate over the array and construct the new object
        customFields.forEach(item => {
            newObj[item.name] = item.value;
        });
        
        let postData = {
            "profiles": [
                {
                    "fields":newObj,
                    "identifiers": [
                        {
                            "type": "mobile",
                            "value": localStorage.getItem("mobile")
                        }
                    ],
                }
            ],
            // "customFields": {
            //             "field": customFields
            // },   
        }
        dispatch(postCustomer(postData));
        dispatch(ProfileresetData())
        setUpdateLoading(false)
    }
    const handleDistrict=(provinceCode)=>{
        const districtArr = cmsData?.province.filter(data => data.provincecode === provinceCode);
        const filterDistrictResp = filtereddistrict(districtArr);
        setDistricts(filterDistrictResp);
        setSearchDistrictsList(filterDistrictResp)
        setSearchDistrict("")
        setSearchSubdistrict("")
        setSearchPostcode("")
        setSearchSubdistrictsList([])
        setSearchPostcodesList([])
        setSubdistricts([]);
        setPostcodes([]);
    }
    const handlesubDistrict=(districtCode)=>{
        const subdistrictArr = cmsData?.province.filter(data => data.districtcode === districtCode);
        const filterSubdistrictResp = filteredsubdistrict(subdistrictArr);
        setSubdistricts(filterSubdistrictResp);
        setSearchSubdistrictsList(filterSubdistrictResp)
        setSearchPostcodesList([])
        setSearchSubdistrict("")
        setSearchPostcode("")
        setPostcodes([]);
    }
    const handlepostcode = (subdistrictCode) =>{
        const pincodeArr = cmsData?.province.filter(data => data.subdistrictcode === subdistrictCode);
        // console.log(pincodeArr,subdistrictCode)
        setPostcodes(pincodeArr);
        setSearchPostcodesList(pincodeArr)
        setSearchPostcode("")
    }
    const handleProvienceSearch =(e)=>{
    //    console.log(e.target.value)
       if(e.target.value?.length == 0){
        setDistricts([])
        setSubdistricts([]);
        setPostcodes([]);
        }
      if(e.target.value?.length >0){
        setProvincesDropDown(true)
       }

       setSearchProvience(e.target.value)
       setSearchProvincesList(provinces?.filter(data=>data.province?.trim()?.toLowerCase()?.includes(e.target.value?.trim()?.toLowerCase())))
    //    console.log(provinces)
    }
    const  handleProvienceDropDown=()=>{
        setProvincesDropDown(!provincesDropDown)
        setDistrictsDropDown(false)
        setSubdistrictsDropDown(false)
        setPostcodesDropDown(false)
    }

    const handleDistrictSearch =(e)=>{
        // console.log(e.target.value)
        if(e.target.value?.length == 0){
            // setDistricts([])
            setSubdistricts([]);
            setPostcodes([]);
        }
        if(e.target.value?.length >0){
            setDistrictsDropDown(true)
        }
        
        setSearchDistrict(e.target.value)
        setSearchDistrictsList(districts?.filter(data=>data.district?.trim()?.toLowerCase()?.includes(e.target.value?.trim()?.toLowerCase())))
        // console.log(provinces)
    }

    const  handleDistrictDropDown=()=>{
                setDistrictsDropDown(!districtsDropDown)
                setSubdistrictsDropDown(false)
                setProvincesDropDown(false)
                setPostcodesDropDown(false)
    }

    const handleSubdistrictSearch =(e)=>{
        // console.log(e.target.value)
        if(e.target.value?.length == 0){
            // setDistricts([])
            // setSubdistricts([]);
            setPostcodes([]);
        }
        if(e.target.value?.length >0){
            setSubdistrictsDropDown(true)
        }
        
        setSearchSubdistrict(e.target.value)
        setSearchSubdistrictsList(subdistricts?.filter(data=>data.subdistrict?.trim()?.toLowerCase()?.includes(e.target.value?.trim()?.toLowerCase())))
        // console.log(provinces)
    }

    const  handleSubdistrictDropDown=()=>{
                setSubdistrictsDropDown(!subdistrictsDropDown)
                setDistrictsDropDown(false)
                setProvincesDropDown(false)
                setPostcodesDropDown(false)
    }

    const handlePostcodeSearch =(e)=>{
        // console.log(e.target.value)
        
        if(e.target.value?.length >0){
            setPostcodesDropDown(true)
        }
        
        setSearchPostcode(e.target.value)
        setSearchPostcodesList(postcodes?.filter(data=>data.postalcode?.trim()?.toLowerCase()?.includes(e.target.value?.trim()?.toLowerCase())))
        setCusInfo((prevValues) => ({
            ...prevValues,
            ["postcode"]: e.target.value
        }));
        // console.log(provinces)
    }

    const  handlePostcodeDropDown=()=>{
                setPostcodesDropDown(!postcodesDropDown)
                setDistrictsDropDown(false)
                setSubdistrictsDropDown(false)
                setProvincesDropDown(false)
    }
    
    const changeColourSize=()=>{
        if (phoneNoRef.current) {
            phoneNoRef.current.style.backgroundColor = "rgb(242, 242, 242)";
            phoneNoRef.current.style.color = "rgba(0, 0, 0, 0.38)";
          }
          if (dateRef.current) {
            // dateRef.current.style.cssText = "text-align: right !important;";
          }
    }
    useEffect(() => {
        if (success == false) {
            dispatch(getCustomer(localStorage.getItem("mobile")));
        }
        else if (customerDetails?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }       
    }, [success]);
    useEffect(()=>{
        getApi();
        changeColourSize()
    },[customerDetails,disabled,cmsData])

    useEffect(()=>{
        (async()=>{
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData?.province?.length == 0
            ) {
            const provinceApi = await CmsDataService.getCmsData("provinces");
            if (provinceApi?.status == 200) {
                updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["province"]: provinceApi?.data,
                    ["language"]: localStorage.getItem("i18nextLng"),
                }));
            }
        }
        })()
        // return()=>{
        //     dispatch(resetData());
        // }
    },[])

    useEffect(() => {
        // (async () => {
        //     const ppimageApi = await CustomerApiService.imageGet(profilepic)
        //     if (ppimageApi?.status == "true") {
        //         setppImage(ppimageApi);
        //     } else {
        //         console.log("network error");
        //     }
        // })()
        if(imageSuccess== false && profilePic?.length>0){
        dispatch(getProfilePicture(profilePic))
        }
        if (profileImage?.status == "true" ) {
            setppImage(profileImage)
        } 
        else if (profileImage?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
    }, [profilePic,profileImage]);
    const filteredProvince = (arr) =>{
        const provSet = new Set();
        return arr.filter(obj=>{
            if(!provSet.has(obj.province)){
                provSet.add(obj.province)
                return true
            }
            return false
        })
    }
    const filtereddistrict = (arr) =>{
        const provSet = new Set();
        return arr.filter(obj=>{
            if(!provSet.has(obj.district)){
                provSet.add(obj.district)
                return true
            }
            return false
        })
    }
    const filteredsubdistrict = (arr) =>{
        const provSet = new Set();
        return arr.filter(obj=>{
            if(!provSet.has(obj.subdistrict)){
                provSet.add(obj.subdistrict)
                return true
            }
            return false
        })
    }
    useEffect(()=>{
        const GetProvince = cmsData?.province;
        const filterProvinceResp = filteredProvince(GetProvince);
        setProvinces(filterProvinceResp);
        setSearchProvincesList(filterProvinceResp)
        const districtArr = cmsData?.province.filter(data => data.provincecode === province);
        const filterDistrictResp = filtereddistrict(districtArr);
        setDistricts(filterDistrictResp);
        setSearchDistrictsList(filterDistrictResp)
        // handleDistrict(province);
        // handlesubDistrict(district);
        const subdistrictArr = cmsData?.province.filter(data => data.districtcode === district);
        const filterSubdistrictResp = filteredsubdistrict(subdistrictArr);
        setSubdistricts(filterSubdistrictResp);
        setSearchSubdistrictsList(filterSubdistrictResp)
        // handlepostcode(subdistrict);
        const pincodeArr = cmsData?.province.filter(data => data.subdistrictcode === subdistrict);
        setPostcodes(pincodeArr);
        setSearchPostcodesList(pincodeArr)
    },[cmsData,customerDetails,district]);
    return (
        <>
            {loading ?    <div className="loader">
                <CircularProgress />
                <div> Loading....</div>
            </div>   :
            
                <div className="edit-profile">
                    <div className="login-content">
                        <div className="row align-items-center border-bottom plr-15">
                            <div className="col-2 text-center">
                                <div className="backpageSection">
                                    <Link to="/viewprofile">
                                        <img src={Back} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="boldhHeadingH5 text-center">{t('viewprofile.edit')}</h5>
                            </div>
                            <div className="col-2"></div>
                        </div>
                        {updateLoading && 
              <div className="loading-overlay" style={{backgroundColor:"transparent"}}>
              <div className="blurred-background" style={{backgroundColor:"transparent"}}></div>
              <CircularProgress />
            </div>
              }
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input className="inputField" type="file" onChange={(event) => { setSelectedImage(event.target.files[0]); }} id="imageUpload" accept=".png, .jpg, .jpeg" />
                                <label htmlFor="imageUpload">
                                    <img
                                        className="fileimage"
                                        src={Camera}
                                    />
                                </label>
                            </div>
                            <div className="avatar-preview">
                                {/* {console.log(ppimage.file_path)} */}
                                <div
                                    id="imagePreview"
                                    style={{
                                        backgroundImage: ppimage.file_path ? `url('${ppimage.file_path}')` : `url('${profile}')`,
                                    }}
                                // style="background-image: url(https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDM2ODA0NA&ixlib=rb-1.2.1&q=85);"
                                ></div>
                            </div>
                        </div>
                        <div className="inputfield">
                            <label htmlFor="firstname">{t('viewprofile.name')} </label>
                            <br />
                            <input className="inputField" type="text" id="firstname" name="firstname" value={firstname} onChange={handleChange} />
                            {(firstname == "") ? <span className="error-message">{t('viewprofile.first_name_error')}</span> : ""}
                        </div>

                        <div className="inputfield">
                            <label htmlFor="lastname">{t('viewprofile.lastname')} </label>
                            <br />
                            <input className="inputField" type="text" id="lastname" name="lastname" value={lastname} onChange={handleChange} />
                            {(lastname == "") ? <span className="error-message">{t('viewprofile.last_name_error')}</span> : ""}
                        </div>

                        <div className="inputfield">
                            <div className="moblabel">
                                <label htmlFor="lastname">{t('viewprofile.phoneno')} </label>
                                <br />
                                <span className="labelsub">{t('viewprofile.contact_cs')}</span>
                            </div>
                            <div className="mobile">
                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    countries={Object.keys(countryCodes)}
                                    customLabels={countryCodes}
                                    showSelectedLabel={true}
                                    showSecondarySelectedLabel={false}
                                    fullWidth={false}
                                    searchable
                                    style={{WebkitTextFillColor:"rgba(0, 0, 0, 0.38)",backgroundColor:"rgb(242, 242, 242)"}}
                                    className="customFlagSelect"
                                />
                                <input ref={phoneNoRef} className="inputField" type="text" id="phoneNo" name="phoneNo" readOnly={true} value={mobile?.slice(2, 12)} onChange={handleChange} />
                            </div>
                            {/* {(mobile.length != "12")?<span className="error-message">Please enter valid phone number</span>:""} */}
                        </div>

                        <div className="inputfield">
                            <label htmlFor="email">{t('viewprofile.email')} </label>
                            <br />
                            <input className="inputField" type="text" spellCheck="false" id="email" name="email" value={email}
                                // className={disabled ? "disabled" : ""}
                                onChange={handleChange}
                                // disabled={((email == "" && !emailerr.length > 0) || (email == null  && !emailerr.length > 0))?false:true}
                                // disabled={disabled}
                                // onBlur={handleBlur}
                            />
                             <span className="error-message">{(emailerr != "") ? emailerr : ""}</span>
                             {(email == "" && emailerr=="") ? <span className="error-message">{t('viewprofile.email_error')}</span> : ""}
                            
                        </div>

                        {/* <div className="inputfield">
                            <label htmlFor="gender">{t('viewprofile.gender')} </label>
                            <div className="radiobutton">
                                <input className="inputField" type="radio" value="Male" id="gender" name="gender" onChange={handleChange} checked={(gender == "Male") ? true : false} />
                                <span className="radiolabel"> {t('editprofile.male')}</span>
                                <input className="inputField" type="radio" value="Female" id="gender" name="gender" onChange={handleChange} checked={(gender == "Female") ? true : false} />
                                <span className="radiolabel"> {t('editprofile.female')}</span>
                                <input
                                    className="inputField"
                                    type="radio"
                                    value="Transgender"
                                    id="gender"
                                    name="gender" onChange={handleChange}
                                    checked={(gender == "Transgender") ? true : false}
                                />
                                <span className="radiolabel"> {t('editprofile.LGBTQIA+')}</span>
                                <input
                                    className="inputField"
                                    type="radio"
                                    value="Other"
                                    id="gender"
                                    name="gender" onChange={handleChange}
                                    checked={(gender == "Other") ? true : false}
                                />
                                <span className="radiolabel">{t('editprofile.rather_not_say')}</span>
                            </div>
                            {(gender == "") ? <span className="error-message">{t('viewprofile.gender_error')}</span> : ""}
                        </div> */}
                        <div className="">
                        <label style={{padding:"15px 0px 0px 15px"}} htmlFor="gender">{t('viewprofile.gender')} </label>
                        <RadioGroup value={gender} name="gender" id="gender" onChange={handleChange} sx={{ display: 'flex', flexDirection: 'row' }} style={{padding:"0px 0px 0px 20px"}}>
      <FormControlLabel value="Male" control={<Radio />} label={<span style={{ fontSize: '14px' }}>{t('editprofile.male')}</span>} sx={{marginRight: '10px','& .MuiSvgIcon-root': {fontSize: 20},'& .MuiButtonBase-root': {padding:"5px"} }} />
      <FormControlLabel value="Female" control={<Radio />} label={<span style={{ fontSize: '14px' }}>{t('editprofile.female')}</span>} sx={{marginRight: '10px','& .MuiSvgIcon-root': {fontSize: 20},'& .MuiButtonBase-root': {padding:"5px"}  }} />
      <FormControlLabel value="Transgender" control={<Radio />} label={<span style={{ fontSize: '14px' }}>{t('editprofile.LGBTQIA+')}</span>} sx={{marginRight: '10px','& .MuiSvgIcon-root': {fontSize: 20},'& .MuiButtonBase-root': {padding:"5px"}  }} />
      <FormControlLabel value="Other" control={<Radio />} label={<span style={{ fontSize: '14px' }}>{t('editprofile.rather_not_say')}</span>} sx={{marginRight: '10px' ,'& .MuiSvgIcon-root': {fontSize: 20},'& .MuiButtonBase-root': {padding:"5px"} }}  />
    </RadioGroup>
    {(gender == "") ? <span style={{paddingLeft:"15px"}} className="error-message">{t('viewprofile.gender_error')}</span> : ""}
    </div>
                        <div className="inputfield">
                            <div className="doblabel">
                                <label htmlFor="dob">{t('viewprofile.dateofbirth')} </label>
                                <br />
                                <span className="labelsub">
                                    {t('editprofile.editinglater')}
                                </span>
                            </div>

                            {!useDobValue ?
                            <TextField
                        className="inputFieldDatePicker"
                             type="date"
                            value={dob}
                            ref={dateRef}
                            format={'dd/MM/yyyy'}
                            id="date" 
                            name="dob" 
                            disabled={false}
                            onChange={handleChange}
                            InputProps={{
                                max: new Date().toISOString().split('T')[0],
                                style: {
                                  textAlign: 'left',
                                  WebkitAppearance: 'none',
                                  fontSize:"14px"
                                },
                                sx:{textAlign:"center"},
                                                                inputProps: {
                                    format: 'dd-MM-yyyy', // Set the desired date format
                                  },
                              }}
                             
                         
                        />
                        :
                        <div className="inputField disabled" style= {{
                            textAlign: 'left',
                            backgroundColor:  "#F2F2F2",
                            WebkitAppearance: 'none',
                            color: "rgba(0, 0, 0, 0.38)"
                          }}>{dob.split("-").reverse().join("/")}</div>} 

                        <span className="error-message">{(dobError != "") ? dobError : ""}</span>
                             {(dob == "" && dobError=="") ? <span className="error-message">{t('viewprofile.dob_error')}</span> : ""}
                        </div>

                        

                        <div className="inputfield">
                            <label htmlFor="address">{t('viewprofile.address')}</label>
                            <br />
                            <input className="inputField" type="text" id="address" name="address" onChange={handleChange} value={address} />
                            {(address == "") ? <span className="error-message">{t('viewprofile.address_error')}</span> : ""}
                        </div>

                        <div className="inputfield">
                            <label htmlFor="province">{t('editprofile.province')} </label>
                            <br />
                            <div style={{display:"flex"}} onClick={handleProvienceDropDown}>
                        <input
                          className="inputField"
                          type="text"
                          id="searchProvience"
                          placeholder={t('editprofile.select_provience')}
                          autoComplete="off"
                          name="province"
                          value={searchProvience}
                          onChange={handleProvienceSearch}
                          style={{
                            height: "50px",
                            borderRight: "1px solid #C1C1C1",
                            color:"black"
                          }}
                        ></input>
                         <img src={Dropdown} style={{marginLeft:"-15px", width:"10px"}} />
                      </div>             
                       {provincesDropDown &&  <div>
                          <ul
                            className="dropdown-editprofile"
                            style={{
                              paddingLeft: "0px",
                              fontSize: "inherit",
                              marginBottom: "5px",
                              position:"absolute",
                              backgroundColor:"#F0F0F0"
                            }}
                          >
                            {searchProvincesList?.map((option, id) => {
                              return (
                                <li
                                  key={`searchedStoreList${id}`}
                                  onClick={() => {handleCodeChange({name:"province",value:option.provincecode,data:option}); handleDistrict(option.provincecode)}}
                                  value={option.provincecode}
                                  name="province" 
                                  
                                  className="liHover"
                                >
                                  {option.province}
                                </li>
                              );
                            })}
                          </ul>
                        </div>}
                        
                      
                            {/* <select id="province" name="province" value={province} onChange={(e) => {handleChange(e); handleDistrict(e.target.value)}}>
                                <option>{t('editprofile.select_provience')}</option>
                                {provinces?.map((option)=>(
                                    <option key={option._id} value={option.provincecode}>{option.province}</option>
                                ))}
                            </select> */}
                            {(searchProvience == "") ? <span className="error-message">{t('viewprofile.province_error')}</span> : ""}
                        </div>
                        <div className="inputfield">
                            <label htmlFor="district">{t('editprofile.district')} </label>
                            <br />
                            <div style={{display:"flex"}} onClick={handleDistrictDropDown}>
                        <input
                        className="inputField"
                          type="text"
                          id="searchDistrict"
                          placeholder={t('editprofile.select_district')}
                          autoComplete="off"
                          name="district"
                          value={searchDistrict}
                          onChange={handleDistrictSearch}
                          style={{
                            height: "50px",
                            borderRight: "1px solid #C1C1C1",
                            color:"black"
                          }}
                        ></input>
                         <img src={Dropdown} style={{marginLeft:"-15px", width:"10px"}} />
                      </div>             
                       {districtsDropDown &&  <div>
                          <ul
                            className="dropdown-editprofile"
                            style={{
                              paddingLeft: "0px",
                              fontSize: "inherit",
                              marginBottom: "5px",
                              position:"absolute",
                              width:"91.5%",
                              backgroundColor:"#F0F0F0"
                            }}
                          >
                            {searchDistrictsList?.map((option, id) => {
                              return (
                                <li
                                  key={`searchedDistrictList${id}`}
                                  onClick={() => {handleCodeChange({name:"district",value:option.districtcode,data:option}); handlesubDistrict(option.districtcode)}}
                                  value={option.provincecode}
                                  name="district" 
                                  
                                  className="liHover"
                                >
                                  {option.district}
                                </li>
                              );
                            })}
                          </ul>
                        </div>}
                            {/* <select id="district" name="district" value={district} onChange={(e)=>{handleChange(e); handlesubDistrict(e.target.value)}}>
                                <option>{t('editprofile.select_district')}</option>
                                {districts?.map((option)=>(
                                    <option key={option._id} value={option.districtcode}>{option.district}</option>
                                ))}
                            </select> */}
                            {(searchDistrict == "") ? <span className="error-message">{t('viewprofile.district_error')}</span> : ""}
                        </div>
                        <div className="inputfield">
                            <label htmlFor="district">{t('editprofile.subdistrict')} </label>
                            <br />
                            <div style={{display:"flex"}} onClick={handleSubdistrictDropDown}>
                        <input
                        className="inputField"
                          type="text"
                          id="searchSubdistrict"
                          placeholder={t('editprofile.select_subdistrict')}
                          autoComplete="off"
                          name="district"
                          value={searchSubdistrict}
                          onChange={handleSubdistrictSearch}
                          style={{
                            height: "50px",
                            borderRight: "1px solid #C1C1C1",
                            color:"black"
                          }}
                        ></input>
                         <img src={Dropdown} style={{marginLeft:"-15px", width:"10px"}} />
                      </div>             
                       {subdistrictsDropDown &&  <div>
                          <ul
                            className="dropdown-editprofile"
                            style={{
                              paddingLeft: "0px",
                              fontSize: "inherit",
                              marginBottom: "5px",
                              position:"absolute",
                              width:"91.5%",
                              backgroundColor:"#F0F0F0"
                            }}
                          >
                            {searchSubdistrictsList?.map((option, id) => {
                              return (
                                <li
                                  key={`searchedSubdistrictList${id}`}
                                  onClick={() => {handleCodeChange({name:"subdistrict",value:option.subdistrictcode,data:option}); handlepostcode(option.subdistrictcode)}}
                                  value={option.subdistrictcode}
                                  name="subdistrict" 
                                  
                                  className="liHover"
                                >
                                  {option.subdistrict}
                                </li>
                              );
                            })}
                          </ul>
                        </div>}
                            {/* <select id="subdistrict" name="subdistrict" value={subdistrict} onChange={(e)=>{handleChange(e); handlepostcode(e.target.value)}}>
                                <option>{t('editprofile.select_subdistrict')}</option>
                                {subdistricts?.map((option)=>(
                                    <option key={option._id} value={option.subdistrictcode}>{option.subdistrict}</option>
                                ))}
                            </select> */}
                            {(searchSubdistrict == "") ? <span className="error-message">{t('viewprofile.subdistrict_error')}</span> : ""}
                        </div>
                        <div className="inputfield">
                            <label htmlFor="postcode">{t('editprofile.postcode')}</label>
                            <br />
                            {/* <input
                          type="number"
                          id="postcode" name="postcode" value={postcode}
                          placeholder={t('editprofile.select_postal_code')}
                          className="w-100 p-2"
                          onChange={handleChange}
                          style={{
                            height: "50px",
                            borderRight: "1px solid #C1C1C1",
                          }}
                        ></input> */}
                            {/* <select id="postcode" name="postcode" value={postcode} onChange={handleChange}>
                                <option>{t('editprofile.select_postal_code')}</option>
                                {postcodes?.map((option)=>(
                                    <option key={option._id} value={option.postalcode}>{option.postalcode}</option>
                                ))}
                            </select> */}
                            {/* <input type="text" id="postcode" name="postcode" value={postcode} onChange={handleChange} /> */}
                            <div style={{display:"flex"}} onClick={handlePostcodeDropDown}>
                        <input
                        className="inputField"
                          type="text"
                          id="searchPostcode"
                          placeholder={t('editprofile.select_postal_code')}
                          autoComplete="off"
                          name="postcode"
                          value={searchPostcode}
                          onChange={handlePostcodeSearch}
                          style={{
                            height: "50px",
                            borderRight: "1px solid #C1C1C1",
                            color:"black"
                          }}
                        ></input>
                         <img src={Dropdown} style={{marginLeft:"-15px", width:"10px"}} />
                      </div>             
                       {postcodesDropDown &&  <div>
                          <ul
                            className="dropdown-editprofile"
                            style={{
                              paddingLeft: "0px",
                              fontSize: "inherit",
                              marginBottom: "5px",
                              position:"absolute",
                              width:"91.5%",
                              backgroundColor:"#F0F0F0"
                            }}
                          >
                            {searchPostcodesList?.map((option, id) => {
                              return (
                                <li
                                  key={`searchedPostcodeList${id}`}
                                  onClick={() => {handleCodeChange({name:"postcode",value:option.postalcode,data:option})}}
                                  value={option.postalcode}
                                  name="postcode" 
                                  
                                  className="liHover"
                                >
                                  {option.postalcode}
                                </li>
                              );
                            })}
                          </ul>
                        </div>}
                            {(searchPostcode == "") ? <span className="error-message">{t('viewprofile.postcode_error')}</span> : ""}
                        </div>
                        <div className="profile-fields1">
                            <button type="submit" disabled={buttonDisabled} onClick={handleSubmit}>{t('editprofile.save')}</button>
                        </div>
                        <div style={{ height: '150px' }}></div>
                    </div>
                </div>}
        </>
    );
};

export default EditProfile;
