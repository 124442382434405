/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow.svg"
import './notification.scss'
import { useNavigate } from 'react-router-dom'
import CustomerApiService from '../../utils/CustomerApiService'
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, postcusNotification } from '../../redux/slices/customer/GetCustomer';
import { useTranslation } from 'react-i18next'
import { resetAllData } from "../../utils/resetActions";
const Notification = () => {
    const { t } = useTranslation();
    const [notifications, setNofications] = useState(
        {
            sms: false,
            email: false,
            line: false,
        }
    )
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { customerDetails, loading, success } = useSelector(state => state.getCustomer);
    const handleClick = async (e) => {
        console.log(e.target.checked, e.target.id)
        setNofications((prevValues) => ({
            ...prevValues,
            [e.target.id]: !notifications[e.target.id],
        }));

        let postData = {
            "root": {
                "customer": [{
                    "mobile": localStorage.getItem("mobile"),
                    "custom_fields": {
                        "field": [

                        ]
                    },
                    "extended_fields": {
                        "field": [

                        ]
                    },
                    "subscriptions": {
                        "subscription": [
                            { "priority": "TRANS", "scope": "all", "is_subscribed": "1", "channel": "email" },
                            { "priority": "BULK", "scope": "all", "is_subscribed": "1", "channel": "email" },

                        ]
                    }
                }
                ]
            }
        }

        postData?.root?.customer[0]?.subscriptions?.subscription?.forEach(sub => {
            sub.channel = e.target.id;
            if (e.target.checked) {
                sub.is_subscribed = "1";
            }
            else {
                sub.is_subscribed = "0";
            }
        });
        await dispatch(postcusNotification(postData));
        await dispatch(getCustomer(localStorage.getItem("mobile")));
    }
    const goBack = () => {
        navigate(-1);
    };
    const getSub = () => {
        if (customerDetails?.status?.success == "true") {
            let sms, email, line;
            sms = customerDetails?.customers?.customer?.[0]?.subscription?.[0]?.channel?.find(item => item?.name === "SMS")
            email = customerDetails?.customers?.customer?.[0]?.subscription?.[0]?.channel?.find(item => item?.name === "EMAIL")
            line = customerDetails?.customers?.customer?.[0]?.subscription?.[0]?.channel?.find(item => item?.name === "LINE")

            if (sms?.priority[0]?.user_preference == "SUBSCRIBED") {
                setNofications((prevValues) => ({
                    ...prevValues,
                    ["sms"]: true,
                }));
            }
            if (email?.priority[0]?.user_preference == "SUBSCRIBED") {
                setNofications((prevValues) => ({
                    ...prevValues,
                    ["email"]: true,
                }));
            }
            if (line?.priority[0]?.user_preference == "SUBSCRIBED") {
                setNofications((prevValues) => ({
                    ...prevValues,
                    ["line"]: true,
                }));
            }
        }
    }
    useEffect(() => {
        if (success == false) {
            dispatch(getCustomer(localStorage.getItem("mobile")));
        }
        else if (customerDetails?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        getSub();
    }, [customerDetails]);

    return (
        <>
            {loading ? <div className="loader">
                <CircularProgress />
                <div> Loading....</div>
            </div> :
                <section className="samelayoutpage">
                    <div className="container">
                        <div className="row align-items-center border-bottom plr-15">
                            <div className="col-1">
                                <div className="backpageSection" onClick={goBack}>

                                    <img src={Back} alt="" />

                                </div>
                            </div>
                            <div className="col">
                                <h5 className="nav_heading text-center">
                                    {t('notification.notification')}
                                </h5>
                            </div>
                            <div className="col-2"></div>
                        </div>
                        {loading ? <div
                            className="loader"
                        >
                            <CircularProgress />
                            <div> Loading....</div>
                        </div> :
                            <div className="toggler-section">
                                <div className="d-flex justify-content-between form-check form-switch px-0">
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault" >{t('notification.sms')}</label>
                                    <input className="form-check-input" type="checkbox" checked={notifications.sms} role="switch" id="sms" onChange={handleClick} />
                                </div>
                                <div className="d-flex justify-content-between form-check form-switch px-0">
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t('notification.email')}</label>
                                    <input className="form-check-input" type="checkbox" checked={notifications.email} role="switch" id="email" onChange={handleClick} />
                                </div>
                                <div className="d-flex justify-content-between form-check form-switch px-0">
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t('notification.line')}</label>
                                    <input className="form-check-input" type="checkbox" checked={notifications.line} role="switch" id="line" onChange={handleClick} />
                                </div>
                            </div>}
                    </div>
                </section>}
        </>
    )
}

export default Notification