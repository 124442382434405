import React, { useEffect, useState } from "react";
import Back from "../../assets/images/backarrow.svg"
import Challenge from "../../assets/images/chanllenger_reward.png"
import './earnProgress.scss'
import greencheck from "../../assets/images/pdpa-green-check.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getMilestoneReward } from "../../redux/slices/Rewards/GetMilestoneRewards"; 
import { format } from 'date-fns';
import { th } from "date-fns/locale";
import { useLocation } from "react-router-dom";
import gqcoin from "../../assets/images/GQ-Coin.png";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";

const EarnProgress = () => {
  const { cmsData, updateCmsData } = useCms();
  const search = useLocation().search;
  const targetId = new URLSearchParams(search).get("id");
  const { t } = useTranslation();
  const [challengeBg, setChallengeBg] = useState(null);
  const [milestoneRewards,setMilestoneRewards]=useState([])
  const { milestoneRewardDetails, milestoneRewardsuccess , mileStoneLoading} = useSelector(
    (state) => state.getMilestoneReward
);
const [mileStoneRewards,setMileStoneReward]=useState()
const dispatch = useDispatch();
  useEffect(()=>{
    if (milestoneRewardsuccess == false) {
        dispatch(getMilestoneReward());
    }
    if(milestoneRewardDetails?.data){

      let targets=[];
      if (milestoneRewardDetails?.data?.targetGroups?.length > 0) { 
          milestoneRewardDetails?.data?.targetGroups.map((targetGroup, targetGroupIndex) => {
              targetGroup?.targets?.map((target,targetIndex)=>{
                if(new Date(target?.periodEndDate).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)){
                  let temp={...target};
                  temp['id']=targetGroup.id;
                  targets.push(temp)
                  }
              })
          })
          // let filtered=milestoneRewardDetails?.data?.targetGroups?.filter(e=>new Date(e.targets[0].periodEndDate).setHours(0,0,0,0) > new Date().setHours(0,0,0,0))
          let temp=targets?.filter(e=>e?.targetId == targetId)
          
          if(temp){
            let updatedFiltered=temp;
            
            let tempMileStoneReward= milestoneRewards?.filter((e)=> {return e.targetid == temp[0].id})
                   
                   if(tempMileStoneReward?.length>0){
                   updatedFiltered[0].points=tempMileStoneReward?.[0]?.points,
                   updatedFiltered[0].description=tempMileStoneReward?.[0]?.description
                }
                setMileStoneReward(updatedFiltered[0])
                // setMileStoneReward(temp[0])
          }
            }
          }



        // if (milestoneRewardDetails?.data?.targetGroups?.length > 0) { 
        //   let temp=milestoneRewardDetails?.data?.targetGroups?.filter(e=>e?.targets[0].targetId == targetId)
        //   if(temp)
        //         setMileStoneReward(temp[0]?.targets)
        //     }
    // }
},[milestoneRewardDetails,milestoneRewards])

useEffect(() => {
  (async () => {
      if (
          cmsData.language !== localStorage.getItem("i18nextLng") ||
          cmsData?.assets?.length == 0
      ) {
          const assetsData = await CmsDataService.getCmsData("assets");
          if (assetsData?.status == 200) {
              updateCmsData((prevValues) => ({
                  ...prevValues,
                  ["assets"]: assetsData?.data[0],
                  ["language"]: localStorage.getItem("i18nextLng"),
              }));
          }
      }
      if (
        cmsData.language !== localStorage.getItem("i18nextLng") ||
        cmsData?.milestoneRewrads?.length == 0
    ) {
        const milestoneRewradsData = await CmsDataService.getCmsData("milestonerewards");
        if (milestoneRewradsData?.status == 200) {
            updateCmsData((prevValues) => ({
                ...prevValues,
                ["milestoneRewrads"]: milestoneRewradsData?.data,
                ["language"]: localStorage.getItem("i18nextLng"),
            }));
        }
    }
  })();
}, [])
useEffect(() => {
 
  if (cmsData?.assets?.length !==0){
  setChallengeBg(cmsData?.assets?.challengesone)
  }
  if (cmsData?.milestoneRewrads?.length !==0){
    setMilestoneRewards(cmsData?.milestoneRewrads)
  }
}, [cmsData])

  return (
    <>
    {mileStoneLoading ?
            <div className="loader">
            <CircularProgress />
            <div> Loading....</div>
          </div>
    :
      <section className="earnprogress">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div className="backpageSection">
                <Link to="/earn">
                  <img src={Back} alt="" />
                </Link>
              </div>
            </div>
            <div className="col">
              <h5 className="boldhHeadingH5_topHeading text-center">{t('earn.earn')}</h5>
            </div>
            <div className="col"></div>
          </div>
        </div>
        <div className="earnprogressSec">
          <div className="img-section">
            <img
              src={challengeBg? challengeBg :Challenge}
              alt=""
              width="100%"
            />
          </div>
        </div>
        <div className="container-fluid footerEndSp">
          <div className="challenger-box">
            <h5 className="boldhHeadingH5 text-center">{t('earn.challenge_progress')}</h5>
            <div className="row justify-content-center">
              <div className="col-8">
                <div
                  role="progressbar"
                  aria-label="Success example"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  className="progress"
                  style={{backgroundColor:"#EBEBEB",border:"1px solid #EBEBEB"}}
                >
                  <div className="progress-bar-earn" style={{ width:  `${((mileStoneRewards?.targetAchievedValue/mileStoneRewards?.targetValue) * 100).toFixed(2)}%`,backgroundColor:"#00AD2F"}}></div>
                </div>
                <div className="d-flex justify-content-between Ponit_status">
                  <p>{mileStoneRewards?.targetAchievedValue >= mileStoneRewards?.targetValue ? mileStoneRewards?.targetValue : mileStoneRewards?.targetAchievedValue}/{mileStoneRewards?.targetValue}</p>
                 {mileStoneRewards?.targetAchievedValue < mileStoneRewards?.targetValue ?  <p className="tier_points_status">{(new Date(mileStoneRewards?.periodEndDate).setHours(0,0,0,0)- new Date().setHours(0,0,0,0))/(24 * 60 * 60 * 1000) } {" "}{t('earn.days_left')}</p>
                 :
                 <p className={mileStoneRewards?.targetAchievedValue < mileStoneRewards?.targetValue ? "tier_points_status_gray" :"tier_points_status_green"}><img src={greencheck} style={{ paddingRight: "2px",marginBottom:"2px",height:"8px" }}></img>{t('earn.completed')}</p>}
                </div>
              </div>
            </div>
            <div className="challenger-details">
              <h5 className="boldhHeadingH5">
                {mileStoneRewards?.targetName}
              </h5>
              <div className="points store_channel">
              <img src={gqcoin} className="GQ-Coin" />
                <span className="pointBold">{mileStoneRewards?.points ? mileStoneRewards?.points : 0}</span> {t('earn.points')}
              </div>
              <div className="date">
                <p>
                {t('global.expire_in')}{": "}<span>{ mileStoneRewards?.periodEndDate ?   localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(mileStoneRewards?.periodEndDate),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(mileStoneRewards?.periodEndDate),
                                                "dd MMM yyyy"
                                              ) :""}</span>
                </p>
              </div>
            </div>
            <div className="howWork">
              <h6 className="title">{t('earn.how_it_work')}</h6>
              <p className="description">
                {mileStoneRewards?.description}
              </p>
            </div>
          </div>
        </div>
      </section>}
    </>
  );
};

export default EarnProgress;
