/* eslint-disable no-undef */
import axios from "axios"
axios.defaults.headers.common['Content-Type'] = 'application/json';
class AuthService {

    async tokenGenerate() {
        try {
            const idVal = localStorage.getItem("mobile")

            if ([undefined, null, 'undefined', 'null'].includes(idVal)) {
                throw 'idVal not set';
            }
            const body = {
                identifierType: process.env.REACT_APP_identifierType,
                identifierValue: idVal,
                brand: process.env.REACT_APP_brand,
                // password: password,
                // confirmPassword: password,
                deviceId: process.env.REACT_APP_deviceId,
            };

            const data = await axios.post(`${process.env.REACT_APP_authURL}token/generate`, body);
            localStorage.setItem('ROsession', data.data.user?.sessionId);
            return data.data
        } catch (error) {
            console.error('Tocken generation failed', error);
            throw error;
        }
    }

    async generateOtp() {
        const idVal = localStorage.getItem("mobile")

        const body = {
            identifierType: process.env.REACT_APP_identifierType,
            identifierValue: idVal,
            brand: process.env.REACT_APP_brand,
            sessionId: localStorage.getItem("ROsession"),
            deviceId: process.env.REACT_APP_deviceId,
        };

        const generateOtpUrl = process.env.REACT_APP_authURL + 'otp/generate';
        const data = await axios.post(generateOtpUrl, body);
        return data;
    }

    async validateOtp(otpValue) {
        const idVal = localStorage.getItem("mobile")

        const body = {
            identifierType: process.env.REACT_APP_identifierType,
            identifierValue: idVal,
            brand: process.env.REACT_APP_brand,
            sessionId: localStorage.getItem("ROsession"),
            deviceId: process.env.REACT_APP_deviceId,
            otp: otpValue,
        };

        const validateOtpUrl = process.env.REACT_APP_authURL + 'otp/validate';
        const data = await axios.post(validateOtpUrl, body);
        if (data.data.status.success) {

            localStorage.setItem("token", data.data.auth.token);
            localStorage.setItem("tokenKey", data.data.auth.key)
        }
        return data.data;
    }

    async passwordValidate(password) {
        try {
            const idType = process.env.REACT_APP_identifierType;
            const idVal = localStorage.getItem("mobile");
            const body = {
                identifierType: idType,
                identifierValue: idVal,
                brand: process.env.REACT_APP_brand,
                password: password,
                sessionId: localStorage.getItem("ROsession"),
                deviceId: process.env.REACT_APP_deviceId,
            };
            const passwordValidateUrl = process.env.REACT_APP_authURL + 'password/validate';
            const data = await axios.post(passwordValidateUrl, body)
            if (data.data.status.success) {
                localStorage.setItem("token", data.data.auth.token);
                localStorage.setItem("mobile", idVal)
            }
            return data.data;
        }
        catch (e) {
            console.log(e)
        }

    }

    async tokenExpire(Token) {
        try {
            const body = {
                brand: process.env.REACT_APP_brand,
                token: Token,
                deviceId: process.env.REACT_APP_deviceId
            };
            const TokenexpireUrl = process.env.REACT_APP_authURL + 'token/expire';
            const data = await axios({
                method: 'DELETE',
                url: TokenexpireUrl,
                data: body
            })
            return data.data;
        }
        catch (e) {
            console.log(e)
        }

    }

    async getStorelocators(latitude, lngitude) {
        try {
            const data = await axios.get(`${process.env.REACT_APP_storegetURL}v1/brand/nearbystores?latitude=${latitude}&longitude=${lngitude}&distance=5&size=10000`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_mobile: localStorage.getItem("mobile"),
                    cap_brand: process.env.REACT_APP_brand,
                    cap_device_id: process.env.REACT_APP_deviceId,
                    'Content-Type': "application/json",
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
}

export default new AuthService();