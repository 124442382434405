import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow.svg"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import CmsDataService from '../../utils/CmsDataService';
const HowToRedeem = () => {
    const { t } = useTranslation();
    const [howToRedeem, setHowToRedeem] = useState([])
    useEffect(() => {
        (async () => {
            const data = await CmsDataService.getCmsData("howtoredeem")
            console.log(data.data)
            setHowToRedeem(data?.data)
        })()
    }, [])
  return (
    <>
    <section className="samelayoutpage">
    <div className="container">
        <div className="row align-items-center border-bottom">
            <div className="col-1">
                <div className="backpageSection">
                    <Link to="/profile" >
                        <img src= {Back}alt="" />
                    </Link>
                </div>
            </div>
            <div className="col">
                <h5 className="nav_heading text-center">
                {t('rewards.how_to_redeem')}
                </h5>
            </div>
            <div className="col-1"></div>
        </div>
        <div className="terms-text">
        {howToRedeem?.map((item) => (
                            <div key={item._id}>
                                <div dangerouslySetInnerHTML={
                                    { __html: item.description }
                                } />
                            </div>
                        ))}
        </div>
    </div>
</section>
    </>
  )
}

export default HowToRedeem