/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getOrder = createAsyncThunk(
    "customer/getOrder",
    async() => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}customer/transactions/get?limit=50&size=100`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    orderLoading: false,
    customerOrder: {},
    error: "",
    orderSuccess: false
}
const getOrderhistorySlice = createSlice({
    name: "getOrderhistory",
    initialState,
    reducers:{
        resetOrderData: (state) => {
            state.customerOrder = {};
            state.orderSuccess=false
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrder.pending,(state)=>{
            state.orderLoading = true;
        });
        builder.addCase(getOrder.fulfilled, (state,action) => {
            state.orderLoading = false;
            state.customerOrder = action.payload;
            state.orderSuccess = true;
        });
        builder.addCase(getOrder.rejected,(state)=>{
            state.orderLoading = false;
            state.error = "No data";
        });
    }
});

export {getOrder};
export const { resetOrderData } = getOrderhistorySlice.actions;
export default getOrderhistorySlice.reducer;