/* eslint-disable no-undef */


import React from "react";
import Axios from "./AxiosService"
import { format } from 'date-fns';
import { toast } from "react-toastify";
import axios from "axios";
class CustomerApiService {

    constructor(dispatch) {
        this.dispatch = dispatch;
    }


    async getCustomerByMobile(mobilenumber) {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}customer/get/any?phno=${mobilenumber}&mlp=true`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async registerCustomerNO(body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}customer/shopify?mobile_number=${localStorage.getItem("mobile")}&first_name=${body?.profiles?.[0]?.firstName}&last_name=${body?.profiles?.[0]?.lastName}`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
    async registerShopifyCustomer(body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}shopifyregistration`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
    async mergeLineIdWithMobile(userId,body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}changeidentifierline?source=LINE&accountId=${process.env.REACT_APP_CRM_ORG_ID}&userid=${userId}`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async getReferralCode() {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}customer/referrals`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_mobile: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async getMarvelrewards() {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}marvel/reward/detail?language=${localStorage.getItem("i18nextLng")}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async updateCustomer(body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}customer/update`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async issueReward(id, rewardName) {
        try {
            var todayDate = new Date().getTime().toString();
            var today = new Date();
            let body = {
                "transactionNumber": id + "-" + todayDate.toString(),
                "notes": "1 X " + rewardName,
                "redemptionTime": format(today, 'yyyy-MM-dd HH:mm:ss'),
            }
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}marvel/rewards/issue?id=${id}&till_id=${process.env.REACT_APP_tillname}`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            });
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
    async customerGet() {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}/customer/get?format=json&user_id=true&mlp=true&gap_to_upgrade=1&subscriptions=true`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            });
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async getmyRewards() {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}get/marvel/vouchers?status=true&till_id=${process.env.REACT_APP_tillname}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            });
            return data.data;
        } catch (e) {
            console.log(e)
        }


    }

    async getTransaction() {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}customer/transactions/get?limit=10&size=100`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
    }

    async getpointsHistory() {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}points/history?format=json`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
    }

    async linelogin(lineId) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_lineWrapper}Auth/${lineId}`)
            return data.data
        } catch (e) {
            toast.error(e?.response?.data?.error,{toastId:"LineApiError"})
            console.log(e)
        }
    }

    // }
    // async getTransactionbynumber(txnno) {
    //     return this.http.get(env.gatewayURL + 'customer/transactions/getbynumber?number=' + txnno + '&tenders=true', {
    //     }).pipe(map(data => {
    //         return data;
    //     }));
    // }

    async imageURLget(filename) {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}image/upload?filename=` + filename, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
        // return this.http.get(env.gatewayURL + 'image/upload?filename=' + filename, {
        // }).pipe(map(data => {
        //     return data;
        // }));
    }

    async imageUpload(path, formdata) {
        try {
            const data = await Axios.put(path, formdata, {})
            return data.data
        } catch (e) {
            console.log(e)
        }
    }

    async imageGet(filename) {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}image?filename=` + filename, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
    }

    async getNearbyStores(latitude) {
        try {
            const data = await axios.get(`${process.env.REACT_APP_storegetURL}v1/brand/nearbystores?latitude=${latitude[0]}&longitude=${latitude[1]}&distance=${latitude[2]}&size=10000`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_mobile: localStorage.getItem("mobile"),
                    cap_brand: process.env.REACT_APP_brand,
                    cap_device_id: process.env.REACT_APP_deviceId,
                    'Content-Type': "application/json",
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async getCustomerByEmail(email) {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}customer/getbyemail?email=${email}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_type:'EMAIL',
                    cap_identifier_value: email,
                    cap_brand: process.env.REACT_APP_brand,
                    cap_device_id: process.env.REACT_APP_deviceId,
                    'Content-Type': "application/json",

                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async updateIdentifier(customerId,body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}customeridentifier?userid=${customerId}`, body , {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async getGoodWillPoints(customerId) {
        try {
            const data = await Axios.get(`${process.env.REACT_APP_gatewayURL}goodwillpoints?userid=${customerId}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async singleSignOn(body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}singlesignon`, body , {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

    async couponRedeem(body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}couponredeem`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
    async getDailyPoints(body) {
        try {
            const data = await Axios.post(`${process.env.REACT_APP_gatewayURL}daily_reward`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }

}

export default new CustomerApiService();