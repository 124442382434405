/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Back from "../../assets/images/backarrow.svg";
// import Coupon from "../../assets/images/couponhistoryimg.png";
import { Link, useNavigate } from 'react-router-dom'
import "./couponHistory.scss";
import CustomerApiService from "../../utils/CustomerApiService";
import { format } from 'date-fns';
import { th } from "date-fns/locale";
import { useTranslation } from 'react-i18next';
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getRewardVoucher } from '../../redux/slices/Rewards/GetRewardVoucher';
import { resetAllData } from "../../utils/resetActions";
import { resetRewardsVouchersData } from "../../redux/slices/Rewards/GetRewardVoucher";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";
const CouponHistory = () => {
    const { cmsData, updateCmsData } = useCms();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [marvelrewards, setMarvelrewards] = useState([]);
    const [birthdayRewards, setBirthdayRewards] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(new Date().toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }));
    const [selectedYear, setSelectedYear] = useState(new Date().toLocaleString('en-US', { year: 'numeric' }));
    const [hasHistory, setHasHistory] = useState(false)
    const monthOptions = [
        { value: '01', label: t('global.january') },
        { value: '02', label: t('global.february') },
        { value: '03', label: t('global.march') },
        { value: '04', label: t('global.april') },
        { value: '05', label: t('global.may') },
        { value: '06', label: t('global.june') },
        { value: '07', label: t('global.july') },
        { value: '08', label: t('global.august') },
        { value: '09', label: t('global.september') },
        { value: '10', label: t('global.october') },
        { value: '11', label: t('global.november') },
        { value: '12', label: t('global.december') },
    ];
    const yearOptions = [
        { value: new Date().toLocaleString('en-US', { year: 'numeric' }), label: new Date().toLocaleString('en-US', { year: 'numeric' }) },
        { value: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1, label: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1 },
    ];
    const handleSelectChange = (event) => {
        setSelectedMonth(event.target.value);
        setHasHistory(false);

    };
    const handleSelectYearChange = (e) => {
        setHasHistory(false)
        setSelectedYear(e.target.value)
    }
    const { rewardVouchersData, rewardvouchersuccess, loading } = useSelector(state => state.getRewardVoucher);
    useEffect(() => {
        if (rewardvouchersuccess == false) {
            dispatch(getRewardVoucher());
        }
         else if (rewardVouchersData?.status?.code == 401) {
            resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        setMarvelrewards(rewardVouchersData?.userVouchers);
        // (async () => {
        //     const marvelRewardsapi = await CustomerApiService.getmyRewards();
        //     if (marvelRewardsapi.status.success == true) {
        //         setMarvelrewards(marvelRewardsapi.userVouchers)
        //     } else if (marvelRewardsapi.status.code == 401 || marvelRewardsapi.status.code == 403) {
        //         localStorage.clear();
        //         localStorage.setItem("i18nextLng", process.env.REACT_APP_defaultLanguage);
        //         navigate(`/${process.env.REACT_APP_redirectionPage}`);
        //     }
        //     else {
        //         console.log("network error")
        //     }
        // })()
        
    }, [rewardVouchersData]);
    useEffect(() => {
        const handleVisibilityChange = () => {
          if (!document.hidden) {
            // if (rewardvouchersuccess == false) {
            //     dispatch(getRewardVoucher());
            // } else 
            if (rewardVouchersData?.status?.code == 401) {
              resetAllData(dispatch);
              let lang=localStorage.getItem("i18nextLng")
              let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
              let lineId = localStorage.getItem('lineId');
              localStorage.clear();
              localStorage.setItem("i18nextLng", lang);
              localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
              localStorage.setItem('lineId', lineId);
              navigate(`/${process.env.REACT_APP_redirectionPage}`);
            }else{
                dispatch(getRewardVoucher());
            }
            setMarvelrewards(rewardVouchersData?.userVouchers);
          }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          dispatch(resetRewardsVouchersData());
        };
      }, []);
      useEffect(() => {
        if (cmsData?.birthday_tierup_rewards?.length > 0)
          setBirthdayRewards(
            cmsData?.birthday_tierup_rewards
        );
        
      }, [cmsData]);
      useEffect(() => {
        (async () => {
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData?.birthday_tierup_rewards?.length == 0
            ) {
                const rewardsData = await CmsDataService.getCmsData("birthday_tierup_rewards");
                if (rewardsData?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["birthday_tierup_rewards"]: rewardsData?.data,
                        ["language"]: localStorage.getItem("i18nextLng"),
                    }));
                }
            }
            
        })();
    }, [])
    return (
        <>
            {loading ?
                <div className="loader">
                    <CircularProgress />
                    <div> Loading....</div>
                </div>
                :
                <section className="earnhistory couponhistory">
                    <div className="container">
                        <div className="row align-items-center border-bottom">
                            <div className="col-1">
                                <div className="backpageSection">
                                    <Link to="/my-coupon">
                                        <img src={Back} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="nav_heading  text-center">{t('rewards.coupon_history')}</h5>
                            </div>
                            <div className="col-1"></div>
                        </div>
                        <div className="year-dropdown">
                            <div className="custom-select" >
                                <select name="" id="Jan" value={selectedMonth} onChange={handleSelectChange}>
                                    {monthOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div className="custom-select" >
                                <select name="" id="2024" value={selectedYear} onChange={handleSelectYearChange}>
                                    {yearOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>

                        <div className={ marvelrewards?.length > 0 ? "footerEndSp":""}>
                            {marvelrewards?.length > 0 ?
                                marvelrewards.map((item) => {
                                    if (item.vouchers?.length > 0 ) {
                                        return item.vouchers.map((vouchers, VouchersIndex) => {
                                            let expiry = (vouchers.redemptions.redemption == null ? new Date(vouchers.expiry).toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }) == selectedMonth && new Date(vouchers.expiry).toLocaleString('en-US', { year: 'numeric' }) == selectedYear : false);
                                            let redemption=(vouchers.redemptions.redemption !== null ? new Date(vouchers?.redemptions?.redemption[0]?.date).toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }) == selectedMonth && new Date(vouchers?.redemptions?.redemption[0]?.date).toLocaleString('en-US', { year: 'numeric' }) == selectedYear : false)
                                            if ((new Date(vouchers.expiry) < new Date() || 
                                            vouchers.redemptions.redemption !== null) && (expiry || redemption )) {
                                                if (!hasHistory) setHasHistory(true)

                                                return (
                                                    <div key={`expiredVouchers${VouchersIndex}`} className="copHis_challengeHistory_row pt-3">
                                                        <div className="d-flex copHis_challenge_row">
                                                            <div className="col-4">
                                                                <div className="img-section">
                                                                    <img 
                                                                    src={item.rewardDetails !== null ? item.rewardDetails?.thumbnailUrl : birthdayRewards?.length>0 ? birthdayRewards.find(item => item.seriesid == vouchers.seriesId)?.rewardimage:""} 
                                                                    alt={item.rewardDetails?.name} 
                                                                    title={item.rewardDetails?.name} 
                                                                    width="100%" />
                                                                    <div className="status-div d-flex flex-nowrap reward_button_store">
                                                                        <button type="button" className="btn btn-outline " style={{ marginLeft: 0, top: "70%", background: "transparent", color: "grey", left: "0px", width: "100%", padding: "0px 0px", fontSize: "5.5vw", fontWeight: 800 }}> {vouchers.redemptions.redemption !== null ? t('rewards.redeemed') : t('rewards.expired')} </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="getPoints">
                                                                    <p className="challengeName">
                                                                        {item?.rewardDetails!==null ? item.rewardDetails?.name:vouchers.seriesName}{" "}
                                                                    </p>
                                                                    {vouchers.redemptions.redemption !==null ? 
                                                                    <div className="expDate">
                                                                        {t('rewards.redeemed_on')}: <span> {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(vouchers?.redemptions?.redemption[0]?.date),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(vouchers?.redemptions?.redemption[0]?.date),
                                                "dd MMM yyyy"
                                              )}</span>
                                                                    </div>:<div className="expDate">
                                                                        {t('rewards.Expired')}: <span> {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(vouchers.expiry),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(vouchers.expiry),
                                                "dd MMM yyyy"
                                              )} </span>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                            }
                                        })
                                    }
                                }


                                )
                                : ""}
                        </div> {!hasHistory && <div className="no_data">{t('point_history.no_data')}</div>}
                    </div>
                </section>}
        </>
    );
};

export default CouponHistory;
