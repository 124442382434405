import React, { useEffect, useState } from "react";
import Back from "../../assets/images/backarrow.svg";
import './orderHistory.scss'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { format } from 'date-fns';
import { th } from "date-fns/locale";
import { getOrder } from '../../redux/slices/customer/GetOrder'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetOrderData } from "../../redux/slices/customer/GetOrder";
import { resetAllData } from "../../utils/resetActions";
const OrderHistory = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { customerOrder, orderLoading, orderSuccess } = useSelector(state => state.getOrder);
    const [transactionData, setTransactionData] = useState(null)
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const [history, setHistory] = useState(false)
    const [selectedMonth, setSelectedMonth] = useState(state?.month ? state.month : new Date().toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }));
    const [selectedYear, setSelectedYear] = useState(state?.year ? state.year : new Date().toLocaleString('en-US', { year: 'numeric' }));
    const monthOptions = [
        { value: '01', label: t('global.january') },
        { value: '02', label: t('global.february') },
        { value: '03', label: t('global.march') },
        { value: '04', label: t('global.april') },
        { value: '05', label: t('global.may') },
        { value: '06', label: t('global.june') },
        { value: '07', label: t('global.july') },
        { value: '08', label: t('global.august') },
        { value: '09', label: t('global.september') },
        { value: '10', label: t('global.october') },
        { value: '11', label: t('global.november') },
        { value: '12', label: t('global.december') },
    ];

    const yearOptions = [
        { value: new Date().toLocaleString('en-US', { year: 'numeric' }), label: new Date().toLocaleString('en-US', { year: 'numeric' }) },
        { value: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1, label: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1 },
    ];
    const handleSelectChange = (event) => {
        setHistory(false)
        setSelectedMonth(event.target.value);
    };
    const handleSelectYearChange = (e) => {
        setHistory(false)
        setSelectedYear(e.target.value)
    }

    const handleClick = (data) => {
        const orderData = {
            month: selectedMonth,
            year: selectedYear,
            orderDetails: data,
            customerData: state?.customerData
        }
        navigate("/order-detail", { state: orderData })
    }
    useEffect(() => {
        if (orderSuccess == false) {
            dispatch(getOrder());
        }
        else if (customerOrder.status.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        setTransactionData(customerOrder?.customer?.transactions?.transaction)
    }, [customerOrder])
    useEffect(() => {
        return () => {
            dispatch(resetOrderData());
        };
    }, []);
    return (
        <>
            {orderLoading ? <div
                className="loader"
            >
                <CircularProgress />
                <div> Loading....</div>
            </div> :

                <section className="earnhistory">
                    <div className="container">
                        <div className="row align-items-center border-bottom plr-15">
                            <div className="col-1">
                                <div className="backpageSection">
                                    <Link
                                        to="/profile"
                                    >
                                        <img src={Back} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="nav_heading text-center">{t('profile.order_history')}</h5>
                            </div>
                            <div className="col-1"></div>
                        </div>

                        <div className="year-dropdown">
                            <div className="custom-select" >
                                <select name="" id="Jan" value={selectedMonth} onChange={handleSelectChange}>
                                    {monthOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div className="custom-select" >
                                <select name="" id="2024" value={selectedYear} onChange={handleSelectYearChange}>
                                    {yearOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>

                    {transactionData?.map((data, index) => {
                        if (new Date(data.billing_time).toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }) == selectedMonth && new Date(data.billing_time).toLocaleString('en-US', { year: 'numeric' }) == selectedYear) {
                            if (!history) setHistory(true)
                            return (
                                <div key={`orderHistory${index}`} className="challengeHistory_row">
                                    <div className="container" onClick={() => handleClick(data)} style={{cursor:"pointer"}}>
                                        <div className="d-flex justify-content-between">
                                            <div style={{width:"70%"}}>
                                                <p style={{ textDecoration: "underline", color: "#2172DA" }}>
                                                {data.number?.includes("#") ? "":"#"}{data.number}
                                                </p>
                                                <p className="py-2">
                                                    <span style={{color:"#717171"}}>{t('order_history.order_status')}</span>
                                                    {data?.type=="REGULAR" ? 
                                                    <span className="pointGreen"> {t('earn.completed')} </span>:
                                                    <span className="pointGrey"> {t('earn.return')} </span>}
                                                </p>
                                                <div className="expDate"> 
                                                {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(data.billing_time),
                                                "dd/MMM/yyyy HH:mm a",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(data.billing_time),
                                                "dd/MMM/yyyy HH:mm a"
                                              )}
</div>
                                            </div>
                                            <div style={{width:"20%"}} className="thb">{data.amount} {t('order_history.tbh')}</div>
                                        </div>
                                    </div>
                                </div>


                            )
                        }
                    })
                    }
                    {!history && <div className="no_data">{t('point_history.no_data')}</div>}

                </section>}
        </>
    );
};

export default OrderHistory;
