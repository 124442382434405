import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow.svg"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import CmsDataService from '../../utils/CmsDataService';
// import CmsDataService from '../../utils/CmsDataService'
const HowToEarn = () => {
    const { t } = useTranslation();
    const navigate=useNavigate()
    const navigateBack=()=>{
        navigate(-1)
    }
    const [howToEarn, setHowToEarn] = useState([])
    useEffect(() => {
        (async () => {
            const data = await CmsDataService.getCmsData("howtoearn")
            setHowToEarn(data?.data)
        })()
    }, [])
    return (
        <>
            <section className="samelayoutpage">
                <div className="container">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-1">
                            <div className="backpageSection">
                                <div onClick={navigateBack}>
                                    <img src={Back} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">
                            {t('earn.how_to_earn')}
                            </h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="terms-text">
                    {howToEarn?.map((item) => (
                            <div key={item._id}>
                                <div dangerouslySetInnerHTML={
                                    { __html: item.description }
                                } />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}
export default HowToEarn





