/* eslint-disable no-undef */
import "./rewards.scss";
import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { CircularProgress } from "@mui/material";
// import { useForm } from '../../utils/FormContext';
import { faGift } from "@fortawesome/free-solid-svg-icons";
import gqcoin from "../../assets/images/GQ-Coin.png";
import mycouponicon from "../../assets/images/mycouponicon.svg";
import { th } from "date-fns/locale";
// import pen from "../../assets/images/Re-1.jpg";
// import card from "../../assets/images/Re-2.jpg";
// import can from "../../assets/images/Re-3.jpg";
// import paper from "../../assets/images/Re-4.jpg";
// import CustomerApiService from "../../utils/CustomerApiService";
import lock from "../../assets/images/Icons/unlock.svg";
import gift from "../../assets/images/green-gift-icon.png";
import { useTranslation } from "react-i18next";
import { getCustomer } from "../../redux/slices/customer/GetCustomer";
import { useDispatch, useSelector } from "react-redux";
import { getReward } from "../../redux/slices/Rewards/GetReward";
import { resetAllData } from "../../utils/resetActions";
import { getRewardVoucher } from "../../redux/slices/Rewards/GetRewardVoucher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Rewards = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [customer, setCustomerinfo] = useState([]);
  const [apiCalled,setApiCalled]=useState(false);
  const [marvelrewards, setMarvelrewards] = useState([]);
  const [FilteredMarvelrewards, setFilteredMarvelrewards] = useState([]);
  const { rewardVouchersData, rewardvouchersuccess } = useSelector(
    (state) => state.getRewardVoucher
  );
  const [firstFilteredMarvelrewards, setFirstFilteredMarvelrewards] = useState(
    []
  );
  const [marvelVouchers, setMarvelVouchers] = useState([]);
  const [secondFilteredMarvelrewards, setSecondFilteredMarvelrewards] =
    useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  // const [loading, setLoading] = useState(false);
  const [selectedZero, setSelectedZero] = useState(false);
  const [selectedOne, setSelectedOne] = useState(false);
  const [selectedTwo, setSelectedTwo] = useState(false);
  const handleFirstFilter = (e) => {
    setSelectedFilter("");
    if (e.target.value == 0) setSelectedZero(!selectedZero);
    if (e.target.value == 1) setSelectedOne(!selectedOne);
    if (e.target.value == 2) setSelectedTwo(!selectedTwo);
    if (e?.target?.id == "online" && !selectedZero) {
      let set1 = [];
      let set2 = [];
      let set3 = [];
      if (selectedOne)
        set1 = marvelrewards.filter((item) => catListCheck(item, "flagship"));
      if (selectedTwo)
        set2 = marvelrewards.filter((item) => catListCheck(item, "partner"));
      set3 = marvelrewards.filter((item) => catListCheck(item, "online"));
      setFirstFilteredMarvelrewards(
        Array.from(new Set([...set1, ...set2, ...set3]))
      );
      setFilteredMarvelrewards(
        Array.from(new Set([...set1, ...set2, ...set3]))
      );
    }
    if (e?.target?.id == "offline" && !selectedOne) {
      let set1 = [];
      let set2 = [];
      let set3 = [];
      if (selectedZero)
        set1 = marvelrewards.filter((item) => catListCheck(item, "online"));
      if (selectedTwo)
        set2 = marvelrewards.filter((item) => catListCheck(item, "partner"));
      set3 = marvelrewards.filter((item) => catListCheck(item, "flagship"));
      setFirstFilteredMarvelrewards(
        Array.from(new Set([...set1, ...set2, ...set3]))
      );
      setFilteredMarvelrewards(
        Array.from(new Set([...set1, ...set2, ...set3]))
      );
    }
    if (e?.target?.id == "partner" && !selectedTwo) {
      let set1 = [];
      let set2 = [];
      let set3 = [];
      if (selectedOne)
        set1 = marvelrewards.filter((item) => catListCheck(item, "flagship"));
      if (selectedZero)
        set2 = marvelrewards.filter((item) => catListCheck(item, "online"));
      set3 = marvelrewards.filter((item) => catListCheck(item, "partner"));
      setFirstFilteredMarvelrewards(
        Array.from(new Set([...set1, ...set2, ...set3]))
      );
      setFilteredMarvelrewards(
        Array.from(new Set([...set1, ...set2, ...set3]))
      );
    }
    if (e?.target?.id == "online" && selectedZero) {
      let set1 = [];
      let set2 = [];
      if (selectedOne)
        set1 = marvelrewards.filter((item) => catListCheck(item, "flagship"));
      if (selectedTwo)
        set2 = marvelrewards.filter((item) => catListCheck(item, "partner"));
      setFirstFilteredMarvelrewards(Array.from(new Set([...set1, ...set2])));
      setFilteredMarvelrewards(Array.from(new Set([...set1, ...set2])));
    }
    if (e?.target?.id == "offline" && selectedOne) {
      let set1 = [];
      let set2 = [];
      if (selectedZero)
        set1 = marvelrewards.filter((item) => catListCheck(item, "online"));
      if (selectedTwo)
        set2 = marvelrewards.filter((item) => catListCheck(item, "partner"));
      setFirstFilteredMarvelrewards(Array.from(new Set([...set1, ...set2])));
      setFilteredMarvelrewards(Array.from(new Set([...set1, ...set2])));
    }
    if (e?.target?.id == "partner" && selectedTwo) {
      let set1 = [];
      let set2 = [];
      if (selectedZero)
        set1 = marvelrewards.filter((item) => catListCheck(item, "online"));
      if (selectedOne)
        set2 = marvelrewards.filter((item) => catListCheck(item, "flagship"));
      setFirstFilteredMarvelrewards(Array.from(new Set([...set1, ...set2])));
      setFilteredMarvelrewards(Array.from(new Set([...set1, ...set2])));
    }
  };
  const handleSecondFilter = (e) => {
    setSelectedFilter(e?.target?.id);
    setFilteredMarvelrewards(marvelrewards);

    if (e?.target?.id == "Ready to use") {
      firstFilteredMarvelrewards.length > 0
        ? (setFilteredMarvelrewards(
            firstFilteredMarvelrewards.filter(
              (item) => customer.loyalty_points >= item.intouchPoints
            )
          ),
          setSecondFilteredMarvelrewards(
            firstFilteredMarvelrewards.filter(
              (item) => customer.loyalty_points >= item.intouchPoints
            )
          ))
        : (setFilteredMarvelrewards(
            marvelrewards.filter(
              (item) => customer.loyalty_points >= item.intouchPoints
            )
          ),
          setSecondFilteredMarvelrewards(
            marvelrewards.filter(
              (item) => customer.loyalty_points >= item.intouchPoints
            )
          ));
    } else if (e?.target?.id == "descending") {
      firstFilteredMarvelrewards.length > 0
        ? (setFilteredMarvelrewards(
            firstFilteredMarvelrewards.sort((a, b) =>
              a.intouchPoints < b.intouchPoints ? 1 : -1
            )
          ),
          setSecondFilteredMarvelrewards(
            firstFilteredMarvelrewards.sort((a, b) =>
              a.intouchPoints < b.intouchPoints ? 1 : -1
            )
          ))
        : (setFilteredMarvelrewards(
            marvelrewards.sort((a, b) =>
              a.intouchPoints < b.intouchPoints ? 1 : -1
            )
          ),
          setSecondFilteredMarvelrewards(
            marvelrewards.sort((a, b) =>
              a.intouchPoints < b.intouchPoints ? 1 : -1
            )
          ));
    } else if (e?.target?.id == "ascending") {
      firstFilteredMarvelrewards.length > 0
        ? (setFilteredMarvelrewards(
            firstFilteredMarvelrewards.sort((a, b) =>
              a.intouchPoints > b.intouchPoints ? 1 : -1
            )
          ),
          setSecondFilteredMarvelrewards(
            firstFilteredMarvelrewards.sort((a, b) =>
              a.intouchPoints > b.intouchPoints ? 1 : -1
            )
          ))
        : (setFilteredMarvelrewards(
            marvelrewards.sort((a, b) =>
              a.intouchPoints > b.intouchPoints ? 1 : -1
            )
          ),
          setSecondFilteredMarvelrewards(
            marvelrewards.sort((a, b) =>
              a.intouchPoints > b.intouchPoints ? 1 : -1
            )
          ));
    } else if (e?.target?.id == "popular") {
      firstFilteredMarvelrewards.length > 0
        ? (setFilteredMarvelrewards(
            firstFilteredMarvelrewards.filter((item) => item.group == "Popular")
          ),
          setSecondFilteredMarvelrewards(
            firstFilteredMarvelrewards.filter((item) => item.group == "Popular")
          ))
        : (setFilteredMarvelrewards(
            marvelrewards.filter((item) => item.group == "Popular")
          ),
          setSecondFilteredMarvelrewards(
            marvelrewards.filter((item) => item.group == "Popular")
          ));
    }
  };
  const catListCheck = (data, name) => {
    let result = false;
    if (data?.categoryList) {
      for (let i = 0; i < data.categoryList.length; i++) {
        if (data.categoryList[i].name?.includes(name)) {
          result = true;
          return result;
        }
      }
      return result;
    }
  };
  const handleNavigate = (item) => {
    navigate(`/active-redeem/reward?id=${item.id}`);
  };
  const { customerDetails, success,loading } = useSelector(
    (state) => state.getCustomer
  );
  const { rewardDetails, rewardsuccess } = useSelector(
    (state) => state.getReward
  );

  const returnRedeemReward = (reward) => {
    if (reward?.tier?.length > 0 && reward?.tier) {
      if (
        reward?.tier &&
        reward?.tier.length > 0 &&
        customer?.current_slab &&
        customer?.current_slab.length > 0
      ) {
        let arr = reward?.tier?.toLowerCase().trim().split(",");
        // console.log(arr)
        // console.log(arr.includes(customer?.current_slab?.toLowerCase().trim()))
        // console.log("condition2",customer?.current_slab?.toLowerCase().trim() == process.env.REACT_APP_tier1.toLowerCase().trim(),process.env.REACT_APP_tier1)
        if (arr.includes(customer?.current_slab?.toLowerCase().trim())) {
          return false;
        } else if (
          customer?.current_slab?.toLowerCase().trim() ==
          process.env.REACT_APP_tier1.toLowerCase().trim()
        ) {
          return false;
        } else if (
          customer?.current_slab?.toLowerCase().trim() ==
          process.env.REACT_APP_tier2.toLowerCase().trim()
        ) {
          if (arr.includes(process.env.REACT_APP_tier3.toLowerCase().trim())) {
            return false;
          }
        }
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (success == false || apiCalled!== true) {
      setApiCalled(true)
      dispatch(getCustomer(localStorage.getItem("mobile")));
    } else if (
      customerDetails?.status?.code == 401 ||
      customerDetails?.status?.code == 403
    ) {
      resetAllData(dispatch);
      let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
      navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    setCustomerinfo(customerDetails?.customers?.customer[0]);

    if (rewardvouchersuccess == false) {
      dispatch(getRewardVoucher());
    } else if (rewardVouchersData?.status?.code == 401) {
      resetAllData(dispatch);
      let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
      navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    let rewardsDetails = rewardVouchersData?.userVouchers?.filter(
      (e) => e?.rewardDetails !== null
    );
    let rewardIds = rewardsDetails?.map((e) => e?.rewardDetails?.rewardId);
    setMarvelVouchers(rewardIds);
    // (async () => {
    //   let customerData;
    //   try {
    //     setLoading(true);
    //     customerData = await CustomerApiService.getCustomerByMobile(
    //       localStorage.getItem("mobile")
    //     );
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     setLoading(false);
    //     if (customerData.status.success == "true") {
    //       setCustomerinfo(customerData.customers.customer[0]);
    //     } else if (
    //       customerData.status.code == 401 ||
    //       customerData.status.code == 403
    //     ) {
    //       localStorage.clear();
    //       localStorage.setItem(
    //         "i18nextLng",
    //         process.env.REACT_APP_defaultLanguage
    //       );
    //       navigate(`/${process.env.REACT_APP_redirectionPage}`);
    //     } else {
    //       console.log("network error");
    //     }
    //   }
    // })();
  }, [customerDetails, rewardVouchersData]);
  useEffect(() => {
    (async () => {
      if (rewardsuccess == false) {
        dispatch(getReward());
      }
      if (rewardDetails?.length > 0) {
        let rewardDetailsDatesFilter = rewardDetails?.filter(
          (eachreward) =>
            eachreward.started == true && eachreward.expired == false
        );
        // if(marvelVouchers?.length>0){
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        let rewardDetailsDatesFilter2 = rewardDetailsDatesFilter?.filter(
          (eachreward) =>
            eachreward.label !== "Sign up reward" ||
            (new Date().setHours(0, 0, 0, 0) -
              new Date(customer?.registered_on).setHours(0, 0, 0, 0)) /
              millisecondsInDay <=
              7
        );
        let rewardDetailsDatesFilter3 = rewardDetailsDatesFilter2?.filter(
          (eachreward) =>
            eachreward.label !== "Sign up reward" ||
            !marvelVouchers?.includes(eachreward?.id)
        );
        // }
        setMarvelrewards(
          rewardDetailsDatesFilter3
            ?.slice()
            .sort((a, b) => (a.priority > b.priority ? 1 : -1))
        );
      } else if (rewardDetails?.status?.code == 401) {
        resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
        navigate(`/${process.env.REACT_APP_redirectionPage}`);
      }
    })();
  }, [rewardDetails, marvelVouchers]);

  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
          <div> Loading....</div>
        </div>
      ) : (
        <>
          <div className="reward-container">
            <section className="sections rewardsPage">
              <div className="earn-pointRange linerGradientRedBlack">
                <div
                  className="bg-light-gray paddingGap-right paddingGap-left"
                  style={{ paddingTop: "15px" }}
                >
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="col-3"></div>
                    <h5 className="col" style={{ textAlign: "center" }}>
                      {t("rewards.reward")}
                    </h5>
                    <Link
                      to="/my-coupon"
                      style={{ color: "#fff ", textDecoration: "none" }}
                    >
                      <div className="couponShow">
                        <div className="coupon">
                          <p>
                            <img src={mycouponicon} alt="" className="p-1" />
                            {t("rewards.my_coupons")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="tier">
                    <h5 className="boldhHeadingH5 title">
                      {customer?.current_slab}
                    </h5>
                  </div>
                  <div className="points">
                    {t("rewards.current_points")} &nbsp;{" "}
                    <img src={gqcoin} alt="" width="15px" style={{width:"15px",height:"15px",marginBottom:"5px"}} />
                    <span className="pointBold">
                      {" "}
                      {customer?.loyalty_points?.toLocaleString()}{" "}
                    </span>
                    {t("rewards.points")}
                  </div>
                </div>
              </div>
            </section>
            <section className="sections Reward-section-Reward-Page container-fluid">
              <div className="footerEndSp">
                <h5 className="boldhHeadingH5">
                  {t("rewards.rewards_by_store")}
                </h5>
                <div className="status-div d-flex flex-wrap reward_button_store">
                  <button
                    type="button"
                    className={` ${
                      selectedZero ? "dark-bg" : "white-bg"
                    } btn-sm rounded-pill`}
                    id="online"
                    value={0}
                    style={{ marginLeft: 0 }}
                    onClick={handleFirstFilter}
                  >
                    {t("rewards.online")}
                  </button>
                  <button
                    type="button"
                    className={` ${
                      selectedOne ? "dark-bg" : "white-bg"
                    } btn-sm rounded-pill`}
                    id="offline"
                    value={1}
                    onClick={handleFirstFilter}
                  >
                    {t("rewards.offline")}
                  </button>
                  <button
                    type="button"
                    className={` ${
                      selectedTwo ? "dark-bg" : "white-bg"
                    } btn-sm rounded-pill`}
                    id="partner"
                    value={2}
                    onClick={handleFirstFilter}
                  >
                    {t("rewards.partner")}
                  </button>
                </div>
                <h5 className="boldhHeadingH5">{t("rewards.filter")}</h5>
                <div
                  className="status-div d-flex flex-wrap reward_button_store"
                  data-set="set2"
                >
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm rounded-pill"
                    id="popular"
                    value={3}
                    style={{ marginLeft: 0 }}
                    onClick={handleSecondFilter}
                  >
                    {t("rewards.popular")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm rounded-pill"
                    id="Ready to use"
                    value={4}
                    onClick={handleSecondFilter}
                  >
                    {t("rewards.ready_to_use")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm rounded-pill"
                    id="descending"
                    value={5}
                    onClick={handleSecondFilter}
                  >
                    {t("rewards.descending")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm rounded-pill"
                    id="ascending"
                    value={6}
                    onClick={handleSecondFilter}
                  >
                    {t("rewards.ascending")}
                  </button>
                </div>
                <div className="tier">
                  <h5 className="boldhHeadingH5 title">
                    {" "}
                    {t("rewards.rewards")}
                  </h5>
                </div>
                <div className="d-flex flex-wrap ">
                  {FilteredMarvelrewards.length > 0
                    ? FilteredMarvelrewards.map((item) => {
                        return (
                          <div className="reward-sec-col" key={item.id}>
                            <div
                              to={`/active-redeem/reward?id=${item.id}`}
                              onClick={() => handleNavigate(item)}
                              style={{ color: "#000 ", textDecoration: "none" }}
                            >
                              <div className="image-box">
                                <div className="img-tag">
                                  <img
                                    src={item.thumbnailUrl}
                                    alt={item.name}
                                    title={item.name}
                                    width="100%"
                                    style={{ minHeight: "11rem" }}
                                    className={customer?.loyalty_points <
                                      item.intouchPoints ||
                                      returnRedeemReward(item) ? "img-bg-blur":""}
                                  />
                                  {item?.label?.length > 0 && (
                                    <div className="status-div d-flex flex-nowrap reward_button_store ">
                                      <button
                                        type="button"
                                        className="btn btn-outline "
                                        style={{ marginLeft: 0, top: "5px" }}
                                      >
                                        {item?.label}
                                      </button>
                                    </div>
                                  )}
                                  {(customer?.loyalty_points <
                                    item.intouchPoints ||
                                    returnRedeemReward(item)) && (
                                    <div className="status-div d-flex flex-nowrap reward_button_store">
                                      <button
                                        type="button"
                                        className="btn btn-outline disbaledRewards"
                                        style={{borderColor:"#C1C1C1",padding:"3px 10px"}}
                                      >
                                        <img
                                          src={lock}
                                          style={{
                                            height: "12px",
                                            width: "12px",
                                            minHeight: "unset",
                                            position: "relative",
                                            paddingBottom: "2px",
                                          }}
                                        ></img>
                                        {t("rewards.not_enough_balance")}
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="img-box-text">
                                  <div className="reward-text">{item.name}</div>
                                  <div className="points store_channel">
                                    {item.intouchPoints !== 0 ? (
                                      <>
                                        {" "}
                                        <img src={gqcoin} className="GQ-Coin" />
                                        <span className="pointBold">
                                          {" "}
                                          {item.intouchPoints?.toLocaleString()}
                                        </span>
                                      </>
                                    ) : (
                                      <div className="status-div d-flex flex-nowrap reward_button_store highest" >
                                        <span className="freebox" >
                                          {/* <FontAwesomeIcon
                                            icon={faGift}
                                            style={{ color: "#0CAA37" }}
                                          />{" "} */}
                                          <img src={gift} style={{width:"12px",height:"12px",margin:"0px 3px 3px 0px"}}></img>
                                          <span className="greenfree" >
                                            {t("rewards.free")}
                                          </span>
                                        </span>

                                        {/* <img src={gqcoin} className="GQ-Coin" /> */}
                                      </div>
                                    )}
                                    <div className="status-div d-flex flex-nowrap reward_button_store highest">
                                      {
                                        item.tier?.length > 0
                                          ? item?.tier
                                              .split(",")
                                              .map((tierName, id) => {
                                                return (
                                                  <button
                                                    key={`tierName${id}`}
                                                    type="button"
                                                    className="btn btn-outline"
                                                    style={{ marginLeft: 0 }}
                                                  >
                                                    {tierName}
                                                  </button>
                                                );
                                              })
                                          : ""
                                        // <button type="button"
                                        //   style={{ marginLeft: 0, height: "17px", background: "transparent", border: "none" }}></button>
                                      }
                                    </div>

                                    <div className="status-div d-flex flex-wrap reward_button_store">
                                      {item.categoryList.map((categoryList) => (
                                        <div
                                          className="categoryName"
                                          key={categoryList.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {categoryList.name}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="date">
                                      <p>
                                        {t("mainhome.expire_in")}{" "}
                                        <span>
                                          {" "}
                                          {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? item?.label == "Sign up reward"
                                              ? format(
                                                  new Date(
                                                    new Date(
                                                      customer?.registered_on
                                                    ).getFullYear(),
                                                    new Date(
                                                      customer?.registered_on
                                                    ).getMonth(),
                                                    new Date(
                                                      customer?.registered_on
                                                    ).getDate() + 7
                                                  ),
                                                  "dd MMM yyyy",
                                                  { locale: th }
                                                )
                                              : format(
                                                  new Date(item.endTime),
                                                  "dd MMM yyyy",
                                                  { locale: th }
                                                )
                                            : item?.label == "Sign up reward"
                                            ? format(
                                                new Date(
                                                  new Date(
                                                    customer?.registered_on
                                                  ).getFullYear(),
                                                  new Date(
                                                    customer?.registered_on
                                                  ).getMonth(),
                                                  new Date(
                                                    customer?.registered_on
                                                  ).getDate() + 7
                                                ),
                                                "dd MMM yyyy"
                                              )
                                            : format(
                                                new Date(item.endTime),
                                                "dd MMM yyyy"
                                              )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : selectedFilter == "" &&
                      !(selectedZero || selectedOne || selectedTwo) &&
                      marvelrewards?.map((item) => {
                        return (
                          <div className="reward-sec-col" key={item.id}>
                            <div
                              to={`/active-redeem/reward?id=${item.id}`}
                              onClick={() => handleNavigate(item)}
                              style={{ color: "#000 ", textDecoration: "none" }}
                            >
                              <div className="image-box">
                                <div className="img-tag">
                                  <img
                                    src={item.thumbnailUrl}
                                    alt={item.name}
                                    title={item.name}
                                    width="100%"
                                    style={{ minHeight: "11rem"}}
                                    className={customer?.loyalty_points <
                                      item.intouchPoints ||
                                      returnRedeemReward(item) ? "img-bg-blur":""}
                                  />
                                  {item?.label?.length > 0 && (
                                    <div className="status-div d-flex flex-nowrap reward_button_store ">
                                      <button
                                        type="button"
                                        className="btn btn-outline "
                                        style={{ marginLeft: 0, top: "5px" }}
                                      >
                                        {item?.label}
                                      </button>
                                    </div>
                                  )}
                                  {(customer?.loyalty_points <
                                    item.intouchPoints ||
                                    returnRedeemReward(item)) && (
                                    <div
                                      className="status-div d-flex flex-nowrap reward_button_store"
                                      
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-outline disbaledRewards"
                                        style={{borderColor:"#C1C1C1",padding:"3px 10px"}}
                                      >
                                        <img
                                          src={lock}
                                          style={{
                                            height: "12px",
                                            width: "12px",
                                            minHeight: "unset",
                                            position: "relative",
                                            paddingBottom: "2px",
                                          }}
                                        ></img>
                                        {t("rewards.not_enough_balance")}
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="img-box-text">
                                  <div className="reward-text">{item.name}</div>
                                  <div className="points store_channel">
                                    {item.intouchPoints !== 0 ? (
                                      <>
                                        {" "}
                                        <img src={gqcoin} className="GQ-Coin" />
                                        <span className="pointBold">
                                          {" "}
                                          {item.intouchPoints?.toLocaleString()}
                                        </span>
                                      </>
                                    ) : (
                                      <div className="status-div d-flex flex-nowrap reward_button_store highest">
                                        <span className="freebox">
                                          {/* <FontAwesomeIcon
                                            icon={faGift}
                                            style={{ color: "#0CAA37" }}
                                          />{" "} */}
                                          <img src={gift} style={{width:"15px",height:"15px",margin:"0px 3px 3px 0px"}}></img>
                                          <span className="greenfree">
                                            {t("rewards.free")}
                                          </span>
                                        </span>
                                        {/* <img src={gqcoin} className="GQ-Coin" /> */}
                                      </div>
                                    )}
                                    <div className="status-div d-flex flex-nowrap reward_button_store highest">
                                      {
                                        item.tier?.length > 0
                                          ? item?.tier
                                              .split(",")
                                              .map((tierName, id) => {
                                                return (
                                                  <button
                                                    key={`tierName${id}`}
                                                    type="button"
                                                    className="btn btn-outline"
                                                    style={{ marginLeft: 0 }}
                                                  >
                                                    {tierName}
                                                  </button>
                                                );
                                              })
                                          : ""
                                        // <button type="button"
                                        //   style={{ marginLeft: 0, height: "17px", background: "transparent", border: "none" }}></button>
                                      }
                                    </div>
                                    <div className="status-div d-flex flex-wrap reward_button_store">
                                      {item.categoryList.map((categoryList) => (
                                        <div
                                          className="categoryName"
                                          key={categoryList.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {categoryList.name}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="date">
                                      <p>
                                        {t("mainhome.expire_in")}{" "}
                                        <span>
                                          {" "}
                                          {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? item?.label == "Sign up reward"
                                              ? format(
                                                  new Date(
                                                    new Date(
                                                      customer?.registered_on
                                                    ).getFullYear(),
                                                    new Date(
                                                      customer?.registered_on
                                                    ).getMonth(),
                                                    new Date(
                                                      customer?.registered_on
                                                    ).getDate() + 7
                                                  ),
                                                  "dd MMM yyyy",
                                                  { locale: th }
                                                )
                                              : format(
                                                  new Date(item.endTime),
                                                  "dd MMM yyyy",
                                                  { locale: th }
                                                )
                                            : item?.label == "Sign up reward"
                                            ? format(
                                                new Date(
                                                  new Date(
                                                    customer?.registered_on
                                                  ).getFullYear(),
                                                  new Date(
                                                    customer?.registered_on
                                                  ).getMonth(),
                                                  new Date(
                                                    customer?.registered_on
                                                  ).getDate() + 7
                                                ),
                                                "dd MMM yyyy"
                                              )
                                            : format(
                                                new Date(item.endTime),
                                                "dd MMM yyyy"
                                              )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}


                </div>
                {(FilteredMarvelrewards.length == 0 && !(selectedFilter == "" && !(selectedZero || selectedOne || selectedTwo) ) ) || marvelrewards?.length ==0 ?
                      <div style={{textAlign:"center",paddingTop:"40px"}}>{t("rewards.no_reward")}</div>:""  }
              </div>
              {/* color:"#CC0000",fontWeight:"600" */}
            </section>
          </div>
          <Footer></Footer>
        </>
      )}
    </>
  );
};

export default Rewards;
