import React, { useEffect } from "react";
import "./App.css";
import { HelmetProvider } from 'react-helmet-async';
import {  Route, Routes,Navigate } from 'react-router-dom';
import Rewards from "./pages/rewards/Rewards.js";
import WelcomeComponent from "./pages/welcome/WelcomeComponent.js";
import Signup from "./pages/signup/Signup";
import Login from "./pages/login/Login.jsx";
import Earn from "./pages/earn/Earn.jsx";
import Profile from "./pages/profile/Profile.jsx";
import ViewProfile from "./pages/viewProfile/ViewProfile.jsx";
import UpdateProfile from "./pages/updateprofile/UpdateProfile.jsx";
import EditProfile from "./pages/editprofile/EditProfile.jsx";
import EarnProgress from "./pages/earnprogress/EarnProgress.jsx";
import EarnHistory from "./pages/earnhistory/EarnHistory.jsx";
import CouponHistory from "./pages/couponhistory/CouponHistory.jsx";
import Otp from "./pages/otp/Otp.jsx";
import Loading from "./pages/loading/Loading.jsx";
import {PrivateRoutes,LineIdPrivateRoutes,SignupPrivateRoutes,LoginPrivateRoutes,CustomerFoundRoutes} from "./utils/PrivateRoutes.js";
import WelcomeRewards from "./pages/welcomeReward/WelcomeRewards.jsx";
import Faq from "./pages/faq/Faq.jsx";
import TermsCondition from "./pages/termsConditions/TermsCondition.jsx";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy.jsx";
import ContactUs from "./pages/contactUs/ContactUs.jsx";
import DeleteAccount from "./pages/deleteAccount/DeleteAccount.jsx";
import TellFriend from "./pages/tellFriend/TellFriend.jsx";
import HowToRedeem from "./pages/howToRedeem/HowToRedeem.jsx";
import HowToEarn from "./pages/howToEarn/HowToEarn.jsx";
import StoreLocator from "./pages/storeLocator/StoreLocator.jsx";
import Notification from "./pages/notification/Notification.jsx";
import MembershipTier from "./pages/membershipTier/MembershipTier.jsx";
import RewardHistory from "./pages/rewardHistory/RewardHistory.jsx";
import PointsHistory from "./pages/pointsHistory/PointsHistory.jsx";
import OrderHistory from "./pages/orderHistory/OrderHistory.jsx";
import OrderDetail from "./pages/orderDetail/OrderDetail.jsx";
import Language from "./pages/language/Language.jsx";
import InactiveRedeem from "./pages/inactiveRedeem/InactiveRedeem.jsx";
import ActiveRedeem from "./pages/activeRedeem/activeRedeem.jsx";
import GuestNotification from "./pages/guestNotification/GuestNotification.jsx";
import NotificationList from "./pages/notificationList/NotificationList.jsx";
import NotificationDetails from "./pages/notificationDetails/NotificationDetails.jsx";
import SizeChart from "./pages/sizeChart/SizeChart.jsx";
import QrCode from "./pages/qrCode/QrCode.jsx";
import CompleteProfile from "./pages/completeProfile/CompleteProfile";
import YourSizes from "./pages/yourSize/YourSize";
import OrderTracking from "./pages/orderTracking/OrderTracking";
import JoinUs from "./pages/joinUs/JoinUs";
import MyCoupon from "./pages/mycoupon/MyCoupon";
import EditYourSize from "./pages/edit-you-size/Edit.jsx";
// import Dashboard from "./pages/dashboard/Dashboard";
import MainHome from "./pages/mainhome/MainHome";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import VerifyPassword from "./pages/verifyPassword/VerifyPassword";
import NewPassword
    from "./pages/newPassword/NewPassword";
import LineLanding from "./pages/lineLanding/LineLanding";
import ScanQrCode from "./pages/scanqrcode/ScanQrcode.jsx";
import ScanSuccess from "./pages/scansuccess/ScanSuccess.jsx";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import PropTypes from 'prop-types';
function App({lineValidation}) {
  // useEffect(() => {
  //   window.fbAsyncInit = function() {
  //     window.FB.init({
  //       appId: '708265771475311',
  //       autoLogAppEvents: true,
  //       xfbml: true,
  //       version: 'v12.0'
  //     });
  //   };
  
  //   (function(d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {return;}
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));
  // }, []);
  return (
   <>
<HelmetProvider>
      <Routes>
        <Route path="/page-not-found" exact element={<PageNotFound text={"You can not access site outside line browser"}/> }/>
        <Route path="/line-landing" exact element={<LineLanding />}/>
        <Route element={lineValidation? <LineIdPrivateRoutes/> :""} >
        <Route path="/"  element={<Navigate to="/main-home" />} />
        <Route element={<CustomerFoundRoutes/>} >
        <Route element={lineValidation? <SignupPrivateRoutes/>:""} >
        <Route path="/signup" exact element={<Signup />} />
        </Route>
        <Route element={lineValidation?<LoginPrivateRoutes/>:""} >
        <Route path="/login" exact element={<Login />} />
        </Route>
        <Route path="/otp" exact element={<Otp />} />
        </Route>
        <Route path="/verify-password" exact element={<VerifyPassword />}/>
        <Route path="/forgot-password" exact element={<ForgetPassword />}/>
        <Route path="/new-password" exact element={<NewPassword />}/>
        <Route path="/welcome" exact element={<WelcomeComponent />}/>
        <Route path="/welcome-rewards" exact element={<WelcomeRewards />} />
        {/* <Route path="/dashboard" exact element={<Dashboard />} /> */}
        <Route path="/main-home" exact element={<MainHome />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/join-us" exact element={<JoinUs />} />
        <Route path="/how-to-redeem" exact element={<HowToRedeem />} />
        <Route path="/how-to-earn" exact element={<HowToEarn />} />
        <Route path="/faq" exact element={<Faq />} />
        <Route path="/term-of-use" exact element={<TermsCondition />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/loading" exact element={<Loading />} />
        <Route path="/language" exact element={<Language />} />
        <Route path="/order-tracking" exact element={<OrderTracking />} />
        <Route element={<PrivateRoutes/>} >
        <Route path="/earn" exact element={<Earn />} />
        <Route path="/reward" exact element={<Rewards />} />
        <Route path="/viewprofile" exact element={<ViewProfile />} />
        <Route path="/update-profile" exact element={<UpdateProfile />} />
        <Route path="/editprofile" exact element={<EditProfile />} />
        <Route path="/Edit" exact element={<EditYourSize />} />
        <Route path="/earn-progress/:id" exact element={<EarnProgress />} />
        <Route path="/earn-history" exact element={<EarnHistory />} />
        <Route path="/coupon-history" exact element={<CouponHistory />} />
        <Route path="/store-locator" exact element={<StoreLocator />} />
        <Route path="/delete-Account" exact element={<DeleteAccount />} />
        <Route path="/tell-friend" exact element={<TellFriend />} />
        <Route path="/notification" exact element={<Notification />} />
        <Route path="/membership-tier" exact element={<MembershipTier />} />
        <Route path="/reward-history" exact element={<RewardHistory />} />
        <Route path="/point-history" exact element={<PointsHistory />} />
        <Route path="/order-history" exact element={<OrderHistory />} />
        <Route path="/order-detail" exact element={<OrderDetail />} />
        <Route path="/scanqr" exact element={<ScanQrCode />} />
        <Route path="/scan-success/:code" exact element={<ScanSuccess />} />
        <Route path="/inactive-redeem" exact element={<InactiveRedeem />} />
        <Route path="/active-redeem/:id" element={<ActiveRedeem />} />
        <Route
          path="/guest-notification"
          exact
          element={<GuestNotification />}
        />
        <Route path="/notification-list" exact element={<NotificationList />} />
        <Route path="/notification-details/:id" exact element={<NotificationDetails />} />
        <Route path="/size-chart" exact element={<SizeChart />} />
        <Route path="/qr-code" exact element={<QrCode />} />
        <Route path="/complete-profile" exact element={<CompleteProfile />} />
        <Route path="/your-size" exact element={<YourSizes />} />
        
        <Route path="/my-coupon" exact element={<MyCoupon />} />
       </Route>
</Route>
<Route path="*" exact element={<PageNotFound text={"Page Not Found"}/> }/>
      </Routes>
      </HelmetProvider>
      </>
  );
}
App.propTypes = {
  lineValidation: PropTypes.bool.isRequired
};

export default App;
