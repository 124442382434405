import React from 'react'
import Back from "../../assets/images/backarrow.svg";
import Art from "../../assets/images/Artworks for mockup/Re-2.jpg"
import GQCoin from "../../assets/images/GQ-Coin.png"
import Gift from "../../assets/images/gift-box-with-lace-svgrepo-com.svg"
import { Link } from 'react-router-dom';
import './inactiveRedeem.scss'

const InactiveRedeem = () => {
  return (
   <>
   <section className="earnprogress">
    <div className="opacity-popup">
    <div className="container">
        <div className="row align-items-center">
            <div className="col">
                <div className="backpageSection">
                <Link to="/reward">
                        <img src={Back} alt="" />
                   </Link>
                </div>
            </div>
            <div className="col">
                <h5 className="boldhHeadingH5 text-center">
                    Redeem
                </h5>
            </div>
            <div className="col"></div>
        </div>
    </div>
    <div className="earnprogressSec">
        <div className="img-section">
            <img src={Art} alt="" width="100%" />
            <div className="special">Special</div>
        </div>
    </div>
    <div className="container-fluid footerEndSp">
        <div className="challenger-box">
          
            <div className="challenger-details">
                <div className="heading">
                    <h5 className="boldhHeadingH5">Lorem Ipsum is simply dummy text<br/> of the printing</h5>
                    <div className="left-item">20 Items left<br/>&nbsp;</div>
                </div>
                <div className="points store_channel">
                   <img src={GQCoin} className="GQ-Coin"/>
                    <span className="pointBold">
                        150
                    </span> Points
                </div>
                <div className="date">
                    <p>Expire in: <span> 10 Sep 2023</span>
                    </p>
                </div>
                <div className="store-button">
                    <div className="store-text">Store</div>
                    <div className="store-but1">Offline</div>
                    <div className="store-but2">Online</div>
                    <div className="store-but3">Partner</div>
               </div>
            </div>
            <div className="faq-row">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                               <span className="Description">Description</span> 
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </div>
                        </div>
                    </div>
                    <div className="faq-row">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        <span className="Description">Term & Condition</span>
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        It is a long established fact that a reader will be distracted by the readable content of a
                                        page when looking at its layout.
                                    </div>
                                </div>
                            </div>
                           
                           
            
                        </div>
                    </div>
    
                </div>
            </div>
        </div>

    </div>
    <div className="savebutton">
        <div className="above-button">You will have 10 Points left after redemption</div>
        
        <button type="submit"  ><img src={Gift} className="redeem-img"/> Redeem</button>
      </div>
      
      </div>
  
      
</section>
   </>
  )
}

export default InactiveRedeem