// resetActions.js

import { resetData } from '../redux/slices/customer/GetCustomer';
import { resetOrderData } from '../redux/slices/customer/GetOrder';
import { resetPointHistory } from '../redux/slices/customer/GetPointhistory';
import { resetRewardData } from '../redux/slices/Rewards/GetReward';
import { resetRewardsVouchersData } from '../redux/slices/Rewards/GetRewardVoucher';
import { resetReferralCode } from '../redux/slices/customer/GetReferralcode';
import {resetstoreData} from '../redux/slices/customer/GetStore'
import { ProfileresetData } from '../redux/slices/customer/GetProfilePicture';
import { resetMilestoneRewardData } from '../redux/slices/Rewards/GetMilestoneRewards';

export const resetAllData = (dispatch) => {

  dispatch(resetData());
  dispatch(resetOrderData());
  dispatch(resetPointHistory());
  dispatch(resetRewardData());
  dispatch(resetRewardsVouchersData());
  dispatch(resetReferralCode());
  dispatch(resetstoreData());
  dispatch(ProfileresetData())
  dispatch(resetMilestoneRewardData())
};
