/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
import { format } from 'date-fns';
const issueReward = createAsyncThunk(
    "reward/issue",
    async (id, rewardName) => {
        try {
            var todayDate = new Date().getTime().toString();
            var today = new Date();
            let body = {
                "transactionNumber": id + "-" + todayDate.toString(),
                "notes": "1 X " + rewardName,
                "redemptionTime": format(today, 'yyyy-MM-dd HH:mm:ss'),
            }
            const data = await axios.post(`${process.env.REACT_APP_gatewayURL}marvel/rewards/issue?id=${id}&till_id=${process.env.REACT_APP_tillname}`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            });
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    issueDetail: {},
    issueSuccess: false
}
const issueRewardSlice = createSlice({
    name: "issuerewardDetail",
    initialState,
    reducers: {
        issueRewardsReset: (state) => {
            state.issueDetail = {},
            state.issueSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(issueReward.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(issueReward.fulfilled, (state, action) => {
            state.loading = false;
            state.issueDetail = action.payload;
            state.issueSuccess = true;
        });
        builder.addCase(issueReward.rejected, (state) => {
            state.loading = false;
        });
    }
});

export { issueReward };
export const { issueRewardsReset } = issueRewardSlice.actions;
export default issueRewardSlice.reducer;