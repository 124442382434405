import React, { useEffect, useState } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import BackArrow from "../../assets/images/backarrow.svg"
import Mail from "../../assets/images/joinus.png"
import './joinUs.scss'
import { useTranslation } from 'react-i18next';
import CmsDataService from '../../utils/CmsDataService.js'
import { CircularProgress } from '@mui/material';
const JoinUs = () => {
    const { t } = useTranslation();
    const navigate=useNavigate();
    const [text, setText] = useState()
    const[loading,setLoading]=useState(false)
    const handleClick=()=>{
navigate(-1);
    }
    useEffect(() => {
        setLoading(true);
        (async () => {
            const joinUsData = await CmsDataService.getCmsData('joinus')
            setText(joinUsData.data[0]);
            setLoading(false)
        })()

    }, []);
    
    return (
        <>
        {loading  ? 
            <div className="loader">
              <CircularProgress />
              <div> Loading....</div>
            </div>
          :
        <section className="orderdetils">
            <div className="row align-items-center joinus-back-arrow border-bottom">
                <div className="col-2 text-center">
                    <div className="backpageSection" onClick={handleClick}>
                            <img src={BackArrow} alt="" />
                    </div>
                </div>
                <div className="col">
                    <h5 className="boldhHeadingH5_topHeading text-center">
                    {t('join_us.join_us')}
                    </h5>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="track-order-section max-width-box">
                <div className="track-order-box">
                    <img src={Mail} alt="" className='mailimg' />
                    <div className="join-text">{text?.title}</div>
                    <p>
                    {text?.description}
                    </p>
                    <Link to="/signup">
                        <button className="comman-button">
                        {t('join_us.join_us')}
                        </button>
                    </Link>
                </div>

            </div>

        </section>}
        </>
    )
}

export default JoinUs;