/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getCustomer = createAsyncThunk(
    "customer/get",
    async(mobile) => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}/customer/get?format=json&user_id=true&mlp=true&gap_to_upgrade=1&subscriptions=true`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            });
            if(data?.customers?.customer[0]){
                localStorage.setItem("email",data?.customers?.customer[0]?.email)
            }
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const postCustomer = createAsyncThunk(
    "customer/post",
    async(body) => {
        console.log(body)
        try {
            const data = await axios.post(`${process.env.REACT_APP_gatewayURL}customerupdatecap`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)

const postcusNotification = createAsyncThunk(
    "customer/postnotification",
    async(body) => {
        try {
            const data = await axios.post(`${process.env.REACT_APP_gatewayURL}customer/update`, body, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    customerDetails: {},
    postcustomerDetails:{},
    error: "",
    success: false,
    postCustomerSuccess:false,
    postCustomerLoading:false
}
const getCustomerSlice = createSlice({
    name: "getCustomer",
    initialState,
    reducers:{
        resetData: (state) => {
            state.customerDetails = {};
            state.success=false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomer.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getCustomer.fulfilled, (state,action) => {
            
                state.customerDetails = action.payload;
                state.loading = false;
                state.success = true;
        });
        builder.addCase(getCustomer.rejected,(state)=>{
            state.loading = false;
            state.error = "No data";
            state.success = false;
        });
        builder.addCase(postCustomer.pending,(state)=>{
            state.postCustomerLoading= true;
        });
        builder.addCase(postCustomer.fulfilled, (state,action) => {
                state.postCustomerLoading = false;
                // state.customerDetails = {};
                state.postcustomerDetails=action.payload
                state.postCustomerSuccess = true;
        });
        builder.addCase(postCustomer.rejected,(state)=>{
            state.postCustomerSuccess = false;
            state.postCustomerLoading = false;
            state.error = "No data";
        });
        builder.addCase(postcusNotification.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(postcusNotification.fulfilled, (state,action) => {
            state.loading = false;
            // state.customerDetails = action.payload;
            state.success = true;
        });
        builder.addCase(postcusNotification.rejected,(state)=>{
            state.loading = false;
            state.error = "No data";
        });
    }
});

export {getCustomer, postCustomer,postcusNotification};
export const { resetData } = getCustomerSlice.actions;
export default getCustomerSlice.reducer;