/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Back from "../../assets/images/backarrow.svg";
import './rewardHistory.scss'
import { Link, useNavigate } from "react-router-dom";
import CustomerApiService from "../../utils/CustomerApiService";
import { format } from 'date-fns';
import { th } from "date-fns/locale";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getRewardVoucher } from '../../redux/slices/Rewards/GetRewardVoucher';
import { resetAllData } from "../../utils/resetActions";
const RewardHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [marvelrewards, setMarvelrewards] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(new Date().toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }));
    const [selectedYear, setSelectedYear] = useState(new Date().toLocaleString('en-US', { year: 'numeric' }));
    const [history, setHistory] = useState(false)
    // const [loading, setLoading] = useState(false)
    const monthOptions = [
        { value: '01', label: t('global.january') },
        { value: '02', label: t('global.february') },
        { value: '03', label: t('global.march') },
        { value: '04', label: t('global.april') },
        { value: '05', label: t('global.may') },
        { value: '06', label: t('global.june') },
        { value: '07', label: t('global.july') },
        { value: '08', label: t('global.august') },
        { value: '09', label: t('global.september') },
        { value: '10', label: t('global.october') },
        { value: '11', label: t('global.november') },
        { value: '12', label: t('global.december') },
    ];
    const yearOptions = [
        { value: new Date().toLocaleString('en-US', { year: 'numeric' }), label: new Date().toLocaleString('en-US', { year: 'numeric' }) },
        { value: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1, label: new Date().toLocaleString('en-US', { year: 'numeric' }) - 1 },
    ];
    const handleSelectChange = (event) => {
        setHistory(false)
        setSelectedMonth(event.target.value);
    };
    const handleSelectYearChange = (e) => {
        setHistory(false)
        setSelectedYear(e.target.value)
    }

    const { rewardVouchersData, rewardvouchersuccess, loading } = useSelector(state => state.getRewardVoucher);
    useEffect(() => {
        if (rewardvouchersuccess == false) {
            dispatch(getRewardVoucher());
        }
        else if (rewardVouchersData?.status?.code == 401) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        setMarvelrewards(rewardVouchersData?.userVouchers);
        // (async () => {
        //     let marvelRewardsapi;
        //     try {
        //         setLoading(true)
        //         marvelRewardsapi = await CustomerApiService.getmyRewards();
        //     } catch (e) {
        //         setLoading(false)
        //         console.log(e)
        //     }
        //     finally {
        //         setLoading(false)
        //         if (marvelRewardsapi.status.success == true) {
        //             setMarvelrewards(marvelRewardsapi.userVouchers)
        //         }
        //         else if (marvelRewardsapi.status.code == 401) {
        //             localStorage.clear();
        //             localStorage.setItem("i18nextLng", process.env.REACT_APP_defaultLanguage);
        //             navigate(`/${process.env.REACT_APP_redirectionPage}`);
        //         }
        //         else {
        //             console.log("network error")
        //         }
        //     }
        // })()
    }, [rewardVouchersData]);
    return (
        <>
            {loading ?
                <div className="loader">
                    <CircularProgress />
                    <div> Loading....</div>
                </div>
                :
                <section className="earnhistory">

                    <div className="container">
                        <div className="row align-items-center border-bottom">
                            <div className="col-1">
                                <div className="backpageSection">
                                    <Link
                                        to="/profile"
                                    >
                                        <img src={Back} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="nav_heading text-center">{t('profile.reward_history')}</h5>
                            </div>
                            <div className="col-1"></div>
                        </div>

                        <div className="year-dropdown">
                            <div className="custom-select" >
                                <select name="" id="Jan" value={selectedMonth} onChange={handleSelectChange}>
                                    {monthOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div className="custom-select" >
                                <select name="" id="2024" value={selectedYear} onChange={handleSelectYearChange}>
                                    {yearOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>

                    {marvelrewards?.map((item) => {
                        if (item.vouchers.length > 0) {
                            return item.vouchers.map((vouchers) => {
                                let expiry = (new Date(vouchers.issued).toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }) == selectedMonth && new Date(vouchers.issued).toLocaleString('en-US', { year: 'numeric' }) == selectedYear);
                                            // let redemption=(vouchers.redemptions.redemption !== null ? new Date(vouchers?.redemptions?.redemption[0]?.date).toLocaleString('en-US', { month: '2-digit', minimumIntegerDigits: 2 }) == selectedMonth && new Date(vouchers?.redemptions?.redemption[0]?.date).toLocaleString('en-US', { year: 'numeric' }) == selectedYear : false)
                                            // if ((new Date(vouchers.expiry) < new Date() || 
                                            // vouchers.redemptions.redemption !== null) && (expiry || redemption )) {
                                                if (expiry) {
                                    if (!history) setHistory(true)
                                    return (
                                        <div key={`rewardHistory${vouchers.code}`} className="challengeHistory_row">
                                            <div className="container">
                                                <div className="d-flex justify-content-between">
                                                    <div style={{width:"70%"}}>  
                                                        <p>{t('rewards.you_have_rewarded')} {item?.rewardDetails !== null ? item.rewardDetails?.name: vouchers.seriesName}</p>
                                                        <p className={new Date(vouchers.expiry) < new Date() ? "pointRed" : vouchers.redemptions.redemption !== null ? "greytext" : "pointGreen"}>{new Date(vouchers.expiry) < new Date() ? "Expired" : vouchers.redemptions.redemption !== null ? "Redeemed" : "Active"}</p>
                                                    </div>
                                                    <div style={{width:"25%"}} className="rewardHis_expDate">
                                                    {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(vouchers.issued),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(vouchers.issued),
                                                "dd MMM yyyy"
                                              )}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    }
                    )}

                    {!history && <div className="no_data">{t('point_history.no_data')}</div>}
                </section>}
        </>
    );
};

export default RewardHistory;
