// FormContext.js
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import axios from "./AxiosService"
const FormContext = createContext();

const FormProvider = ({ children }) => {
  const defaultFormValues = {
    firstname: '',
    lastname: '',
    countryCode: process.env.REACT_APP_countryCode,
    phoneNo: '',
    password: '',
    referal: '',
    agreePDPA: false,
    acceptMarketing: false,
  };
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [currentLocation, setCurrentLocation] = useState([]);

  
const updateCurrentLocation =(newCurrentLocation)=>{
  setCurrentLocation(newCurrentLocation)
}
  const updateFormValues = (newFormValues) => {
    setFormValues(newFormValues);
  };

  const resetFormValues = () => {
    setFormValues(defaultFormValues);
  };

  return (
    <FormContext.Provider value={{ formValues, updateFormValues, resetFormValues, currentLocation,updateCurrentLocation }}>
      {children}
    </FormContext.Provider>


  );
};
FormProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
const useForm = () => useContext(FormContext);

export { FormProvider, useForm };
