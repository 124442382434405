import React, { useState, useEffect } from "react";
import "./signup.scss";
import { NavLink, useNavigate } from 'react-router-dom';
import backarrow from "../../assets/images/backarrow.svg";
import eyeslash from "../../assets/images/eye-slash.svg";
import eye from "../../assets/images/Icons/eye.svg";
import greycheck from "../../assets/images/pdpa-gray-check.svg";
import greencheck from "../../assets/images/pdpa-green-check.svg";
import ReactFlagsSelect from 'react-flags-select';
import { useForm } from '../../utils/FormContext';
import AuthService from "../../utils/AuthService";
import { countries } from 'countries-list';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import {phone} from 'phone';
const Signup = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [countryCodes, setCountryCodes] = useState({})
    const { formValues, updateFormValues } = useForm();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(Object.keys(countryCodes).find(key => countryCodes[key].primary === formValues.countryCode));
    const [formErrors, setFormErrors] = useState({
        firstname: "",
        lastname: "",
        phoneNo: "",
        password: "",
        referal: "",
        agreePDPA: "",
        // acceptMarketing: "",
    });
    const handleSelect = (countryCode) => {
        setSelectedCountry(countryCode);
        updateFormValues((prevValues) => ({
            ...prevValues,
            ["countryCode"]: countryCodes[countryCode].primary,
        }));
        if (!phone(`${countryCodes[countryCode].primary}${formValues.phoneNo.trim().replace(/^0+/, '')}`,{strictDetection: true}).isValid ) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                ["phoneNo"]: `${t("sign_up.phone_error")}`,
            }));
        }
        else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                ["phoneNo"]: "",
            }));
        }
    };

    const handleToggle = () => {
        setPasswordVisible(!passwordVisible)
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        let newValue = value;

        if (type === 'text' && (name === 'firstname' || name === 'lastname')) {
            // Only allow letters for name fields
            newValue = value.replace(/[^a-zA-Zก-๙เ-ไๅๆ\s]/gi, '');
            newValue = newValue.replace(/\s{2,}/g, ' ');
        }
        if (type === 'text' && name === 'phoneNo') {
            // Only allow digits for phoneNo field
            newValue = value.replace(/[^0-9]/g, '');
        }
        if (type === 'text' && name === 'referal') {
            // Allow alphanumeric characters for referral code
            newValue = value.replace(/[^0-9a-zA-Z]/gi, '');
        }

        updateFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : newValue,
        }));
        if (type === 'text' || type === 'password') {
            if (newValue.trim() && (name === 'firstname' || name === 'lastname')) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            } else if (!newValue.trim() && (name === 'firstname' || name === 'lastname')) {
                if (name === 'firstname') {
                    setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: `${t("viewprofile.first_name_error")}`,
                    }));
                }

                if (name === 'lastname') {
                    setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: `${t("viewprofile.last_name_error")}`,
                    }));
                }

            }
            const regex = /^[1-9][0-9]*$/;
            if (name === 'phoneNo' && newValue.trim()) {
                if (!phone(`${formValues.countryCode}${newValue.trim().replace(/^0+/, '')}`,{strictDetection: true}).isValid ) {
                    setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: `${t("sign_up.phone_error")}`,
                    }));
                }
                else {
                    setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: "",
                    }));
                }

            }

            // if (name === 'password' && newValue.trim()) {
            //     if (newValue.trim().length < 8) {
            //         setFormErrors((prevErrors) => ({
            //             ...prevErrors,
            //             [name]: `${t("sign_up.password_error")}`,
            //         }));
            //     }
            //     else {
            //         setFormErrors((prevErrors) => ({
            //             ...prevErrors,
            //             [name]: "",
            //         }));
            //     }
            // }
        }
        if (checked && (name === 'agreePDPA' || name === 'acceptMarketing')) {
            if (name === 'agreePDPA') {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            }

            // if (name === 'acceptMarketing') {
            //     setFormErrors((prevErrors) => ({
            //         ...prevErrors,
            //         [name]: "",
            //     }));
            // }
        }
        if (name === 'refral' && newValue.trim()) {
            
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            

        }
    };
    const validateForm = () => {
        const errors = {
            firstname: "",
            lastname: "",
            phoneNo: "",
            password: "",
            referal: "",
            agreePDPA: "",
            // acceptMarketing: "",
        };
        if (!formValues.firstname.trim()) {
            errors.firstname = `${t("viewprofile.first_name_error")}`;
        }

        if (!formValues.lastname.trim()) {
            errors.lastname = `${t("viewprofile.last_name_error")}`;
        }
        const regex = /^[1-9][0-9]*$/;
        if (!formValues.phoneNo.trim()) {
            errors.phoneNo = `${t("sign_up.phone_required_error")}`;
        } else if (!phone(`${formValues.countryCode}${formValues.phoneNo.trim().replace(/^0+/, '')}`,{strictDetection: true}).isValid) {
            errors.phoneNo = `${t("sign_up.phone_error")}`
        }

        // if (!formValues.password.trim()) {
        //     errors.password = `${t("sign_up.password_required_error")}`;
        // } else if (formValues.password.length < 8) {
        //     errors.password = `${t("sign_up.password_error")}`;
        // }

        if (formValues.agreePDPA == false) {
            errors.agreePDPA = `${t("sign_up.pdpa_required_error")}`
        }

        // if (formValues.acceptMarketing == false) {
        //     errors.acceptMarketing = `${t("sign_up.pdpa_required_error")}`
        // }
        setFormErrors(errors);
        return Object.values(errors).every(error => error === "");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validation = validateForm();
        if (validation) {
            try {
                const mobile = `${formValues.countryCode}${formValues.phoneNo.replace(/^0+/, '')}`.replace(/^\+/, '')
                // localStorage.clear();
                // localStorage.setItem("i18nextLng", 'en')
                localStorage.setItem("mobile", mobile)
                // const refralResponse = await AuthService.tokenGenerate()
                // if(!refralResponse?.valid){
                //     toast.error("Invalid refral code");
                //     setFormErrors((prevErrors) => ({
                //         ...prevErrors,
                //         ["refral"]: "Invalid refral code",
                //     }));
                //     return;
                // }
                localStorage.setItem("updatePassword", false)
                localStorage.setItem("login", false)
                localStorage.setItem("newRegistration", true)
                let temp=JSON.stringify(formValues)
                localStorage.setItem("formVlaues",temp)
                const tokenGenerateResponse = await AuthService.tokenGenerate()
                if (tokenGenerateResponse?.status?.code == 200) {
                    const otpGenerateResponse = await AuthService.generateOtp()
                    navigate("/otp");
                }
                else {
                    toast.error(tokenGenerateResponse?.status?.message,{toastId:"signupError"})
                    localStorage.removeItem("formVlaues")
                }
            } catch {
                toast.error("Something went wrong. please try again",{toastId:"signupError"})
                localStorage.removeItem("formVlaues")
            }
        } else {
            console.log('Form validation failed. Please check the errors.');
            localStorage.removeItem("formVlaues")
        }
    };
    useEffect(() => {
        const refralCode=localStorage.getItem("refralCode")
    if(refralCode){
        updateFormValues((prevValues) => ({
            ...prevValues,
            ["referal"]: refralCode,
        }));
    }
        const countryCodes = {};
        Object.keys(countries).forEach((countryCode) => {
            if (countryCode !== 'TH' || countryCode !== 'SG' || countryCode !== 'IN' || countryCode !== 'CN') {
                const countryData = countries[countryCode];
                const dialCode = `+${countryData.phone}`;
                countryCodes[countryCode] = { primary: dialCode, secondary: countryData.name };
            }
        });
        const newCountry = {
            "TH": {
                "primary": "+66",
                "secondary": "Thailand"
            },
            "SG": {
                "primary": "+65",
                "secondary": "Singapore"
            },
            "IN": {
                "primary": "+91",
                "secondary": "India"
            },
            "CN": {
                "primary": "+86",
                "secondary": "China"
            }
        };
        const updatedCountryCodes = { ...newCountry, ...countryCodes }
        setCountryCodes(updatedCountryCodes);
        setSelectedCountry(Object.keys(updatedCountryCodes).find(key => updatedCountryCodes[key].primary === formValues.countryCode))

    }, []);

    return (
        <>
            <div className="signup-container1">
                <div className="row align-items-center border-bottom plr-15">
                    <div className="col-2 text-center">
                        <div className="backpageSection">
                            <NavLink to="/main-home" style={{ textDecoration: "none", color: "#000" }}>
                                <img src={backarrow} alt="" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="boldhHeadingH5_topHeading text-center">{t("sign_up.sign_up")}</h5>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
            <div className="signup-container">
                <div className="login-content">

                    <form onSubmit={handleSubmit}>
                        <div style={{ marginTop: "24px" }}>
                            <label htmlFor="firstname">{t("sign_up.name")} </label>
                            <br />
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder={`${t("sign_up.name_placeholder")}`}
                                value={formValues.firstname}
                                onChange={handleInputChange}
                            />
                            <span className="error-message">{formErrors.firstname}</span>
                        </div>
                        <div>
                            <label htmlFor="lastname">{t("sign_up.lastname")} </label>
                            <br />
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                value={formValues.lastname}
                                placeholder={`${t("sign_up.lastname_placeholder")}`}
                                onChange={handleInputChange}
                            />
                            <span className="error-message">{formErrors.lastname}</span>
                        </div>
                        <div>
                            <div className="moblabel">
                                <label htmlFor="lastname">{t("sign_up.phone_no")}</label>
                            </div>
                            <div className="mobile flag-btn">
                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    onSelect={handleSelect}
                                    countries={Object.keys(countryCodes)}
                                    customLabels={countryCodes}
                                    showSelectedLabel={true}
                                    showSecondarySelectedLabel={false}
                                    fullWidth={false}
                                    searchable
                                />
                                <input type="text" id="phoneNo" inputMode="numeric" pattern="[0-9]*" placeholder="XXXXXXXX" name="phoneNo" style={{ height: "50px" }} value={formValues.phoneNo} onChange={handleInputChange} />

                            </div>
                            <span className="error-message">{formErrors.phoneNo}</span>
                        </div>

                        {/* <div>
                            <label htmlFor="password">{t("sign_up.password")} </label>
                            <br />
                            <input
                                type={passwordVisible ? "text" : "password"}

                                name="password"
                                placeholder={`${t("sign_up.password")}`}
                                value={formValues.password}
                                onChange={handleInputChange}
                            // autoComplete="current-password"
                            />
                            <span className="eyeicon" onClick={handleToggle}>
                                 <img src={passwordVisible?eye:eyeslash} /> 
                            </span>
                            <p className="forgot-label"> {t("sign_up.password_error")}</p>
                            <span className="error-message">{formErrors.password}</span>
                        </div> */}
                        <div>
                            <label htmlFor="referal">{t("sign_up.refral")} </label>
                            <br />
                            <input
                                type="text"
                                id="referal"
                                name="referal"
                                placeholder={`${t("sign_up.refral_placeholder")}`}
                                value={formValues.referal}
                                onChange={handleInputChange}
                            />
                            <span className="error-message">{formErrors.referal}</span>
                        </div>
                        <div className="checkbox">
                            <div className="checkbox1">

                                <img
                                    src={formValues.agreePDPA ? greencheck : greycheck}
                                    alt="Grey Check"
                                    // onClick={handleGreyCheckClick}
                                    onClick={() => handleInputChange({ target: { name: 'agreePDPA', type: 'checkbox', checked: !formValues.agreePDPA } })}
                                />


                                <span className="checktext">
                                    {t("sign_up.agree_to")}{" "}
                                    <a href="http://www.gqsize.com/pages/gq-member-pp" 
                                    // target="_blank" 
                                    rel="noreferrer" style={{ textDecoration: "underline", color: "#2172DA" }}>
                                        {t("sign_up.pdpa")}
                                    </a>{" "}
                                    {t("sign_up.and")}{" "}
                                    <a href="https://www.gqsize.com/pages/gq-member-tos" 
                                    // target="_blank" 
                                    rel="noreferrer" style={{ textDecoration: "underline", color: "#2172DA" }}>
                                        {t("sign_up.term_of_service")}
                                    </a>
                                </span>

                            </div>
                            <span className="error-message">{formErrors.agreePDPA}</span>
                            <div className="checkbox1" />

                            <img
                                src={formValues.acceptMarketing ? greencheck : greycheck}
                                alt="Grey Check"
                                onClick={() => handleInputChange({ target: { name: 'acceptMarketing', type: 'checkbox', checked: !formValues.acceptMarketing } })}
                            />

                            <span className="checktext">{t("sign_up.marketing_material")}</span>

                        </div>
                        {/* <span className="error-message">{formErrors.acceptMarketing}</span> */}
                        <div className="signupbutton">
                            <button type="submit">{t("sign_up.sign_up")}</button>
                            <p className="alternative">
                                {" "}
                                {t("sign_up.already_have_an_account")}  <NavLink to="/login" className="signin" style={{ fontWeight: "600", textDecoration: "underline", color: "#000" }}>{t("sign_up.sign_in")}</NavLink>
                            </p>
                        </div>
                    </form>
                </div>
                <div style={{ height: "75px" }}></div>
            </div>
        </>
    );
};

export default Signup;
