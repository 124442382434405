/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from "react";
import Back from "../../assets/images/backarrow.svg";
import { useNavigate } from "react-router-dom";
import "./otp.scss";
import { useForm } from "../../utils/FormContext";
import AuthService from "../../utils/AuthService";
import CustomerApiService from "../../utils/CustomerApiService";
import { toast } from "react-toastify";
import axios from "../../utils/AxiosService";
import { useTranslation } from "react-i18next";
import { resetData } from "../../redux/slices/customer/GetCustomer";
import { resetOrderData } from "../../redux/slices/customer/GetOrder";
import { resetPointHistory } from "../../redux/slices/customer/GetPointhistory";
import { resetRewardData } from "../../redux/slices/Rewards/GetReward";
import { resetRewardsVouchersData } from "../../redux/slices/Rewards/GetRewardVoucher";
import { resetReferralCode } from "../../redux/slices/customer/GetReferralcode";
import { useDispatch } from "react-redux";
import { resetstoreData } from "../../redux/slices/customer/GetStore";
const Otp = () => {
  const dispatch = useDispatch();
  const { formValues, resetFormValues } = useForm();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const registereddate = new Date().toLocaleString("en-US", options);
  const navigate = useNavigate();
  const inputRefs = Array.from({ length: 6 }, () => useRef(null));
  const buttonRef = useRef(null);
  const [subButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const { t } = useTranslation();

  const handleResendClick = async () => {
    setResendDisabled(true);
    const otpGenerateResponse = await AuthService.generateOtp();
  };

  const handleInputChange = (index, e) => {
    const value = e.replace(/[^0-9]/g, "");
    if (/^\d$/.test(value)) {
      const newValues = [...otpValues];
      newValues[index] = value;
      setOtpValues(newValues);

      if (value && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      console.log(otpValues[index], index);
      const newValues = [...otpValues];
      newValues[index] = "";
      setOtpValues(newValues);
      console.log(otpValues);
      if (index > 0 && !otpValues[index]) {
        inputRefs[index - 1].current.focus();
      }
    }
    if (e.key === "Enter") {
      buttonRef.current.click();
    }
  };

  const handleSubmit = async () => {
    setSubmitButtonDisabled(true);
    const isAllDigits = (arr) => arr.every((item) => /^\d$/.test(item));
    if (!otpValues.includes("") && isAllDigits(otpValues)) {
      const data = await AuthService.validateOtp(otpValues.join(""));
      console.log(data);
      if (data.status.code == 200) {
        const data = await CustomerApiService.getCustomerByMobile(
          localStorage.getItem("mobile")
        );
        console.log(data);
        let reg_field = true;
        if (data.status.success === "true") {
          let tmp = data.customers.customer[0].custom_fields.field.filter(
            (e) => e.name == "already_reg_user"
          );
          if (tmp?.length > 0) {
            if (tmp[0].value == false || tmp[0].value == "false") {
              reg_field = true;
            }
          }
          if (
            localStorage.getItem("login") == true ||
            localStorage.getItem("login") == "true"
          ) {
            // localStorage.setItem("token", passwordValidateResponse.auth.token)

            localStorage.setItem("customerFound", true);
            localStorage.removeItem("formVlaues");
            navigate("/main-home");
          } else {
            let tempFormValues = JSON.parse(localStorage.getItem("formVlaues"));
            let shopifyPost = {
              phone: localStorage.getItem("mobile"),
              first_name: tempFormValues?.firstname,
            };

            const addShopify = await CustomerApiService.registerShopifyCustomer(
              shopifyPost
            );
            if (addShopify?.code == 300) {
              let shopifyPostData = {
                phone: localStorage.getItem("mobile"),
                first_name: tempFormValues?.firstname,
                last_name: tempFormValues?.lastname,
              };
              const shopifyUpdate = await axios.post(
                `${process.env.REACT_APP_gatewayURL}customerupdateshopify`,
                shopifyPostData,
                {
                  headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                  },
                }
              );
            }

            if (
              tempFormValues?.referal &&
              tempFormValues?.referal?.length > 0
            ) {
              let referalData = {
                mobile: localStorage.getItem("mobile"),
                referral_code: tempFormValues?.referal,
                type: "Goodwill",
              };
              const referalWebhook = await axios.post(
                `${process.env.REACT_APP_referalWebhook}`,
                referalData,
                {
                  headers: {
                    "api-key": process.env.REACT_APP_shopifyApiKey,
                    client: process.env.REACT_APP_shopifyClient,
                  },
                }
              );
            }

            let mergPostData = {
              add: [
                {
                  type: "mobile",
                  value: localStorage.getItem("mobile"),
                },
              ],
            };

            const addCustomer = await CustomerApiService.mergeLineIdWithMobile(
              localStorage.getItem("customerId"),
              mergPostData
            );
            if (addCustomer?.createdId) {
              let postData = {
                profiles: [
                  {
                    firstName: tempFormValues?.firstname,
                    lastName: tempFormValues?.lastname,
                    fields: {
                      already_reg_user: reg_field,
                    },
                    identifiers: [
                      {
                        type: "mobile",
                        value: localStorage.getItem("mobile"),
                      },
                    ],
                  },
                ],
              };
              const data = await axios.post(
                `${process.env.REACT_APP_gatewayURL}customerupdatecap`,
                postData,
                {
                  headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                  },
                }
              );

              resetFormValues();
              localStorage.setItem("customerFound", true);
              localStorage.removeItem("formVlaues");
              navigate("/welcome");
            } else if (!addCustomer?.errors?.[0]?.status) {
              toast.error(t("otp.mobile_no_reg_error"));
              setSubmitButtonDisabled(false);
              localStorage.removeItem("formVlaues");
              navigate("/signup");
            } else {
              toast.error(t("otp.mobile_no_reg_error"));
              setSubmitButtonDisabled(false);
              localStorage.removeItem("formVlaues");
              navigate("/signup");
            }
          }
        } else if (
          data.status.success === "false" &&
          data.status.code == 500 &&
          (localStorage.getItem("login") == false ||
            localStorage.getItem("login") == "false")
        ) {
          // let identifiersVlaue = [
          //     {
          //         "type": "mobile",
          //         "value": localStorage.getItem("mobile")
          //     }
          // ]
          // if (localStorage.getItem("lineId")) {
          //     identifiersVlaue.push({
          //         "type": "line",
          //         "value": localStorage.getItem("lineId")
          //     })
          // }
          // const postData = {
          //     "root": {
          //         "customer": [
          //             {
          //                 "mobile": localStorage.getItem("mobile"),
          //                 "firstname": formValues.firstname,
          //                 "lastname": formValues.lastname,
          //                 "email": "",
          //                 "registered_on": registereddate,
          //                 "referral_code": formValues.referal,
          //                 "identifiers":
          //                     identifiersVlaue
          //             }
          //         ]
          //     }
          // }
          console.log(
            formValues?.acceptMarketing,
            formValues?.agreePDPA,
            localStorage.getItem("formValues")
          );
          let tempFormValues = JSON.parse(localStorage.getItem("formVlaues"));
          const postData = {
            loyaltyInfo: {
              loyaltyType: "loyalty",
            },
            referralCode: tempFormValues?.referal,
            profiles: [
              {
                firstName: tempFormValues?.firstname,
                lastName: tempFormValues?.lastname,
                identifiers: [
                  {
                    type: "mobile",
                    value: localStorage.getItem("mobile"),
                  },
                  {
                    type: "line",
                    value: localStorage.getItem("lineId"),
                  },
                ],
                commChannels: [
                  {
                    type: "mobile",
                    value: localStorage.getItem("mobile"),
                    primary: true,
                  },
                ],
              },
            ],
            extendedFields: {
              app_tnc: tempFormValues?.agreePDPA,
              data_sharing_consent: tempFormValues?.acceptMarketing,
            },
          };
          const addCustomer = await CustomerApiService.registerCustomerNO(
            postData
          );
          // if (addCustomer?.warnings?.[0]?.code == 8050) {
          //     localStorage.setItem("customerFound", true)
          //     if(addCustomer?.warnings?.[0]?.message !== "Same Customer found in other sources,Combining"){
          //         toast(addCustomer?.warnings?.[0]?.message);
          //     }
          //     navigate("/welcome-rewards");
          // }
          // else {
          //     toast.error(addCustomer?.warnings?.[0]?.message);
          //     // toast.error(t('otp.add_customer_api_error'));
          //     navigate("/signup")
          // }
          if (addCustomer?.code == 300) {
            let shopifyPostData = {
              phone: localStorage.getItem("mobile"),
              first_name: tempFormValues?.firstname,
              last_name: tempFormValues?.lastname,
            };
            const shopifyUpdate = await axios.post(
              `${process.env.REACT_APP_gatewayURL}customerupdateshopify`,
              shopifyPostData,
              {
                headers: {
                  cap_authorization: localStorage.getItem("token"),
                  cap_identifier_value: localStorage.getItem("mobile"),
                },
              }
            );
          }
          if (addCustomer?.createdId) {
            let postData = {
              profiles: [
                {
                  firstName: tempFormValues?.firstname,
                  lastName: tempFormValues?.lastname,
                  fields: {
                    already_reg_user: reg_field,
                  },
                  identifiers: [
                    {
                      type: "mobile",
                      value: localStorage.getItem("mobile"),
                    },
                  ],
                },
              ],
              loyaltyInfo: {
                loyaltyType: "loyalty",
              },
              extendedFields: {},
            };

            const data = await axios.post(
              `${process.env.REACT_APP_gatewayURL}customerupdatecap`,
              postData,
              {
                headers: {
                  cap_authorization: localStorage.getItem("token"),
                  cap_identifier_value: localStorage.getItem("mobile"),
                },
              }
            );
            resetFormValues();
            localStorage.setItem("customerFound", true);
            localStorage.removeItem("formVlaues");
            navigate("/welcome");
          } else if (addCustomer?.errors) {
            toast.error(addCustomer?.error);
            setSubmitButtonDisabled(false);
            localStorage.removeItem("formVlaues");
            navigate("/signup");
          } else {
            toast.error(t("otp.add_customer_api_error"));
            setSubmitButtonDisabled(false);
            localStorage.removeItem("formVlaues");
            navigate("/signup");
          }
        } else if (
          data.status.success === "false" &&
          data.status.code == 500 &&
          (localStorage.getItem("login") == true ||
            localStorage.getItem("login") == "true")
        ) {
          toast.error(t("sign_in.toaster_user_not_found"));
          setSubmitButtonDisabled(false);
          localStorage.removeItem("formVlaues");
          navigate("/login");
        }
      } else if (data.status.code == 1506) {
        toast.error(t("otp.otp_validate_error"));
        setSubmitButtonDisabled(false);
      } else if (data.status.code == 1505) {
        toast.error("Invalid session");
        setSubmitButtonDisabled(false);
        localStorage.removeItem("formVlaues");
        navigate("/signup");
      }
    } else {
      setSubmitButtonDisabled(false);
      toast.warning(t("otp.otp_error"));
    }
  };

  useEffect(() => {
    let countdownInterval;

    if (isResendDisabled) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [isResendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
      setCountdown(30);
      clearInterval(countdown);
    }
  }, [countdown]);
  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("formVlaues")));
    return () => {
      dispatch(resetData());
      dispatch(resetOrderData());
      dispatch(resetPointHistory());
      dispatch(resetRewardData());
      dispatch(resetRewardsVouchersData());
      dispatch(resetReferralCode());
      dispatch(resetstoreData());
    };
  }, []);
  return (
    <>
      <div className="otp-container1" style={{ overflowX: "hidden" }}>
        {/* <div className="row align-items-center border-bottom plr-15">
          <div className="col-2">
            <div className="backpageSection">

              <img
                src={Back}
                alt=""
                onClick={() => {
                  navigate(-1);
                }}
              />
              
            </div>
          </div>
          <div className="col">
            <h5 className="boldhHeadingH5 text-center">{t("otp.enter_otp")}</h5>
          </div>
          <div className="col-2"></div>
        </div> */}

        <div className="row align-items-center border-bottom plr-15">
          <div className="col-2 text-center">
            <div
              className="backpageSection"
              onClick={() => {
                navigate(-1);
              }}
            >
              <div style={{ textDecoration: "none", color: "#000" }}>
                <img src={Back} alt="" />
              </div>
            </div>
          </div>
          <div className="col">
            <h5 className="boldhHeadingH5_topHeading text-center">
              {t("otp.enter_otp")}
            </h5>
          </div>
          <div className="col-1"></div>
        </div>
      </div>

      <div className="otp-container">
        <div className="login-content">
          <div className="otplabel">
            {t("otp.code_sent")} <br /> {localStorage.getItem("mobile")}{" "}
          </div>
          <div className="inputfield">
            {Array.from({ length: 6 }, (_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                inputMode="numeric"
                className="input"
                pattern="[0-9]*"
                value={otpValues[index]}
                ref={inputRefs[index]}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
            ))}
          </div>
          {/* { !isResendDisabled ? <span
            className="resend"
            style={{
              color: "blue",
              cursor: "pointer",
            }}
            onClick={handleResendClick}
            // disabled={isResendDisabled}
          >
            {t("otp.resend")}
          </span> : <span
            className="resend"
            style={{
              color: "blue",
              cursor: "pointer",
            }}
            onClick={handleResendClick}
            // disabled={isResendDisabled}
          >
            
          </span> } */}
          <span
            className="resend"
            style={{
              color: "blue",
              cursor: "pointer",
            }}
            onClick={handleResendClick}
            // disabled={isResendDisabled}
          ></span>
          <button
            type="submit"
            ref={buttonRef}
            onClick={handleSubmit}
            disabled={subButtonDisabled}
          >
            {t("otp.continue")}
          </button>

          <p className="alternative">
            {isResendDisabled
              ? t("otp.not_get_otp") + " " + countdown + " " + t("otp.sec")
              : t("otp.not_get_otp_please_click")}{" "}
            {!isResendDisabled ? (
              <span
                className="resend"
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={handleResendClick}
                // disabled={isResendDisabled}
              >
                {t("otp.resend")}
              </span>
            ) : (
              ""
            )}
            {}
          </p>
        </div>
      </div>
    </>
  );
};

export default Otp;
