// import { Scanner, useDeviceList } from "@yudiel/react-qr-scanner";
import React, { useState, useEffect } from "react";
import CustomerApiService from "../../utils/CustomerApiService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import close from "../../assets/images/Icons/closed.svg";
import { Link,useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import CmsDataService from '../../utils/CmsDataService';
const ScanSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { code } = useParams();

  const [couponCodes, setCouponCodes] = useState(null);
    // CMS 
    useEffect(() => {
        (async () => {
            const coupons = await CmsDataService.getCmsData('scantoearn')
            if (coupons?.status == 200) {
            let couponPoints=coupons.data[0].codes?.data.filter((e)=>e.coupon_code?.toLowerCase()?.trim() == code?.toLowerCase()?.trim())
            console.log(couponPoints)
            if(couponPoints?.length>0)
            setCouponCodes(couponPoints[0].points);
            }
        })()
    }, [couponCodes]);


  return (
    <>
 <div>
      <div style={{ textAlign: "center", padding: "80px 20px 20px 20px", height: "100%" }}>
        <h5 style={{ fontSize: "18px",fontWeight:"600" }}>{t("scanqr.congrats")}</h5>
        <p style={{ paddingTop: "15px", fontSize: "14px"}}>
          {t("scanqr.congrats_text1")}{" "}{couponCodes}{" "}{t("scanqr.congrats_text2")}
        </p>
        <button className="btn my-4 text-white" style={{alignItems:"end !important",padding:"10px 20px",fontSize:"18px",backgroundColor:"black",cursor:"pointer"}} onClick={()=>navigate("/earn")}>
                        {t("scanqr.back_to_earn")}
        </button>
      </div>
    </div>
    </>
  );
};

export default ScanSuccess;
