import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow.svg"
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
import CmsDataService from '../../utils/CmsDataService';
const ContactUs = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    const { t } = useTranslation();

    const [contactUs, setContactUs] = useState([])
    useEffect(() => {
        (async () => {
            const data = await CmsDataService.getCmsData("contactus")
            setContactUs(data?.data)
        })()
    }, [])
    return (
        <>
            <section className="samelayoutpage">
                <div className="container">
                    <div className="row align-items-center border-bottom">
                        <div className="col-1 ">
                            <div className="backpageSection" onClick={goBack}>

                                <img src={Back} alt="" />

                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">
                            {t('profile.contact_us')}
                            </h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="terms-text">
                    {contactUs?.map((item) => (
                            <div key={item._id}>
                                <div dangerouslySetInnerHTML={
                                    { __html: item.description }
                                } />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs