import React, { useEffect, useState } from 'react';
import Back from "../../assets/images/backarrow.svg";
import { useNavigate } from 'react-router';
import CmsDataService from '../../utils/CmsDataService';
import { Collapse } from "@mui/material";
import { useTranslation } from 'react-i18next';
const Faq = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const goBack = () => {
        navigate(-1);
    };
    const [apifaqs, setApifaqs] = useState([]);
    // CMS Faqs
    useEffect(() => {
        (async () => {
            const faqAPIdata = await CmsDataService.getCmsData('faqs')
            setApifaqs(faqAPIdata.data.sort((a, b) => a.sequence > b.sequence ? 1 : -1));
        })()
    }, []);

    const [isCollapsed, setCollapsed] = useState(false);
    const handleClick = (itemId) => {
        setCollapsed((prevId) => (prevId === itemId ? null : itemId));
      };


    return (
        <>
            <section className="faq">
                <div className="container">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-1">
                            <div className="backpageSection" onClick={goBack}>

                                <img src={Back} alt="" />

                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">{t('global.faqs')}</h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="faq-row">
                        <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                        >
                            {apifaqs.map((item) => {
                                return (
                                        <div className="accordion-item" key={item._id}>
                                            <h2 className="accordion-header pt-2 pb-2 background-none" id="flush-headingOne">
                                                <button className={`accordion-button ${isCollapsed === item._id ? '' : 'collapsed'}`} onClick={() => handleClick(item._id)}
                                                    type="button">{item.question}</button>
                                            </h2>
                                            <Collapse
                                                id="flush-collapseOne" in={isCollapsed === item._id}
                                                className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <div dangerouslySetInnerHTML={
                                                        { __html: item.answer }
                                                    } />
                                                </div>
                                            </Collapse>
                                        </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;
