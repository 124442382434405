


class CmsDataService {

    async getCmsData(key) {
        try {
            const response = await fetch(`${process.env.REACT_APP_cmsurl}${key}?lang=${localStorage.getItem("i18nextLng")}`);
            return response.json()
        }
        catch (error) {
            console.log(error);
        }
    }

}

export default new CmsDataService();