/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getMilestoneReward = createAsyncThunk(
    "milestoneReward/get",
    async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}targetGroups`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    mileStoneLoading: false,
    milestoneRewardDetails: {},
    milestoneRewardsuccess: false
}
const getMilestoneRewardSlice = createSlice({
    name: "getMilestoneRewardDetail",
    initialState,
    reducers: {
        resetMilestoneRewardData: (state) => {
            state.milestoneRewardDetails = {};
            state.milestoneRewardsuccess=false
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getMilestoneReward.pending, (state) => {
            state.mileStoneLoading = true;
        });
        builder.addCase(getMilestoneReward.fulfilled, (state, action) => {
            state.milestoneRewardDetails = action?.payload;
            state.mileStoneLoading = false;
            state.milestoneRewardsuccess = true;
        });
        builder.addCase(getMilestoneReward.rejected, (state) => {
            state.mileStoneLoading = false;
            state.milestoneRewardDetails = {};
            state.milestoneRewardsuccess=false
        });
    }
});

export { getMilestoneReward };
export const { resetMilestoneRewardData } = getMilestoneRewardSlice.actions;
export default getMilestoneRewardSlice.reducer;