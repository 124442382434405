/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getReward = createAsyncThunk(
    "reward/get",
    async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}marvel/reward/detail?language=${localStorage.getItem("i18nextLng")}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    rewardDetails: {},
    rewardsuccess: false
}
const getRewardSlice = createSlice({
    name: "getrewardDetail",
    initialState,
    reducers: {
        resetRewardData: (state) => {
            state.rewardDetails = {};
            state.rewardsuccess=false
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getReward.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getReward.fulfilled, (state, action) => {
            state.rewardDetails = action?.payload?.rewardList;
            state.loading = false;
            state.rewardsuccess = true;
        });
        builder.addCase(getReward.rejected, (state) => {
            state.loading = false;
            state.rewardDetails = {};
            state.rewardsuccess=false
        });
    }
});

export { getReward };
export const { resetRewardData } = getRewardSlice.actions;
export default getRewardSlice.reducer;