import React, { useEffect, useState } from 'react'
import Back from "../../assets/images/backarrow.svg"
import { useNavigate } from 'react-router'
import CmsDataService from '../../utils/CmsDataService';
import { useTranslation } from 'react-i18next';
const TermsCondition = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const goBack = () => {
        navigate(-1);
    };

    const [apitnc, setApitnc] = useState([]);
    useEffect(() => {
        (async () => {
            const tncAPIdata = await CmsDataService.getCmsData('termsofuse')
            setApitnc(tncAPIdata.data);
            console.log(apitnc)
            // console.log(apitnc,'tnc data')
        })()
    }, []);

    return (
        <>
            <section className="samelayoutpage">
                <div className="container">
                    <div className="row align-items-center border-bottom plr-15">
                        <div className="col-1">
                            <div className="backpageSection" onClick={goBack}>

                                <img src={Back} alt="" />

                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">
                            {t('profile.terms_of_use')}
                            </h5>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <div className="terms-text">
                        {apitnc.map((item) => (
                            <div key={item._id}>
                                <div dangerouslySetInnerHTML={
                                    { __html: item.description }
                                } />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsCondition