import React, { useEffect, useState } from "react";
import HomeImg from "../../assets/images/gray-home.svg";
import Earn from "../../assets/images/gray-trophy.svg"
import { NavLink } from "react-router-dom";
import Reward from "../../assets/images/gray-reward.svg"
import Store from "../../assets/images/gray-store.svg"
import Account from "../../assets/images/gray-account.svg"
import { getPointHistory } from "../../redux/slices/customer/GetPointhistory";
import "./footer.scss";
import { useTranslation } from "react-i18next";
import { resetAllData } from "../../utils/resetActions";
import { useDispatch, useSelector } from "react-redux";
import { getMilestoneReward } from "../../redux/slices/Rewards/GetMilestoneRewards"; 
const Footer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const { pointDataHis, Pointsuccess } = useSelector(
    //     (state) => state.getPointHistory
    // );
    const { milestoneRewardDetails, milestoneRewardsuccess , mileStoneLoading} = useSelector(
        (state) => state.getMilestoneReward
    );
    const [guest, setGuest] = useState(true);
    const [pointData, setPointData] = useState(false);
    useEffect(() => {
        if (
            localStorage.getItem("token") &&
            localStorage.getItem("customerFound")
        ) {
            setGuest(false);
        }
    }, []);
    useEffect(() => {
    //     if (Pointsuccess == false && localStorage.getItem("token") && localStorage.getItem("customerFound")) {
    //         dispatch(getPointHistory());
    //     }
    //  if (
    //         pointDataHis?.status?.code == 401 ||
    //         pointDataHis?.status?.code == 403
    //     ) {
    //         resetAllData(dispatch);
    //         let lang=localStorage.getItem("i18nextLng")
    //         localStorage.clear();
    //         localStorage.setItem("i18nextLng", lang);
    //         window.location.reload();
    //     }
    //     else if (pointDataHis?.promotion_points?.[0]?.customer?.item?.length > 0) {
    //         // setpointsExpiry(pointDataHis?.expiry_schedule?.[0]?.schedule[0]);
    //         let newPromotion=pointDataHis?.promotion_points?.[0]?.customer?.item?.find((e)=>{
    //             return new Date(e.issued_on).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)
    //         })
    //         if(newPromotion)
    //         setPointData(true);
    //     }
        if (milestoneRewardsuccess == false && localStorage.getItem("token") && localStorage.getItem("customerFound")) {
            dispatch(getMilestoneReward());
        }
        if (
            milestoneRewardDetails?.status?.code == 401 ||
            milestoneRewardDetails?.status?.code == 403
        ) {
            resetAllData(dispatch);
            let lang=localStorage.getItem("i18nextLng")
            let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
            let lineId = localStorage.getItem('lineId');
            localStorage.clear();
            localStorage.setItem("i18nextLng", lang);
            localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
            localStorage.setItem('lineId', lineId);
            window.location.reload();
        }
        if(milestoneRewardDetails?.data){
            let targets=[];
            if (milestoneRewardDetails?.data?.targetGroups?.length > 0) { 
                milestoneRewardDetails?.data?.targetGroups.map((targetGroup, targetGroupIndex) => {
                    targetGroup?.targets?.map((target,targetIndex)=>{
                        if(new Date(target?.enrolledOn).setHours(0,0,0,0) == new Date().setHours(0,0,0,0))
                        targets.push(target)
                    })
                })
                if(targets?.length>0)
                setPointData(true)
                }
        }


    }, [milestoneRewardsuccess]);
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="footer-item col">
                            <NavLink to="/main-home"
                                className={({ isActive }) =>
                                    isActive ? 'active_footer' : ''
                                }>
                                <img
                                    src={HomeImg}
                                    alt=""
                                />
                                <p  className={localStorage.getItem("i18nextLng")=='th'? "nav-max-content_home" :""}>{t('footer.home')}</p>
                            </NavLink>
                        </div>  
                        <div className="footer-item col">                      
                            <NavLink to="/earn"
                            style={{ paddingTop: pointData && !guest ? "5px":"" }}
                                className={({ isActive }) =>
                                    isActive ? 'active_footer' : ''
                                }>
                            {pointData && !guest ?<div className="red-dot"></div>:""} 
                                <img
                                    src={Earn}
                                    alt=""
                                    loading="lazy"
                                />
                                 <p  className={ localStorage.getItem("i18nextLng")=='th'? "nav-max-content" :""}>{t('footer.earn')}</p>
                            </NavLink>
                        </div>
                        <div className="footer-item col">
                            <NavLink to="/reward"
                                className={({ isActive }) =>
                                    isActive ? 'active_footer' : ''
                                }>
                                <img src={Reward} alt="" />
                                <p>{t('footer.reward')}</p>
                            </NavLink>
                        </div>
                        <div className="footer-item col text-center">
                            <NavLink to="/store-locator"
                                className={({ isActive }) =>
                                    isActive ? 'active_footer' : ''
                                }>
                                <img src={Store} alt="" />
                                <p>{t('footer.store')}</p>
                            </NavLink>
                        </div>
                        <div className="footer-item col">
                            <NavLink to="/profile"
                                className={({ isActive }) =>
                                    isActive ? 'active_footer' : ''
                                }>
                                <img src={Account} alt="" />
                                <p>{t('footer.account')}</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
