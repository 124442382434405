/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const nearbystore = createAsyncThunk(
    "store/nearby",
    async(latitude)=> {
        try {
            const data = await axios.get(`${process.env.REACT_APP_storegetURL}v1/brand/nearbystores?latitude=${latitude[0]}&longitude=${latitude[1]}&distance=${latitude[2]}&size=10000`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_mobile: localStorage.getItem("mobile"),
                    cap_brand: process.env.REACT_APP_brand,
                    cap_device_id: process.env.REACT_APP_deviceId,
                    'Content-Type': "application/json",
                }
            })
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    storeDetails: {},
    error: "",
    Storesuccess: false
}
const nearbystoreSlice = createSlice({
    name: "storesdetail",
    initialState,
    reducers:{
        resetstoreData: (state) => {
            state.storeDetails = {};
            state.Storesuccess=false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(nearbystore.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(nearbystore.fulfilled, (state,action) => {
            state.loading = false;
            state.storeDetails = action.payload;
            state.Storesuccess = true;
        });
        builder.addCase(nearbystore.rejected,(state)=>{
            state.loading = false;
            state.error = "No data";
        });
    }
});

export {nearbystore};
export const {resetstoreData} = nearbystoreSlice.actions;
export default nearbystoreSlice.reducer;