import React from 'react'
import { Link } from 'react-router-dom'
import Back from "../../assets/images/backarrow.svg"
import close from "../../assets/images/Icons/closed.svg"
import './completeProfile.scss'
const CompleteProfile = () => {



    function closeModal() {
        const modalDiv = document.getElementById('exampleModalCenter');
        if (modalDiv != null) {
            modalDiv.style.display = 'none';
        }
    }



    return (

        <div className="complete-profile">
            <div className="login-content">
                <div className="row align-items-center border-bottom">
                    <div className="col-2 text-center">
                        <div className="backpageSection">
                            <Link to="/viewprofile">
                                <img src={Back} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="boldhHeadingH5 text-center">
                            Complete Profile
                        </h5>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="container-padding">
                    <div className="toptext">Don’t miss out 10 points<br /><span>Just easily fill in you profile information</span></div>
                    <form>
                        <div className="input-field1">
                            <label htmlFor="email">Email  </label><br />
                            <input type="text" id="email" name="email" />
                        </div>


                        <div className="input-field">
                            <label htmlFor="gender">Gender </label>
                            <div className="radiobutton">
                                <input type="radio" value="male" id="gender" name="gender" />
                                <span className="radiolabel"> Male</span>
                                <input type="radio" value="female" id="gender" name="gender" />
                                <span className="radiolabel"> Female</span>
                                <input
                                    type="radio"
                                    value="lgbtqia+"
                                    id="gender"
                                    name="gender"
                                />
                                <span className="radiolabel"> LGBTQIA+</span>
                                <input
                                    type="radio"
                                    value="rathernotsay"
                                    id="gender"
                                    name="gender"
                                />
                                <span className="radiolabel">Rather not say</span>
                            </div>
                        </div>
                        <div className="input-field">
                            <div className="doblabel">
                                <label htmlFor="dob">Date of Birth </label><br />
                                <span className="labelsub">Editing later, Please contact CS</span>
                            </div>
                            <input type="text" id="dob" name="dob" />
                        </div>

                        <div className="input-field">
                            <label htmlFor="address">Address</label><br />
                            <input type="text" id="address" name="address" />
                        </div>
                        <div className="input-field">
                            <label htmlFor="post">Postcode</label><br />
                            <input type="text" id="post" name="post" />
                        </div>

                        <div className="input-field">
                            <label htmlFor="province">Province </label><br />
                            <select id="province" name="province">
                                <option value="1">Pnratumwa</option>
                                <option value="2">xxx</option>
                                <option value="3">yyy</option>
                                <option value="4">zzz</option>
                            </select>
                        </div>

                        <div className="input-field">
                            <label htmlFor="province">District </label><br />
                            <select id="province" name="province">
                                <option value="1">Pratumwan</option>
                                <option value="2">xxx</option>
                                <option value="3">yyy</option>
                                <option value="4">zzz</option>
                            </select>
                        </div>


                        <div className="savebutton" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                            <button type="button" >Save</button>

                        </div>

                    </form>
                    <div style={{ height: '80px' }}></div>
                </div>
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header" onClick={closeModal}>
                                <img src={close} data-bs-dismiss="modal" />
                            </div>
                            <div className="modal-text">BIG CONGRATS!</div>
                            <div className="modal-subtext">Your profile is completed</div>
                            <Link to="/viewprofile">  <div className="modal-button"><button type="button" className="btn btn-secondary modal-button" data-bs-dismiss="modal">View my profile</button></div></Link>
                            <div className="modal-foottext">Back to Home</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CompleteProfile