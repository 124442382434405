/* eslint-disable no-undef */
import axios from 'axios';

const instance = axios.create({
    headers: {
        cap_identifier_type: process.env.REACT_APP_identifierType,
        cap_brand: process.env.REACT_APP_brand,
        cap_device_id: process.env.REACT_APP_deviceId,
        'Content-Type': "application/json",
    },
});


export default instance;
