import React from 'react'
import Back from "../../assets/images/backarrow.svg"
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next';
const DeleteAccount = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <>
            <section className="samelayoutpage">
                <div className="container">
                    <div className="row align-items-center border-bottom">
                        <div className="col-1" style={{width:"4% !important"}}>
                            <div className="backpageSection" onClick={goBack}>

                                <img src={Back} alt="" />

                            </div>
                        </div>
                        <div className="col">
                            <h5 className="nav_heading text-center">
                            {t('profile.delete_account')}
                            </h5>
                        </div>
                        <div className="col-1" style={{width:"4% !important"}}></div>
                    </div>
                    <div className="terms-text pt-3">
                        <p style={{fontSize:"14px"}}>
                        {t('delete_account.delete_account_text')} <br /><br></br>
                            <b>Email:</b> csteam@suparagroup.com
                            <br /><br></br> <b>Phone:</b><br></br> (+66) 80 059 7128<br></br>
                                          (+66) 99 503 1736<br></br>
                                          (+66) 61 394 7966
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DeleteAccount