/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getRewardVoucher = createAsyncThunk(
    "reward/getvoucher",
    async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}get/marvel/vouchers?status=true&till_id=${process.env.REACT_APP_tillname}`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            });
            return data.data;
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    rewardVouchersData: {},
    rewardvouchersuccess: false
}
const getRewardVoucherSlice = createSlice({
    name: "getrewardvoucherDetail",
    initialState,
    reducers:{
        resetRewardsVouchersData: (state) => {
            state.rewardVouchersData = {};
            state.rewardvouchersuccess=false
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getRewardVoucher.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getRewardVoucher.fulfilled, (state,action) => {
            state.loading = false;
            state.rewardVouchersData = action.payload;
            state.rewardvouchersuccess = true;
        });
        builder.addCase(getRewardVoucher.rejected,(state)=>{
            state.loading = false;
        });
    }
});

export {getRewardVoucher};
export const { resetRewardsVouchersData } = getRewardVoucherSlice.actions;
export default getRewardVoucherSlice.reducer;