/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from "react";
import Back from "../../assets/images/backarrow.svg";
import './verifyPassword.scss';
import { Link } from 'react-router-dom';

const VerifyPassword = () => {
    const inputRefs = Array.from({ length: 4 }, () => useRef(null));

    const handleInputChange = (index, e) => {
        const value = e.target.value;
        if (value && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    return (
        <>
            <div className="verify-container1">
                <div className="row align-items-center border-bottom plr-15">
                    <div className="col-2 text-center">
                        <div className="backpageSection">
                            <Link to="/forgot-password" style={{ textDecoration: "none", color: "#000" }}>
                                <img src={Back} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="boldhHeadingH5 text-center">Verification</h5>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
            <div className="verify-container">
                <div className="login-content">
               
                    <div className="inputfield">
                        {Array.from({ length: 4 }, (_, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                className="input"
                                ref={inputRefs[index]}
                                onChange={(e) => handleInputChange(index, e)}
                            />
                        ))}
                    </div>
                    <div className="resenddiv">
                    <p  className="resend">If you don't receive a code. <span className="resendbold">Resend</span></p>
                    </div>
                   
                        <button type="submit">Verify</button>
                   
                </div>
            </div>
        </>
    );
};

export default VerifyPassword;
