import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import {phone} from 'phone';
// 

const PrivateRoutes = () => {
  const auth = { token: localStorage.getItem("token"),
                 customerFound: localStorage.getItem("customerFound")
};

  return auth.token && auth.customerFound ?
    <Outlet />
    :
    <Navigate to="/join-us" replace={true} />;
};

const LineIdPrivateRoutes = () => {
  const auth = { lineId: localStorage.getItem("lineId"),
};
  return auth.lineId != null && auth.lineId != 'null' && auth.lineId != 'undefined' && auth.lineId != undefined && auth.lineId != ""?
    <Outlet />
    :
    <Navigate to="/page-not-found" replace={true} />;
};
const SignupPrivateRoutes = () => {
  const auth = { mobile: localStorage.getItem("line_mobile")};
  const { phoneNumber, countryCode } = phone(`+${auth.mobile}`);
  console.log(phoneNumber, countryCode)
  return !(phoneNumber && countryCode) ?
    <Outlet />
    :
    <Navigate to="/login" replace={true} />;
};
const LoginPrivateRoutes = () => {
  const auth = { mobile: localStorage.getItem("line_mobile")};
  const { phoneNumber, countryCode } = phone(`+${auth.mobile}`);
  console.log(phoneNumber, countryCode)
  return (phoneNumber && countryCode) ?
    <Outlet />
    :
    <Navigate to="/signup" replace={true} />;
};

const CustomerFoundRoutes = () => {
  const data = localStorage.getItem("customerFound");
  // const { phoneNumber, countryCode } = phone(`+${auth.mobile}`);
  // console.log(phoneNumber, countryCode)
  return !data ?
    <Outlet />
    :
    <Navigate to="/main-home" replace={true} />;
};
export {PrivateRoutes,LineIdPrivateRoutes,SignupPrivateRoutes,LoginPrivateRoutes,CustomerFoundRoutes};

