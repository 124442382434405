import React from 'react'
import Back from "../../assets/images/backarrow.svg";
import RedMail from "../../assets/images/Icons/red-mail.svg"
import { useNavigate } from 'react-router-dom';
import './guestNotification.scss'
const GuestNotification = () => {
    const navigate=useNavigate()
  return (
    <>
    <section className="guest-notification">
    <div className="container">
        <div className="row align-items-center border-bottom plr-15">
            <div className="col-2 text-center">
                <div className="backpageSection">
                        <img src={Back} alt="" onClick={()=>{navigate(-1)}}/>
                </div>
            </div>
            <div className="col">
                <h5 className="boldhHeadingH5 text-center">
                    Notification
                </h5>
            </div>
            <div className="col-2"></div>
        </div>
    </div>
    <div className="container">
        <div className="track-order-section max-width-box">
            <div className="track-order-box">
                <img src={RedMail} alt="" />
                <div className="join-text">No message yet</div>
                <p>Let’s be our membership to get the previleges. You won’t miss out any of special offers!</p>
                <button className="comman-button" onClick={()=>{navigate("/signup")}}>
                    Join us
                </button>
            </div>
           
        </div>
    </div>
</section>
    </>
  )
}

export default GuestNotification