/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import Humburger from "../../assets/images/earnhumburger.svg";
import challengerImg from "../../assets/images/challenges-1.png";
import { CircularProgress } from "@mui/material";
// import challengerImg2 from "../../assets/images/challenges-2.jpg";
// import challengerImg3 from "../../assets/images/challenges-3.jpg";
import scaneerImg from "../../assets/images/Scanning.png";
import gqcoin from "../../assets/images/GQ-Coin.png"
import Footer from "../../components/footer/Footer";
import close from "../../assets/images/close.svg"
import greencheck from "../../assets/images/pdpa-green-check.svg";
import './earn.scss'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code';
import { useSwipeable } from "react-swipeable";
// import CustomerApiService from "../../utils/CustomerApiService";
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns';
import { th } from "date-fns/locale";
import { getCustomer } from '../../redux/slices/customer/GetCustomer';
import { getPointHistory } from "../../redux/slices/customer/GetPointhistory";
import { getMilestoneReward } from "../../redux/slices/Rewards/GetMilestoneRewards"; 
import { resetAllData } from "../../utils/resetActions";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";
import { height } from "@mui/system";
const Earn = () => {
    const { cmsData, updateCmsData } = useCms();
    const [promotionBenefits, setPromotionBenefits] = useState([])
    const [mileStoneRewards,setMileStoneReward]=useState([])
    const [containerHeight, setContainerHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [apiCalled,setApiCalled]=useState(false);
    // const [currentTime, setCurrentTime] = useState(new Date());
    const [banners, setBanners] = useState([])
    const [scanNow, setScanNow] = useState(null);
    const [milestoneRewards,setMilestoneRewards]=useState([])
    const [challengeBg, setChallengeBg] = useState(null);
    const [activeBanner, setActiveBanner] = useState(0);
    const [sliderIndexnew, setSliderIndexnew] = useState(0);
    const navigate = useNavigate()
    const [tierinfo, setTierinfo] = useState({
        cSlab: "",
        nSlab: "",
        avPoint: "",
        gapUpgrade: ""
    });
    const dispatch = useDispatch();
    const { customerDetails, success, loading } = useSelector(state => state.getCustomer);
    const { milestoneRewardDetails, milestoneRewardsuccess , mileStoneLoading} = useSelector(
        (state) => state.getMilestoneReward
    );
    const handleChange = (e) => {
        setSliderIndexnew(parseInt(e.target.value, 10));
      };
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
          if (eventData.dir == "Right" && sliderIndexnew >= 0)
            setSliderIndexnew(sliderIndexnew - 1);
          if (eventData.dir == "Right" && sliderIndexnew == 0)
            setSliderIndexnew(banners?.length - 1);
          if (
            eventData.dir == "Left" &&
            sliderIndexnew < banners?.length - 1
          ) {
            setSliderIndexnew(sliderIndexnew + 1);
          }
          
          if (
            eventData.dir == "Left" &&
            sliderIndexnew == banners?.length - 1
          ) {
            setSliderIndexnew(0);
          }
        },
        // Other configuration options
      });
    const getApi = async () => {
        setTierinfo({
            cSlab: customerDetails?.customers?.customer[0].points_summaries.points_summary[0]?.currentSlab,
            nSlab: customerDetails?.customers?.customer[0].points_summaries.points_summary[0]?.nextSlab,
            avPoint: customerDetails?.customers?.customer[0].points_summaries.points_summary[0]?.loyaltyPoints,
            gapUpgrade: customerDetails?.customers?.customer[0]?.points_summaries.points_summary[0]?.gap_to_upgrade?.upgrade_strategy[0]?.customer_upgrade_entity_values?.gap_to_upgrade
        });
    }
    const { cSlab, nSlab, avPoint, gapUpgrade } = tierinfo
    const { t } = useTranslation();
    const handleNavigate =(data)=>{
        
navigate(`/earn-progress/earn?id=${data.targetId}`)
    }

    
    const updateContainerSize = (id, ratio) => {
        const container = document.getElementById(id);
        console.log("set value out")
        if (container) {
            console.log("set value")
          const { width } = container.getBoundingClientRect();
          setContainerWidth(width);
          setContainerHeight(width * ratio); // Adjust the aspect ratio here
        }
      }; 

    useEffect(() => {
        if (success == false || apiCalled!== true) {
            setApiCalled(true)
            dispatch(getCustomer(localStorage.getItem("mobile")));
        }
         if ((customerDetails?.status?.code == "404")) {
            resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
            navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }
        

        getApi();

        
          window.addEventListener("resize", () =>
            updateContainerSize("scan-to-earn-banner", 59 / 175)
          );

        //   return () => {
        //     window.removeEventListener("resize", () =>
        //       updateContainerSize("scan-to-earn-banner", 59 / 175)
        //     );}

    }, [cmsData ,customerDetails])
    useEffect(()=>{
        if (milestoneRewardsuccess == false) {
            dispatch(getMilestoneReward());
        }
        if (
            milestoneRewardDetails?.status?.code == 401 ||
            milestoneRewardDetails?.status?.code == 403
        ) {
            resetAllData(dispatch);
        let lang=localStorage.getItem("i18nextLng")
        let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
        let lineId = localStorage.getItem('lineId');
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
        localStorage.setItem('lineId', lineId);
            window.location.reload();
        }
        if(milestoneRewardDetails?.data){
            let targets=[];
            if (milestoneRewardDetails?.data?.targetGroups?.length > 0) { 
                milestoneRewardDetails?.data?.targetGroups.map((targetGroup, targetGroupIndex) => {
                    targetGroup?.targets?.map((target,targetIndex)=>{
                        if(new Date(target?.periodEndDate).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)){
                        let temp={...target};
                        temp['id']=targetGroup.id;
                        targets.push(temp)
                        }
                    })
                })
                let filtered=targets?.sort((a, b) => ( (((a?.targetAchievedValue>=a?.targetValue ? a?.targetValue: a?.targetAchievedValue) /a?.targetValue) * 100).toFixed(2) > (((b?.targetAchievedValue>=b?.targetValue ? b?.targetValue: b?.targetAchievedValue)/b?.targetValue) * 100).toFixed(2) ? 1 : -1))
                
                let updatedFiltered = filtered.map((data, index) => {
                    let tempMileStoneReward = milestoneRewards?.filter((e) => e.targetid == data.id);
                
                    if (tempMileStoneReward?.length > 0) {
                        return {
                            ...data,
                            points: tempMileStoneReward[0]?.points,
                            description: tempMileStoneReward[0]?.description
                        };
                    } else {
                        return data; 
                    }
                });
                
                setMileStoneReward(updatedFiltered)
                }
        }
    },[milestoneRewardsuccess,milestoneRewards,cmsData])

    useEffect(() => {
        updateContainerSize("scan-to-earn-banner", 59 / 175);
        (async () => {
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData?.earnBenefitsBanners?.length == 0
            ) {
                const earnBenefitsBannersData = await CmsDataService.getCmsData("earnbenefitsbanners");
                if (earnBenefitsBannersData?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["earnBenefitsBanners"]: earnBenefitsBannersData?.data,
                        ["language"]: localStorage.getItem("i18nextLng"),
                    }));
                }
            }
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData?.assets?.length == 0
            ) {
                const assetsData = await CmsDataService.getCmsData("assets");
                if (assetsData?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["assets"]: assetsData?.data[0],
                        ["language"]: localStorage.getItem("i18nextLng"),
                    }));
                }
            }
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData?.milestoneRewrads?.length == 0
            ) {
                const milestoneRewradsData = await CmsDataService.getCmsData("milestonerewards");
                if (milestoneRewradsData?.status == 200) {
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["milestoneRewrads"]: milestoneRewradsData?.data,
                        ["language"]: localStorage.getItem("i18nextLng"),
                    }));
                }
            }
            
        })();
    }, [scanNow])
    useEffect(() => {
        if (cmsData?.earnBenefitsBanners?.length > 0)
        setBanners(
            cmsData?.earnBenefitsBanners?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
        );
        if (cmsData?.assets?.length !==0){
        setScanNow(
            cmsData?.assets?.scantoearnbgimage
        );
        setChallengeBg(cmsData?.assets?.challengesone)
        }
        if (cmsData?.milestoneRewrads?.length !==0){
            setMilestoneRewards(cmsData?.milestoneRewrads)
        }
    }, [cmsData])


    return (
        <>
            {loading || mileStoneLoading ? <div
                className="loader"
            >
                <CircularProgress />
                <div> Loading....</div>
            </div> :
                <section className="sections earn">
                    <div className="earn-pointRange linerGradientRedBlack">
                        <div className="bg-light-gray p-4">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col"></div>
                                    <div className="col-6">
                                        <h5 className="heading_earn"> {t('earn.earn')} </h5>
                                    </div>
                                    <div className="col text-end" style={{ cursor: "pointer" }}>
                                        <Link to="/earn-history">
                                            <img src={Humburger} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="points">
                                {" "}
                                {t('membershiptier.your_curr_point')} &nbsp;
                                <i className="fa-solid fa-circle-user"></i>
                                <span className="pointBold"><img src={gqcoin} alt="" width="15px" /> {avPoint? parseInt(avPoint)?.toLocaleString():""} </span>
                                {t('membershiptier.points')}{" "}
                            </div>
                            <div className="tier">
                                <h5 className="boldhHeadingH5 title"> {cSlab} </h5>
                                <h5 className="boldhHeadingH5 nextTier title"> {nSlab} </h5>
                            </div>
                            <div
                                role="progressbar"
                                aria-label="Success example"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                className="progress"
                                
                            >
                                <div className="pointProgressBar" style={{ width: `${((avPoint / customerDetails?.customers?.customer?.[0]?.points_summaries?.points_summary?.[0]?.gap_to_upgrade?.upgrade_strategy?.[0]?.upgrade_threshold * 100).toFixed(2))}%` }}></div>
                            </div>
                            {gapUpgrade?
                            <p className="nextTierText text-white">{t('membershiptier.earn')} {gapUpgrade} {t('membershiptier.point_to_next_tier')}</p>:""}
                        </div>
                    </div>
                    <section className="paddingGap-right paddingGap-left">
                        <div className="earn-benefit">
                            <h5 className="boldhHeadingH5"> {t('earn.earn_benefit')} </h5>
                            {/* <div id="carouselExampleIndicators" className=" earn-carousals carousel slide">

                                <div className="carousel-inner">
                                    {banners?.map((bannersData, index) => {
                                        if (new Date(bannersData.startdate) < new Date() && new Date(bannersData.enddate) > new Date()) {
                                            return (
                                                <div key={`bannersData${index}`} className={ activeBanner==index? "tierScroller carousel-item active":"tierScroller carousel-item"}>
                                                    <img src={bannersData?.banner} style={{ width: "inherit" }}></img>
                                                </div>
                                            )
                                        }
                                    })}

                                </div>
                                <div className="carousel-indicators">
                                {banners?.map((bannersData, index) => {
                                    if (new Date(bannersData.startdate) < new Date() && new Date(bannersData.enddate) > new Date()) {
                                    return(
                                    <button key={`indecators${index}`} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index}
                                        className={activeBanner==index? "active":""} aria-current="true" aria-label={`Slide ${index}`} ></button>
                                    )}
                                })}

                                </div>
                            </div> */}
                            

                            <div {...handlers} id="carouselExampleIndicators" className=" earn-carousals carousel slide">
              <div className="carousel">
                <div className="carousel-inner">
              {banners?.map((bannersData, index) => {
                                        if (new Date(bannersData.startdate) < new Date() && new Date(bannersData.enddate) > new Date() && sliderIndexnew == index) {
                                            return (
                                                <div key={`bannersData${index}`} className={ sliderIndexnew==index? "tierScroller carousel-item active":"tierScroller carousel-item"}>
                                                    <img src={bannersData?.banner} style={{ width: "inherit" }}></img>
                                                </div>
                                            )
                                        }
                                    })}
                                    </div>
                </div></div>
                <div style={{ position: "static",textAlign:"center" }} >
              {banners.map((buttonData, buttonIndex) => {
                return (
                  <button
                    onClick={handleChange}
                    value={buttonIndex}
                    key={`button${buttonIndex}`}
                    type="button"
                    className={
                      sliderIndexnew == buttonIndex ? "earnbanner-active earn-dots" : "earn-dots"
                    }
                  ></button>
                );
              })}
            </div>

                        </div>
                        <div className="earn-more">

                            {mileStoneRewards?.length >0 && <h5 className="boldhHeadingH5" style={{paddingBottom:"15px"}}> {t('earn.let_earn_more')} </h5>}

                            {mileStoneRewards?.map((data, id) => {
                                // let data=target?.targets[0]
                                return (
                                    <div key={`promotionBenefits${id}`} id={data.targetId} className="d-flex challenge_row" onClick={()=>handleNavigate(data)}>
                                        <div className="col-4">
                                            <div className="img-section" style={{height:"100%"}}>
                                                <img
                                                    src={challengeBg? challengeBg :challengerImg}
                                                    alt=""
                                                    width="100%"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="getPoints" style={{height:"100%",display:"flex",flexDirection:"column",justifyContent: "space-between", overflow:"hidden" }}>
                                                <div><p className="challengeName">
                                                    {" "}
                                                    {data.targetName}{" "}
                                                </p></div>
                                                <div>
                                                <span><img src={gqcoin} alt="" width="15px" style={{padding:"0px 1px 3px 0px"}} />{" "}<span className="challenge-points">{data?.points ? data?.points?.toLocaleString() : 0 }</span></span>
                                                <div className="row align-items-center">
                                               
                                                    <div className="col-8">
                                                        
                                                        {/* <p className="challenge-points">{data.points}</p> */}
                                                        <div
                                                            role="progressbar"
                                                            aria-label="Success example"
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            className="progress"
                                                            style={{backgroundColor:"#EBEBEB"}}
                                                        >
                                                            <div
                                                                className="progress-bar"
                                                                style={{ width: `${((data?.targetAchievedValue/data?.targetValue) * 100).toFixed(2)}%`}}
                                                            ></div>
                                                        </div>
                                                        <div className="d-flex justify-content-between Ponit_status">
                                                            <p>{data?.targetAchievedValue >= data?.targetValue ? data?.targetValue : data?.targetAchievedValue }/{data?.targetValue}</p>
                                                            
                                                            <p
                                                                className={data.targetAchievedValue < data.targetValue ? "tier_points_status_gray" :"tier_points_status_green"}

                                                            >
                                                               {data.targetAchievedValue >= data.targetValue && <img src={greencheck} style={{ paddingRight: "2px",marginBottom:"2px",height:"8px" }}></img>}
                                                                {data.targetAchievedValue < data.targetValue ? t('earn.in_progress') : t('earn.completed')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-5" style={{ paddingLeft: "5px",paddingRight:"5px" }}>


                                                       {/* {data.targetAchievedValue < data.targetValue ?  <button
                                                            disabled
                                                            className="btn btn-primary get_points_btn"
                                                            style={{ display: "flex" }}
                                                        >
                                                            <p>{t('earn.get_points')}</p>
                                                        </button> : <button
                                                            disabled
                                                            className="btn btn-primary claimed_btn"
                                                            style={{ display: "flex" }}
                                                        >
                                                            <img src={greencheck} style={{ paddingRight: "4px" }}></img>
                                                            <p>{t('earn.claimed')}</p>
                                                        </button>} */}
                                                    </div>
                                                    <div className="Ponit_status" style={{color:"#707070",paddingTop:"5px"}}><p>Expire in: {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(data.periodEndDate),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(data.periodEndDate),
                                                "dd MMM yyyy"
                                              )}</p> </div>
                                                    <div className="getPointPopup">
                                                        <div
                                                            id="exampleModal"
                                                            // tabindex="-1"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true"
                                                            className="modal fade"
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header"></div>
                                                                    <div className="modal-body">
                                                                        <h5> 🎉 Congrats! </h5>
                                                                        <p> You got {data.points} Points </p>
                                                                    </div>
                                                                    <div className="modal-footer"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* {scanNow && <section className="container-fluid login section footerEndSp ScanEarn" style={{paddingLeft:"0px",paddingRight:"0px"}} >
                                <img
                                        src={scanNow}    
                                            alt=""
                                            width="inherit"
                                            style={{width:"100%",maxWidth:"100%"}}
                                        />
                                <button className="btn bg-dark text-white p-1 px-3 my-2"  style={{ position: "relative", top: "-50px",marginLeft:"10px" }} onClick={()=>navigate("/scanqr")}>
                                    {t('earn.scan_now')}
                                </button>
                            </section>} */}

                {scanNow && 
                <section className="container-fluid login section footerEndSp ScanEarn" style={{paddingLeft:"0px",paddingRight:"0px"}} >
                    <h5 className="boldhHeadingH5" style={{paddingBottom:"15px"}}> {t('earn.scan_more_earn_more')} </h5>
                <div
                    id="scan-to-earn-banner"
                    style={{
                      backgroundImage: `url('${scanNow}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: containerHeight && containerHeight!== 0 ? containerHeight :"200px",
                      padding:"7px"
                    }}
                    className="bg-pink cool-section  d-flex justify-content-space"
                  >
                    <div className="img-text-area get_it_now">
                      {/* <h3 className="fw-bold">
                            {t('mainhome.free_cool_tech')}<sup className="fs-6 text">{t('mainhome.tm')}</sup>
                        </h3> */}
                      <div >
                        {/* <p className="fw-medium white-font">
                                                    {t("mainhome.just_easily")}
                                                </p> */}
                                <button className="btn bg-dark text-white p-1 px-3 my-2" onClick={()=>navigate("/scanqr")}>
                                    {t('earn.scan_now')}
                                </button>
                      </div>
                    </div>
                    {/* <img className='hidden' src={Complete} alt="" width="50%" /> */}
                  </div>
                  </section>
                  }
                        </div>
                    </section>
                    <span className="earnPageModal">
                        <div className="modal fade come-from-modal right" id="exampleModal_qr" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <QRCode value={localStorage.getItem('mobile')} size={128}></QRCode>
                                        <p className="qr-code-digit">{localStorage.getItem("mobile")?.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ")}</p>
                                        {/* <p className="qr-code-time">{currentTime.toLocaleTimeString([], { hour12: false })}</p> */}
                                    </div>
                                    <div className="modal-footer">
                                        <h6>{t('mainhome.scan_to_get_points')}</h6>
                                        <img className="pb-2 earmModalImg" src={close} alt=""
                                            data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                    <app-footer></app-footer>
                </section>}
            <Footer></Footer>
        </>
    );
};

export default Earn;
