/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./myCoupon.scss";
// import card from "../../assets/images/Re-2.jpg";
// import discountimg_20 from "../../assets/images/Re-11.jpg";
// import Gqbag from "../../assets/images/Re-12.jpg";
// import airpond_img from "../../assets/images/Re-9.jpg";
import back_Arrow from "../../assets/images/backarrow.svg";
import humburger_whiteIcon from "../../assets/images/earnhumburger.svg";
// import CustomerApiService from "../../utils/CustomerApiService";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getRewardVoucher } from "../../redux/slices/Rewards/GetRewardVoucher";
import { resetAllData } from "../../utils/resetActions";
import { resetRewardsVouchersData } from "../../redux/slices/Rewards/GetRewardVoucher";
import CmsDataService from "../../utils/CmsDataService";
import { useCms } from "../../utils/cmsContext";
const MyCoupon = () => {
  const { cmsData, updateCmsData } = useCms();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [marvelrewards, setMarvelrewards] = useState([]);
  const [birthdayRewards, setBirthdayRewards] = useState([])
  const [hasCoupons, setHasCoupons] = useState(false);
  const navigate = useNavigate();
  const handleClick = (vouchers) => {
    if (vouchers) {
      localStorage.setItem("couponCode", vouchers?.code);
      localStorage.setItem("rewardId", vouchers?.rewardId!== null ? vouchers?.rewardId : vouchers?.seriesId);
      navigate("/qr-code");
    }
  };
  const { rewardVouchersData, rewardvouchersuccess, loading } = useSelector(
    (state) => state.getRewardVoucher
  );
  useEffect(() => {
    if (rewardvouchersuccess == false) {
      dispatch(getRewardVoucher());
    } else if (rewardVouchersData?.status?.code == 401) {
      resetAllData(dispatch);
      let lang=localStorage.getItem("i18nextLng")
      let line_mobile=localStorage.getItem("line_mobile")
          let customerId=localStorage.getItem("customerId")
      let lineId = localStorage.getItem('lineId');
      localStorage.clear();
      localStorage.setItem("i18nextLng", lang);
      localStorage.setItem("line_mobile", line_mobile);
          localStorage.setItem("customerId",customerId);
      localStorage.setItem('lineId', lineId);
      navigate(`/${process.env.REACT_APP_redirectionPage}`);
    }
    setMarvelrewards(rewardVouchersData?.userVouchers);
    // (async () => {
    //     const marvelRewardsapi = await CustomerApiService.getmyRewards();
    //     if (marvelRewardsapi.status.success == true) {

    //         setMarvelrewards(marvelRewardsapi.userVouchers)
    //     } else if (marvelRewardsapi.status.code == 401 || marvelRewardsapi.status.code == 403) {
    //         localStorage.clear();
    //         localStorage.setItem("i18nextLng", process.env.REACT_APP_defaultLanguage);
    //         navigate(`/${process.env.REACT_APP_redirectionPage}`);
    //     }
    //     else {
    //         console.log("network error")
    //     }
    // })()
  }, [rewardVouchersData]);


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // if (true) {
          
        // } else 
        if (rewardVouchersData?.status?.code == 401) {
          resetAllData(dispatch);
          localStorage.clear();
          localStorage.setItem("i18nextLng", process.env.REACT_APP_defaultLanguage);
          navigate(`/${process.env.REACT_APP_redirectionPage}`);
        }else{
          dispatch(getRewardVoucher());
        }
        setMarvelrewards(rewardVouchersData?.userVouchers);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      dispatch(resetRewardsVouchersData());
    };
  }, []);
  useEffect(() => {
    (async () => {
        if (
            cmsData.language !== localStorage.getItem("i18nextLng") ||
            cmsData?.birthday_tierup_rewards?.length == 0
        ) {
            const rewardsData = await CmsDataService.getCmsData("birthday_tierup_rewards");
            if (rewardsData?.status == 200) {
                updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["birthday_tierup_rewards"]: rewardsData?.data,
                    ["language"]: localStorage.getItem("i18nextLng"),
                }));
            }
        }
        
    })();
}, [])
useEffect(() => {
  if (cmsData?.birthday_tierup_rewards?.length > 0)
    setBirthdayRewards(
      cmsData?.birthday_tierup_rewards
  );
  
}, [cmsData])
  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress />
          <div> Loading....</div>
        </div>
      ) : (
        <section className="mycoupon">
          <div className="container">
            <div className="row align-items-center border-bottom plr-15">
              <div className="col-1" style={{paddingLeft:"17px"}}>
                <div className="backpageSection">
                  <Link to="/reward">
                    <img src={back_Arrow} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col">
                <h5 className="nav_heading text-center">
                  {t("rewards.my_coupon")}
                </h5>
              </div>
              <div className="col-1 text-end ">
                <Link to="/coupon-history" className="">
                  <img
                    src={humburger_whiteIcon}
                    alt=""
                    className="humburger-icon"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="coupon-card container footerEndSp">
            <div className="d-flex flex-wrap reward">
              {marvelrewards?.length > 0
                ? marvelrewards?.map((item) => {
                    if (item.vouchers.length > 0 ) {
                      return item?.vouchers?.map((vouchers, VouchersIndex) => {
                        if (
                          vouchers.redemptions?.redemption == null &&
                          new Date() < new Date(vouchers?.expiry)
                        ) {
                          if (!hasCoupons) setHasCoupons(true);
                          return (
                            <div
                              key={`vouchers${VouchersIndex}`}
                              className="reward-sec-col"
                            >
                              <div
                                className="image-box"
                                onClick={() => handleClick(vouchers)}
                              >
                                <div className="img-tag" style={{ border: "0px solid var(--lightgray)" ,borderBottom: "0px", borderRadius:"4px 4px 0px 0px"}}>
                                  <img
                                    src={item.rewardDetails !== null ? item.rewardDetails?.thumbnailUrl : birthdayRewards?.length>0 ? birthdayRewards.find(item => item.seriesid == vouchers.seriesId)?.rewardimage:""}
                                    alt={item.rewardDetails?.name}
                                    title={item.rewardDetails?.name}
                                    width="100%"
                                    style={{
                                      height: "100%",
                                      // minHeight: "10rem",
                                    }}
                                  />

                                  {item?.rewardDetails?.label?.length > 0 && (
                                    <div className="status-div d-flex flex-nowrap reward_button_store ">
                                      <button
                                        type="button"
                                        className="btn btn-outline "
                                        style={{ marginLeft: 0, top: "5px",maxWidth:"-webkit-fill-available" }}
                                      >
                                        {item?.rewardDetails?.label}
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="img-box-text my-counpn-card" style={{maxHeight:"170px",display: "flex", flexDirection: "column",justifyContent: "space-between",minHeight: "170px"}}>
                                  <div className="reward-text">
                                    {" "}
                                    {item.rewardDetails !== null ? item.rewardDetails?.name : vouchers.seriesName}
                                  </div>
                                  <div className="points store_channel">
                                    
                                      {item.rewardDetails?.tier?.length > 0
                                        ? item?.rewardDetails?.tier
                                            .split(",")
                                            .map((tierName, id) => {
                                              return (
                                                <div key={`tierName${id}`} className="status-div d-flex flex-nowrap reward_button_store highest">
                                                <button
                                                  
                                                  type="button"
                                                  className="btn btn-outline"
                                                  style={{ marginLeft: 0 }}
                                                >
                                                  {tierName}
                                                </button>
                                                </div>
                                              );
                                            })
                                        : ""}
                                    <div className="status-div d-flex flex-nowrap reward_button_store">
                                      {/* {item.categoryList.map((categoryList) => (
                                            <div className='categoryName' key={categoryList.id}>{categoryList.name}</div>
                                      ))} */}
                                    </div>
                                    <div className="date">
                                      <p>
                                        {t("mainhome.expire_in")}{" "}
                                        <span>
                                          {" "}
                                          {localStorage.getItem("i18nextLng") ==
                                          "th"
                                            ? format(
                                                new Date(vouchers.expiry),
                                                "dd MMM yyyy",
                                                { locale: th }
                                              )
                                            : format(
                                                new Date(vouchers.expiry),
                                                "dd MMM yyyy"
                                              )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      });
                    }
                  })
                : ""}
            </div>
            {!hasCoupons && (
              <div className="no_data">{t("point_history.no_data")}</div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default MyCoupon;
