import React, { useEffect } from "react";
import Back from "../../assets/images/backarrow.svg";
import Closed from "../../assets/images/Icons/closed.svg";
import GQLogo from "../../assets/images/GQ-Member-Club-Black-Text.png";
// import BarCodeImg from "../../assets/images/barcode.png";
// import QRCodeImg from "../../assets/images/qr-code.svg";
// import fs from 'fs';
// import jsonData from '../../text.html';
import axios from 'axios';
import './qrCode.scss'
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useCms } from "../../utils/cmsContext";
import CmsDataService from "../../utils/CmsDataService";
import CustomerApiService from "../../utils/CustomerApiService";
import { CircularProgress } from "@mui/material";
import copy from 'copy-to-clipboard';
const QrCode = () => {


    const { t } = useTranslation();
    const { cmsData, updateCmsData } = useCms();
    const data = localStorage.getItem("couponCode") ? localStorage.getItem("couponCode") : "123456"
    const rewardId = localStorage.getItem("rewardId") ? localStorage.getItem("rewardId") : null
    const [redeemRewardsOnWebData, setRedeemRewardsOnWebData] = useState([]);
    const [redirectionUrl, setRedirectionUrl] = useState(null);
    const [homepageuicontrolData, setHomepageuicontrolData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const handleCopyClick = async () => {
        const textArea = document.getElementById('myInput');
        try {
            if (textArea) {
                copy(textArea.value)
                // const data = await navigator.clipboard.writeText(textArea.value);
                toast.success(t('refer.text_copied'),{toastId:"copyCodeSuccess"})
            } else {
                toast.error("Unable to copy to clipboard",{toastId:"copyCodeError"})
                console.error('Textarea element not found');
            }
        } catch (e) {
            toast.error("Unable to copy to clipboard",{toastId:"copyCodeError"})
        }
    }
    const handleNavigate = (data) => {
        if(data.length>0)
        navigate(data)
    }
    const singleSignOn= async ()=>{
        
        let arr=redirectionUrl.split("com");

       try{
        setLoading(true)
        const data = await CustomerApiService.singleSignOn(
            { phone:localStorage.getItem("mobile"), current_link:arr[1]}
          );
          if(data?.code==200 && data?.url){
            console.log("if",data.url)
            setLoading(false)
            //    setRedirectionUrl(data.url)
            // var windowReference = window.open("about:blank","_blank");
            // windowReference.location = data.url;
            let link = document.getElementById('link');
        link.setAttribute('href', data.url);
        // link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noreferer');
        link.click();
            // window.open(data.url, "_blank")
          }else{
            console.log("else")
            setLoading(false)
            toast.error(data.error,{toastId:"ssoError"})
          }
       }
       catch(e){
        setLoading(false)
        console.log(e)
        toast.error(e,{toastId:"ssoError"})
       }

    }
    const handleBack = () => {
        localStorage.removeItem("rewardId");
        localStorage.removeItem("couponCode")
        if (localStorage.getItem("redeemedReward") && (localStorage.getItem("redeemedReward") == true || localStorage.getItem("redeemedReward") == "true")) {
            localStorage.removeItem("redeemedReward")
            navigate("/reward")
        }
        else {
            navigate(-1)
        }
    }

    // const getRedirectionUrl = () => {
    //     if (rewardId && redeemRewardsOnWebData.length>0) {
    //         console.log(typeof rewardId)
    //         // const data = redeemRewardsOnWebData.find((item) => {item?.rewardid == rewardId})
    //         redeemRewardsOnWebData.forEach(item=>console.log(typeof item.rewardId))
    //         // console.log(data)
    //         if (data) {
    //             setRedirectionUrl(data?.redirecturl)
    //         }
    //     }
    // }

    useEffect(() => {
        (async () => {
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData.redeemRewardsOnWeb.length == 0
            ) {
                const redeemRewardsOnWebData = await CmsDataService.getCmsData("redeemonweb");
                if (redeemRewardsOnWebData?.status == 200) {
                    console.log(redeemRewardsOnWebData.data)
                    updateCmsData((prevValues) => ({
                        ...prevValues,
                        ["redeemRewardsOnWeb"]: redeemRewardsOnWebData?.data,
                        ["language"]: localStorage.getItem("i18nextLng"),
                    }));
                }
            }
            if (
                cmsData.language !== localStorage.getItem("i18nextLng") ||
                cmsData.homepageuicontrolData.length == 0
              ) {
                const homepageuiData = await CmsDataService.getCmsData(
                  "homepageuicontrol"
                );
                if (homepageuiData?.status == 200) {
                  updateCmsData((prevValues) => ({
                    ...prevValues,
                    ["homepageuicontrolData"]: homepageuiData?.data,
                    ["language"]: localStorage.getItem("i18nextLng"),
                  }));
                }
              }
        })();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    useEffect(() => {
        setRedeemRewardsOnWebData(cmsData?.redeemRewardsOnWeb);
        setHomepageuicontrolData(cmsData?.homepageuicontrolData);
        // getRedirectionUrl()
        if (rewardId && cmsData?.redeemRewardsOnWeb?.length>0) {
            console.log(typeof rewardId)
            const data = cmsData?.redeemRewardsOnWeb?.find((item) => item?.rewardid == rewardId)
            // cmsData?.redeemRewardsOnWeb?.forEach(item=>console.log(typeof item.rewardId))
            console.log(data)
            if (data) {
                setRedirectionUrl(data?.redirecturl)
            }
        }
    }, [cmsData])

    return (
        <>
            <section className="container-fluid qr-code bg-black" >
                <div className="row align-items-center plr-15">
                    <div className="col-2 text-center">
                        <div className="backpageSection" onClick={handleBack}>
                            <img src={Back} alt="" className="invertimage" />
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="boldhHeadingH5 text-center">{t('qr_code.scan_qr')}</h5>
                    </div>
                    <div className="col-2 cross" onClick={handleBack}>
                        <img src={Closed} className="invertimage" alt="" />
                    </div>
                </div>
                <div className="bg-white border-container">
                    <div className="gqlogoimage">
                        {" "}
                        <img
                    src={GQLogo}
                    alt="logo"
                    // width="150"
                  />{" "}
                    </div>
                    <div className="barcode">
                        {" "}
                        <Barcode value={data} width={4.2} height={65}></Barcode>
                    </div>
                    <div className="copycontent">
                        <input type="text" readOnly value={data} id="myInput" />
                        <button onClick={handleCopyClick}>{t('qr_code.copy')}</button>
                    </div>
                    <div className="grcodeimage">
                        {" "}
                        <QRCode value={data} size={128}></QRCode>
                    </div>
                </div>
                {redirectionUrl !== null ? 
                //singleSignOn() window.open(redirectionUrl, "_blank")
                <div  className="redeem-text">
                    <a onClick={() => singleSignOn()}  id="link2" style={{width:"230px"}}> {t('qr_code.redeem_on_website')}</a>
                    <a   id="link" style={{width:"230px",display:"none",cursor:"pointer"}}> {t('qr_code.redeem_on_website')}</a>
                </div>:  <div  className="redeem-text-hidden">
                    <p style={{border:"0px"}}></p>
                </div>}
                <div className="redeem-text" >
                    <p onClick={() => handleNavigate("/store-locator")} style={{width:"230px"}}>  {t('qr_code.search_nearby_shop')}</p>
                </div>

                <div style={{ height: "185px" }}></div>
            </section>
            {loading?
        <div className="loading-overlay" style={{backgroundColor:"transparent"}}>
          <div className="blurred-background" style={{backgroundColor:"transparent"}}></div>
          <CircularProgress />
        </div>
        :""
      }
        </>
    );
};

export default QrCode;
