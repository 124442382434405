/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/AxiosService";
const getPointHistory = createAsyncThunk(
    "customer/getpointhis",
    async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_gatewayURL}points/history?format=json`, {
                headers: {
                    cap_authorization: localStorage.getItem("token"),
                    cap_identifier_value: localStorage.getItem("mobile"),
                }
            })
            return data.data
        } catch (e) {
            console.log(e)
        }
    }
)
const initialState = {
    loading: false,
    pointDataHis: {},
    Pointsuccess: false
}
const getPointHistorySlice = createSlice({
    name: "getPoint",
    initialState,
    reducers: {
        resetPointHistory: (state) => {
            state.pointDataHis = {};
            state.Pointsuccess = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPointHistory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPointHistory.fulfilled, (state, action) => {
            state.pointDataHis = action.payload;
            state.loading = false;
            state.Pointsuccess = true
        });
        builder.addCase(getPointHistory.rejected, (state) => {
            state.loading = false;
        });
    }
})
export { getPointHistory }
export const { resetPointHistory } = getPointHistorySlice.actions;
export default getPointHistorySlice.reducer;